.desktop-footer() {
  .region-footer-first {
    .footer-support-ask {
      h3 {
        margin-bottom: 0;
      }
      p {
        padding-top: 1.25rem;
        margin-top: 0;
      }
    }
    .region-footer-first-inner {
      display: flex;
      justify-content: space-between;
      .footer-support-ask {
        z-index: 2;
        width: 70%;
        padding-right: 3rem;
        text-align: left;
      }
      .footer-support-link {
        z-index: 2;
        margin: auto 0;
      }
    }
    .background {
      position: relative;
      &:before {
        .abs-0();

        z-index: 0;
        content: ' ';
        background: @brand-secondary;
        opacity: .9;

        mix-blend-mode: multiply;
      }
    }
  }

  .region-footer-second-inner {
    display: flex;
    align-items: center;
    .region-blockgroup-footer-row-1 {
      flex: 1 100%;
      order: 2;
      justify-content: flex-end;
    }
    .region-blockgroup-footer-row-2 {
      flex: 1 100%;
      order: 1;
    }
  }
  .region-blockgroup-footer-row-1-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .footer-menu {
      flex: 1 0 100%;
      .menu-block-wrapper {
        .menu {
          .unbullet();

          display: flex;
          width: 100%;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;

           > li {
            max-width: 300px;
            margin-left: 1.25rem;
            flex: 0 1 auto;
          }
        }
      }
    }
    .footer-social {
      flex: 1 0 100%;
      .content {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;

         > a {
          height: 1.5rem;
          margin: 1.25rem;
          flex: 0 1 auto;
        }
      }
    }
    .footer-pba-link {
      text-align: right;
      flex: 1 0 100%;
      .content .bell-link {
        justify-content: flex-end;
      }
    }
  }

  .region-blockgroup-footer-row-2-inner {
    .footer-signature {
      max-width: 450px;
      img {
        // max-height: 77px;
        // For 60th anniversary logo (PBFSD-178):
        max-height: 100px;
      }
    }
  }
}

.tablet-footer() {
  .region-footer-first {
    .footer-support-ask {
      h3 {
        margin-bottom: 0;
      }
      p {
        padding-top: 1.25rem;
        padding-bottom: 2.25rem;
        margin-top: 0;
      }
    }
  }

  .region-blockgroup-footer-row-1-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .footer-menu {
      flex: 0 1 80%;
      .menu-block-wrapper {
        .menu {
          .unbullet();

          margin: 0;
        }
         > .menu {
          .unbullet();

          display: flex;
          width: 100%;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-around;

           > li {
            max-width: 300px;
            flex: 0 1 auto;
          }
        }
      }
    }
    .footer-social {
      flex: 0 1 20%;
      .content {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;

         > a {
          height: 1.5rem;
          margin-left: 1.25rem;
          flex: 0 1 auto;
        }
      }
    }
    .footer-pba-link {
      margin: 1rem 0;
      flex: 1 0 100%;
    }
  }

  .region-blockgroup-footer-row-2-inner {
    text-align: center;

    .footer-social {
      margin-bottom: 3.5rem;

      .content {
        display: flex;
        max-width: 300px;
        margin: 0 auto;
        align-items: center;
        justify-content: space-around;
      }
      .icon {
        margin: 0 10px;
      }
    }

    .footer-signature {
      max-width: 450px;
      img {
        // max-height: 77px;
        // For 60th anniversary logo (PBFSD-178):
        max-height: 100px;
      }
    }

    .footer-legal {
      .content > *:first-child {
        margin-bottom: .875rem;
      }
    }
  }
}

.mobile-footer() {
  .menu-block-wrapper {
    .menu {
      .unbullet();

      margin: 0;
    }
  }
  .menu-block-wrapper > .menu > li > .menu {
    display: none;
  }

  .region-footer-first {
    .block > .content > * {
      text-align: center;
    }
    .footer-support-ask {
      h3 {
        margin-bottom: 0;
      }
      p {
        padding-top: 1.25rem;
        padding-bottom: 2.25rem;
        margin-top: 0;
      }
    }
  }

  .region-blockgroup-footer-row-1-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .footer-menu {
      flex: 1 0 100%;
      .menu-block-wrapper {
        .menu {
          .unbullet();

          display: flex;
          width: 100%;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-around;

           > li {
            max-width: 300px;
            flex: 0 1 auto;
          }
        }
      }
    }
    .footer-social {
      flex: 1 0 100%;
      .content {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;

         > a {
          height: 1.5rem;
          margin: 1.25rem;
          flex: 0 1 auto;
        }
      }
    }
    .footer-pba-link {
      margin-bottom: 1.25rem;
      flex: 1 0 100%;
    }
  }

  .footer-contact,
  .footer-address {
    margin-bottom: 2.85rem;
  }

  .region-blockgroup-footer-row-2-inner {
    text-align: center;

    .footer-social {
      margin-bottom: 3.5rem;

      .content {
        display: flex;
        max-width: 300px;
        margin: 0 auto;
        align-items: center;
        justify-content: space-around;
      }
      .icon {
        margin: 0 10px;
      }
    }

    .footer-legal {
      .content > *:first-child {
        margin-bottom: .875rem;
      }
    }
  }
  .footer-signature {
    .content {
      position: relative;
      display: inline-block;
      padding-bottom: 2rem;
      vertical-align: middle;

      img {
        .trans();

        position: relative;
        z-index: 1;
        width: auto;
        height: 77px;
        opacity: 0;
      }

      &:before {
        .abs-0();
        .trans();

        z-index: 0;
        width: 100%;
        height: 80px;
        content: ' ';
        background-color: transparent;
        background-image: url(../images/icons/PBF_Tertiary_White_Mobile.svg);
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
      }
    }
  }
}


// Enables Sticky Footer (From: SEPA-407)
// ================================
.section-content {
  min-height: calc(~"100vh - 500px");
  // .resp(@tabletmax, {
  //   min-height: calc(~"100vh - 460px");
  // });
}


.section-footer {
  .resp-min(@tabletmax + 1, {
    .desktop-footer();
  });
  .resp-range(@mobilemax + 1, @tabletmax, {
    .tablet-footer();
  });
  .resp(@mobilemax, {
    .mobile-footer();
  });

  color: @white;
  background-color: @blue;
  * {
    color: inherit;
  }

  .region-footer-first {
    text-align: center;
    .block > .content h4 {
      line-height: 1.4;
      color: @white;
    }
  }

  .footer-subscribe {
    .subscribe-wrapper {
       > .manual-form {
        #subscribe-form {
          input[type="text"] {
            padding: 0.625rem 1.3rem 0.5rem;
            font-weight: 600;
          }

          input[type="submit"] {
            min-width: 100px;
            padding: 0.45rem 1em 0.45rem;
            color: @white;
            background-color: @button-color;
            border-width: 3px;

            &:hover {
              background-color: @button-cta-active;
              border-color: @button-cta-active;
            }
          }
        }
      }
    }
  }

  .footer-social {
    .icon {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      img {
        .trans();

        position: relative;
        z-index: 1;
        width: auto;
        height: 1.5rem;
        opacity: 1;
      }

      &:before {
        .abs-0();
        .trans();

        z-index: 0;
        content: ' ';
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 0;
      }
      &.facebook-link:before {
        background-image: @facebook-reversed--red;
      }
      &.twitter-link:before {
        background-image: @twitter-share--red;
      }
      &.linkedin-link:before {
        background-image: @linkedin--red;
      }

      &:hover {
        img {
          opacity: 0;
        }
        &:before {
          opacity: 1;
        }
        &:active {
          opacity: .5;
        }
      }
    }
  }

  .footer-copyright * {
    .fine-print();
  }


  .region-footer-second {
    h4 {
      .footer-block-title();
      .resp(@mobilemax, {
        margin-bottom: 1.25rem;
      });

      line-height: 1.4;
    }
  }
  .menu-block-wrapper {
    .menu a {
      .footer-list-item();
    }
     > .menu > li > a {
      .footer-block-title();
    }
  }

  .footer-pba-link {
    .content .bell-link {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      &.icon img {
        padding-right: 0.625rem;
      }
      .PhilBar {
        .h6;

        color: @white;
      }
    }
  }
}
