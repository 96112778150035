.cle-special {
  .content {
    columns: 2;
    .form-item:last-of-type {
      // stabilize the layout by modifying the element in the second column
      margin-top: -1em;
      margin-bottom: 2em;
    }
  }
}

// .page-cart,
// .page-user-cle,
// .page-checkout {
//   table {
//     border: 0px none transparent;
//     margin-bottom: 1rem;
//     th {
//       color: @darkgrey !important;
//       text-transform: uppercase;
//       font-size: 1rem;
//     }
//     th,
//     td {
//       border: 0px none transparent !important;
//       padding: .5rem 0 .5rem 0 !important;
//       background-color: @white !important;
//       text-align: left !important;
//       vertical-align: top;
//       line-height: 1.2;
//       &:last-of-type {
//         text-align: right !important;
//       }
//       &.cle-class-info {
//         .title {
//         }
//         .description {
//           color: @grey;
//           font-size: 1rem;
//         }
//       }
//     }
//     tbody tr {
//       border-bottom: 1px solid @lightgrey;
//       &:last-of-type,
//       &.component-type-base-price,
//       &.component-type-member-price,
//       &.component-type-commerce-price-formatted-amount {
//         border-bottom: 0px none;
//       }
//     }
//     .field-session-date {
//       color: @grey;
//       font-size: 1rem;
//       .date-display-range {
//         display: inline !important;
//       }
//     }
//     .delete-line-item {
//       margin: .5rem 0 1rem 0;
//     }
//   }
//   .commerce-order-handler-area-order-total {
//     border-top: 1px solid @lightgrey;
//     padding-top: 2rem;
//     & + a {
//       position: relative;
//       bottom: 5.25rem;
//     }
//   }
//   #edit-checkout {
//     margin: 1rem 0 2rem;
//   }
//
//   .commerce_fieldgroup_pane__group_cle_questionnaire,
//   .commerce_payment {
//     background-color: @neutrallight;
//     padding: 2.1rem 2rem;
//     legend {
//       margin-bottom: 1rem;
//     }
//     .fieldset-wrapper {
//       columns: 2;
//       @media (max-width: @goldilocks) {
//         columns: 1;
//       }
//       label {
//         display: block;
//         color: @darkgrey !important;
//         text-transform: uppercase;
//         font-size: 1rem;
//       }
//       #edit-account-suffix {
//         color: @grey;
//         font-size: 1rem;
//         font-style: italic;
//       }
//       .form-item-commerce-fieldgroup-pane--group-cle-questionnaire-field-cle-question-und-select {
//         margin-bottom: 5rem;
//         @media (max-width: @goldilocks) {
//           margin-bottom: 0rem;
//         }
//       }
//       .form-item-commerce-fieldgroup-pane--group-cle-questionnaire-field-cle-question-und-other {
//         padding-top: .1px; // weird Safari positioning fix
//       }
//     }
//   }
//
//   .account #account-login-container {
//
//   }
//
//   .customer_profile_billing {
//     margin-top: 0;
//     .fieldset-wrapper {
//       padding: 0rem 2rem 2.1rem;
//     }
//   }
//
//   .commerce_payment .fieldset-wrapper {
//     columns: 1;
//   }
//
//   .checkout-continue {
//     margin-right: .5rem;
//   }
// }

#commerce-checkout-form-account {
  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    #edit-help {
      flex-basis: 100%;
    }
    .form-wrapper {
      flex-basis: ~"calc(50% - 2rem)";
      margin: 0;
      @media (max-width: @goldilocks) {
        flex-basis: 100%;
      }
      legend {
        margin-bottom: 1rem;
      }
    }
    #edit-account-form {
      padding: 2.1rem 0;
      #edit-account-form-select {
        background-color: white;
      }
    }
    .checkout-buttons {
      padding: 2.1rem 2rem;
      background-color: @neutrallight;
    }
  }
}

// Had to pull the stuff below out of the LESS stack,
// because I can't find a more precise targeter than
// .logged-in and .not-logged-in.

body.logged-in.page-checkout .account {
  background-color: @neutrallight;
  padding: 2.1rem 2rem;
  legend {
    margin-bottom: 1rem;
  }
  .fieldset-wrapper {
    columns: 2;
    label {
      display: block;
      color: @darkgrey !important;
      text-transform: uppercase;
      font-size: 1rem;
    }
    #edit-account-suffix {
      color: @grey;
      font-size: 1rem;
      font-style: italic;
    }
    .form-item-commerce-fieldgroup-pane--group-cle-questionnaire-field-cle-question-und-select {
      margin-bottom: 5rem;
    }
    .form-item-commerce-fieldgroup-pane--group-cle-questionnaire-field-cle-question-und-other {
      padding-top: .1px; // weird Safari positioning fix
    }
  }
}

body.not-logged-in.page-checkout {
  .commerce-order-handler-area-order-total + a {
    bottom: 3rem;
  }
  .account {
    background-color: @neutrallight;
    padding: 2.1rem 2rem 0;
    legend {
      margin-bottom: 1rem;
    }
  }
}

.page-checkout .checkout_review {
  table.checkout-review {
    display: block;
    width: 100%;
    tbody,
    tr,
    td {
      display: block;
      width: 100%;
      &:empty {
        display: none;
      }
      h3 {
        margin-top: 1.125rem;
        text-transform: none;
        letter-spacing: 0;
        font-size: 1.125rem;
        margin-bottom: .2rem;
      }
      .commerce-order-handler-area-order-total + a {
        bottom: 0;
      }
      &.pane-title {
        font-size: 1.25rem;
      }
      &.pane-data {
        padding-bottom: 1rem;
      }
      &:last-of-type {
        text-align: left !important;
      }
      label {
        display: block;
        font-size: 1.125rem !important;
        color: @darkgrey !important;
        font-weight: bold !important;
      }
    }
    .view-commerce-cart-summary {
      td {
        display: table-cell;
        .commerce-price-formatted-components {
          margin-left: 0;
          td {
            display: block;
            padding: 0;
          }
        }
      }
    }
    .commerce-price-formatted-components {
      .component-total,
      td:last-of-type {
        min-width: 100px;
        text-align: right !important;
      }
    }
  }
}
