/// Toggles

.mobileMainMenuToggle {
  .resp(@tabletmax, {
    #ma-toggle > .hamburger-menu(@with-x: false);
  });

  .resp-min(@mobilemax + 1, {
    .text { width: 28px; }
  });
}

.toggleSwitch {
  padding: 10px 14px;
  width: 70px; // Was 50px
  text-align: center;
  display: inline-block;
  &.isClosed {

  }
  // .resp(@mobilemax, {
  //   margin-top: 14px;
  // });
}

.mainSearchToggle {
  .text {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    padding: 0;
    padding-top: 5px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: @search--white;
    width: 40px;
    position: relative;
    text-indent: -9999px;
    display: block;

    &:hover {
      background-image: @search--red;
    }

    &:before,
    &:after {
      content: ' ';
      position: absolute;
      display: block;
      top: 50%;
      left: 10px;
      right: 10px;
      height: 3px;
      width: 20px;
      background-color: transparent;
      z-index: 10;
      .t-y(~"calc(-50% - .5px)");
      .trans;
    }
    // IE bug
    &:after {
      .t-rot(-45deg);
    }
    &:before {
      .t-rot(45deg);
    }

    &#search-helper .menu-item-inner:before {
      content: none;
    }
  }
  &.isOpen .text {
    background-image: none;
    &:before,
    &:after {
      background-color: @brand-secondary;
    }
    &:before {
      .t-y(~"calc(-50% - .5px)");
      .t-rot(45deg);
    }
    &:after {
      .t-y(~"calc(-50% - .5px)");
      .t-rot(-45deg);
    }
  }

  .resp(@tabletmax, {
    display: none;
  });
}


.togglePane.hasToggle {
  &.mainMenuPane {
    .resp(@tabletmax, {
      #ma-toggle > .pane();
      position: absolute;
      z-index: 40;
    });
  }

  &.tlAccordion {
    .resp(@tabletmax, {
      #ma-toggle > .pane();
    });
  }

  &.mainSearchPane {
    .resp(@tabletmax, {
      height: auto;
      transform: none;
      opacity: 1;
    });
    // .resp(@mobilemax, {
    //   #ma-toggle > .pane();
    // });
  }

  &.tabAccordion {
    // See: /
    .resp(@baby_bear, {
      #ma-toggle > .pane();
    });
  }
}

.hasAccordionSwitch {
  #ma-toggle > .accordionSwitch();
}
.accordionPane.hasToggle {
  #ma-toggle > .pane();
  padding: 1.5rem 20px;
  background-color: @background;
}

// Hides toggle on tab/mobile for modified header
.not-front {
  &.page-events-register,
  &.page-checkout,
  &.page-donate {
    .toggleSwitch {
      .hide-me-always;
    }
  }

}
