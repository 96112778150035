
// Icons
// ================================
@iconsdir: '../images/icons/';

// Search Icon
@search: url("@{iconsdir}search-slate.svg");
@search--grey: url("@{iconsdir}search--grey.svg");
@search--red: url("@{iconsdir}search-red.svg");
@search--teal: url("@{iconsdir}search--teal.svg");
@search--white: url("@{iconsdir}search-white.svg");


// Dropdown Arrow
@dropdown-arrow: url("@{iconsdir}dropdown-arrow.svg");
@dropdown-arrow--white: url("@{iconsdir}dropdown-arrow--white.svg");
@dropdown-arrow--gray: url("@{iconsdir}dropdown-arrow--gray.svg");
@dropdown-arrow--brightblue: url("@{iconsdir}dropdown-arrow--brightblue.svg");
@dropdown-arrow--red: url("@{iconsdir}dropdown-arrow--red.svg");
@dropdown-arrow--yellow: url("@{iconsdir}dropdown-arrow--yellow.svg");
@dropdown-arrow--navy-fade: url("@{iconsdir}dropdown-arrow--navy-fade.svg");
@dropdown-arrow--white: url("@{iconsdir}dropdown-arrow--white.svg");
@link-arrow--blue: url("@{iconsdir}link--arrow-blue.svg");

// Arrow icons

@arrow-left: url("@{iconsdir}arrow-left.svg");
@arrow-left--gray: url("@{iconsdir}arrow-left--gray.svg");
@arrow-left--white: url("@{iconsdir}arrow-left--white.svg");
@arrow-left--navy: url("@{iconsdir}arrow-left--navy.svg");
@arrow-left--red: url("@{iconsdir}arrow-left--red.svg");
@arrow-left--mediumblue: url("@{iconsdir}arrow-left--mediumblue.svg");
@arrow-left--violet: url("@{iconsdir}arrow-left--violet.svg");

@arrow-right: url("@{iconsdir}arrow-right.svg");
@arrow-right--gray: url("@{iconsdir}arrow-right--gray.svg");
@arrow-right--teal: url("@{iconsdir}arrow-right--teal.svg");
@arrow-right--white: url("@{iconsdir}arrow-right--white.svg");
@arrow-right--navy: url("@{iconsdir}arrow-right--navy.svg");
@arrow-right--red: url("@{iconsdir}arrow-right--red.svg");
@arrow-right--mediumblue: url("@{iconsdir}arrow-right--mediumblue.svg");
@arrow-right--violet: url("@{iconsdir}arrow-right--violet.svg");

@arrow-up: url("@{iconsdir}arrow-up.svg");
@arrow-up--gray: url("@{iconsdir}arrow-up--gray.svg");
@arrow-up--white: url("@{iconsdir}arrow-up--white.svg");
@arrow-up--navy: url("@{iconsdir}arrow-up--navy.svg");
@arrow-up--red: url("@{iconsdir}arrow-up--red.svg");
@arrow-up--mediumblue: url("@{iconsdir}arrow-up--mediumblue.svg");
@arrow-up--violet: url("@{iconsdir}arrow-up--violet.svg");

@arrow-down: url("@{iconsdir}arrow-down.svg");
@arrow-down--gray: url("@{iconsdir}arrow-down--gray.svg");
@arrow-down--white: url("@{iconsdir}arrow-down--white.svg");
@arrow-down--navy: url("@{iconsdir}arrow-down--navy.svg");
@arrow-down--red: url("@{iconsdir}arrow-down--red.svg");
@arrow-down--mediumblue: url("@{iconsdir}arrow-down--mediumblue.svg");
@arrow-down--violet: url("@{iconsdir}arrow-down--violet.svg");

// Pagers
@pager-left: url("@{iconsdir}pager-left.svg");
@pager-right: url("@{iconsdir}pager-right.svg");
@pager-left-blue: url("@{iconsdir}pager-left-blue.svg");
@pager-right-blue: url("@{iconsdir}pager-right-blue.svg");
@pager-left-red: url("@{iconsdir}pager-left-red.svg");
@pager-right-red: url("@{iconsdir}pager-right-red.svg");

// To Top
@back-to-top: url("@{iconsdir}back-to-top.svg");


// Checkbox X
@small-x: url("@{iconsdir}small-x.svg");
@small-x--white: url("@{iconsdir}small-x--white.svg");
@small-x--green: url("@{iconsdir}small-x--green.svg");
@small-x--orange: url("@{iconsdir}small-x--orange.svg");
@small-x--brightblue: url("@{iconsdir}small-x--brightblue.svg");
@small-x--red: url("@{iconsdir}small-x--red.svg");
@small-x--yellow: url("@{iconsdir}small-x--yellow.svg");

@close-x: @small-x;
@close--x: url("@{iconsdir}close--x.svg");

// Radio Dot
@radio-dot: url("@{iconsdir}radio-dot.svg");
@radio-dot--brightblue: url("@{iconsdir}radio-dot--brightblue.svg");
@radio-dot--red: url("@{iconsdir}radio-dot--red.svg");
@radio-dot--teal: url("@{iconsdir}radio-dot--teal.svg");
@radio-dot--yellow: url("@{iconsdir}radio-dot--yellow.svg");


// Small Square
@small-square: url("@{iconsdir}small-square.svg");
@small-square--red: url("@{iconsdir}small-square--red.svg");

// Checkmark
@checkmark--white: url("@{iconsdir}checkmark--white.svg");

// Triple Dots
@triple-dots: url("@{iconsdir}triple-dots.svg");
@triple-dots--white: url("@{iconsdir}triple-dots--white.svg");
@triple-dots--navy: url("@{iconsdir}triple-dots--navy.svg");
@triple-dots--navy-fade: url("@{iconsdir}triple-dots--navy-fade.svg");
@triple-dots--green: url("@{iconsdir}triple-dots--green.svg");
@triple-dots--orange: url("@{iconsdir}triple-dots--orange.svg");

// Pager Chevrons
@double-chevron-left: url("@{iconsdir}double-chevron-left.svg");
@double-chevron-left--gray: url("@{iconsdir}double-chevron-left--gray.svg");
@double-chevron-left--white: url("@{iconsdir}double-chevron-left--white.svg");
@double-chevron-left--green: url("@{iconsdir}double-chevron-left--green.svg");
@double-chevron-left--orange: url("@{iconsdir}double-chevron-left--orange.svg");

@chevron-left: url("@{iconsdir}chevron-left.svg");
@chevron-left--gray: url("@{iconsdir}chevron-left--gray.svg");
@chevron-left--white: url("@{iconsdir}chevron-left--white.svg");
@chevron-left--green: url("@{iconsdir}chevron-left--green.svg");
@chevron-left--orange: url("@{iconsdir}chevron-left--orange.svg");
@chevron-left--navy: url("@{iconsdir}chevron-left--navy.svg");
@chevron-left--red: url("@{iconsdir}chevron-left--red.svg");
@chevron-left--brightblue: url("@{iconsdir}chevron-left--brightblue.svg");
@chevron-left--aqua: url("@{iconsdir}chevron-left--aqua.svg");
@chevron-left--yellow: url("@{iconsdir}chevron-left--yellow.svg");

@double-chevron-right: url("@{iconsdir}double-chevron-right.svg");
@double-chevron-right--gray: url("@{iconsdir}double-chevron-right--gray.svg");
@double-chevron-right--white: url("@{iconsdir}double-chevron-right--white.svg");
@double-chevron-right--green: url("@{iconsdir}double-chevron-right--green.svg");
@double-chevron-right--orange: url("@{iconsdir}double-chevron-right--orange.svg");

@chevron-right: url("@{iconsdir}chevron-right.svg");
@chevron-right--gray: url("@{iconsdir}chevron-right--gray.svg");
@chevron-right--white: url("@{iconsdir}chevron-right--white.svg");
@chevron-right--green: url("@{iconsdir}chevron-right--green.svg");
@chevron-right--orange: url("@{iconsdir}chevron-right--orange.svg");
@chevron-right--red: url("@{iconsdir}chevron-right--red.svg");
@chevron-right--yellow: url("@{iconsdir}chevron-right--yellow.svg");
@chevron-right--navy: url("@{iconsdir}chevron-right--navy.svg");
@chevron-right--brightblue: url("@{iconsdir}chevron-right--brightblue.svg");
@chevron-right--aqua: url("@{iconsdir}chevron-right--aqua.svg");


@chevron-up: url("@{iconsdir}chevron-up.svg");
@chevron-up--white: url("@{iconsdir}chevron-up--white.svg");
@chevron-up--red: url("@{iconsdir}chevron-up--red.svg");
@chevron-up--yellow: url("@{iconsdir}chevron-up--yellow.svg");
@chevron-up--brightblue: url("@{iconsdir}chevron-up--brightblue.svg");
@chevron-up--navy: url("@{iconsdir}chevron-up--navy.svg");

@chevron-down: url("@{iconsdir}chevron-down.svg");
@chevron-down--white: url("@{iconsdir}chevron-down--white.svg");
@chevron-down--red: url("@{iconsdir}chevron-down--red.svg");
@chevron-down--yellow: url("@{iconsdir}chevron-down--yellow.svg");
@chevron-down--brightblue: url("@{iconsdir}chevron-down--brightblue.svg");
@chevron-down--navy: url("@{iconsdir}chevron-down--navy.svg");



// Misc Icons
@bookmark--red: url("@{iconsdir}bookmark--red.svg");
@bookmark-hollow--red: url("@{iconsdir}bookmark-hollow--red.svg");

@briefcase--white: url("@{iconsdir}briefcase--white.svg");
@briefcase--green: url("@{iconsdir}briefcase--green.svg");
@briefcase--orange: url("@{iconsdir}briefcase--orange.svg");
@briefcase: url("@{iconsdir}briefcase.svg");

@bulb--white: url("@{iconsdir}bulb--white.svg");
@bulb--green: url("@{iconsdir}bulb--green.svg");
@bulb--orange: url("@{iconsdir}bulb--orange.svg");
@bulb: url("@{iconsdir}bulb.svg");

@calendar: url("@{iconsdir}calendar.svg");
@calendar--white: url("@{iconsdir}calendar--white.svg");
@calendar--yellow: url("@{iconsdir}calendar--yellow.svg");
@calendar--mediumblue: url("@{iconsdir}calendar--mediumblue.svg");
@calendar--red: url("@{iconsdir}calendar--red.svg");
@calendar--navy: url("@{iconsdir}calendar--navy.svg");
@calendar--navy-fade: url("@{iconsdir}calendar--navy-fade.svg");
@calendar--gold: url("@{iconsdir}calendar-gold.svg");

@cart--blue: url("@{iconsdir}cart--blue.svg");
@cart--white: url("@{iconsdir}cart--white.svg");

@case--white: url("@{iconsdir}case--white.svg");
@case--navy: url("@{iconsdir}case--navy.svg");
@case--navy-fade: url("@{iconsdir}case--navy-fade.svg");

@chat--white: url("@{iconsdir}chat--white.svg");
@chat--navy: url("@{iconsdir}chat--navy.svg");
@chat--navy-fade: url("@{iconsdir}chat--navy-fade.svg");

@convo: url("@{iconsdir}convo.svg");

@clipboard: url("@{iconsdir}clipboard.svg");
@clipboard--white: url("@{iconsdir}clipboard--white.svg");
@clipboard--yellow: url("@{iconsdir}clipboard--yellow.svg");
@clipboard--mediumblue: url("@{iconsdir}clipboard--mediumblue.svg");
@clipboard--red: url("@{iconsdir}clipboard--red.svg");
@clipboard--navy: url("@{iconsdir}clipboard--navy.svg");

@chart--navy-fade: url("@{iconsdir}chart--navy-fade.svg");

@clock--white: url("@{iconsdir}clock--white.svg");
@clock--green: url("@{iconsdir}clock--green.svg");
@clock--orange: url("@{iconsdir}clock--orange.svg");
@clock--navy-fade: url("@{iconsdir}clock--navy-fade.svg");
@clock: url("@{iconsdir}clock.svg");

@office: url("@{iconsdir}office-rect.svg");

@dollar: url("@{iconsdir}dollar.svg");
@dollar--white: url("@{iconsdir}dollar--white.svg");
@dollar-sign: url("@{iconsdir}dollar-sign.svg");
@dollar-sign--white: url("@{iconsdir}dollar-sign--white.svg");
@dollar-sign--red: url("@{iconsdir}dollar-sign--red.svg");
@dollar-sign--yellow: url("@{iconsdir}dollar-sign--yellow.svg");
@dollar-sign--brightblue: url("@{iconsdir}dollar-sign--brightblue.svg");
@dollar-sign--mediumblue: url("@{iconsdir}dollar-sign--mediumblue.svg");
@dollar-sign--navy: url("@{iconsdir}dollar-sign--yellow.navy");

@droplet--white: url("@{iconsdir}droplet--white.svg");
@droplet--green: url("@{iconsdir}droplet--green.svg");
@droplet--orange: url("@{iconsdir}droplet--orange.svg");
@droplet--blue: url("@{iconsdir}droplet--blue.svg");
@droplet: url("@{iconsdir}droplet.svg");

@download-file--gray: url("@{iconsdir}download-file--gray.svg");
@download-blue: url("@{iconsdir}download--blue.svg");
@get--directions-blue: url("@{iconsdir}get--directions-blue.svg");

@envelope--yellow: url("@{iconsdir}envelope--yellow.svg");
@envelope--navy-fade: url("@{iconsdir}envelope--navy-fade.svg");

@facebook-reversed--white: url("@{iconsdir}facebook-reversed--white.svg");
@facebook-reversed--aqua: url("@{iconsdir}facebook-reversed--aqua.svg");
@facebook-reversed--navy-fade: url("@{iconsdir}facebook-reversed--navy-fade.svg");
@facebook-reversed--green: url("@{iconsdir}facebook-reversed--green.svg");
@facebook-reversed--orange: url("@{iconsdir}facebook-reversed--orange.svg");
@facebook-reversed--red: url("@{iconsdir}facebook-reversed--red.svg");
@facebook-reversed: url("@{iconsdir}facebook-reversed.svg");

@facebook-share--white: url("@{iconsdir}facebook-share--white.svg");
@facebook-share--green: url("@{iconsdir}facebook-share--green.svg");
@facebook-share--orange: url("@{iconsdir}facebook-share--orange.svg");
@facebook-share: url("@{iconsdir}facebook-share.svg");

@fax-machine--yellow: url("@{iconsdir}fax-machine--yellow.svg");

@female--white: url("@{iconsdir}female-symbol--white.svg");
@female--black: url("@{iconsdir}female-symbol--black.svg");
@female--orange: url("@{iconsdir}female-symbol--orange.svg");

@flag--white: url("@{iconsdir}flag--white.svg");
@flag--green: url("@{iconsdir}flag--green.svg");
@flag--orange: url("@{iconsdir}flag--orange.svg");
@flag: url("@{iconsdir}flag.svg");

@gavel--white: url("@{iconsdir}gavel--white.svg");
@gavel--green: url("@{iconsdir}gavel--green.svg");
@gavel--orange: url("@{iconsdir}gavel--orange.svg");
@gavel: url("@{iconsdir}gavel.svg");

@globe--white: url("@{iconsdir}globe--white.svg");
@globe--green: url("@{iconsdir}globe--green.svg");
@globe--orange: url("@{iconsdir}globe--orange.svg");
@globe: url("@{iconsdir}globe.svg");

@grad-cap--white: url("@{iconsdir}grad-cap--white.svg");
@grad-cap--navy: url("@{iconsdir}grad-cap--navy.svg");
@grad-cap--navy-fade: url("@{iconsdir}grad-cap--navy-fade.svg");

@heads--white: url("@{iconsdir}heads--white.svg");
@heads--green: url("@{iconsdir}heads--green.svg");
@heads--orange: url("@{iconsdir}heads--orange.svg");
@heads: url("@{iconsdir}heads.svg");

@hourglass--white: url("@{iconsdir}hourglass--white.svg");
@hourglass--green: url("@{iconsdir}hourglass--green.svg");
@hourglass--orange: url("@{iconsdir}hourglass--orange.svg");
@hourglass--gray: url("@{iconsdir}hourglass--gray.svg");
@hourglass: url("@{iconsdir}hourglass.svg");

@house: url("@{iconsdir}house.svg");
@house--white: url("@{iconsdir}house--white.svg");
@house--red: url("@{iconsdir}house--red.svg");
@house--yellow: url("@{iconsdir}house--yellow.svg");
@house--mediumblue: url("@{iconsdir}house--mediumblue.svg");
@house--navy: url("@{iconsdir}house--navy.svg");

@instagram--white: url("@{iconsdir}instagram--white.svg");
@instagram--navy-fade: url("@{iconsdir}instagram--navy-fade.svg");

@leaf--white: url("@{iconsdir}leaf--white.svg");
@leaf--green: url("@{iconsdir}leaf--green.svg");
@leaf--orange: url("@{iconsdir}leaf--orange.svg");
@leaf: url("@{iconsdir}leaf.svg");

@lightbulb--navy-fade: url("@{iconsdir}lightbulb--navy-fade.svg");

@link--white: url("@{iconsdir}link--white.svg");
@link--green: url("@{iconsdir}link--green.svg");
@link--orange: url("@{iconsdir}link--orange.svg");
@link--gray: url("@{iconsdir}link--gray.svg");
@link: url("@{iconsdir}link.svg");

// LinkedIn
@linkedin--red: url("@{iconsdir}iconmonstr-linkedin--red.svg");

@link-external--gray: url("@{iconsdir}link-external--gray.svg");

@linkedin: url("@{iconsdir}linkedin.svg");
@linkedin--white: url("@{iconsdir}linkedin--white.svg");

@location--pin: url("@{iconsdir}location-pin-gold.svg");

@list--white: url("@{iconsdir}list--white.svg");
@list--green: url("@{iconsdir}list--green.svg");
@list--orange: url("@{iconsdir}list--orange.svg");
@list: url("@{iconsdir}list.svg");

@mail--white: url("@{iconsdir}mail--white.svg");
@mail--green: url("@{iconsdir}mail--green.svg");
@mail--orange: url("@{iconsdir}mail--orange.svg");
@mail--gray: url("@{iconsdir}mail--gray.svg");
@mail--blue: url("@{iconsdir}mail--blue.svg");
@mail--gold: url("@{iconsdir}mail--gold.svg");
@mail: url("@{iconsdir}mail.svg");

@magnifying-glass--white: url("@{iconsdir}magnifying-glass--white.svg");
@magnifying-glass--green: url("@{iconsdir}magnifying-glass--green.svg");
@magnifying-glass--orange: url("@{iconsdir}magnifying-glass--orange.svg");
@magnifying-glass: url("@{iconsdir}magnifying-glass.svg");

@map-pin: url("@{iconsdir}map-pin--yellow.svg");
@map-pin--yellow: url("@{iconsdir}map-pin--yellow.svg");

@people: url("@{iconsdir}people.svg");
@people--white: url("@{iconsdir}people--white.svg");
@people--red: url("@{iconsdir}people--red.svg");
@people--yellow: url("@{iconsdir}people--yellow.svg");
@people--mediumblue: url("@{iconsdir}people--mediumblue.svg");
@people--navy: url("@{iconsdir}people--navy.svg");

@phone: url("@{iconsdir}phone--yellow.svg");
@phone--yellow: url("@{iconsdir}phone--yellow.svg");
@phone--navy: url("@{iconsdir}phone--navy.svg");
@phone--navy-fade: url("@{iconsdir}phone--navy-fade.svg");
@phone--gray: url("@{iconsdir}phone--gray.svg");
@phone--blue: url("@{iconsdir}phone--blue.svg");
@phone--gold: url("@{iconsdir}phone--gold.svg");

@plus--white: url("@{iconsdir}plus--white.svg");
@plus--green: url("@{iconsdir}plus--green.svg");
@plus--orange: url("@{iconsdir}plus--orange.svg");
@plus: url("@{iconsdir}plus.svg");

@rss--white: url("@{iconsdir}rss--white.svg");
@rss--aqua: url("@{iconsdir}rss--aqua.svg");

@scales--white: url("@{iconsdir}scales--white.svg");
@scales--green: url("@{iconsdir}scales--green.svg");
@scales--orange: url("@{iconsdir}scales--orange.svg");
@scales: url("@{iconsdir}scales.svg");

@share-facebook--teal: url("@{iconsdir}share-facebook--teal.svg");
@share-facebook--red: url("@{iconsdir}share-facebook--red.svg");
@share-facebook--grey: url("@{iconsdir}share-facebook--grey.svg");
@share-facebook: url("@{iconsdir}share-facebook.svg");

@share-mail--teal: url("@{iconsdir}share-mail--teal.svg");
@share-mail--red: url("@{iconsdir}share-mail--red.svg");
@share-mail--grey: url("@{iconsdir}share-mail--grey.svg");
@share-email: url("@{iconsdir}share-email.svg");

@share-twitter--teal: url("@{iconsdir}share-twitter--teal.svg");
@share-twitter--red: url("@{iconsdir}share-twitter--red.svg");
@share-twitter--grey: url("@{iconsdir}share-twitter--grey.svg");
@share-twitter: url("@{iconsdir}share-twitter.svg");

@share-linkedin: url("@{iconsdir}share-linkedin.svg");

@share_plus--white: url("@{iconsdir}share_plus--white.svg");
@share_plus--green: url("@{iconsdir}share_plus--green.svg");
@share_plus--orange: url("@{iconsdir}share_plus--orange.svg");
@share_plus: url("@{iconsdir}share_plus.svg");

@speech: url("@{iconsdir}speech.svg");
@speech--white: url("@{iconsdir}speech--white.svg");
@speech--red: url("@{iconsdir}speech--red.svg");
@speech--yellow: url("@{iconsdir}speech--yellow.svg");
@speech--mediumblue: url("@{iconsdir}speech--mediumblue.svg");
@speech--navy: url("@{iconsdir}speech--navy.svg");

@speech-bubble-dots--aqua: url("@{iconsdir}speech-bubble-dots--aqua.svg");
@speech-bubble-dots--blue: url("@{iconsdir}speech-bubble-dots--blue.svg");
@speech-bubble-dots--white: url("@{iconsdir}speech-bubble-dots--white.svg");
@speech-bubble-dots--grey: url("@{iconsdir}speech-bubble-dots--grey.svg");
@speech-bubble-dots--gray: @speech-bubble-dots--grey;

@star--white: url("@{iconsdir}star--white.svg");
@star--green: url("@{iconsdir}star--green.svg");
@star--orange: url("@{iconsdir}star--orange.svg");
@star--yellow: url("@{iconsdir}star--yellow.svg");
@star: url("@{iconsdir}star.svg");

@sun: url("@{iconsdir}sun--white.svg");
@sun--white: url("@{iconsdir}sun--white.svg");
@sun--navy: url("@{iconsdir}sun--navy.svg");
@sun--navy-fade: url("@{iconsdir}sun--navy-fade.svg");

@ticket: url("@{iconsdir}ticket.svg");
@ticket--white: url("@{iconsdir}ticket--white.svg");
@ticket--yellow: url("@{iconsdir}ticket--yellow.svg");
@ticket--mediumblue: url("@{iconsdir}ticket--mediumblue.svg");
@ticket--red: url("@{iconsdir}ticket--red.svg");
@ticket--navy: url("@{iconsdir}ticket--navy.svg");

@twitter-share--white: url("@{iconsdir}twitter-share--white.svg");
@twitter-share--green: url("@{iconsdir}twitter-share--green.svg");
@twitter-share--orange: url("@{iconsdir}twitter-share--orange.svg");
@twitter-share--red: url("@{iconsdir}twitter-share--red.svg");
@twitter-share: url("@{iconsdir}twitter-share.svg");

@twitterbird--white: url("@{iconsdir}twitterbird--white.svg");
@twitterbird--aqua: url("@{iconsdir}twitterbird--aqua.svg");
@twitterbird--green: url("@{iconsdir}twitterbird--green.svg");
@twitterbird--orange: url("@{iconsdir}twitterbird--orange.svg");
@twitterbird: url("@{iconsdir}twitterbird.svg");

@question-mark--white: url("@{iconsdir}question-mark--white.svg");
@question-mark--green: url("@{iconsdir}question-mark--green.svg");
@question-mark--orange: url("@{iconsdir}question-mark--orange.svg");
@question-mark: url("@{iconsdir}question-mark.svg");

@youtube--white: url("@{iconsdir}youtube--white.svg");
@youtube--navy-fade: url("@{iconsdir}youtube--navy-fade.svg");

@youtube-wordmark--white: url("@{iconsdir}youtube-wordmark--white.svg");
@youtube-wordmark--aqua: url("@{iconsdir}youtube-wordmark--aqua.svg");



.remove-inline-icon() {
  padding-left: 0;
  &:before {
    content: none;
  }
}
