// Inits
// ================================

/* --- [ Variables and Mixins ] --------------- */
@import "_fonts";
@import "_MQs";
@import "_colors";
@import "_icons";
@import "_utils";
@import "_keyframes";

@import "_ma_th";


// Base
// ================================
@import "partials/_base";
@import "partials/tables";

// Components
// ================================

// Standard Pager Styling
//@import 'components/pager';
@import 'components/carousel';


/* --- [ Behaviors ] --------------- */
@import "partials/behaviors/toggles";
@import "partials/behaviors/overlay-effects";


/* --- [ Forms ] --------------- */
@import "partials/forms";
@import "partials/components/search-field";
@import "partials/views-filters";
@import "partials/chosen";
@import "partials/components/dropdown-filters";
@import "partials/components/date-picker";

@import "partials/components/donate";
@import "partials/components/node-type-webform";
@import "partials/components/membership";
@import "partials/components/request";
@import "partials/components/databases";
@import "partials/components/user";
@import "partials/components/login";
@import "partials/components/checkout";

@import "partials/components/commerce";


@import "partials/components/masquerade";

/* --- [ Owl Carousel ] --------------- */
@import (inline) "vendor/owl.carousel.css";
@import (inline) "vendor/owl.theme.default.css";

// Layout
// ================================

.region-inner,
.zone-preface,
.zone-content {
  margin: 0 auto;
  max-width: 100%;
  max-width: 1266px;
  //.front & { max-width: 1400px; }
}

.region-content-inner {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column; // possibly meant to position pager at bottom
  max-width: 100%;

  flex-direction: row;

  > * {
    width: 100%;
    max-width: 100%; // for IE
  }

  .block-guide-pager,
  .block[id*="guide-pager"] {
    align-self: flex-end;
    width: 100%;
  }
}

// Mixin for horizonatal padding
@side-space: 2.063rem; // 33px
@tablet-side-space: 1.75rem; // 28px
@mobile-side-space: 0.934rem; // 15px

.side-space(@sides: @side-space; @rest...) {
  & when (length(@arguments) = 1) and (length(@sides) = 1) {
    padding-left: @sides;
    padding-right: @sides;
  }
  & when (length(@arguments) = 1) and (length(@sides) > 1) {
    padding-left: extract(@sides, 1);
    padding-right: extract(@sides, 2);
  }
  & when (length(@arguments) > 1) and (length(@sides) > 1) {
    padding-left: extract(@sides, 1);
    padding-right: extract(@sides, 2);
  }
  & when (length(@arguments) > 1) and (length(@sides) = 1) {
    padding-left: @sides;
    padding-right: @rest;
  }
}

// Mixin for vertical padding
@head-space: 3rem, 3.25rem; // 48px 52px;
@tablet-head-space: 3.125rem, 3.75rem; // 26px
@mobile-head-space: 2.25rem; // 36px

.head-space(@ends: @head-space; @rest...) {
  & when (length(@arguments) = 1) and (length(@ends) = 1) {
    padding-top: @ends;
    padding-bottom: @ends;
  }
  & when (length(@arguments) = 1) and (length(@ends) > 1) {
    padding-top: extract(@ends, 1);
    padding-bottom: extract(@ends, 2);
  }
  & when (length(@arguments) > 1) and (length(@ends) > 1) {
    padding-top: extract(@ends, 1);
    padding-bottom: extract(@ends, 2);
  }
  & when (length(@arguments) > 1) and (length(@ends) = 1) {
    padding-top: @ends;
    padding-bottom: @rest;
  }
}

// Give these things default left/right padding
.region-postscript-second-inner {
  .side-space(@side-space);
  .resp(@tabletmax, { .side-space(@tablet-side-space); });
  .resp(@mobilemax, { .side-space(@mobile-side-space); });
}

.region-preface-first,
.region-preface-second,
.zone-content, /* special */
.region-user-first-inner /* Emergency Banner */ {
  .side-space(@side-space);
  .resp(@tabletmax, { .side-space(@tablet-side-space); });
  .resp(@mobilemax, { .side-space(@mobile-side-space); });
}
.not-front {
  &.node-type-landing-page {
    .region-preface-first-inner {
      max-width: 1200px;
      padding: 1.125rem 5rem 1.813rem;
      .resp(@tabletmax, {
        padding: 1.313rem 4rem 2.188rem;
      });
      .resp(@mobilemax, {
        padding: 0.875rem 0.938rem 1.5rem;
      });
    }
    .region-branding-inner {
      .resp(@tabletmax, {
        padding-bottom: 4.25rem;
      });
      .resp(@mobilemax, {
        padding-bottom: 3.5rem;
      });
    }
  }
  &.node-type-listing-page {
    .region-preface-first-inner {
      max-width: 1200px;
      padding: 2rem 5rem 1.813rem;
      .resp(@tabletmax, {
        padding: 2rem 4rem 1.188rem;
      });
      .resp(@mobilemax, {
        padding: 0.875rem 0.938rem 0.3rem;
      });
      .field-subheading {
        .h3;
        padding-top: 1rem;
        padding-bottom: 2rem;
        .resp(@mobilemax, {
          .h3--small;
        });
      }
    }
  }
  &.node-type-event {
    .region-preface-first-inner {
      max-width: 1200px;
      padding: 2.125rem 5rem 0;
      .block-event-tabs {
        margin: 0 -5rem;
      }
      .resp(@tabletmax, {
        padding: 1.313rem 4rem 0;
        .block-event-tabs {
          margin: 0 -4rem;
        }
      });
      .resp(@mobilemax, {
        padding: 0.875rem 0.938rem 0;
        .block-event-tabs {
          margin: 0 -0.938rem;
        }
      });
    }
    .region-branding-inner {
      .resp(@tabletmax, {
        padding-bottom: 4.25rem;
      });
      .resp(@mobilemax, {
        padding-bottom: 3.5rem;
      });
    }
    .field-event-date-display {
      .field-contents {
        display: inline;
        .icon-before(@calendar--gold, 1.75rem);
        &:before {
          top: -5px;
          left: 10px;
        }
        .resp(@goldilocks, {
          .icon-above(@calendar--gold);
        });
      }
    }
  }
  &.page-taxonomy-term,
  &.page-user,
  &.page-search-node,
  &.node-type-webform,
  &.node-type-page {
    .region-preface-first-inner {
      max-width: 1200px;
      padding: 2.5rem 0rem 1.813rem;
      .resp(@tabletmax, {
        padding: 2rem 0;
      });
      .resp(@mobilemax, {
        padding: 0.875rem 0.938rem 1.5rem;
      });
    }
  }
  &.node-type-grantee-profile,
  &.node-type-person-profile,
  &.node-type-news-post,
  &.page-events-register,
  &.page-checkout,
  &.page-donate {
    .region-preface-first-inner {
      max-width: 1200px;
      padding: 4.6rem 0rem 3rem;
      .resp(@tabletmax, {
        padding: 2rem 0;
      });
      .resp(@mobilemax, {
        padding: 1.6rem 0.938rem 1.5rem;
      });
    }
  }
}

// Emergency Banner
.region-user-first-inner {
  .head-space(.95rem, 1.05rem);
}

.zone-content {
  .head-space(0, 4.525rem);
  .resp(@mobilemax, { .head-space(0, 3.225rem); });
  // See: / (Become a Member, Continuing Legal Education)
  .front & {
    .head-space(3.025rem, 2.625rem);
    .resp(@tabletmax, { .head-space(2.675rem, 3.025rem); });
    .resp(@mobilemax, { .head-space(@mobile-head-space, 2.625rem); });
  }
}

.region-postscript-second-inner {
  // See: / (From the Blog)
  .head-space(2.5rem, 3.65rem);
  .resp(@tabletmax, {
    .head-space(2.35rem, 3.7rem);
    .side-space(3.425rem);
  });
  .resp(@mobilemax, {
    .head-space(1.35rem, 3.05rem);
    .side-space(1.2rem);
  });
}

.region-footer-first .region-footer-first-inner {
  // See: (Footer)
  .side-space(1.5rem);
  .head-space(2.75rem, 3.75rem); // 18px 20px

  .resp(@tabletmax, {
    .head-space(2.125rem, 2.563rem); // 34px 41px
  });
  .resp(@mobilemax, {
    .side-space(.5rem);
    .head-space(1.688rem, 2.438rem); // 27px 39px
  });
}

.region-footer-second-inner {
  // See: (Footer)
  .head-space(1rem);
  .side-space(1.5rem);
  .resp(@tabletmax, {
    .side-space(4.75rem); // 76px
    .head-space(1.575rem, 2.0625rem);
    max-width: 637px;
  });
  .resp(@mobilemax, {
    .head-space(1.85rem);
    padding-top: 1rem;
    .side-space(.5rem);
  });
}



/* --- [ Layout things ] --------------- */
@import "partials/layout/layout-reset";
@import "partials/layout/grids";


@import "partials/layout/search-results";



// Components
// ================================

/* --- [ Aside Items ] --------------- */
@import "partials/components/aside-items";

/* --- [ Share links ] --------------- */
@import "partials/components/share-links";

/* --- [ View Modes ] --------------- */
@import "partials/layout/view-modes";

/* --- [ Site Status ] --------------- */
@import "partials/components/site-status";

/* --- [ Cookie Banner ] --------------- */
@import "partials/components/cookie-banner";



// Blocks
// ================================

@import "partials/layout/blocks";


// Views
// ================================

@import "partials/layout/views";



// Standard Pager Styling
@import 'partials/components/pager';

@import "partials/components/progress";

@import "partials/components/flexslider";




@import "partials/header.less";
@import "partials/components/main-nav";
@import "partials/components/block-search-form";
@import "partials/components/logo";

@import "partials/components/tabs.less";

@import "partials/components/back-to-top";

@import "partials/footer.less";


// Javascript (global.js)
// ================================

.js_hide {
  display: none !important;
}

.js_no-admin-margin {
  padding-top: 0 !important;
  &:before {
    .js_hide;
  }
}


// Hide me always
// ================================

.hide-me-always {
  display: none !important;
}
