
#backtotop {
  display: none;
  position: fixed;
  bottom: 20px;
  z-index: 1;
  width: 100%;

  .text {
    display: block;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    text-indent: -9999px;

    &:before {
      content: ' ';
      display: block;
      height: 45px;
      width: 45px;
      position: absolute;
      .abs(auto, 15px, 100%, auto);
      cursor: pointer;
      background-image: @back-to-top;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}
