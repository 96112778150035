.desktop-header() {
  .clearfix {
    &:before,
    &:after {
      content: none;
    }
  }

  .quick-menu {
    display: none;
  }

  .menu {
    .unbullet();
  }

  // Zone Branding
  .zone-branding-wrapper {
    .zone-branding {
      background: @brand-secondary;
      // .region-branding-page {
      //   padding: 0.625rem 0rem;
      // }

      .banner-image {
        height: 400px;
      }
      .region-branding-homepage {
        height: 100%;
      }
      .background {
        background-size: cover;
        position: relative;
        &:before {
          content: " ";
          .abs-0();
          z-index: 0;
          // background: @brand-secondary;
          // opacity: 0.7;
          // For 60th anniversary logo (PBFSD-178):
          background: @black;
          opacity: 0.6;
          // -------------------------------
          mix-blend-mode: multiply;
        }
      }
      .ma_toggleSwitch {
        display: none;
      }
      .branding-center {
        padding: 4rem 11rem 4rem;
        z-index: 5;
      }
    }
  }
  .region-branding-inner {
    padding: 0 @side-space;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-basis: 100%;
    position: static;

    > * {
      flex: 0 1 auto;
      z-index: 2;
    }
  }
  .mobile-top-level-nav {
    display: none;
  }
  .top-level-nav {
    position: static;
    display: flex;

    .block-title {
      display: none;
    }

    > .content,
    .menu-block-wrapper,
    .menu-block-wrapper > .menu,
    .menu-block-wrapper > .menu > li,
    .tlAccSwitchWrap {
      display: inline-block;
    }

    > .content {
      &:before,
      &:after {
        content: none;
      }
    }

    .menu-block-wrapper > .menu > li > a,
    .menu-block-wrapper > .menu > li > .nolink,
    .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
    .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
    .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink {
      display: flex;
      align-items: center;
      flex: 0 1 auto;
      max-width: 100%;
      width: auto;
      height: 100%;
    }
  }

  .block-search.isClosed {
    display: none;
  }

  // Zone menu
  .zone-menu-wrapper {
    flex-basis: 100%;
    background-color: @contrast-shade-bg;
  }
  .region-menu-inner {
    display: flex;
    flex-wrap: nowrap;
    padding: 0 @side-space;
    position: relative;

    .block.donate-button {
      position: absolute;
      top: 70px;
      z-index: 7;
      margin: 0;
      margin-left: auto;
      right: 0;
      padding: 0 @side-space;
    }
    .block.block-search {
      flex-grow: 0;
      margin: 0;
      position: absolute;
      margin-left: auto;
      right: 0;
      padding: 0 2.063rem;
    }
    .ma_toggleSwitch {
      margin: 0;
      margin-left: auto;
      flex-grow: 0;
      width: 50px;
    }
  }

  .menu-block-wrapper > .menu > li {
    display: inline-block;
  }
}

.tablet-header() {
  background-color: @brand-accent;
  position: relative;

  .menu {
    .unbullet();
  }

  .zone-menu-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 100%;
    //padding: 0 28px;

    .block-menu-block {
      order: 2;
      margin-top: 3px;
      position: relative;

      ~ .block-menu-block {
        &:before {
          background-color: @white;
        }
      }

      &:first-child[aria-hidden] + .block-menu-block {
        &:before {
          background-color: transparent;
        }
      }
    }
    .block-search {
      order: 1;
    }
    .block.donate-button {
      order: 3;
      margin: 0 auto;
      padding-top: 0.5rem;
      padding-bottom: 3.125rem;
      text-align: center;
    }

    .menu-block-wrapper {
      position: relative;

      &.menu-name-main-menu {
        padding-top: 1rem;
      }

      > .menu > li {
        > a,
        > .nolink {
          .mobile-top-menu-link();
          font-weight: 400;
          padding: 0.5rem 27px;

          .menu-item-inner {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }

  // Zone Header
  .zone-branding-wrapper {
    order: initial;
    flex-basis: 100%;
    padding: 0;

    .region-inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      > * {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .branding-top {
        width: 100%;
        z-index: 41;
      }
      .branding-center {
        padding: 3.6rem 7rem 3.6rem;
      }
      > .block-logo {
        flex-basis: auto;
        padding-right: 14px;
        padding-left: 14px;
        width: 30%;
        > .content {
          width: 100%;
          height: 100%;
        }
      }
      > .mainSearchToggle {
        display: none;
      }
      > .block-mobile-search {
        flex: 1 0 0%;
        padding-right: 24px;
        padding-left: 24px;
      }
    }
  }
  .mobile-top-level-nav {
    display: none;
  }
  .top-level-nav {
    .block-title {
      display: none;
    }
  }
  .zone-branding-wrapper {
    background-color: @brand-accent;
    order: initial;
    padding: 0;
    .zone-branding {
      .banner-image {
        height: 350px;
      }
      .region-branding-homepage {
        height: 100%;
      }
      .background {
        background-size: cover;
        position: relative;
        &:before {
          content: " ";
          .abs-0();
          z-index: 0;
          background: @brand-secondary;
          opacity: 0.7;
          mix-blend-mode: multiply;
        }
      }
    }
  }
  .zone-menu-wrapper.isOpen + .zone-branding-wrapper {
    .background {
      background-size: 0;
    }
    .branding-top,
    .branding-center {
      background-color: @brand-accent;
    }
  }

  .region-branding-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    > * {
      flex: 1 1 0%;
      z-index: 2;
    }

    .top-level-nav {
      order: 10;
      width: 100%;
      background-color: @contrast-bg;
      flex: 1 0 100%;

      > .content,
      .menu-block-wrapper,
      .menu-block-wrapper > .menu,
      .tlAccSwitchWrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        // flex: 1 1 0%;
        margin: 0 auto;
      }

      > .content {
        &:before,
        &:after {
          content: none;
        }
      }

      .menu-block-wrapper > .menu > {
        justify-content: center;
      }

      .menu-block-wrapper > .menu > li {
        display: flex;
      }
    }
  }

  // Zone menu
  .zone-menu-wrapper {
    flex-basis: 100%;
    background-color: @brand-accent;
    .abs(91px, 0, auto, 0);
    z-index: 100;
    a {
      color: @white;
    }
  }
  .region-menu-inner {
    display: flex;
    flex-direction: column;
    padding: 3rem 5rem 0 5.625rem;
  }
  .menu-block-wrapper > .menu > li {
    display: inline-block;
  }
}

.mobile-header() {
  .tablet-header;
  .region-menu-inner {
    padding: 0rem 1rem;
  }
  .zone-menu-wrapper {
    top: 70px;
    padding-top: 20px;
    .menu-block-wrapper.menu-name-main-menu {
      padding-top: 0;
    }
    .block-menu-block {
      margin-top: 11px;
    }
  }
  .zone-branding-wrapper .region-inner .branding-center {
    padding: 4rem 1.9rem 4rem;
  }
}

.section-header {
  .resp-min(@tabletmax + 1, {
    .desktop-header();
  });

  .resp-range(@mobilemax + 1, @tabletmax, {
    .tablet-header();
  });

  .resp(@mobilemax, {
    .mobile-header();
  });
}
.front.node-type-homepage {
  // For 60th anniversary (PBFSD-178):
  #page-title {
    .sr-only();
  }
  .region-branding-inner .branding-center {
    background-image: url(../images/60th-anniversary-justice-ball-graphic-light.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center -50px;

    padding-top: 0;
    transform: translateY(-35px);
    width: 100%;
    height: 600px;
    .resp(@tabletmax, {
      height: 350px;
      background-position: center -35px;
    });
  }
  .field-box-icon {
    img {
      clip-path: inset(71px 0 0 0);
    }
  }
  // -------------------------------

  .section-header {
    .zone-branding-wrapper {
      .zone-branding {
        // height: 620px;
        // For 60th anniversary (PBFSD-178):
        height: 750px;
        // -------------------------------
        .resp-range(@mobilemax + 1, @tabletmax, {
          height: 550px
        });
        .resp(@mobilemax, {
          height: 450px;
        });
      }
    }
  }
}

// PBFSD-174 - One off banner image styling for Philly Justice Ball

.page-node-799 {
  .section-header {
    .zone-branding-wrapper {
      .zone-branding {
        .banner-image {
          min-height: 600px;

          .resp-range(@tabletmax + 1, @tabletmax, {
            min-height: 700px;
          });
          .resp-min(@tabletmax + 1, {
            min-height: 700px;
          });
        }

        .background {
          min-height: 600px;
          .resp-min(@tabletmax + 1, {
            min-height: 640px;
          });
        }
      }
    }
  }
}

#node-event-799.node-teaser {
  h2 {
    .sr-only();
  }
}
