
// Form Elements
// ================================

/* --- [ Variables ] --------------- */

@inputheight: 3rem; // IE and Firefox compat
@placeholder-line-height: 1.4;
@inputpadding: 10px;

@input-border-width: 2px;

@error-color: @brand-error;

@select-arrow: @arrow-down;

@input-checked-bg-color: @blue;

@checkbox-tick: @checkmark--white;
@radio-dot: @radio-dot--brightblue;

@label-color: @slate;

@item-margin: 1rem;

@inline-grid-padding: .75rem;


// utils
.__full-width {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__half-width {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__one-fifth-width {
  display: inline-block;
  vertical-align: top;
  width: 20%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__two-fifths-width {
  display: inline-block;
  vertical-align: top;
  width: 40%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__three-fifths-width {
  display: inline-block;
  vertical-align: top;
  width: 60%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__four-fifths-width {
  display: inline-block;
  vertical-align: top;
  width: 80%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__auto-width {
  display: inline-block;
  vertical-align: top;
  width: auto;
}
.__p-left {
  padding-left: @inline-grid-padding;
  // padding-right: 0;
}
.__p-right {
  // padding-left: 0;
  padding-right: @inline-grid-padding;
}
.__m-top {
  margin: 0;
  margin-top: 1rem;
}
.__m-top-double {
  margin: 0;
  margin-top: 2.5rem;
}
.__m-top-0 {
  margin-top: 0;
}

.hidden {
  display: none;
}


.__inputs-no-border() {
  textarea,
  select,
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  input[type="checkbox"],
  input[type="radio"] {
    border-color: @white;
    box-shadow: 0 6px 12px -5px rgba(0,0,0,.05);
  }
  input[type="checkbox"],
  input[type="radio"] {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  }
}

.__inputs-has-border() {
  textarea,
  select,
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  input[type="checkbox"],
  input[type="radio"] {
    border-color: @input-border-color;
    box-shadow: none;
  }
  input[type="checkbox"],
  input[type="radio"] {
    box-shadow: none;
  }
}


// Form Elements
// ================================

.input {
  &:extend(.p);
  margin-top: 0;
  line-height: 1;
  font-weight: normal;
  color: @neutraldark;

  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    opacity: .5;
    pointer-events: none;
  }

  .field() {
    box-sizing: border-box;
    height: @inputheight;
    width: 100%;
    appearance: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    border: @input-border-width solid @input-border-color;
    background-color: @input-bg-color;
    padding: @inputpadding;

    .placeholder({
      font: inherit;
      line-height: @placeholder-line-height;
      font-style: italic;
      color: inherit;
    });

    &.error {
      color: @error-color;
      border-color: @error-color;
    }
  }
}

textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
.form-item.bt-hosted-field /* See: braintree hosted fields */ {
  .input();.field();
}

.select-box(
  @select: native;
  @toggle: none;
  @pane: none;
  @padded-pane: false;
) {
  @padding-right: 3rem;

  @steez: {
    &:extend(.h6);
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    appearance: none;
    outline: none;
    border-radius: 0;
    box-shadow: none;
    border: @input-border-width solid @input-border-color;
    background-color: @input-bg-color;
  };

  & when (@select = native) and (@toggle = none) {
    @steez();
    padding: @inputpadding;
    padding-right: @padding-right;
    &::-ms-expand {
      display: none;
    }
  }
  & when (@toggle = none) and not (@select = native) {
    padding: @inputpadding;
    padding-right: @padding-right;
  }
  & when not (@toggle = none) {
    @{toggle} {
      @steez();
      > * {
        padding: @inputpadding;
        padding-right: @padding-right;
      }
    }
  }

  & when (@pane = none) and (@select = native) { @steez(); }
  & when not (@pane = none) {
    @{pane} {
      @steez();
      border-top: none;
      margin-top: -1 * (@input-border-width);
      & when (@padded-pane = true) {
        padding: @inputpadding;
      }
    }
  }

  & when (@select = native) {
    height: @inputheight;
    padding: @inputpadding/2 @inputpadding;
    line-height: 1.2;
  }
  & when not (@select = native) {
    min-height: @inputheight;
  }
  .icon() {
    background-image: @dropdown-arrow;
    background-size: auto .6rem;
    background-repeat: no-repeat;
    background-position: right 1rem top .95rem;
    cursor: pointer;

    & when not (@toggle = none) {
      width: @padding-right;
    }
  }
}

select {
  .select-box();
  .icon();
}

.tick-base(@background-color: @input-bg-color, @checked-background-color: @input-checked-bg-color, @left-pos: 0) {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 3px solid @neutrallight;
  background-color: transparent;
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: -10px;
  border-radius: 2px;
  margin-right: 0;

  .checked,
  &:checked {
    outline: none;
    box-shadow: none;
    background-color: @background;
  }

  .checkbox() {
    .checked(@background-color: @checked-background-color) {
      background-color: @background-color;
      border-color: @background-color;
      background-image: @checkbox-tick;
      border: none;
      width: 20px;
      height: 20px;
      background-size: contain;
    }
    width: 14px;
    height: 14px;
  }
  .radio() {
    border-radius: 100%;
    background-size: auto 75%;
    background-repeat: no-repeat;
    background-position: center;
    border-width: 2px;
    .checked(@background-color: @checked-background-color, @icon-color: true) {
      & when (@icon-color) { background-image: @radio-dot; }
      & when (@icon-color = @brand-primary) { background-image: @radio-dot--purple; }
      border-color: @background-color;
    }
  }

  &:focus,
  &:active {}
}

input[type="checkbox"] {
  .tick-base();
  .checkbox();
  &:checked {
    .checked();
  }
  .form-type-checkbox &,
  .form-type-radios & {
    position: absolute;
    margin-right: 0;
  }
}
input[type="radio"] {
  .tick-base();
  .radio();
  &:checked {
    .checked();
  }
  .form-type-checkbox &,
  .form-type-radios & {
    position: absolute;
    margin-right: 0;
  }
}

input[type="checkbox"] {
  .element-invisible();
  opacity: 0; // moz-appearance is broken for checkboxes, so we have to use another method.
  & + label {
    font-size: @base-font-size;
    margin-left: 3px;
    margin-right: 4px;
    position: relative;
    padding-left: 2rem;
    &:before {
      border-radius: 0;
      width: 1rem;
      height: 1rem;
      background-size: .75rem auto;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 2px;
      border: 1px solid @input-border-color;
      content: "";
      display: inline-block;
      margin-left: -1.35rem;
      margin-right: 4px;
      position: relative;
      // top: 4px;
      right: .3rem;
    }
  }
  &:checked + label:before {
    background-image: @checkmark--white;
    background-color: @brand-secondary;
    border-color: @brand-secondary;
  }
}
input[type="radio"] {
  .element-invisible();
  opacity: 0; // moz-appearance is broken for checkboxes, so we have to use another method.
  & + label {
    font-size: @base-font-size;
    margin-left: 3px;
    margin-right: 4px;
    position: relative;
    padding-left: 2rem;
    &:before {
      border-radius: 200rem;
      width: 1rem;
      height: 1rem;
      background-size: .75rem auto;
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid @medium-text-color;
      content: "";
      display: inline-block;
      margin-left: -1.35rem;
      margin-right: 4px;
      position: relative;
      //top: 4px;
      right: .3rem;
    }
  }
  &:checked + label:before {
    background-image: @radio-dot;
    background-color: transparent;
    border-color: @brand-secondary;
    box-shadow: 0px 0px 0px 1px @brand-secondary;
  }
}


input::-ms-check {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Good browsers :) */
  opacity: 0;
}

textarea {
  height: auto;
  line-height: @base-line-height;
}

input {
  &.password-confirm,
  &.password-field {
    margin-bottom: 0;
  }
}

input[type="email"] {}

input[type="submit"] {
  .button-cta();
}
input[type="submit"][id*="add-more"] {
  .button-cta();
}
input[type="submit"][id*="upload-button"] {
  .button-cta();
}
input[type="reset"] {
  .button-dim();
}
// input[type="submit"][id*="edit-submit"] {
//   .button();
// }
button {
  .button();
}

html.js input.form-autocomplete {
  background-position: right 10px center !important;
}

.form-item {
  margin-bottom: 1rem;

  .messages {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
}

.form-item-agreement {
  margin-top: 1em;
}

.form-item-combine {
  .icon-before(@search--grey);
  &:hover { .icon-before(@search--teal); }
  input { padding-right: 2.5em; }
}

.form-item-keys {
  label { display: none !important; }
  input {
    padding-right: 2.5em;
    font-size: @h5-size;
    background-size: 1rem 1rem;
    background-image: @search--grey;
    padding-right: 3rem;
    background-position: right 1rem center !important;
    background-repeat: no-repeat;

    &:hover {
      background-image: @search--teal;
    }
  }
}

div.form-item {
  .notification-bar(~'div.password-suggestions',  @severity: normal);
}

.container-inline {
  position: relative;

  > div {
    display: inline-block;
  }

  > .form-item {
    margin: 0;
  }
  > .form-submit {
    display: inline-block;
  }
}

legend {
  float: left;
  width: 100%;
  box-sizing: border-box;
  font-size: @base-font-size;
  line-height: @base-line-height;
  letter-spacing: normal;
  color: inherit;

  + * {
    clear: left;
    .clearly;
  }

  span {
    display: block;
    .h3;
    margin: 0;
    font-size: 14.5px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 2;
    letter-spacing: 0.03em;
    color: @white;

    a {
      display: block;
      &:link { color: inherit; }
      &:hover {
        text-decoration: none;
      }
    }

  }

}


fieldset {
  position: relative;
  padding: 0;
  border: none;
  margin: 0 auto;

  ~ fieldset,
  ~ [id*="ajax-wrapper"] {
    margin-top: 40px;
  }


  legend {
    float: left;
    width: 100%;

    + * {
      clear: left;
    }

    span {
      .block-title;
      display: block;
    }
  }
}


.collapsible {

  legend {
    background-color: @hr-color;
  }
  .fieldset-wrapper {
    padding: 30px;

    fieldset {
      margin-top: 30px;
    }
  }

  > .fieldset-wrapper > .form-wrapper .fieldset-wrapper   {
    margin-left: -30px;
    margin-right: -30px;
    // pull back outwards
  }

  .fieldset-title {
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-image: @dropdown-arrow;
      background-size: contain;
      background-size: 10px 10px;
      top: 8px;
      right: 0;
      transform: scale(1,-1,1);
      width: 16px;
      height: 16px;
      .trans(transform, 100ms, ease, 0ms);
    }
  }
  .fieldset-wrapper .fieldset-title:after {
    background-image: @dropdown-arrow--red;
  }

  &.collapsed {

    html.js & {
      height: auto;
    }
    .fieldset-title:after {
      transform: scale3d(1,-1,1);
    }
  }
}


.webform-client-form {
  fieldset ~ fieldset {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid @input-border-color;
  }

  label {
    display: inline-block;
    margin-bottom: 8px;
  }

  .fieldset-description,
  .description {
    font-size: @caption-font-size;
    letter-spacing: normal;
    color: @medium-text-color;
  }

  .form-actions {
    font-size: 0;
    margin: -.5rem;
    margin-top: 1.875rem;
    padding-top: 1.25rem;

    input[type="submit"],
    input[type="reset"] {
      .button();
      margin: .5rem;
    }
    input[id*="pay"] {
      .button();
      margin: .5rem;
    }

    .ajax-progress {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 30;
      background: fade(@black, 75);
      animation: fadein 500ms;

      .throbber {
        background-image: @triple-dots--white;
        background-repeat: no-repeat;
        background-position: center;
        float: none;
        margin: 0 auto;
        width: 40px;
        height: 100%;
        animation: fadeinout 750ms infinite;
      }
    }
  }
}

label {
  margin-bottom: 8px;
  display: inline-block;

  .form-type-checkbox &,
  .form-type-radios & {
    margin-bottom: 0;
    display: inline;
  }
}


form {
  .description {
    margin-top: 6px;
    font-size: @caption-font-size;
    letter-spacing: normal;
    color: @medium-text-color;
  }

}

// Forms
form {
  margin: 0 auto;

  label,
  .form-item.form-type-radios > label,
  .group-subscribe > div > span,
  .group-demo > div > span {
    .h5;
    margin: 11px 0;
    font-weight: bold;
    // font-size: @caption-font-size;
    // line-height: @caption-line-height;
    color: @brand-secondary;
    display: inline-block;
    vertical-align: middle;
  }

  .form-type-checkbox,
  .form-type-radios {
    position: relative;
    input {
      .element-invisible();
    }
    label {
      margin: 0;
      display: block;
      position: relative;
      font-size: 1rem;
      line-height: 1.2;
      color: #666666;
      font-weight: 700;
      letter-spacing: .02em;
      font-weight: 600;
      &:before {
        content: ' ';
        position: absolute;
        left: 0;
      }
    }
  }

  /* --- [ Form type checkbox ] --------------- */

  .form-type-checkbox {
    input[type="checkbox"] {
      + label {
        .text {
          display: inline-block;
          vertical-align: top;
        }
        &:before {
          .tick-base();
          .checkbox();
        }
      }
      &:checked + label:before {
        .tick-base();
        .checkbox();
        .checked();
      }
    }
  }
  .form-radios,
  .form-type-radios {
    input[type="radio"] {
      + label:before {
        .tick-base();
        .radio();
      }
      &:checked + label:before {
        .tick-base();
        .radio();
        .checked();
      }
    }
  }

  .form-wrapper + fieldset,
  fieldset + .form-wrapper {
    margin-top: 30px;
  }

  .fieldset-description {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid  @brand-secondary;
    background-color: fade(@white, 75%);
    color: @blue;

    > * {
      margin-top: 12px;
      &:last-child { margin-bottom: 0; }
    }
    ul,
    ol {
      margin-left: 0 !important;
      li ~ li {
        margin-top: 8px;
      }
    }
  }

  .form-required,
  .name-required-component-marker {
    color: @error-color;
  }



  &#commerce-registration-node-registrations-settings-form {
    // See: /node/1718/registrations/settings?destination=node/1718

    input[type="submit"] {
      margin-left: auto;
      margin-right: 0;
      display: block;
      width: 100%;
      margin-top: 30px;
    }

    > div {
      > .form-item,
      > .container-inline-date {
        border-bottom: 1px solid @hr-color;
        padding-bottom: 20px;
        margin-bottom: 20px;
        position: relative;
      }
    }

    .container-inline-date {
      + .container-inline-date {
        margin-top: 20px;
        border-top: 1px solid @hr-color;
        padding-top: 12px;
      }

      > .form-type-date-popup {
        width: 100%;
        margin-right: 0;
        float: none;

        > label {
          float: none;
          display: inline-block;
          vertical-align: middle;
          width: 20%;
        }

        input,
        select {
          margin-left: 10px;
          margin-right: 10px;
        }

        > .form-select,
        > .form-text,
        > .date-padding {
          display: inline-block;
          vertical-align: middle;
          width: auto;
          float: none;

          label,
          .form-item {
            float: none;
            display: inline-block;
            vertical-align: middle;

            input {
              width: auto;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
        .description {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

  }


  // See: /checkout
  &[id^="commerce-checkout-form-review"],
  &[id^="commerce-checkout"] {
    margin: 0 auto;
    box-sizing: border-box;


    > div {
      > fieldset:not(.cart_contents):not(.checkout-buttons):not(.registration_information) > .fieldset-wrapper,
      > div[id*="ajax-wrapper"] > fieldset > .fieldset-wrapper {
        background-color: @background;
      }
    }
    .product-title {
      .h4;
      color: @brand-primary;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      margin-top: 1.25rem;
      border-top: 2px solid @neutrallight;
      display: inline-block;
      width: 100%;
    }

    input[type="submit"] {
      &#edit-skip {
        margin-bottom: 30px;
      }
      .resp(@tabletmax, {
        width: 100%;
        margin-bottom: 0;
      });
      &.checkout-back {
        .button;
      }
    }
    .button-operator {
      .h5;
      display: inline-block;
      margin: 1rem;
      text-align: center;
      .resp(@tabletmax, {
        display: block;
      });
    }

    #edit-customer-profile-billing-field-behalf-of-company-und {
      padding-top: 1rem;
    }

    .field-name-field-first-name,
    .field-name-field-last-name,
    .field-name-field-email,
    .field-name-field-work-phone,
    .field-name-field-cell-phone,
    .field-name-field-current-employer,
    .field-name-field-race-ethnicity,
    .field-name-field-company-name,
    .field-name-field-company-phone,
    .field-name-field-gender,
    .field-name-field-sexual-orientation,
    .field-name-field-year-of-birth,
    .field-name-commerce-customer-address,
    .field-name-field-phone,
    .bt-wrapper {
      .__half-width;
      display: block;
      .__m-top;
      .resp(@tabletmax, {
        width: 100%;
      });
    }
    .bt-wrapper {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    .form-item[class*="field-registration-name"],
    .form-item[class*="field-registration-email"] {
      .__half-width;
      width: 48%;
      .resp(@tabletmax, {
        width: 100%;
      });
    }
    #edit-commerce-payment-payment-details-cvv {
      padding-left: 1rem;
    }
    #edit-commerce-payment-payment-details-expiration,
    #edit-commerce-payment-payment-details-cvv {
      .__half-width;
      width: 24.7%;
      .resp(@tabletmax, {
        width: 100%;
        padding-left: 0;
      });
    }
    .form-item[class*="field-registration-email"] {
      float: right;
    }

    .form-item[class*="postal-code"] {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      position: relative;
      margin-bottom: 0;
      margin-top: .9em;
      .resp(@tabletmax, {
        width: 100%;
      });
    }
    .field-name-field-first-name,
    .field-name-field-last-name {
      &:not([id*="edit-registration-information"]) {
        .__m-top-0;
      }
    }
    // .field-name-field-first-name,
    // .field-name-field-company,
    // .field-name-field-race-ethnicity,
    // .field-name-field-sexual-orientation,
    // .field-name-field-phone,
    // .form-item[class*="locality"] {
    //   .__p-right;
    // }
    // .field-name-field-last-name,
    // .field-name-field-gender,
    // .field-name-field-year-of-birth,
    // .form-item[class*="administrative-area"]  {
    //   .__p-left;
    // }

  /**
   * The below selectors cause serious rendering issues on webforms.
   * It's uncertain if they're still necessary—I hope they're not!
   *
   * —Rory 11.14.16
   */

  /*
      .addressfield-container-inline {
        letter-spacing: -1em;
      }

      .addressfield-container-inline > .addressfield-container-inline,
      .addressfield-container-inline > .form-item,
      .street-block {
        .__m-top;
        letter-spacing: normal;
        float: none;
      }
  */

    .name-block > .form-item:first-child {
      .__m-top-0;
    }

    .field-type-addressfield:not([id^="edit-customer-profile-billing"]),
    [id*="billing"] > [id^="addressfield-wrapper"],
    .group-subscribe,
    .group-demo {

    }

    #edit-customer-profile-billing {
      .field-group-fieldset {
        padding-top: 1.25rem;
        margin-top: 1.25rem;
        border-top: 2px solid @neutrallight;
        .fieldset-legend {
          .h4;
          color: @brand-primary;
          padding-bottom: 1.25rem;
        }
      }
    }

    #payment-details {
      > .form-item,
      > .commerce-credit-card-expiration {
        .__m-top;
      }

      .form-type-select,
      .form-type-textfield {
        .__auto-width;
        vertical-align: bottom;
      }

      .commerce-month-year-divider {
        color: transparent;
        text-indent: -9999px;
        height: 100%;
        display: inline-block;
        padding: 9px 4px 8px;
        position: relative;
        margin: 0 8px 0 6px;

        &:after {
          content: ' ';
          position: absolute;
          top: 10%;
          bottom: 10%;
          left: 50%;
          transform: translateX(-50%) rotate(15deg);
          width: 2px;
          background-color: @background;
        }
      }

      .form-item[class*="credit-card-type"] {
        .__p-right;
      }
      .form-item[class*="credit-card-number"] {
        .__p-left;
      }
      .form-item[class*="credit-card-exp-month"],
      .form-item[class*="credit-card-code"] {
        white-space: nowrap;
        margin-bottom: 0;

        label {
          margin-right: 10px;
        }
        label,
        select,
        .form-text {
          display: inline-block;
          vertical-align: middle;
          width: auto;
        }
      }
      .form-item[class*="credit-card-exp-year"] {
        margin-bottom: 0;
      }
      > .commerce-credit-card-expiration {
        display: inline-block;
        margin-top: 24px;
        .__p-right;
      }
      .form-item[class*="credit-card-code"] {
        .__p-left;
      }

    }
    .commerce_payment {
      margin-top: 0;
      .fieldset-description {
        .h5;
        margin: 11px 0;
        font-weight: bold;
        color: @brand-secondary;
        display: inline-block;
        vertical-align: middle;
        padding: 0;
        border: none;
        background: inherit;
      }
    }
  }

  &[id^="ma-ux-user-password"] {
    .form-item-pass {
      label:first-child {
        display: none;
      }
      .form-type-password {
        display: inline-flex;
        flex-direction: column;
        div.password-strength,
        div.password-confirm {
          order: 2;
          margin-top: .5em;
        }
      }
    }
    div.confirm-parent,
    div.password-parent,
    div.password-confirm,
    div.password-strength,
    div.password-suggestions {
      width: 100%;
    }
    div.password-strength,
    div.password-confirm {
      font-size: 0.875rem;
      letter-spacing: normal;
      color: #666666;
    }
    input[type="submit"] {
      .button-cta();
      padding: 0.6rem 2em 0.7rem;
    }
  }

}


.region[class*="sidebar"] {
  .form-item {
    label {
      display: inline-block;
      margin-top: 6px;
      width: 100%;
      color: @faint-text-color;
    }
    p {
      margin-top: 0;
    }
    &.form-type-checkbox,
    &.form-type-radio {
      label {
        margin-top: 0;
      }
    }
  }
  .form-submit {
    display: block;
    margin-top: 1.875rem;
    width: 100%;
    .button-cta();

    &[id*="reset"] {
      color: @brand-secondary;
      padding: 0;
      background-color: transparent;

      &:hover {
        color: @brand-primary;
      }
    }
  }

  .front & {
    [id^="search-block-form"] label { .element-invisible; }
  }

  .field-registration-product {
    margin-bottom: 20px;
    &:last-child { margin-bottom: 0; }
  }

  .commerce-add-to-cart {
    > div > * ~ * {
      margin-top: 20px;
    }

    .quantity-wrapper {
      padding: 20px 30px;
      box-sizing: border-box;
      background-color: @input-border-color;
      min-width: 100%;
      width: calc(~"100% + 60px");
      margin: 20px -30px;

      > * {
        display: table-cell;
        vertical-align: middle;
        width: 50%;

        &:nth-child(odd) {
          padding-right: 10px;
        }
        &:nth-child(even) {
          padding-left: 10px;
        }
      }
    }
    .price {
      font-size: 24px;
      color: @brand-secondary;
      width: auto;
    }
    .form-item-quantity {
      position: relative;
      width: 500px;  // A hack to get the thing be full width
      label { .element-invisible; }
    }
    .quantity-buttons {
      position: absolute;
      .abs(0,0,0,auto);
      border: 1px solid transparent; // prevent overlap of input border
      &:after {
        content: ' ';
        height: 1px;
        .abs(50%,0,auto,0);
        background-color: darken(@hr-color, 5%);
      }

      .commerce-quantity-plusminus-link {
        display: block;
        float: none;
        height: 50%;
        width: 30px;

        a {
          margin: 0;
          display: block;
          height: 100%;
          border: none;
          border-radius: 0;
          padding: 0;
          line-height: 1;
          text-indent: -9999px;
          position: relative;
          background-color: @input-border-color;

          &:before {
            content: ' ';
            background-repeat: no-repeat;
            background-color: transparent;
            background-size: 10px 10px;
            background-position: center;
            display: block;
            .abs(0,0,0,0);
          }

          &:focus {
            transform: none;
          }
          &:active {
            background-color: @hr-color;
          }
        }

        &[class*="increase"] a:before {
          background-image: url(../images/arrowup--gray.svg);
        }
        &[class*="decrease"] a:before {
          background-image: url(../images/arrowdown--gray.svg);
        }
      }
    }

    label[for="edit-quantity"] {
      .element-invisible;
    }

  }
}
