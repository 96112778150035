
.menu-block-wrapper {
  // Top Level Nav
  .top-level-nav &,
  .mobile-top-level-nav & {
    z-index: 30;

    .menu{
      .unbullet();
      position: static;
    }

    .menu-title-layout-group > .nolink {
      display: none !important;
    }

    .menu-title-search {
      .mainSearchToggleProxy {
        background-image: @search--white !important;
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-size: 18px 18px;
        background-color: transparent !important;
      }
      .menu-item-inner {
        white-space: nowrap;
        text-indent: -9999px;
        overflow: hidden;
      }
      .resp(@tabletmax, {
        display: none !important;
      });
    }

    .resp(@tabletmax, {
      text-align: left;
    });

    a,
    .nolink {
      display: block;
      .menu-item-inner {
        display: block;
      }
    }

    > .menu {
      > li {
        height: 50px;
        border-left: 2px solid @brand-accent;
        //padding: 0 15px;
        position: relative;
        &:last-child {
          border-right: 2px solid @brand-accent;
        }
        .resp(@tabletmax, {
          height: inherit;
          border: none;
        });
        &,
        > .tlAccSwitchWrap {
          > a,
          > .nolink {
            .top-menu-link;
            .resp(@tabletmax, {
              .mobile-top-menu-link();
              padding: 0.9rem 1.65rem 0.8rem;
              padding-right: 1rem; // 16px
              flex: 0 1 auto;
              width: auto;
            });
            &:hover {
              text-decoration: none;
            }
          }
        }
        > a {
          span {
            .top-menu-link;
            .resp(@tabletmax, {
              .mobile-top-menu-link();
            });
            &:hover {
              text-decoration: none;
            }
          }
        }

      }
    }

    .menu .menu {
      text-align: justify;
      letter-spacing: -1em;
      line-height: 0;

      > * {
        letter-spacing: normal;
        text-align: left;
        line-height: @h6-line-height;
      }
      &:after {
        content: ' ';
        display: inline-block;
        width: 100%;

        .resp(@tabletmax, {
          content: none;
        });
      }
      a,
      .nolink {
        .menu-item-link();

      }

      .menu-title {
        .icon(@icon) {
          .menu-item-inner {
            position: relative;
            padding-left: 50px;
            padding-right: 0;
            .resp(@tabletmax, {
              padding-left: 36px;
              padding-right: 0;
            });

            &:before {
              content: ' ';
              position: absolute;
              width: 50px;
              height: 32px;
              top: 50%;
              left: 0;
              .t-y(-50%);
              background-image: @icon;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 24px;

              .resp(@tabletmax, {
                width: 36px;
                background-position: left center;
              });
            }
          }
        }
      }
    }

    .menu .menu .menu {
      > li {

        > a,
        > .nolink {
          .menu-item-link;

          .menu-item-inner {
            position: relative;

            &:after {
              content: ' ';
              position: absolute;
              bottom: -.75em;
              left: 0;
              right: 0;
            }
            .resp(@tabletmax, {
              &:after {
                content: none;
              }
            });
          }
          .resp-min(@mobilemax + 1, {
            padding: 0 0 0.8125rem 1.063rem;
          });
          .resp(@mobilemax, {
            padding: 12px;
          });
        }

        &.last {
          > a,
          > .nolink {
            padding-bottom: 0;
          }
        }
        &:first-child:last-child {
          > a,
          > .nolink {
            .menu-item-inner:after {
              content: ' ';
              .resp(@tabletmax, { content: none; });
            }
          }
        }

      }
    }

    .tlAccSwitchWrap,
    .slAccSwitchWrap {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      > * {
        width: 100%;
      }
      > a {
        flex-shrink: 1;
      }
    }
    .tlAccSwitchWrap {
      height: 100%;
    }

    .tlAccSwitch,
    .slAccSwitch {
      display: none;
      position: relative;
      width: 15px;
      .resp(@tabletmax, {
        width: 23px;
      });
      margin-left: 5px;
      padding: 0;
      &:before {
        content: ' ';

        .abs-0();
        width: auto;

        .resp(@mobilemax, {
          .abs(0, auto, 0, -50%);
          width: 200%;
        });
      }
      .text {
        display: block;
        position: absolute;
        top: 50%;
        //left: 50%;/*rtl:ignore*/
        width: 100%;
        height: 100%;
        border-radius: 2000px;
        text-indent: -9999px;
        white-space: nowrap;
        overflow: hidden;
        pointer-events: none;
        background-color: transparent;
        background-image: @dropdown-arrow--white;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 14px;
        transform-origin: center;
        .t-x(-50%);
        .t-y(-50%);
        .t-scale3d(~"1,1,1");
        transition: transform 700ms ease 0ms, background-color 150ms ease 0ms;
        .resp(@tabletmax, {
          background-size: 20px;
        });
      }

      &:active .text,
      &:hover .text {
        background-color: transparent;
      }

      &.isOpen {
        .resp-min(@tabletmax + 1, { background-color: transparent; });
        .resp(@tabletmax, {
          .text {
            transform: translateX(-50%) translateY(-50%) scale3d(1,1,1) rotate(180deg);
            background-color: transparent;
          }
        });
      }
    }

    .tlAccSwitch {
      display: block;
    }
    .slAccSwitch {
      .resp(@mobilemax, {
        display: block;
        padding: 0;
        display: none;
      });
    }

    > .menu {
      .IB-gapfree();
      text-align: right;
      line-height: 0;
      font-size: 0;

      > * {
        line-height: normal;
        font-size: @base-font-size;
      }

      > li {
        > a,
        > .nolink,
        > .tlAccSwitchWrap > a {
          position: relative;
          .resp-min(@tabletmax + 1, {
            &.active-trail {
              &:before {
                content: ' ';
                height: 5px;
                top: 0;
                left: 0;
                background-color: @white;
                display: block;
                position: absolute;
                width: 100%;
              }
            }
          });
        }
        .resp-min(@tabletmax + 1, {
          > a {
            padding: 0 15px;
          }
          > .tlAccSwitchWrap > a {
            padding: 0 30px 0 15px;
          }
        });
        .toggleSwitch {
          .resp-min(@tabletmax + 1, {
            margin-left: -15px;
          });
        }

        .resp(@tabletmax, {
          display: block;
          position: static;
          padding: 0.31rem 0;
          border-bottom: 3px solid @white;
          &:last-child {
            border: none;
          }

          &.leaf > a,
          > a,
          > .nolink,
          > .tlAccSwitchWrap > a,
          > .tlAccSwitchWrap > .nolink {
            &:before {
              content: none;
            }
            padding-left: 0;
          }
          &.expanded {
            > a, > .nolink,
            > .tlAccSwitchWrap > a,
            > .tlAccSwitchWrap > .nolink {
              &:after {
                content: none;
              }
            }
          }
        });
        .resp-min(@tabletmax + 1, {
          &:hover {
            background-color: @brand-accent;
            &.active {
              border-top: 0;
              > .tlAccSwitchWrap {
                margin-top: 0;
              }
            }
          }
        });
      }

      .resp(@tabletmax, {
        text-align: inherit;
        line-height: normal;
        display: block;
        position: static;
        width: 100%;

        > * {
          display: block;
        }
      });

      > li > .menu {
        position: absolute;
        top: 50px;
        bottom: auto;
        overflow: hidden;
        transform-origin: top center;
        z-index: 30;

        .resp(@tabletmax, {
          position: static;
          height: auto;
          min-width: 0;
          background-color: @brand-accent;

          > li {
            &:first-child {
              border-top: none;
              padding-top: 0;
            }
            padding-top: 1.3rem;
            border-left: 3px solid @white;
          }

          &.isClosed {
            height: 0 !important;
            display: none;
          }
        });
        // .resp(@mobilemax, {
        //   > li { border: none; }
        // });

        .menu {
          height: auto;
          max-height: none;
          opacity: 1;
          position: static;
          transform: none;
          margin-top: 1.063rem;
          margin-bottom: 1.063rem;
          border-left: 3px solid @white;

          &.slAccordion {
            overflow: hidden;
            .resp(@mobilemax, {
              display: none;
            });
          }
        }
      }

      > li.expanded {
        position: static;
        //position: relative;
        .trans(background-color);

        &.tlAccOpen {
          background-color: @brand-accent;
          .resp-min(@tabletmax + 1, {
            background-color: transparent;
            &:hover {
              background-color: @brand-accent;
            }
          });
        }

        > .menu {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 1.125rem 75px 2.5rem;
          margin-left: -2px;

          .resp-min(@tabletmax + 1, {
            max-height: 0;
            padding: 0;
            overflow: visible;
          });

          > li {
            max-width: 270px;
            overflow: hidden;
            padding-right: 40px;
            padding-bottom: 20px;
            width: 50%;
          }
          .resp(@tabletmax, {
            // left: 0;
            // right: 0;
            margin-left: auto;
            margin-right: auto;
            //padding: .36rem 27px 2.65rem;
            padding: 0;
            padding-top: 6px;
            padding-bottom: 23px;
            display: block;
            > li {
              max-width: none;
              .menu {
                display: none;
              }
              padding-left: 1.1rem;
            }
          });
        }

        &:hover {
          .resp-min(@tabletmax + 1, {
            // &.tlAccOpen {
            //   background-color: @brand-primary;
            // }
            > .menu {
              max-height: 4000px;
              padding: 2.5rem 2.063rem 4.063rem;
              overflow: visible;
            }
          });
        }
      }

    }

    > .menu > li {

      &.mobile-only {
        display: none;
        .resp(@tabletmax, {
          display: block;
        });
      }

      > a,
      > .nolink {
        display: block;
      }
      > .menu {
        background-color: transparent;
        background-clip: padding-box;
        padding: 1.75em 0 1.5em;
        width: 100%;
        margin: 0 auto;
        overflow: visible;
        background-color: @brand-accent;
        .resp-min(@tabletmax + 1 {
          max-width: 610px;
          padding: 0;
          max-height: 0;
          overflow: visible;
          opacity: 0;
          transform-origin: top center;
          transform: scale3d(1, 0, 1);
        });

        // &:before {
        //   content: ' ';
        //   .abs(0, -100%, 0, -100%);
        //   z-index: -1;
        //
        //   .resp(@tabletmax, {
        //     content: none;
        //   });
        // }

        .resp(@tabletmax, {
          padding: 0;
          border-top: none;
          box-shadow: none;
          transform: none;
          max-height: 4000px;
          opacity: 1;
          overflow: hidden;
          position: relative;
          top: auto;
          bottom: auto;
        });

        > li {
          > a,
          > .tlAccSwitchWrap > a,
          > .slAccSwitchWrap > a,
          > .nolink,
          > .tlAccSwitchWrap > .nolink,
          > .slAccSwitchWrap > .nolink  {
            .resp-min(@tabletmax, {
              .mobile-top-menu-link();
            });
            .resp(@tabletmax, {
              .menu-item-link;
            });
          }
        }
      }
      &:hover > .menu {
        .resp-min(@tabletmax + 1 {
          max-height: 4000px;
          padding: 1.75em 0 1.5em;
          opacity: 1;
          transform: scale3d(1, 1, 1);
          .trans(max-height, 0ms, ease, 200ms);
          .trans(padding, 0ms, ease, 200ms);
          .trans(transform, 300ms, ease, 300ms);
          .trans(opacity, 200ms, ease, 300ms);
        });
        .resp(@tabletmax, {
          border-top: none;
          box-shadow: none;
          transform: none;
          opacity: 1;
        });
      }

      > .menu {
         li {
          > a,
          > .nolink {

            .menu-item-inner {
              position: relative;
            }
          }
        }
      }
    }

    > .menu > li:last-child {

      .resp-min(@tabletmax, {
        border: 0;
        position: absolute;
        right: 0;
        margin-right: 80px;

        &:hover {
        background: @blue;
      }
      a {
        border: 2px solid @white;
        height: 30px;
        margin-top: 10px;
        border-radius: 100px;

        &:hover {
          background: @white;
          span {
            color: @blue;
          }
        }
      }
    });
  }
  } // /* END .top-level-nav .menu-block-wrapper */

} // /* END .menu-block-wrapper */
