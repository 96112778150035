
// View Modes
// ================================

/* --- [ Mixins ] --------------- */
.for-unpublished-items() {
  &[class*="unpublished"] {
    position: relative;
    &:after {
      content:  ' ';
      pointer-events: none;
      .abs-0();
      background-color: fade(red, 20%);
      mix-blend-mode: multiply;
      visibility: visible;
      height: auto;
      display: block;
    }
  }
}
.card-style (
  @title_el: ~".field-title",
  @meta: ~".group-meta",
  @body: ~".field-body",
  @more_link: ~".field-node-link"
  ) {

  background-color: @white;
  padding: 2.05rem 1.625rem;
  .resp(@mobilemax, { padding: 1.6rem 1.25rem 2.4rem; });

  @{meta} {
    margin-bottom: .2rem;
  }

  .field[class*="signature"] {
    .entity > .field {
      margin-top: .8rem;
      margin-bottom: 1.2rem;

      &.field-image-placeholder {
        display: none;
      }
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  @{title_el} {
    color: @black;
    line-height: 1.5;
    margin-bottom: 1.6rem;
    > * { line-height:  inherit; }
    a {
      color: inherit;
      &:hover { text-decoration: underline; }
    }
  }
  @{body} {
    line-height: 1.35;
    ~ * {
      margin-top: 1.3rem;
    }
  }

  @{more_link} {
    .more-link();
  }
}
.card-style-post (
  @title_el: ~".field-title",
  @meta: ~".group-meta",
  @body: ~".field-body",
  @more_link: ~".field-node-link"
  ) {
  .card-style(@title_el, @meta, @body, @more_link);
  padding: 2.1rem 2rem;
  .resp(@mobilemax, { padding: 1.6rem 1.25rem 2.4rem; });

  @{title_el} {
    color: @black;
    line-height: 1.65;
    margin-bottom: 1.5rem;
    * { line-height:  inherit; }
    a {
      color: inherit;
      &:hover { text-decoration: underline; }
    }
  }

  @{body} {
    color: @medium-text-color;
    line-height: 1.35;
    font-size: 1rem;
    ~ * {
      margin-top: 1.3rem;
    }
  }

}

.list-style(@title_el: ~".field-title", @body: ~".field-body", @meta: ~".group-meta") {
  @{title_el} {
    &, & * {
      .h4();
      margin-bottom: .55rem;
      font-size: @h3-size;
      a {
        display: block;
          &, * { color: @link-hover-color; }
      }
    }
    > * { font: inherit; }
  }
  .field[class*="signature"] {
    img {
      width: 100%;
      height: auto;
    }
    ~ * {
      margin-top: .85rem;
    }
  }
  @{body} {
    font-size: @h4-size;
    line-height: @base-line-height;
    ~ * {
      margin-top: .85rem;
    }
  }
  @{meta} {
    .meta();
    ~ * {
      margin-top: .85rem;
      &.field[class*="signature"] {
        margin-top: 0;
        .entity > * {
          margin-top: .85rem;
        }
      }
    }
  }
}

.emboss {
  &.entity-impact-box,
  > .region-preface-first-inner {
    margin-top: -67px;
    background: @white;
    box-shadow: 0px 8px 10px 0px rgba(214,219,225,1);
    margin-bottom: 44px;
    z-index: 3;
    text-align: center;
  }
  &.region-preface_first-listing_page {
    .region-preface-first-inner {
      margin-top: -28px;
      text-align: center;
      .resp(@tabletmax, {
        margin-top: 0;
      });
    }
  }
  &.region-preface_first-event {
    .region-preface-first-inner {
      .title#page-title {
        padding-bottom: 2.2rem;
        letter-spacing: .1rem;
      }
      .field-event-date-display {
        padding-bottom: 1.3rem;
        .h5;
        font-weight: 700;
        letter-spacing: 0.08em;
      }
      .field-event-location {
        padding-bottom: 2.1rem;
        .h5;
        font-weight: 700;
        letter-spacing: 0.08em;
      }
    }
  }
}
.emboss-body {
  box-shadow: 0px 8px 10px 0px rgba(214,219,225,1);
  text-align: center;
}



/* --- [ Apply Mixins ] --------------- */
[class*='view-mode'] { .for-unpublished-items() !important; }
.view-mode {
  &-teaser {

    &.node-blog-post {
      // See: / (From the Blog)
      .front & {
        .card-style();
      }
      // See: /blog
      .not-front & {
        .card-style-post();
      }
    }

    // See: /home
    &.node-news-post {
      > .field {
        .field-contents {
          a .text {
            .font-smoothing(antialiased);
            letter-spacing: 0.022rem;
          }
        }
        padding-bottom: .5rem;
      }
      .group-meta {
        a,
        .field-contents {
          color: @neutraldark;
          font-weight: 600;
          letter-spacing: 0.05rem;
        }
      }
    }

    &.node-grantee-profile,
    &.node-event {
      a {
        color: @white;
      }
      .background {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        padding: 2rem;
        position: relative;
        .resp(@tabletmax, {
          padding: 1rem;
        });
        a:hover & {
          text-decoration: none;
        }
        .field-title {
          h2 {
            .h3;
            color: @white;
            margin-bottom: 0;
            .resp(@mobilemax, {
              font-size: @h3-size;
            });
          }
        }
        .field-event-date-display {
          .p;
          text-transform: none;
          margin-top: 0;
          .date-display-range {
            .remove-inline-icon;
            padding-top: 0;
          }
        }
        .field {
          color: @white;
          text-align: center;
          z-index: 5;
        }
        &:before {
          content: ' ';
          .abs-0();
          z-index: 0;
          background: @brand-secondary;
          opacity: .35;
          mix-blend-mode: multiply;
        }
      }
    }

    // See: /research/databases
    &.node-database-detail {
      .list-style();
    }

    // See: /research/databases
    &.node-guide {
      .list-style();
    }

    &.node-person-profile {
      .ds-media {
        border: 4px solid @neutrallight;
        width: 290px;
        display: flex;
        flex-direction: column;
        .ds-media-thumb {
          margin: -4px;
          width: inherit;
          .field-profile-image {
            img {
              height: 290px;
              width: 290px;
            }
          }
        }
        .ds-media-text {
          padding: 2.1rem 2.2rem;
          width: inherit;
          .field-title h3 {
            a {
              color: @brand-secondary;
            }
            margin-bottom: .4rem;
          }
          .field-position {
            margin-bottom: 1.4rem;
            .field-contents {
              .p;
              font-weight: 600;
              color: @slate;
              text-transform: none;
            }
          }
          .field-organization .field-contents {
            .p;
            font-weight: 400;
            color: @slate;
            text-transform: none;
          }
        }
      }
      .resp(@mobilemax, {
        .ds-media {
          .ds-media-text {
            padding: 1rem 0.6rem;
            .field-title h3 {
              font-size: 1.25rem;
            }
          }
        }
      });
    }
  }

  &-full {

    &.node-page {
      //max-width: 630px;
      .resp(@tabletmax, { margin: 0 auto; });
    }

    &.node-event,
    &.node-page,
    &.node-news-post {
      .group-inset {
        float: left;
        margin-right: 2rem;
        margin-bottom: 2rem;
        max-width: 280px;
        .resp(@mobilemax, {
          float: none;
          max-width: none;
          width: 100%;
        });
      }
    }

    &.node-event {
      .ds-highlights {
        .field {
        //  width: 65.5%; PBFDEV-33 - not sure where this came in
          .resp(@tabletmax, {
            width: 100%;
            min-width: 100%;
            padding-right: 0;
            flex-basis: 100%;
          });
        }
      }
    }

    // See: /donate
    &.node-webform {
      //.card-style-post();
      position: relative;

      .messages {
        margin: -2.1rem -2rem;
        margin-bottom: 2rem;
      }

      .fieldset-wrapper {
        font-size: 0;
        > .form-item {
          .__m-top();

          &[class*="conditional"] {
            + .form-item[style*="display"],
            + .form-wrapper[style*="display"] {
              margin-top: -.5rem;
              margin-bottom: 2rem;
            }

            + .form-wrapper[style*="display"] {
              .fieldset-wrapper > .form-item:first-child { margin-top: 0; }
            }
          }
        }
      }

      /* --- [ Donation specific ] --------------- */

      .form-radios[id*="gift-amount"] {
        font-size: 0;
        .__m-top();
        .resp(@tabletmax, {
          margin: 1rem -.5rem 0;
        });
      }
      .form-item[class*="submitted"][class*="gift-amount"] {
        display: inline-block;
        vertical-align: middle;
        margin-right: .5rem;

        .resp(@tabletmax, {
          margin-right: 0;
          width: 50%;
          padding: 0 .5rem;
        });
        input {
          + label {
            .button();
            display: block;
            cursor: pointer;
            &:before { content: none; }
          }
          &:checked + label {
            .button-cta();
            display: block;
            cursor: default;
            &:hover {
              background-color: @brand-secondary;
              border-color: @brand-secondary;
            }
          }
        }
      }
      .form-item[class*="display-name"] {
        margin-bottom: 2.5rem;
      }
      .form-item[class*="donor-information"] {
        &[class*="first-name"],
        &[class*="last-name"],
        &[class*="email-address"],
        &[class*="street-address"],
        &[class*="city"],
        &[class*="state"],
        &[class*="phone"] {
          .__half-width();
          .resp(@tabletmax, {
            .__three-fifths-width();
          });
          .resp(@mobilemax, {
            .__full-width();
          });
        }
        &[class*="middle-initial"],
        &[class*="suffix"],
        &[class*="zip"] {
          .__one-fifth-width();
          .resp(@goldilocks, {
            .__two-fifths-width();
          });
          .resp(@mobilemax, {
            .__full-width();
          });
        }

        &[class*="email-address"],
        &[class*="city"] {
          display: block;
        }

        &[class*="first-name"],
        &[class*="last-name"],
        &[class*="email-address"],
        &[class*="street-address"],
        &[class*="city"],
        &[class*="state"],
        &[class*="phone"] {
          .resp-min(@mobilemax + 1, {
            .__p-right();
          });
        }

        &[class*="middle-initial"],
        &[class*="suffix"],
        &[class*="zip"] {
          .resp-min(@mobilemax + 1, {
            .__p-left();
          });
        }
      }

      #edit-submitted[style*="display"][style*="none"] ~ .form-actions {
        margin-top: -.5rem;
        padding-top: 0;
      }
    }

    // See: /research/guides
    &.node-listing-page {
      position: relative;

      .section-heading {
        border-bottom: 4px solid @neutrallight;
        padding-bottom: 0.8125rem;
        letter-spacing: 0.19rem;
      }

      > .field-body {
        font-size: @h4-size;
        line-height: @fine-title-line-height;
        color: @medium-text-color;
        margin-bottom: 2.25rem;
      }
      .view-output {
        padding-bottom: 2rem;
      }
    }

    &.node-event {
      .section-heading {
        border-bottom: 4px solid @neutrallight;
        padding-bottom: 0.8125rem;
        margin-bottom: 3.125rem;
      }
      .group-left {
        width: 72.5%;
        display: inline-block;
        padding-right: 1.25rem;
        .resp(@tabletmax, {
          width: 100%;
          padding-right: 0;
        });
      }
      .group-right {
        width: 27%;
        display: inline-block;
        vertical-align: top;
        .resp(@tabletmax, {
          width: 100%;
        });
      }
      .group-inset {
        .field-event-links {
          .field-contents {
            width: 286px;
            .resp(@mobilemax, {
              width: auto;
            });
            .unbullet;
            .parent {
              padding: 30px 24px 35px;
              .resp(@mobilemax, {
                padding: 20px 20px 23px;
              });
            }
            border: 4px solid @neutrallight;
            .children,
            li {
              padding: 25px;
              border-bottom: 4px solid @neutrallight;
              .h6;
              .icon-inline-after(@link-arrow--blue, 1rem);
              &:after {
                right: 24px;
              }
              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }
    }

    &.entity-sponsor {
      // display: inline-block;
      // max-width: 225px;
      .field-sponsor-image {
        border: 2px solid @neutrallight;
        border-bottom: none;
        min-height: 250px;
        display: flex;
        align-items: center;
        .field-contents {
          margin: auto;
          img {
            width: 100%;
            height: 100%;
            padding: 20px;
          }
        }
      }
      .group-text {
        padding: 1rem;
        border: 1px solid @neutrallight;
        width: inherit;
        .field-sponsor-name,
        .field-sponsor-level,
        .field-organization .field-contents {
          .p;
          text-align: center;
          font-weight: 400;
          color: @slate;
          text-transform: none;
        }
      }
    }
  }

  &-default {
    // See: /
    &.bean-visitor-info {
      > * ~ * {
        margin-top: 1.7rem;

        .resp(@mobilemax, {
          margin-top: 1.6rem;
        });
      }

      > .field-links {
        border-top: 2px solid @hr-color;
        padding-top: 1.75rem;
        margin-top: 2.45rem;
      }

      .field-links {
        a {
          .remove-icon-inline-after();
          .action-link();

          ~ a {
            margin-top: 1.1rem;
          }
        }
      }

      .resp-range(@baby_bear + 1, @tabletmax, {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &:before, &:after { display:  none; }

        > * {
          flex: 0 1 auto;
          margin: 0;

          &:nth-child(2),
          &.group-tel {
            order: 3;
          }
          &:nth-child(3),
          &.field-hours {
            order: 2;
          }
          &:last-child,
          &.field-links {
            order: 100;
            flex-basis: 100%;
            margin-top: 1.8rem;
            margin-bottom: 0;
          }
        }

        > .field-links {
          border: none;
          padding-top: 0;
        }

        .field-links {
          text-align: center;
          a {
            .remove-icon-inline-after();
            .action-link();
            display: inline-block;
            ~ a {
              margin: 0;
              .pipe-before(@medium-text-color);
            }
          }
        }
      });
    }
    // See: /research/guides
    &.bean-contact-us {
      .field-contact-details {
        margin-top: .5rem;

        > * ~ * {
          margin-top: 1.7rem;

          .resp(@mobilemax, {
            margin-top: 1.6rem;
          });
        }

        .resp-range(@mobilemax + 1, @tabletmax, {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          &:before, &:after { display:  none; }

          > * {
            flex: 0 1 auto;
            margin: 0;
            margin-bottom: 1.5rem;
          }

          > .field-links {
            border: none;
            padding-top: 0;
          }

          .field-links {
            text-align: center;
            a {
              .remove-icon-inline-after();
              .action-link();
              display: inline-block;
              ~ a {
                margin: 0;
                .pipe-before(@medium-text-color);
              }
            }
          }
        });
      }
    }
  }

  &-featured {
    &.node-event {
      padding-top: .8rem;
      .ds-media {
        background-color: @brand-primary;
        display: flex;
        .ds-media-thumb {
          width: 50%;
          display: block;
          .field-banner-image {
            min-height: 275px;
            .resp-min(@tabletmax + 1, {
              min-height: 400px;
            });
            height: 100%;
            background-position: center;
            background-size: cover;
            img {
              display: none;
            }
            .field-items {
              height: 100%;
              width: 100%;
              .field-item {
                height: 100%;
                width: 100%;
                a {
                  display: block;
                  height: 100%;
                  width: 100%;
                  min-height: 275px;
                }
              }
            }
          }
        }
        .ds-media-text {
          width: 50%;
          padding: 2rem;
          padding-top: 1.7rem;
          padding-bottom: 3.4rem;
          color: @white;
          .meta,
          a:not(.button-event) {
            color: @white;
          }
          .field:first-child {
            padding-bottom: 1.3rem;
            text-transform: uppercase;
            font-weight: bold;
          }
          .field-title {
            .text {
              letter-spacing: .165rem;
            }
            h2 {
              margin-bottom: 0;
            }
            padding-bottom: 0.3rem;
          }
          .field-event-date-display {
            padding-bottom: 1rem;
            &::before {
              content: none;
            }
            padding-left: 0;
            padding-top: 0;
          }
          .field-body {
            padding-bottom: 2rem;
          }
        }
        .resp(@tabletmax, {
          flex-direction: column;
          .ds-media-thumb {
            width: 100%;
          }
          .ds-media-text {
            width: 100%;
            padding-left: 2.7rem;
            padding-right: 2.7rem;
            padding-bottom: 2.7rem;
          }
        });
        .resp(@mobilemax, {
          margin-left: -0.934rem;
          margin-right: -0.934rem;
          .ds-media-text {
            text-align: center;
            padding: 1.1rem;
            padding-bottom: 2rem;
            .field:first-child {
              padding-bottom: .6rem;
            }
          }
        });
      }
    }
  }
}
