// Inits
// ================================

@go-to-first-icon: @pager-left;
@go-to-first-icon-hover: @pager-left;

@go-to-last-icon: @pager-right;
@go-to-last-icon-hover: @pager-right;

@page-prev-icon: @pager-left;
@page-prev-icon-hover: @pager-left-blue;
@page-prev-icon-active: @pager-left-red;

@page-next-icon: @pager-right;
@page-next-icon-hover: @pager-right-blue;
@page-next-icon-active: @pager-right-red;

@item-text-color: @brand-secondary;
@item-text-hover-color: @brand-secondary;

@current-background-color: transparent;
@current-border-color: transparent;

@pager-nav-background-color: transparent;
@pager-nav-hover-backround-color: transparent;

@pager-item-padding: 0rem;


.pager-bar {
  margin-top: 2.5rem;
  text-align: center;
  clear: both;
}

.pager {
  .unbullet();
  margin-top: 30px;
  padding: 0;
  background-color: transparent;
  padding-right: 6rem;
  position: relative;

  &-default {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
  }
  &-left {
    width: 100%;
    padding: 0;
    padding-left: 6rem;
  }
  &-right {
    width: 100%;
    padding: 0;
    padding-right: 6rem;
  }

  .pager-nav {
    background-color: transparent;
    overflow: hidden;
    position: relative;
    .abs(0,0,0,auto);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    ul {
      .unbullet();
      li { display: inline-block; }
    }
    &:before {
      content: ' ';
      .abs(0, 50%, 0, auto);
      width: 1px;
      //border-right: 1px solid darken(@hr-color, 10%);
    }
  }



  > li,
  .pager-nav ul li {
    .h3;
    display: inline-block;
    vertical-align: middle;
    min-width: 2rem;
    text-align: center;
    line-height: initial;

    &.pager-nav {
      width: auto;
    }

    a,
    span {
      color: @item-text-color;
      text-decoration: none;
      padding: @pager-item-padding;
      display: block;
      width: 100%;
      position: relative;
      white-space: nowrap;
      border-radius: 2000px;

      &:before {
        content: ' ';
        height: auto;
        .abs-0();
        background-position: center;
        background-size: 1.2rem;
        background-repeat: no-repeat;
      }
    }

    a:hover {
      background-color: transparent;
      color: @brand-primary;
      font-weight: bold;
    }
    &.first {
      margin-right: 3.25rem;
    }
    &.pager-first {
      a,
      span {
        &:before {
          background-image: @go-to-first-icon;
          background-size: 2rem 2rem;
        }
      }
      a:hover:before {
        background-image: @go-to-first-icon-hover;
      }
    }
    &.pager-previous {
      a,
      span {
        text-indent: -9999px;
        white-space: nowrap;
        &:before {
          background-image: @page-prev-icon;
        }
      }
      a:hover:before {
        background-image: @page-prev-icon-hover;
      }
    }
    &.pager-next {
      a,
      span {
        &:before {
          background-image: @page-next-icon;
        }
      }
      a:hover:before {
        background-image: @page-next-icon-hover;
      }
    }
    &.pager-last {
      a,
      span {
        &:before {
          background-image: @go-to-last-icon;
          background-size: 2rem 2rem;
        }
      }
      a:hover:before {
        background-image: @go-to-last-icon-hover;
      }
    }
    &.last {
      margin-left: 3.25rem;
    }

    &.pager-first,
    &.pager-previous,
    &.pager-next,
    &.pager-last {
      a {
        text-indent: -9999px;
        .trans(opacity);
        &:hover {
          color: @item-text-hover-color;
          background-color: @pager-nav-background-color;
        }
      }
    }

    &.pager-current {
      &, a, span {
        color: @brand-special;
        font-weight: 700;
      }
      a, span {
        padding: @pager-item-padding;
      }
      background-color: @current-background-color;
      border: 3px solid @current-border-color;
      border-radius: 2000px;
      text-align: center;
    }

    &.pager-ellipsis { display: none; }
  }

}
.pager-load-more {
  &, &:first-child {
    margin-top: 2.55rem;
  }
  text-align: center;
  padding: 0;

  > li.pager-next {
    margin: 0 auto;
    width: auto;
    position: relative;

    a {
      .button();
      text-indent: 0;
      width: auto;
      &:before {
        content: none;
      }
    }
    > span {
      display: none;
    }
    .ajax-progress {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 30;
      background: fade(@black, 75);
      animation: fadein 500ms;

      .throbber {
        background-image: @triple-dots--white;
        background-repeat: no-repeat;
        background-position: center;
        float: none;
        margin: 0 auto;
        width: 40px;
        height: 100%;
        animation: fadeinout 750ms infinite;
      }
    }
  }

  &.pager-load-more-empty {
    display: none;
  }
}
