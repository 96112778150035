#sliding-popup {
  padding: 1em 0;

  .popup-content {
    #popup-text {
      p {
        font-size: 14px;
      }

      a {
        color: @white;
        text-decoration: underline;
      }
    }

    #popup-buttons {
      .agree-button {
        background: transparent;
        border: 2px solid @white;
        border-radius: 100px;
        box-shadow: none;
        color: @white;
        font-weight: 700;
        margin-top: 20px;
        text-shadow: none;

        &:hover,
        &:focus {
          background: @white;
          color: @blue;
        }
      }
    }
  }
}
