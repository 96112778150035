/* --- [ 2 Cols ] --------------- */

.grid.col-2 .grid-container {
  @items: ~".views-row", ~".group-content > .views-row", ~".tile";
  .makeGrid(@items; 40px; 2);
  .resp(@tabletmax, { .makeGrid(@items; 40px; 2); });
  .resp(@mobilemax, { .makeGrid(@items; 60px, 20px; 1); });
}

/* --- [ 3 Cols ] --------------- */

.grid.col-3 .grid-container {
  @items: ~".views-row", ~".group-content > .views-row", ~".tile";
  .makeGrid(@items; 25px; 3);
  margin-top: -7px;
  margin-bottom: -7px;
  .resp(@tabletmax, {
    .makeGrid(@items; 40px; 2);
    margin: -8px -20px -20px;
  });
  .resp(@mobilemax, { .makeGrid(@items; 25px; 1); });
}


/* --- [ 4 Cols ] --------------- */

.grid.col-4 .grid-container {
  @items: ~".views-row", ~".group-content > .views-row", ~".tile";
  .makeGrid(@items; 40px; 4);
  .resp(@tabletmax, { .makeGrid(@items; 40px; 3); });
  .resp(600px, { .makeGrid(@items; 40px; 2); });
  .resp(@mobilemax, { .makeGrid(@items; 40px; 1); });
}


/* --- [ 5 Cols ] --------------- */

.grid.col-5 .grid-container {
  @items: ~".views-row", ~".group-content > .views-row", ~".tile";
  .makeGrid(@items; 20px; 5);
  .resp(@tabletmax, { .makeGrid(@items; 40px; 2); });
  .resp(@mobilemax, { .makeGrid(@items; 40px; 1); });
}


/* --- [ 6 Cols ] --------------- */

.grid.col-6 .grid-container {
  @items: ~".views-row", ~".group-content > .views-row", ~".tile";
  .makeGrid(@items; 20px; 6);
  .resp(@tabletmax, { .makeGrid(@items; 40px; 2); });
  .resp(@mobilemax, { .makeGrid(@items; 40px; 1); });
}
