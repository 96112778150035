.search-results {
  .unbullet();

  .title {
    .h4;
    a {
      text-decoration: none;
    }
  }

  .search-snippet-info {
    padding: 0;
    font-size: @caption-font-size;
  }
  .search-info {
    margin-top: 1em;
  }

  .search-result {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    border-bottom: 2px solid @neutrallight;
    > * {
      padding-bottom: .5rem;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

// Google
.gsc-table-result, .gsc-thumbnail-inside, .gsc-url-top {
  padding-left: 0 !important;
  padding-right: 0 !importantˇ;
}
