// Block Styling
// ================================

/* --- [ Mixins ] --------------- */

.plain-block {
  font-size: @h4-size;
  line-height: @fine-title-line-height;
  padding-top: 1.8rem;
  padding-bottom: 2.25rem;
  color: @medium-text-color;
  &:last-child { padding-bottom: 0; }

  .resp-min(@tabletmax + 1, {
    &:first-child { padding-top: 0; }
  });

  .front & { line-height: 1.4; }

  .region-sidebar-second & {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .resp(@tabletmax, {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  });

  .resp(@mobilemax, {
    position: relative;
    padding: 1.65rem 0 1.8rem;
    &:before {
      content: ' ';
      .abs(0, 0, auto, 0);
    }
  });
  .block-title,
  > .field > .field-label,
  .wysiwyg-heading {
    .section-heading();
    .resp-min(@mobilemax + 1, {
      .h2();
      margin-bottom: 1.5rem;
    });
    .resp-range(@mobilemax + 1, @tabletmax, {
      margin-bottom: 1.3rem;
    });
    .resp(@mobilemax, {
      color: @medium-text-color;
      margin-bottom: .75rem;
    });
  }
}

.plain-block--quiet {
  padding-top: 1.8rem;
  padding-bottom: 2.25rem;
  color: @medium-text-color;
  line-height: 1.45;
  &:last-child { padding-bottom: 0; }

  > .content {
    font-size: @base-font-size;
  }

  .resp-min(@tabletmax + 1, {
    &:first-child { padding-top: 0; }
  });

  .resp(@tabletmax, {
    .front & { max-width: 550px; }
    margin: 0 auto;
  });

  .front .region-sidebar-second & {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .resp(@tabletmax, {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  });

  .resp(@mobilemax, {
    position: relative;
    padding: 1.65rem 0 1.8rem;
    .region-sidebar-second & {
      padding: 2.05rem 1.2rem 1.8rem;

      &:last-child {
        padding-bottom: 0;
      }
    }
  });
  .block-title {
    .section-heading();
    .resp-min(@mobilemax + 1, {
      .h2();
      margin-bottom: 1.5rem;
    });
    .resp-range(@mobilemax + 1, @tabletmax, {
      margin-bottom: 2.8rem;
    });
    .resp(@mobilemax, {
      color: @brand-primary;
      margin-bottom: 1.15rem;
    });
  }

  .field-label,
  .label-inline,
  .label-above {
    font-size: 1rem;
    margin-bottom: 0;

    + * { margin-top: 0; }
  }
  .label-above {
    margin-top: 0.3rem;
    margin-bottom: 0.2rem;
  }
  .label-inline { font-weight: normal; }
}

.sub-block {
  .plain-block();
  .resp(@mobilemax, { margin-top: 1.65rem; });

  .block-title {
    .h3();
    margin-bottom: 1.25rem;
    .resp(@tabletmax, { text-align: initial; });
  }

  .item-list {
    ul {
      .group-meta();
    }
  }
}

.system-block {
  background-color: @background;
  padding: 1.2rem 1.75rem 1.975rem;
  .resp(@tabletmax, {
    padding: 1.15rem 1.7rem 1.85rem;
  });

  .resp(@mobilemax, {
    padding: 1.05rem 1.25rem 1.55rem;
  });

  .block-title {
    .block-title();
    margin-bottom: 1.4rem;
    .resp(@tabletmax, {
      text-align: initial;
      .h3();
    });
    .resp(@mobilemax, {
      text-align: initial;
      margin-bottom: 1.4rem;
    });
  }

  .form-item {
    margin-bottom: .55rem;
    .resp(@mobilemax, { margin-bottom: .4rem; });
    ~ .form-item {
      margin-top: 1.35rem;
      .resp(@mobilemax, { margin-top: .8rem; });
    }
  }

  .form-actions {
    margin-top: 1.35rem;
    .resp(@tabletmax, { margin-top: 1.3rem; });
    .resp(@mobilemax, { margin-top: 1.1rem; });
    text-align: center;
    .form-submit {
      border: 3px solid;
      padding: 0.5rem 1em .45rem;
      width: 86%;

      .button-cta();

      .resp(@tabletmax, {
        font-size: @base-font-size;
        padding: 0.4rem 1em .5rem;

        background-color: @button-cta-color;
        border-color: @button-cta-color;
        color: @white;
        border-width: 3px;
        margin-top: 0;

        &:hover {
          background-color: transparent;
          color: @button-cta-color;
        }
      });

      .resp(@mobilemax, {
        font-size: @caption-font-size;
        padding: 0.5rem 1em .5rem;
        width: ~"calc(100% - 8px)";
        display: block;
        margin: 0 4px;
      });
    }
  }

  label:not(.option) {
    display: none;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    border-width: 3px;
    padding: .5rem .85rem;
  }

  .item-list ul {
    .unbullet();
    font-size: @caption-font-size;
    padding: 0 .15rem;
  }
}

.notification-bar(@bar; @severity: high; @float-on-mobile: FALSE) {
  @{bar} {
    padding: 1rem 1.5rem;
    margin-bottom: 3rem;
    position: relative;
    background-image: none;
    border: none;

    & when (@severity = high) {
      background-color: @brand-danger;
      color: @white;
    }

    & when (@severity = warning) {
      background-color: @gold;
      color: @dark-text-color;
    }

    & when (@severity = success) {
      background-color: @neutrallight;
      color: @dark-text-color;
    }

    & when (@severity = normal) {
      background-color: fade(@background, 50%);
      color: @medium-text-color;
    }

    & when (@float-on-mobile = true) {
      .resp(@mobilemax, {
        position: absolute;
        top: 3.45rem;
        left: -.75rem;
        right: -.75rem;
        z-index: 10;
      });
    }
    &.hasClose {
      display: block;
      padding-right: 8.75rem;
      max-height: 2000px;
      overflow: hidden;
      display: block;
      transform-origin: top center;
      backface-visibility: hidden;
      position: relative;
      .transform(scale3d(1,1,1));
      .trans(transform, 150ms, ease, 0ms);
      .trans(max-height, 150ms, ease, 0ms);
      .trans(margin-bottom, 150ms, ease, 0ms);
      .trans(padding, 0ms, ease, 150ms);

      .resp(@mobilemax, {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 0;
        padding: 1.75rem 2rem 1.3rem;
      });
    }

    &.isClosed {
      padding: 0;
      max-height: 0;
      margin-bottom: 0;
      .transform(scale3d(1,0,1));
    }
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        color: @brand-success;
      }
      & when not (@severity = high) {
        &, &:hover { color: @brand-primary; }
        text-decoration: none;
        &:hover { text-decoration: underline; }
      }
    }
    .button,
    button {
      color: @white;
      border-color: @white;
    }
    .close {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: auto;
      border: none;
      width: 8.75rem;
      .text { .element-invisible; }

      .resp(@mobilemax, {
        .abs(0,0,auto,auto);
        width: 2.6rem;
        padding: 0;
        line-height: 1;
      });

      &:after {
        content: '\00d7';
        font-size: 2.25rem;
      }
    }

    .resp(@tabletmax, {
      margin-bottom: 2.5rem;
    });
  }
}

.callout-block {
  margin-bottom: 1rem;
  border: 4px solid @neutrallight;
  .block-title {
    padding: 1rem;
    color: @brand-secondary;
    text-align: center;
    border-bottom: 4px solid @neutrallight;
  }
  .content {
    padding: 1.188rem 1.375rem 1.875rem;
  }
}

.front {
  .region-sidebar-second {
    .block {
      .callout-block;
    }
  }
}

.block-dc-ajax-add-cart {
  .callout-block;
  position: sticky;
  top: 10px;
  min-width: 270px;
//  position: relative;
  .block-title {
    text-align: left;
  }
  .content {
    .ajax-shopping-cart-edit-link {
      position: absolute;
      right: 1.375rem;
      top: 1.375rem;
    }
    .ajax-shopping-cart-wrapper {
      width: 100%;
      .remove-from-cart .text {
        .icon-inline-after(@small-x--red, .5rem);
        display: inline-block !important;
        vertical-align: middle;
        width: 10px;
        height: 4px;
        background: @white;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        text-indent: -9999px;
        transition: background-color 150ms ease;
        text-align: center;
      }
      // .name {
      //   .icon-before(@small-x--brightblue, .5rem);
      //   &::before {
      //     margin-top: .5rem;
      //   }
      // }
      .ajax-shopping-cart-table {
        border-collapse: collapse;
        thead {

        }
        .ajax-cart-row {
          td {
            border-bottom: 2px solid @neutrallight;
            padding: .5rem;
            padding-left: 0;
            .p;
            margin-top: 0;
            &:last-child {
              text-align: right;
              padding-right: 0;
            }
//             &.name {
// //              .h4;
//               color: @brand-primary;
//             }
          }
          &:last-child {
            td {
              border-bottom: 4px solid @neutrallight;
            }
          }
        }
      }
      .ajax-shopping-cart-more-info {
        text-align: center;
        div:last-child {
          padding-bottom: 0;
        }
      }
      .ajax-shopping-cart-total {
        float: none;
        .h3;
        width: 100%;
        padding: 1.5rem 0;
      }
      .ajax-shopping-cart-checkout {
        clear: left;
        float: none;
        a {
          .button-cta;
          width: 100%;
        }
        a,
        .text {
          .font-smoothing(antialiased);
          color: @button-text-color;
          text-decoration: none;
          &:hover {
            color: @button-text-color;
            text-decoration: none;
          }
          &:active {
            color: @button-text-color;
            transition: none;
            text-decoration: none;
          }
        }
      }
    }
  }
  .resp(@tabletmax, {
    margin-top: 3rem;
  })
}

/* --- [ Plain Blocks ] --------------- */

.plain-sidebar-blocks() {
  .resp(@tabletmax, { text-align: center; });

  > .block,
  > .ds-region {
    .plain-block();

    ~ .block,
    ~ .ds-region {
      .resp(@mobilemax, {
        border-top: none;
        padding-top: 1.8rem;
      });
    }

    > .field {
      padding-bottom: 2.25rem;
      padding-bottom: 3.05rem; // See: /ex-about/example-generic-page
      &:last-child { padding-bottom: 0; }
      .resp(@tabletmax, {
        padding-bottom: 2.5rem;
        &:last-child { padding-bottom: 0; }
      });
      .resp(@mobilemax, {
        padding-bottom: 1.8rem;
        &:last-child { padding-bottom: 0; }
      });
      ~ .field {
        padding-top: 1.8rem;
        .resp(@tabletmax, { padding-top: 2.15rem; });
        .resp(@mobilemax, { padding-top: 1.65rem; });
      }
      .field-contents {
        margin-top: 2.65rem;
        &:first-child { margin-top: 0; }
      }
    }
  }
}

.white-block {
  font-size: @h4-size;
  line-height: @fine-title-line-height;
  padding: 1.8rem 2.1rem 2.25rem;
  color: @medium-text-color;
  background-color: @white;

  .region-sidebar-second & {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .resp(@tabletmax, {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  });

  .resp(@mobilemax, {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  });

  .block-title,
  > .field > .field-label,
  .wysiwyg-heading {
    .section-heading();
    .resp-min(@mobilemax + 1, {
      .h2();
      margin-bottom: 1.5rem;
    });
    .resp-range(@mobilemax + 1, @tabletmax, {
      margin-bottom: 1.3rem;
    });
    .resp(@mobilemax, {
      color: @medium-text-color;
      margin-bottom: .75rem;
    });
  }
}

.white-sidebar-blocks() {
  // .resp(@tabletmax, { text-align: center; });
  // Turned this off because I'm not sure it's needed here

  > .block,
  > .ds-region {
    .white-block();

    ~ .block,
    ~ .ds-region {
      margin-top: 2rem;

      .resp(@mobilemax, {
        border-top: none;
        padding-top: 1.8rem;
      });
    }

    > .field {
      padding-bottom: 2.25rem;
      padding-bottom: 3.05rem;
      &:last-child { padding-bottom: 0; }
      .resp(@tabletmax, {
        padding-bottom: 2.5rem;
        &:last-child { padding-bottom: 0; }
      });
      .resp(@mobilemax, {
        padding-bottom: 1.8rem;
        &:last-child { padding-bottom: 0; }
      });
      ~ .field {
        padding-top: 1.8rem;
        .resp(@tabletmax, { padding-top: 2.15rem; });
        .resp(@mobilemax, { padding-top: 1.65rem; });
      }
      .field-contents {
        margin-top: 2.65rem;
        &:first-child { margin-top: 0; }
      }
    }
  }
}

.region-sidebar-second {
  .block-menu-block .menu-block-wrapper {
    .aside-menu(@menu: ~"> .menu");
  }
}

.node-type-listing-page, // See: /research/databases
.node-type-database-detail, // See: /research/databases/fastcase
.node-type-landing-page, // See: /membership
.not-front.node-type-page, // See: /about
.node-type-blog-post, // See: /blog/*
.page-search // See: /search/node/virtual
{
  .region-sidebar-second-inner {
    .plain-sidebar-blocks();
  }
}


// See: /
.block-visitor-info {
  .plain-block--quiet();
  a {
    .remove-icon-inline-before();
    display: initial;
  }
}

// See: /research/guides
.block-research-contact-info,
.block[id*="research-contact-info"] {
  .plain-block--quiet();
}


/* --- [ Plain Sub-Blocks ] --------------- */

// See: /research/guides/federal-taxation/introduction
.block-guide-categories,
.block[id*="guide-categories"] {
  .sub-block();
}


/* --- [ System Blocks ] --------------- */

// See: / (login block)
.block-login,
.block[id*="user-login"] {
  .system-block();
}



/* --- [ Pager block ] --------------- */

.block-guide-pager,
.block[id*="guide-pager"] {
  background-color: @background;
  margin-top: 1.8rem;
  .resp(@tabletmax, {
    margin-top: 2.15rem;
  });
  .resp(@mobilemax, {
    margin-top: 1.65rem;
  });

  > .content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;

    > * {
      flex: 1 0 50%;
    }
  }

  .prev-tab,
  .next-tab {
    a {
      display: block;
      padding: 1.2rem 1.35rem;
      .resp(@tabletmax, {
        padding: .75rem 1.5rem;
      });
      .resp(@mobilemax, {
        padding: .6rem .75rem;
      });
    }
  }

  .prev-tab {
    text-align: left;
  }
  .next-tab {
    text-align: right;
  }
}


/* --- [ Prompt/Notification block ] --------------- */

.notification-bar(~'.block.notification'; @float-on-mobile: true); // See: /research/databases

//.notification-bar(~'.db-access-wrap'; @severity: normal); // See: /research/databases/example-database //// currently commenting this out, as I have no idea why it was included in the first place. - RM
