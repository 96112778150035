
// Carousel
// ================================
// Using Owl Carousel

.carousel() {
  .owl-carousel {
    margin: 0 auto;
    position: relative;
    .owl-stage-outer {
      overflow: visible;
    }
    .owl-item {
      .resp(@mobilemax, {
        //margin-bottom: 2rem;
        &:nth-child(3) ~ .owl-item {
          display: none;
        }
      });
    }
  }
}

.owl-controls {
  .owl-dots {
    .owl-dot {
      display: block;
    }
  }
}
.region-preface-first-inner {
  // See: <front>
  > .content {
    position: relative;
    .carousel();
  }
}
