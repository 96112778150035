
// Sidebar Items
// ================================

/* --- [ Mixins ] --------------- */

.aside-menu-item {
  .h4;
  letter-spacing: 0.04rem;
  color: @white;
  font-weight: 400;
  display: block;
  position: relative;

  &, &:hover, &.active, &:active {
    text-decoration: none;
  }
}

.aside-menu-checkbox {
  display: block;
  position: relative;
  margin: 0;
  margin-bottom: 1.1rem;
  &, label {
    display: block;
  }
  label {
    padding: .125rem 1.35rem .025rem;
    padding-left: 2.25rem;
    cursor: pointer;
    margin: 0;
  }

  input {
    .abs(.1875rem,auto,auto,.125rem);
    margin: 0;
  }

  .resp-range(@mobilemax + 1, @tabletmax, {
    margin-bottom: 0;
    label {
      padding: .6375rem 1.55rem;
      padding-left: 1.7rem;
    }
    input {
      .abs(.75rem,auto,auto,.125rem);
    }
  });

}

.aside-menu-sub-item {
  .p;
  color: @white;
  padding-left: 1.25rem;
  display: block;
  &:before {
    content: none;
  }
}


.aside-filter-form(@column-context: ~".bef-select-as-links") {
  .reset-views-exposed-form();
  .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
    background-color: @background;
    padding: 1.2rem 1.35rem;
    position: relative;

    .resp(@tabletmax, {
      padding: .75rem 1.5rem;
      margin: 0;
      width: auto;
      min-width: 100%;
    });

    .resp(@mobilemax, {
      padding: .6rem .75rem;
      margin: 0 -0.75rem;
    });

    > label:first-child {
      .aside-title-underlined();
      display: block;
      position: relative;
    }

    .views-widget {
      overflow: visible;
      > .form-item {
        margin: 0 -1.35rem;
        .resp(@tabletmax, {
          margin: 0;
          .bef-select-as-links {
            > .form-item {
              position: relative;
              width: 100%;
              > * {
                display: inline-block;
                width: 100%;
              }
            }
          }
        });
        @{column-context} {
          .resp-range(@mobilemax + 1, @tabletmax, {
            position: relative;
            > .form-item {
              column-count: 2;
              column-gap: 0;
            }
            &:before, &:after {
              content: ' ';
              display: block;
              .abs(.6375rem, auto, .6375rem, auto);
              margin-left: -1px;
              border-left: 2px solid @hr-color;
            }
            &:before {
              left: 0;
            }
            &:after {
              left: auto;
              right: 50%;
            }
          });
        }
        .resp(@mobilemax, {
          margin: 0 -.75rem;
          .bef-select-as-links > .form-item {
            columns: auto;
            &:before, &:after { content: none; }
            > * {
              display: block;
            }
          }
        });
      }
    }

    .form-type-bef-link > a {
      .aside-menu-item();
    }
  }
}

.aside-filter-multi(@label: ~'.block-title',@column-context: ~".bef-checkboxes") {
  background-color: @white;
  padding: 1.2rem 1.35rem;
  position: relative;

  .asidePane {
    background-color: @white;
  }
  .resp(@tabletmax, {
    padding: .75rem 1.5rem;
    margin: 0;
    width: auto;
    min-width: 100%;
  });
  .resp(@mobilemax, {
    padding: .6rem .75rem;
    margin: 0;
  });
  @{label} {
    .aside-title-underlined();
    display: block;
    position: relative;
  }
  form {
    .reset-views-exposed-form();

    .views-exposed-form > .views-exposed-widgets {
      position: static;
      > .views-exposed-widget {

        .views-widget {
          overflow: visible;
          > .form-item {

            .form-checkboxes {
              margin-top: .6rem;
            }
            // needed for dumb chrome bug for columns
            @{column-context} {
              //margin-top: .6rem;
              max-height: 28.25rem;
              overflow-y: auto;

              // .scrollbar();
              > .scroll-element {
                background-color: transparent;
                border: none;
                margin: 0;
                padding: 0;
                position: absolute;
                z-index: 10;
                div {
                  border: none;
                  display: block;
                  height: 100%;
                  left: 0;
                  margin: 0;
                  padding: 0;
                  position: absolute;
                  top: 0;
                  width: 100%;
                  z-index: 10;
                }
                .scroll-element_outer {
                  border-radius: 2000px;
                  overflow: hidden;
                }
                .scroll-element_track {
                  background-color: @scrollbar-track;
                  border-radius: 2000px;
                }
                .scroll-bar {
                  background-color: fade(@scrollbar-thumb, 80%);
                  border-radius: 2000px;
                  &:hover {
                    background-color: @scrollbar-thumb;
                  }
                }

                &.scroll-x {
                  top: auto;
                  bottom: .125rem;
                  height: .75rem;
                  left: 0;
                  width: 100%;
                  margin-left: 0;
                  .scroll-element_outer {
                    height: .75rem;
                    top: 2px;
                  }
                  .scroll-bar {
                    min-width: .75rem;
                  }
                }
                &.scroll-y {
                  height: 100%;
                  right: 0;
                  bottom: 0;
                  width: .75rem;
                  .scroll-element_outer {
                    left: 2px;
                    width: .75rem;
                  }
                  .scroll-bar {
                    min-height: .75rem;
                  }
                }
                &.scroll-draggable {
                  .scroll-bar {
                    background-color: @scrollbar-thumb;
                  }
                }
                &.scroll-scrolly_visible {
                  right: .125rem;
                  margin-left: .75rem;
                  &.scroll-x {
                    margin-left: 0;
                  }
                }
                &.scroll-scrollx_visible {
                  margin-top: .75rem;
                  top: -.75rem;
                  &.scroll-x {
                    top: auto;
                  }
                }
                &.scroll-x.scroll-scrolly_visible {
                  .scroll-element_track {
                    left: -.875rem;
                  }
                  .scroll-element_size {
                    left: -.875rem;
                  }
                }
                &.scroll-y.scroll-scrollx_visible {
                  .scroll-element_track {
                    top: -.875rem;
                  }
                  .scroll-element_size {
                    top: -.875rem;
                  }
                }
              }

              .resp(@tabletmax, {
                max-height: 50vh;
              });
              .resp-range(@mobilemax + 1, @tabletmax, {
                position: relative;
              });
              .resp-range(@baby_bear + 1, @tabletmax, {
                margin: 0;
                margin-top: .6rem;
                position: relative;
                // &.scroll-content {
                //   columns: 2;
                //   column-gap: 0;
                //   width: 100%;
                // }
                > .form-item {
                  display: inline-block;
                  width: 100%;
                }
              });
              .resp(@mobilemax, {
                margin: 0;
                margin-top: .6rem;
                &.scroll-content {
                  columns: auto;
                  width: auto;
                }
                min-width: 100%;
                > .form-item {
                  display: block;
                }
              });
            }
          }
        }

        &.views-submit-button,
        &.views-reset-button {
          margin-left: -.65rem;
          margin-right: -.65rem;
          input {
            min-width: 100%;
            width: ~'calc(100% + 1.3rem)';
          }

          .resp(@mobilemax, {
            margin-left: 0;
            margin-right: 0;
            input {
              min-width: 100%;
              width: 100%;
            }
          })
        }

        &.views-reset-button {
          input {
            border: none;
            padding: 0;
            text-align: left;
            margin-top: 1rem;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
              color: @brand-primary;
            }
          }
        }

        .form-type-bef-checkbox {
          .aside-menu-checkbox();
        }
      }
      .ajax-progress {
        .resp(@tabletmax, {
          .abs(-2rem,0,0,0);
        });
        .resp(@goldilocks, {
          .abs(-2rem,0,0,0);
        });
        .resp(@mobilemax, {
          .abs(-2rem,-.75rem,-2.05rem,-.75rem);
        });
      }
    }
  }
}

.aside-menu(@title_el: ~'.block-title', @menu: ~".item-list > ul", @column-context: ~".menu-block-wrapper") {
  background-color: @background;
  position: relative;

  .resp(@tabletmax, {
    margin: 0;
    width: auto;
    min-width: 100%;
  });
  @{title_el} {
    .aside-title-underlined();
    display: block;
    position: relative;
  }
  // needed for dumb chrome bug for columns
  @{column-context} {
    .resp-range(@mobilemax + 1, @tabletmax, {
      position: relative;
      &:before, &:after {
        content: ' ';
        display: block;
        .abs(.6375rem, auto, .6375rem, auto);
        margin-left: -1px;
        border-left: 2px solid @hr-color;
      }
      &:before {
        left: 0;
      }
      &:after {
        left: auto;
        right: 50%;
      }
    });
  }
  @{menu} {
    .unbullet();
    margin-bottom: 1.125rem;
    max-width: 350px;

    .resp(@tabletmax, {
      max-width: 100%;
    });

    > li {
      margin-bottom: 1.125rem;
      background: @brand-accent;
      text-align: left;
      &.active-trail {
        background-color: @brand-secondary;
      }
      &.active {
        .tlAccSwitchWrap > a .text,
        > a .text {
          text-decoration: underline;
        }
      }
      > div > a,
      > a {
        .aside-menu-item();
      }
      > .tlAccSwitchWrap,
      > a {
        padding: 1rem 1.5rem 1.188rem 1.5rem;
      }
      .tlAccSwitchWrap,
      .slAccSwitchWrap {
        display: flex;
        flex-flow: row;
        justify-content: space-between;

        > * {
          width: 100%;
        }
        > a {
          flex-shrink: 1;
          &:active .text,
          &:hover .text {
            text-decoration: underline;
          }
        }
      }
      > a:hover .text {
        text-decoration: underline;
      }
      .tlAccSwitch,
      .slAccSwitch {
        position: relative;
        width: 23px;
        margin-left: 5px;
        padding: 0;
        &:before {
          content: ' ';

          .abs-0();
          width: auto;

          .resp(@mobilemax, {
            .abs(0, auto, 0, -50%);
            width: 200%;
          });
        }
        .text {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;/*rtl:ignore*/
          width: 100%;
          height: 100%;
          border-radius: 2000px;
          text-indent: -9999px;
          white-space: nowrap;
          overflow: hidden;
          pointer-events: none;
          background-color: transparent;
          background-image: @dropdown-arrow--white;
          background-repeat: no-repeat;
          background-position: left center;
          background-size: 20px;
          transform-origin: center;
          .t-x(-50%);
          .t-y(-50%);
          .t-scale3d(~"1,1,1");
          transition: transform 700ms ease 0ms, background-color 150ms ease 0ms;
        }

        &:active .text,
        &:hover .text {
          background-color: transparent;
        }

        &.isOpen {
          .text {
            transform: translateX(-50%) translateY(-50%) scale3d(1,1,1) rotate(180deg);
            background-color: transparent;
          }
        }
      }
      @{menu} {
        .unbullet();
        margin: 0;
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        padding-bottom: 1.875rem;
        columns: auto;
        display: block;

        > li {
          border-left: 3px solid @white;
          padding-bottom: .625rem;
          &:last-child {
            padding-bottom: 0;
          }
          > a {
            .aside-menu-sub-item();
          }
        }
        &.isClosed {
          display: none;
        }
      }
    }
  }
}

.aside-basic_box(@title_el: ~'.block-title') {
  background-color: @background;
  padding: 1.2rem 1.35rem;
  position: relative;

  .resp(@tabletmax, {
    padding: .75rem 1.5rem;
    margin: 0;
    width: auto;
    min-width: 100%;
  });
  .resp(@mobilemax, {
    padding: .6rem .75rem;
    margin: 0 -0.75rem;
  });

  @{title_el} {
    .aside-title-underlined();
    display: block;
    position: relative;
  }

  .link-tray {
    text-align: center;

    padding-top: 1.5rem; // 10px
    border-top: 2px solid @hr-color;
    margin-top: 1.1875rem; // 19px

    .resp-range(@mobilemax + 1, @tabletmax, {
      margin-top: 1rem;
    });

    .resp(@mobilemax, {
      padding-bottom: .75rem;
    });

    a {
      .button();
      &[href*="login"] { .button-cta(); }

      &, &[href] { display: block; }
    }

    .button-dim {
      cursor: none;
      pointer-events: none;
    }

    .separator {
      display: block;
    }

    > *, > a {
      &, &:first-child, &:last-child, &[href] { margin-bottom: .5rem; }
    }
  }
}

.block-access-prompt {
  .aside-basic_box();
}



/* --- [ Apply Mixins ] --------------- */

// See: /research/databases
[id*="form-database-details"] {
  .aside-filter-form();
}


// See: /blog
.block-views-exp-blog-posts-blog,
.block-views[id*="-exp-blog-posts-blog"] {
  .aside-filter-multi(~".block-title");
}

// See: /staff/adrienne-e-brown
// See: /board/trustees/andrew-r-duffy
.not-front .region-sidebar-first-inner,
.not-front.node-type-page .region-content,
.not-front.node-type-news-post .region-content {
  .group-inset,
  .region-to-block {
    border: 4px solid @neutrallight;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    .field-profile-image  {
      margin: -4px;
      width: inherit;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-bottom: 4px solid @neutrallight;
      img {
        display: block;
      }
      &.square {
        .resp(@tabletmax, {
          text-align: center;
        });
      }
    }
    .field {
      padding: 1rem 1.375rem;
      * {
        .p;
        color: @slate;
        margin-top: 0;
        text-transform: none;
        font-weight: 400;
        .font-smoothing(subpixel-antialiased);
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      &.field-pull-quote {
        &,
        .field-contents {
          .h3;
        }
        .resp(@mobilemax, {
          text-align: center;
        });
      }
      &.field-focus-areas,
      &.field-funded-since,
      &.field-executive-director,
      &.field-num-staff,
      &.field-news-categories,
      &.field-related {
        .field-label .text {
          .h6;
          text-transform: uppercase;
          color: @slate;
        }
      }
      &.field-social-share {
        border-top: 4px solid #E6E6E6;
      }
      &.field-link,
      &.field-website {
        border-top: 4px solid #E6E6E6;
        .icon-inline-after(@get--directions-blue, 1rem);
        &:after {
          right: 24px;
          width: 1rem;
          height: 1rem;
        }
        .text {
          .h6;
          color: @brand-primary;
        }
      }
      ul.field-contents {
        .unbullet;
      }
    }
  }
  .resp(@mobilemax, {
    .ds-media {
      .ds-media-text {
        padding: 1rem 0.6rem;
        .field-title h3 {
          font-size: 1.25rem;
        }
      }
    }
    .group-inset {
      width: 100%;
      float: none;
    }
  });
}

// See: /research/guides
[id*="form-research-guides"] {
  .aside-filter-form();
}

// See: /research/guides/federal-taxation/introduction
.block-guide-contents,
.block[id*="guide-content"] {
  .aside-menu(~".block-title", ~".item-list > ul", ~"> .content > .item-list");
}


// See: /search/google/education
.block-more-search-options,
.block[id*="more-search-options"] {
  .item-list > ul {
    .aside-list();
  }
}


/* --- [ Toggle Styling ] --------------- */

.asideSwitch {
  display: none;
  background: transparent;
  .resp(@tabletmax, {
    display: inline-block;
    .abs(0, 0, 0, 0);
    width: 100%;
    padding: 0;

    .text {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;/*rtl:ignore*/
      width: 100%;
      height: 100%;
      border-radius: 2000px;
      text-indent: -9999px;
      white-space: nowrap;
      overflow: hidden;
      pointer-events: none;
      background-color: transparent;
      background-image: @dropdown-arrow--gray;
      background-repeat: no-repeat;
      background-position: right center;
      background-size: .875rem auto;
      transform-origin: center;
      .t-x(-50%);
      .t-y(-50%);
      .t-scale3d(~"1,1,1");
      transition: transform 150ms ease 0ms, background-color 150ms ease 0ms;
    }

    &.isOpen {
      background: transparent;
      .text {
        .t-x(-50%);
        .t-y(-50%);
        .t-scale3d(~"1,-1,1");
        background-color: transparent;
      }
    }

    &:before {
      content: ' ';
      .abs-0();
      width: auto;
    }
    &:after {
      content: ' ';
      .abs(-.75rem, -1.5rem, -.75rem, -1.5rem);
    }
  });
  .resp(@mobilemax, {
    &:after {
      .abs(-.6rem, -.75rem, -.6rem, -.75rem);
    }
  });
}

.asidePane {
  .resp(@tabletmax, {
    #ma-toggle > .pane();
    overflow: visible; // needed for active border
    background-color: @background;
    .abs(auto, 0, auto, 0);
    z-index: 10;
    padding-top: 1.735rem;
    padding: 2.375rem 2.5rem 1.3rem;

    // See: /blog
    .block-views-exp-blog-posts-blog &,
    .block-views[id*="-exp-blog-posts-blog"] & {
      // padding: 2.375rem 1.5rem 1.3rem .5rem;
    }

    &:before {
      content: ' ';
      .abs(1.085rem, 1.5rem, auto, 1.5rem);
      display: block;
      border-top: 2px solid @hr-color;
    }
  });
  .resp(@mobilemax, {
    padding: 1.335rem 0 0;
    position: static;
    &:before {
      .abs(.45rem, 0, auto, 0);
    }
  });
}
