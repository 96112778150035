
// Share Links
// ================================

// See: /blog/*

.share-links {
  .unbullet();
  letter-spacing: -1em;
  line-height: 0;

  > * {
    letter-spacing: normal;
    line-height: normal;
  }

  > li {
    padding: .75rem;
    display: inline-block;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }

    > a {
      width: 1.875rem;
      height: 1.875rem;
      display: block;
      position: relative;
      background: transparent;

      &:before {
        content: ' ';
        .abs-0();
        .trans(opacity);
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 1.875rem;
        background-color: transparent;
      }

      &.facebook {
        &:before { background-image: @share-facebook; }
      }
      &.mail, &.email {
        &:before { background-image: @share-email; }
      }
      &.twitter {
        &:before { background-image: @share-twitter; }
      }
      &.linkedin {
        &:before { background-image: @share-linkedin; }
      }


      .text { .element-invisible; }
    }
  }
}
