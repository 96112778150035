/* --- [ Variables and Mixins ] --------------- */
/* --- [ Typography ] --------------- */
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400italic,600,700,700italic);
.js-breaks__mobilemax {
  width: 480px;
}
.js-breaks__baby_bear {
  width: 580px;
}
.js-breaks__goldilocks {
  width: 700px;
}
.js-breaks__tabletmax {
  width: 960px;
}
.js-breaks__mama_bear {
  width: 1100px;
}
.js-breaks__berenstain {
  width: 1200px;
}
.js-breaks__grizzly {
  width: 1400px;
}
/* --- [ Reusable Styles ] --------------- */
div.field.field-organization:nth-of-type(4n+1) {
  display: none;
}
.clearly {
  zoom: 1;
}
.clearly:before,
.clearly:after {
  content: " ";
  display: table;
}
.clearly:after {
  clear: both;
}
.cf,
.clearfix {
  zoom: 1;
}
.cf:before,
.clearfix:before,
.cf:after,
.clearfix:after {
  content: " ";
  display: table;
}
.cf:after,
.clearfix:after {
  clear: both;
}
.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}
.normalcase {
  text-transform: none;
  letter-spacing: normal;
}
#text-shadow.extrude-soft {
  text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.46);
}
#text-shadow.extrude-hard {
  text-shadow: 0 1px 0px rgba(0, 0, 0, 0.25), 0 1px 1px rgba(0, 0, 0, 0.325), 0 2px 3px rgba(0, 0, 0, 0.25), 0 -1px 1px rgba(0, 0, 0, 0.12);
}
.abs-0 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.rel-auto {
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
.abs-reset {
  position: static;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
._baseline {
  height: 1rem;
}
.hasLocalCSS {
  position: relative;
}
.hasLocalCSS [id^="__lpform"] {
  display: none;
}
.baselineOverlay {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to top, rgba(0, 255, 255, 0.5) 0, transparent 1px, transparent 100%);
  background-size: 1rem 1rem;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  margin: 0;
}
@media only screen and (max-width: 480px) {
  body {
    font-size: 12px;
    line-height: 1.4;
  }
}
#messages,
.messages {
  overflow: auto;
}
#messages li:before,
.messages li:before {
  margin-left: -1rem;
}
#messages.error .error,
.messages.error .error {
  color: #FFFFFF;
}
.error {
  color: #d0011b;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
a#main-content + h1,
a#main-content + h2,
a#main-content + h3,
a#main-content + h4,
a#main-content + h5,
a#main-content + h6,
.contextual-links-wrapper + h1,
.contextual-links-wrapper + h2,
.contextual-links-wrapper + h3,
.contextual-links-wrapper + h4,
.contextual-links-wrapper + h5,
.contextual-links-wrapper + h6 {
  margin-top: 0;
}
.h1,
.h1--small,
.h1--special,
.billboard-title {
  color: #4d5a6b;
  font-size: 3rem;
  font-weight: 700;
  line-height: 0.979;
  text-transform: none;
  letter-spacing: 0.016rem;
}
.h1--small {
  font-size: 2.25rem;
  line-height: 1.167;
  letter-spacing: 0.012rem;
}
h1 {
  color: #4d5a6b;
  font-size: 3rem;
  font-weight: 700;
  line-height: 0.979;
  text-transform: none;
  letter-spacing: 0.016rem;
}
@media only screen and (max-width: 480px) {
  h1 {
    font-size: 2.25rem;
    line-height: 1.167;
    letter-spacing: 0.012rem;
  }
}
.h1--special {
  color: #FFFFFF;
}
@media only screen and (max-width: 480px) {
  .h1--special {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
  }
}
.h2,
.h2--small,
.h2--special {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
}
.h2--small {
  font-size: 1.6875rem;
  line-height: 1.556;
  letter-spacing: 0.012rem;
}
h2 {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
}
@media only screen and (max-width: 960px) {
  h2 {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
  }
}
.h2--special {
  color: #FFFFFF;
}
@media only screen and (max-width: 480px) {
  .h2--special {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
  }
}
.h3,
.h3--small {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
}
@media only screen and (max-width: 480px) {
  .h3,
  .h3--small {
    font-size: 1.25rem;
  }
}
h3 {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
}
@media only screen and (max-width: 480px) {
  h3 {
    font-size: 1.25rem;
  }
}
.h3--small {
  font-size: 1.25rem;
}
.h4,
.select-option,
.chosen-container-single .chosen-single,
.activeSelection .chosen-container-single .chosen-single,
.chosen-container-single .chosen-search input[type="text"],
.chosen-container .chosen-results li {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
}
h4 {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
}
.h5,
.teaser-title {
  color: #4d5a6b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
}
h5 {
  color: #4d5a6b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
}
.h6,
.billboard-subhead,
select,
select {
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
}
h6 {
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
}
.wysiwyg-heading {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  margin-top: 2.35rem;
  margin-bottom: 1.7rem;
}
@media only screen and (max-width: 480px) {
  .wysiwyg-heading {
    font-size: 1.25rem;
  }
}
.wysiwyg-heading:first-child {
  margin-top: 0;
}
.wysiwyg-subheading {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  margin-top: 2.35rem;
  margin-bottom: 1.7rem;
}
@media only screen and (max-width: 480px) {
  .wysiwyg-subheading {
    font-size: 1.25rem;
  }
}
.wysiwyg-subheading:first-child {
  margin-top: 0;
}
.subtitle {
  margin: 0;
}
.title-with-fancy-rule {
  overflow: hidden;
  text-align: center;
  margin-bottom: 0;
}
.title-with-fancy-rule + * {
  margin-top: 40px;
}
@media only screen and (max-width: 960px) {
  .title-with-fancy-rule + * {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .title-with-fancy-rule + * {
    margin-top: 20px;
  }
}
.title-with-fancy-rule + *.field-sign-post-text {
  margin-top: 0;
}
.title-with-fancy-rule > * {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
  max-width: 80%;
  padding: 0 28px;
}
.title-with-fancy-rule > *:before,
.title-with-fancy-rule > *:after {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 2px;
  width: 1200px;
  position: absolute;
  top: 50%;
  bottom: auto;
  transform: translateY(calc(-50% + 1px));
  border-top: 1px solid #D7D7D7;
}
.title-with-fancy-rule > *:before {
  right: 100%;
}
.title-with-fancy-rule > *:after {
  left: 100%;
}
.fancy-heading-wrapper {
  overflow: hidden;
  text-align: center;
  margin-bottom: 0;
}
.fancy-heading-wrapper + * {
  margin-top: 40px;
}
@media only screen and (max-width: 960px) {
  .fancy-heading-wrapper + * {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .fancy-heading-wrapper + * {
    margin-top: 20px;
  }
}
.fancy-heading-wrapper + *.field-sign-post-text {
  margin-top: 0;
}
.fancy-heading-wrapper > * {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
  max-width: 80%;
  padding: 0 28px;
}
.fancy-heading-wrapper > *:before,
.fancy-heading-wrapper > *:after {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 2px;
  width: 1200px;
  position: absolute;
  top: 50%;
  bottom: auto;
  transform: translateY(calc(-50% + 1px));
  border-top: 1px solid #D7D7D7;
}
.fancy-heading-wrapper > *:before {
  right: 100%;
}
.fancy-heading-wrapper > *:after {
  left: 100%;
}
.view-fancy-heading-wrapper {
  overflow: hidden;
  text-align: center;
  margin-bottom: 0;
}
.view-fancy-heading-wrapper + * {
  margin-top: 40px;
}
@media only screen and (max-width: 960px) {
  .view-fancy-heading-wrapper + * {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .view-fancy-heading-wrapper + * {
    margin-top: 20px;
  }
}
.view-fancy-heading-wrapper + *.field-sign-post-text {
  margin-top: 0;
}
.view-fancy-heading-wrapper > * {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
  max-width: 80%;
  padding: 0 28px;
}
.view-fancy-heading-wrapper > *:before,
.view-fancy-heading-wrapper > *:after {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 2px;
  width: 1200px;
  position: absolute;
  top: 50%;
  bottom: auto;
  transform: translateY(calc(-50% + 1px));
  border-top: 1px solid #D7D7D7;
}
.view-fancy-heading-wrapper > *:before {
  right: 100%;
}
.view-fancy-heading-wrapper > *:after {
  left: 100%;
}
.field-label,
.label-inline,
.label-above,
.field-group-div > h3 {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  font-weight: 600;
  margin-bottom: 1.125rem;
}
@media only screen and (max-width: 480px) {
  .field-label,
  .label-inline,
  .label-above,
  .field-group-div > h3 {
    font-size: 1.25rem;
  }
}
.zone-footer .field-label,
.zone-footer .label-inline,
.zone-footer .label-above,
.zone-footer .field-group-div > h3,
.ds-media-aside .field-label,
.ds-media-aside .label-inline,
.ds-media-aside .label-above,
.ds-media-aside .field-group-div > h3 {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer .field-label,
  .zone-footer .label-inline,
  .zone-footer .label-above,
  .zone-footer .field-group-div > h3,
  .ds-media-aside .field-label,
  .ds-media-aside .label-inline,
  .ds-media-aside .label-above,
  .ds-media-aside .field-group-div > h3 {
    font-size: 1.25rem;
  }
}
.field-mission {
  box-shadow: 0px 8px 10px 0px #d6dbe1;
  text-align: center;
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  padding: 2rem;
  margin-bottom: 2.35rem;
}
@media only screen and (max-width: 480px) {
  .field-mission {
    font-size: 1.25rem;
  }
}
.field-body,
.block > .content {
  font-size: 1.125rem;
  line-height: 1.389;
  padding-bottom: 2.75rem;
}
.field-body > *:first-child,
.block > .content > *:first-child {
  margin-top: 0;
}
.field-body p > iframe,
.block > .content p > iframe {
  margin-top: 1rem;
  max-width: 100%;
}
.field-body p:first-of-type > iframe,
.block > .content p:first-of-type > iframe {
  margin-top: 0;
}
.field-body [style*="color"],
.block > .content [style*="color"] {
  color: inherit !important;
}
.field-body [style*="font"],
.block > .content [style*="font"] {
  font-family: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  font-weight: inherit !important;
  font-style: inherit !important;
}
.field-body [style*="font-size"],
.block > .content [style*="font-size"] {
  font-size: inherit !important;
}
.field-body [style*="line-height"],
.block > .content [style*="line-height"] {
  line-height: inherit !important;
}
.field-body [style*="letter-spacing"],
.block > .content [style*="letter-spacing"] {
  letter-spacing: inherit !important;
}
.field-body ul,
.block > .content ul {
  position: relative;
}
.field-body ul li,
.block > .content ul li {
  position: relative;
}
.field-body ul li:before,
.block > .content ul li:before {
  font-size: 1.1rem;
  float: left;
  position: absolute;
  left: -15px;
}
#page-title {
  margin: 0;
  letter-spacing: normal;
}
@media only screen and (max-width: 960px) {
  .meta--type + #page-title {
    text-align: initial;
  }
}
@media only screen and (max-width: 480px) {
  #page-title {
    text-align: center;
  }
}
.front #page-title {
  color: #4d5a6b;
  font-size: 3rem;
  font-weight: 700;
  line-height: 0.979;
  text-transform: none;
  letter-spacing: 0.016rem;
  color: #FFFFFF;
  margin: 0;
  font-size: 64px;
  text-align: center;
  letter-spacing: 0.17rem;
}
@media only screen and (max-width: 960px) {
  .front #page-title {
    font-size: 55px;
    letter-spacing: 0.04rem;
  }
}
@media only screen and (max-width: 480px) {
  .front #page-title {
    font-size: 2.25rem;
    line-height: 1.167;
    letter-spacing: 0.012rem;
    letter-spacing: 0.1rem;
  }
}
.billboard-titling {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 3rem;
  line-height: 0.979;
  margin: 1.25rem 0 1.5rem;
}
@media only screen and (max-width: 480px) {
  .billboard-titling {
    font-size: 2.25rem;
    line-height: 1.167;
    letter-spacing: 0.012rem;
  }
}
.block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
}
@media only screen and (max-width: 480px) {
  .block-title {
    font-size: 1.25rem;
  }
}
.zone-footer .block-title,
.ds-media-aside .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer .block-title,
  .ds-media-aside .block-title {
    font-size: 1.25rem;
  }
}
[class*="view-mode"] .ds-media .view > .title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
}
@media only screen and (max-width: 480px) {
  [class*="view-mode"] .ds-media .view > .title {
    font-size: 1.25rem;
  }
}
.zone-footer [class*="view-mode"] .ds-media .view > .title,
.ds-media-aside [class*="view-mode"] .ds-media .view > .title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer [class*="view-mode"] .ds-media .view > .title,
  .ds-media-aside [class*="view-mode"] .ds-media .view > .title {
    font-size: 1.25rem;
  }
}
.mobile-menu-block-title {
  margin-bottom: .95rem;
  padding-bottom: 0;
}
.block[id*="start-tabs"] .block-title {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
  margin-bottom: 1.6rem;
}
@media only screen and (max-width: 960px) {
  .block[id*="start-tabs"] .block-title {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .block[id*="start-tabs"] .block-title {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (max-width: 960px) {
  .block[id*="start-tabs"] .block-title {
    margin-bottom: 1.125rem;
  }
}
@media only screen and (max-width: 480px) {
  .block[id*="start-tabs"] .block-title {
    margin-bottom: .925rem;
  }
}
.region-content .block-title,
.region-preface-first .block-title,
.region-postscript-second .block-title {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .region-content .block-title,
  .region-preface-first .block-title,
  .region-postscript-second .block-title {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .region-content .block-title,
  .region-preface-first .block-title,
  .region-postscript-second .block-title {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
.node-type-landing-page .region-preface-first .content .title {
  text-align: center;
}
.node-type-landing-page .region-preface-first .content .field-subheading {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  text-align: center;
  padding-top: 0.938rem;
}
@media only screen and (max-width: 480px) {
  .node-type-landing-page .region-preface-first .content .field-subheading {
    font-size: 1.25rem;
  }
}
.front .region-preface-first-inner {
  max-width: 100%;
}
.front .region-preface-first-inner .region-to-block .owl-stage {
  display: flex;
}
.front .region-preface-first-inner .region-to-block .owl-stage .owl-item {
  display: flex;
}
@media only screen and (min-width: 961px) {
  .front .region-preface-first-inner .region-to-block .owl-controls {
    display: none;
  }
}
.front .region-preface-first-inner .region-to-block .entity-impact-box {
  width: 100%;
  padding: 1.125rem 1.19rem 2.625rem;
}
.front .region-preface-first-inner .region-to-block .entity-impact-box:first-of-type {
  margin-left: 0;
}
.front .region-preface-first-inner .region-to-block .entity-impact-box:last-of-type {
  margin-right: 0;
}
@media only screen and (max-width: 960px) {
  .front .region-preface-first-inner .region-to-block .entity-impact-box {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 961px) {
  .front .region-preface-first-inner .region-to-block .entity-impact-box {
    margin-right: 20px;
    height: 470px;
  }
}
.front .region-preface-first-inner .region-to-block .entity-impact-box .group-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.front .region-preface-first-inner .region-to-block .entity-impact-box .field {
  text-align: center;
}
.front .region-preface-first-inner .region-to-block .entity-impact-box .field.field-box-icon {
  margin-top: -66px;
  padding-bottom: 0.8rem;
}
.front .region-preface-first-inner .region-to-block .entity-impact-box .field.field-box-icon img {
  display: inline-block;
}
.front .region-preface-first-inner .region-to-block .entity-impact-box .field.field-box-heading {
  color: #4d5a6b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
  letter-spacing: 0.09rem;
  text-transform: uppercase;
}
.front .region-preface-first-inner .region-to-block .entity-impact-box .field.field-box-metric {
  color: #4d5a6b;
  font-size: 3rem;
  font-weight: 700;
  line-height: 0.979;
  text-transform: none;
  letter-spacing: 0.016rem;
  font-size: 64px;
  letter-spacing: 0.13rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.front .region-preface-first-inner .region-to-block .entity-impact-box .field.field-box-text {
  letter-spacing: .06rem;
}
.front .region-preface-first-inner .region-to-block .entity-impact-box .field.field-box-link {
  padding-top: 20px;
}
.front .region-preface-first-inner .region-to-block .entity-impact-box .field.field-box-link a {
  width: 100%;
}
@media only screen and (max-width: 960px) {
  .front .region-preface-first-inner .region-to-block .entity-impact-box {
    padding: 1.313rem 4rem 2.188rem;
  }
}
@media only screen and (max-width: 480px) {
  .front .region-preface-first-inner .region-to-block .entity-impact-box {
    padding: 0.875rem 0.938rem 1.5rem;
  }
}
.link-set-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  padding-bottom: .625rem;
  border-bottom: 2px solid #D7D7D7;
  margin-bottom: 1.1875rem;
}
@media only screen and (max-width: 480px) {
  .link-set-title {
    font-size: 1.25rem;
  }
}
.zone-footer .link-set-title,
.ds-media-aside .link-set-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer .link-set-title,
  .ds-media-aside .link-set-title {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .link-set-title {
    margin-bottom: 1rem;
  }
}
.section-footer .block-title {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
  margin-bottom: 2rem;
  color: #FFFFFF;
}
@media only screen and (max-width: 960px) {
  .section-footer .block-title {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .section-footer .block-title {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (max-width: 960px) {
  .section-footer .block-title {
    margin-bottom: 2.325rem;
  }
}
@media only screen and (max-width: 480px) {
  .section-footer .block-title {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    color: #FFFFFF;
    line-height: 1.225;
    margin-bottom: 1.925rem;
  }
}
.billboard-subhead {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}
.billboard-title {
  font-size: 60px;
  line-height: 1;
}
@media only screen and (max-width: 960px) {
  .billboard-title {
    font-size: 48px;
  }
}
@media only screen and (max-width: 480px) {
  .billboard-title {
    font-size: 32px;
    line-height: 1.14;
  }
}
.teaser-title {
  margin: 0;
  letter-spacing: .5px;
}
@media only screen and (min-width: 481px) {
  .region-preface-first .block > .content,
  .front .region-content .block > .content {
    color: #FFFFFF;
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.273;
    text-transform: none;
    letter-spacing: 0.004em;
    margin-bottom: 0;
    font-weight: normal;
  }
}
.caption-title,
.caption-body,
.slide--counter,
.image-field-caption *,
.flex-caption,
.flex-caption strong,
.video-embed-description,
.video-embed-description strong {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  font-size: 0.8125rem;
  line-height: 1.6;
  color: #898989;
}
.caption-title,
.flex-caption,
.flex-caption strong,
.video-embed-description,
.video-embed-description strong {
  font-weight: bold;
}
.caption-body,
.slide--counter,
.image-field-caption * {
  font-weight: 400;
}
.slide--counter {
  color: #FFFFFF;
}
.image-field-caption {
  margin: 0;
}
.flex-caption {
  padding: 0.85rem 0 1.6rem;
  border-bottom: 2px solid #D7D7D7;
}
.video-embed-description {
  padding: 0.85rem 0 1.6rem;
  border-bottom: 2px solid #D7D7D7;
}
.flex-caption-empty {
  display: none;
}
.p,
.input,
textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
.form-item.bt-hosted-field,
.chosen-container {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
}
.p:first-child {
  margin-top: 0;
}
.p--small {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
  font-size: 12px;
  line-height: 1.4;
}
.p--small:first-child {
  margin-top: 0;
}
p {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
}
p:first-child {
  margin-top: 0;
}
.view-mode-full .intro,
[class*="preface-first"] .block > .content .intro {
  color: #4d5a6b;
  font-size: 3rem;
  font-weight: 700;
  line-height: 0.979;
  text-transform: none;
  letter-spacing: 0.016rem;
}
.big-copy {
  font-size: 1.125rem;
  line-height: large-copy-line-height;
  font-style: normal;
}
.fine-print {
  font-size: 0.75rem;
  line-height: 1.9;
  letter-spacing: 0.025em;
}
.fine-print:hover {
  color: #FFFFFF;
}
ul,
ol {
  margin-bottom: 0;
  padding-left: .8rem;
  margin-top: 1.3rem;
}
ul:first-child,
ol:first-child {
  margin-top: 0;
}
ul li,
ol li {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
}
ul li:first-child,
ol li:first-child {
  margin-top: 0;
}
ul li ~ li,
ol li ~ li {
  margin-top: .5rem;
}
ul {
  list-style: none;
  position: relative;
}
ul li:before {
  content: '\2022';
  font-size: 1.1rem;
  float: left;
  position: absolute;
  left: 0;
}
.unbullet {
  list-style: none;
  padding: 0;
}
.unbullet li {
  margin: 0;
}
.unbullet li:before {
  content: none;
}
ul.navbar-menu,
ul.menu,
ul.tabs {
  list-style: none;
  padding: 0;
}
ul.navbar-menu li,
ul.menu li,
ul.tabs li {
  margin: 0;
}
ul.navbar-menu li:before,
ul.menu li:before,
ul.tabs li:before {
  content: none;
}
.footer-list-item {
  font-size: 16px;
  font-weight: 400;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: .59rem 0;
  line-height: 1.6;
}
@media only screen and (max-width: 480px) {
  .footer-list-item {
    padding: 0;
  }
}
.footer-list-item a[href*='tel'] {
  margin-right: .25rem;
}
.footer-list-item a:hover {
  color: #E6E6E6;
  text-decoration: underline;
}
.footer-list-item a.active {
  color: #1890fb;
  text-decoration: underline;
}
.aside-list-link {
  color: #FFFFFF;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.4;
  text-decoration: none;
  font-size: 12px;
  line-height: 1.273;
  font-weight: 400;
  color: #1890fb;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-right: .5rem;
}
.aside-list-link:hover {
  text-decoration: underline;
}
.aside-list-link.active {
  cursor: default;
  text-decoration: underline;
}
.aside-list-link.active:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 960px) {
  .aside-list-link {
    width: auto;
  }
}
.field-files,
.field-links,
.field-related {
  list-style: none;
  padding: 0;
  font-size: 16px;
  clear: both;
}
.field-files li,
.field-links li,
.field-related li {
  margin: 0;
}
.field-files li:before,
.field-links li:before,
.field-related li:before {
  content: none;
}
.field-files li ~ li,
.field-links li ~ li,
.field-related li ~ li {
  margin-top: 1.45rem;
  margin-top: 1.25rem;
}
.field-files li a,
.field-links li a,
.field-related li a {
  color: #FFFFFF;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.4;
  text-decoration: none;
  font-size: 12px;
  line-height: 1.273;
  font-weight: 400;
  color: #1890fb;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-right: .5rem;
  vertical-align: middle;
}
.field-files li a:hover,
.field-links li a:hover,
.field-related li a:hover {
  text-decoration: underline;
}
.field-files li a.active,
.field-links li a.active,
.field-related li a.active {
  cursor: default;
  text-decoration: underline;
}
.field-files li a.active:hover,
.field-links li a.active:hover,
.field-related li a.active:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 960px) {
  .field-files li a,
  .field-links li a,
  .field-related li a {
    width: auto;
  }
}
.field-files .field-lable-above {
  color: #4d5a6b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
  text-transform: uppercase;
  border-bottom: 4px solid #E6E6E6;
  padding-bottom: 0.5625rem;
}
.field-files .field-contents {
  padding-left: 0;
  margin-top: 0;
  padding-bottom: 2.1875rem;
}
.field-file a {
  color: #FFFFFF;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.4;
  text-decoration: none;
  font-size: 12px;
  line-height: 1.273;
  font-weight: 400;
  color: #1890fb;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-right: .5rem;
}
.field-file a:hover {
  text-decoration: underline;
}
.field-file a.active {
  cursor: default;
  text-decoration: underline;
}
.field-file a.active:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 960px) {
  .field-file a {
    width: auto;
  }
}
.file-size {
  display: inline-block;
  vertical-align: middle;
  color: #666666;
  font-weight: 600;
}
/* --- [ Inline Icons ] --------------- */
.file {
  display: block;
  position: relative;
  padding-left: 2.75rem;
}
@media only screen and (max-width: 960px) {
  .file {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.file:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/download--blue.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.75rem 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  margin-top: .05rem;
}
.file .text {
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  color: #1890fb;
}
.field-links a {
  display: block;
  position: relative;
  padding-right: 1.5em;
}
.field-links a:after {
  content: ' ';
  display: inline-block;
  background-image: url("../images/icons/link-external--gray.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 1.125em;
  height: 1.125em;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.field-links a:after {
  top: .8rem;
  width: 1.4rem;
  height: 1.4rem;
}
.field-related a {
  display: block;
  position: relative;
  padding-right: 1.5em;
}
.field-related a:after {
  content: ' ';
  display: inline-block;
  background-image: url("../images/icons/link--gray.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 1.125em;
  height: 1.125em;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.field-related a:after {
  top: .8rem;
  width: 1.4rem;
  height: 1.4rem;
}
.field-email a {
  display: block;
  position: relative;
  padding-left: 2rem;
}
@media only screen and (max-width: 960px) {
  .field-email a {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.field-email a:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/mail--gold.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1rem 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: .05rem;
}
.field-email a:before {
  top: 5px;
}
.field-email a .text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.region-to-block .field-organization-website-link a,
.region-to-block .field-organization-website-link .no-url {
  display: block;
  position: relative;
  padding-left: 2rem;
}
@media only screen and (max-width: 960px) {
  .region-to-block .field-organization-website-link a,
  .region-to-block .field-organization-website-link .no-url {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.region-to-block .field-organization-website-link a:before,
.region-to-block .field-organization-website-link .no-url:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/office-rect.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1rem 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: .05rem;
}
.region-to-block .field-organization-website-link a:before,
.region-to-block .field-organization-website-link .no-url:before {
  top: 5px;
  left: 0;
  background-position: center;
  background-size: 1rem 1rem;
  width: 1rem;
  height: 1rem;
}
.field-phone .field-contents {
  display: block;
  position: relative;
  padding-left: 2rem;
}
@media only screen and (max-width: 960px) {
  .field-phone .field-contents {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.field-phone .field-contents:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/phone--gold.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1rem 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: .05rem;
}
.field-phone .field-contents:before {
  top: 5px;
}
.field-event-location {
  display: block;
}
.field-event-location .field-contents {
  display: inline;
  position: relative;
  padding-left: 2.75rem;
}
@media only screen and (max-width: 960px) {
  .field-event-location .field-contents {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.field-event-location .field-contents:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/location-pin-gold.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.75rem 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  margin-top: .05rem;
}
.field-event-location .field-contents span {
  display: inline;
}
.field-event-location .field-contents:before {
  top: -5px;
  left: 10px;
}
@media only screen and (max-width: 700px) {
  .field-event-location .field-contents:before {
    content: none;
  }
}
@media only screen and (max-width: 700px) {
  .field-event-location .field-contents {
    padding-left: 0;
  }
}
@media only screen and (max-width: 700px) {
  .field-event-location {
    position: relative;
    padding: 0;
    display: block;
    padding-top: 3rem;
  }
  .field-event-location:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    background-image: url("../images/icons/location-pin-gold.svg");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 2rem 2rem;
    width: 2rem;
    height: 2rem;
  }
}
.field-live-chat-link a {
  display: block;
  color: #898989;
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .field-live-chat-link a {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.field-live-chat-link a:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/speech-bubble-dots--grey.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.field-live-chat-link a:hover {
  color: #1890fb;
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .field-live-chat-link a:hover {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.field-live-chat-link a:hover:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/speech-bubble-dots--blue.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.field-map {
  display: flex;
  flex-direction: column;
  padding-bottom: 3.75rem;
}
@media only screen and (max-width: 960px) {
  .field-map {
    width: 100%;
  }
}
.field-map .static_google_map {
  object-fit: cover;
  height: 100%;
}
@media only screen and (max-width: 960px) {
  .field-map .static_google_map {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .field-map .static_google_map {
    width: auto;
  }
}
.field-map .content > div {
  border: 4px solid #E6E6E6;
  border-top: none;
  padding: 1rem 0.6rem;
  position: relative;
  padding-right: 1.5rem;
}
.field-map .content > div:after {
  content: ' ';
  display: inline-block;
  background-image: url("../images/icons/get--directions-blue.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.field-map .content > div:after {
  right: 24px;
  width: 1rem;
  height: 1rem;
}
.field-map .content > div .text {
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  color: #1890fb;
}
blockquote,
.field-quote {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  margin: 0;
  color: #666666;
  line-height: 1.364;
  font-style: normal;
  font-weight: normal;
}
@media only screen and (max-width: 480px) {
  blockquote,
  .field-quote {
    font-size: 1.25rem;
  }
}
.blockquote:before,
.pull-quote-inner:before {
  content: '\201c';
}
.blockquote:after,
.pull-quote-inner:after {
  content: '\201d';
}
.overview-text {
  font-size: 1.4rem;
  line-height: 1.4;
  margin: 0;
  margin-bottom: 30px;
  font-style: italic;
  letter-spacing: -0.02rem;
}
.meta {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
}
.meta .field-contents {
  display: inline;
}
.meta a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.meta a:hover {
  color: #1890fb;
}
.meta.field-date [class*="date-display"] {
  display: inline;
}
.meta--light {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  font-weight: 400;
  color: #898989;
}
.meta--light .field-contents {
  display: inline;
}
.meta--light a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.meta--light a:hover {
  color: #1890fb;
}
.meta--light.field-date [class*="date-display"] {
  display: inline;
}
.meta--related {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
}
.meta--related .field-contents {
  display: inline;
}
.meta--related a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.meta--related a:hover {
  color: #1890fb;
}
.meta--related.field-date [class*="date-display"] {
  display: inline;
}
.field-post-date {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  font-weight: 400;
  color: #898989;
}
.field-post-date .field-contents {
  display: inline;
}
.field-post-date a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.field-post-date a:hover {
  color: #1890fb;
}
.field-post-date.field-date [class*="date-display"] {
  display: inline;
}
.meta--big {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  font-size: 1.375rem;
}
.meta--big .field-contents {
  display: inline;
}
.meta--big a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.meta--big a:hover {
  color: #1890fb;
}
.meta--big.field-date [class*="date-display"] {
  display: inline;
}
.meta--type,
.breadcrumb {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
}
.meta--type .field-contents,
.breadcrumb .field-contents {
  display: inline;
}
.meta--type a,
.breadcrumb a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.meta--type a:hover,
.breadcrumb a:hover {
  color: #1890fb;
}
.meta--type.field-date [class*="date-display"],
.breadcrumb.field-date [class*="date-display"] {
  display: inline;
}
.zone-preface .meta--type:first-child,
.zone-preface .breadcrumb:first-child {
  margin: 0;
  margin-top: -1.3rem;
}
@media only screen and (max-width: 960px) {
  .zone-preface .meta--type:first-child,
  .zone-preface .breadcrumb:first-child {
    margin-top: 0;
  }
}
@media only screen and (max-width: 480px) {
  .zone-preface .meta--type:first-child,
  .zone-preface .breadcrumb:first-child {
    text-align: center;
  }
}
.byline {
  margin-top: .3rem;
}
@media only screen and (max-width: 480px) {
  .byline {
    text-align: center;
  }
}
.byline,
.group-meta {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  padding: 0;
  list-style: none;
}
.byline .field-contents,
.group-meta .field-contents {
  display: inline;
}
.byline a,
.group-meta a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.byline a:hover,
.group-meta a:hover {
  color: #1890fb;
}
.byline.field-date [class*="date-display"],
.group-meta.field-date [class*="date-display"] {
  display: inline;
}
.byline > *,
.group-meta > * {
  display: inline-block;
  position: relative;
  margin: 0;
  margin-right: .4rem;
}
.byline > *:before,
.group-meta > *:before {
  content: none;
}
.byline > *:first-child:not(:last-child):after,
.group-meta > *:first-child:not(:last-child):after {
  content: '\2022';
  color: inherit;
  margin-left: 0.4rem;
  display: inline;
  float: none;
  font-size: inherit;
  position: static;
}
.byline > *:first-child:not(:last-child) ~ *:not(:last-child):after,
.group-meta > *:first-child:not(:last-child) ~ *:not(:last-child):after {
  content: '\2022';
  color: inherit;
  margin-left: 0.4rem;
  display: inline;
  float: none;
  font-size: inherit;
  position: static;
}
.view-mode-full > .field-body ~ .field-resource-types,
.view-mode-full > .field-body ~ .field-topics {
  margin-top: 2.35rem;
}
.field-resource-types > .field-contents,
.field-topics > .field-contents {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  padding: 0;
  list-style: none;
}
.field-resource-types > .field-contents .field-contents,
.field-topics > .field-contents .field-contents {
  display: inline;
}
.field-resource-types > .field-contents a,
.field-topics > .field-contents a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.field-resource-types > .field-contents a:hover,
.field-topics > .field-contents a:hover {
  color: #1890fb;
}
.field-resource-types > .field-contents.field-date [class*="date-display"],
.field-topics > .field-contents.field-date [class*="date-display"] {
  display: inline;
}
.field-resource-types > .field-contents > *,
.field-topics > .field-contents > * {
  display: inline-block;
  position: relative;
  margin: 0;
  margin-right: .4rem;
}
.field-resource-types > .field-contents > *:before,
.field-topics > .field-contents > *:before {
  content: none;
}
.field-resource-types > .field-contents > *:first-child:not(:last-child):after,
.field-topics > .field-contents > *:first-child:not(:last-child):after {
  content: '\2022';
  color: inherit;
  margin-left: 0.4rem;
  display: inline;
  float: none;
  font-size: inherit;
  position: static;
}
.field-resource-types > .field-contents > *:first-child:not(:last-child) ~ *:not(:last-child):after,
.field-topics > .field-contents > *:first-child:not(:last-child) ~ *:not(:last-child):after {
  content: '\2022';
  color: inherit;
  margin-left: 0.4rem;
  display: inline;
  float: none;
  font-size: inherit;
  position: static;
}
.group-header > h3 {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
}
@media only screen and (max-width: 480px) {
  .group-header > h3 {
    font-size: 1.25rem;
  }
}
.zone-footer .group-header > h3,
.ds-media-aside .group-header > h3 {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer .group-header > h3,
  .ds-media-aside .group-header > h3 {
    font-size: 1.25rem;
  }
}
table {
  max-width: 100%;
}
table[style*="width"] {
  width: auto !important;
  max-width: 100% !important;
}
.icon.phone {
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .icon.phone {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.icon.phone:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/phone--yellow.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.icon.mail {
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .icon.mail {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.icon.mail:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/mail.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.icon.addy {
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .icon.addy {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.icon.addy:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/map-pin--yellow.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.field-recurring-date,
.group-event-details > .field-date {
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .field-recurring-date,
  .group-event-details > .field-date {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.field-recurring-date:before,
.group-event-details > .field-date:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/calendar--white.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.field-facebook {
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .field-facebook {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.field-facebook:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/facebook-reversed.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.field-twitter {
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .field-twitter {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.field-twitter:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/twitterbird.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.feed-icon {
  display: none;
}
a {
  color: #1890fb;
  text-decoration: none;
  transition: all 240ms ease 0ms;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}
a:hover {
  color: #1890fb;
  text-decoration: underline;
}
a.active {
  color: #1890fb;
  text-decoration: underline;
}
.more-link {
  font-size: 16px;
  letter-spacing: .05em;
  text-transform: capitalize;
}
.top-menu-link {
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  color: #FFFFFF;
  text-decoration: none;
  line-height: 1.8;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  max-height: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .top-menu-link {
    font-size: 16px;
    padding: .5rem 1rem;
  }
}
.top-menu-link .menu-item-inner {
  position: relative;
}
.top-menu-link:hover {
  text-decoration: underline;
}
.mobile-top-menu-link {
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  letter-spacing: .01rem;
  text-decoration: none;
  line-height: 1.8;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  max-height: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  display: block;
  letter-spacing: 0.01em;
  padding: 0;
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .mobile-top-menu-link {
    font-size: 16px;
    padding: .5rem 1rem;
  }
}
.mobile-top-menu-link .menu-item-inner {
  position: relative;
}
.mobile-top-menu-link:hover {
  text-decoration: underline;
}
.mobile-top-menu-link .menu-item-inner:before {
  content: none;
}
.mobile-quick-menu-link {
  color: #FFFFFF;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.125;
  text-decoration: none;
  letter-spacing: 0.08em;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  padding: 10px 14px;
  display: block;
}
.mobile-quick-menu-link .menu-item-inner:before {
  content: none;
}
.menu-item-link {
  color: #FFFFFF;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.4;
  text-decoration: none;
}
.menu-item-link:hover {
  text-decoration: underline;
}
.menu-item-link.active {
  cursor: default;
  text-decoration: underline;
}
.menu-item-link.active:hover {
  text-decoration: underline;
}
.link-tray {
  margin-top: 2.925rem;
}
.link-tray a {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .05em;
  text-transform: uppercase;
  color: #1890fb;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.link-tray a:hover {
  text-decoration: underline;
}
.link-tray a.active {
  text-decoration: underline;
  color: #1890fb;
}
.link-tray .separator {
  margin: 0 .5rem;
}
@media only screen and (max-width: 960px) {
  .link-tray {
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
  .link-tray {
    margin-top: 2.05rem;
  }
  .link-tray .separator {
    display: none;
  }
  .link-tray a {
    display: block;
  }
  .link-tray a ~ a {
    margin-top: 1.6rem;
  }
}
a#main-content {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
div.contextual-links-wrapper {
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}
div.contextual-links-wrapper a {
  display: none;
  border: none;
  padding: none;
}
div.contextual-links-wrapper a.contextual-links-trigger-active {
  display: block;
  margin-left: -1.5rem;
}
div.contextual-links-wrapper ul.contextual-links {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  z-index: 100;
}
.field-file-attachments {
  list-style: none;
  padding: 0;
  margin: 0;
}
.file img {
  display: none;
}
.field-address {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
}
.field-address .field-contents {
  display: inline;
}
.field-address a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.field-address a:hover {
  color: #1890fb;
}
.field-address.field-date [class*="date-display"] {
  display: inline;
}
.field-location .street-block .thoroughfare,
.field-location .street-block .premise {
  display: inline;
}
.field-location .street-block .premise:before {
  content: ',\0020';
}
.field-location .country {
  display: none;
}
.button,
.button-cta,
.button-signpost,
.button-event,
.button-dim,
.button-admin,
.button-disabled,
.button-donate,
input[type="submit"],
input[type="submit"][id*="add-more"],
input[type="submit"][id*="upload-button"],
input[type="reset"],
form[id^="ma-ux-user-password"] input[type="submit"],
.region[class*="sidebar"] .form-submit,
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other .form-number,
#ma-donate-donate-form .form-item[class*="donation-amount"] input + label,
.db-access-wrap a,
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"],
.block-access-prompt .link-tray a[href*="login"],
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input:checked + label,
.system-block .form-actions .form-submit,
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout a,
.block-login .form-actions .form-submit,
.block[id*="user-login"] .form-actions .form-submit,
.tabs-custom .button-tray .button {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
}
.button:hover,
.button-cta:hover,
.button-signpost:hover,
.button-event:hover,
.button-dim:hover,
.button-admin:hover,
.button-disabled:hover,
.button-donate:hover,
input[type="submit"]:hover,
input[type="submit"][id*="add-more"]:hover,
input[type="submit"][id*="upload-button"]:hover,
input[type="reset"]:hover,
form[id^="ma-ux-user-password"] input[type="submit"]:hover,
.region[class*="sidebar"] .form-submit:hover,
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other .form-number:hover,
#ma-donate-donate-form .form-item[class*="donation-amount"] input + label:hover,
.db-access-wrap a:hover,
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"]:hover,
.block-access-prompt .link-tray a[href*="login"]:hover,
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input:checked + label:hover,
.system-block .form-actions .form-submit:hover,
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout a:hover,
.block-login .form-actions .form-submit:hover,
.block[id*="user-login"] .form-actions .form-submit:hover,
.tabs-custom .button-tray .button:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.button:active,
.button-cta:active,
.button-signpost:active,
.button-event:active,
.button-dim:active,
.button-admin:active,
.button-disabled:active,
.button-donate:active,
input[type="submit"]:active,
input[type="submit"][id*="add-more"]:active,
input[type="submit"][id*="upload-button"]:active,
input[type="reset"]:active,
form[id^="ma-ux-user-password"] input[type="submit"]:active,
.region[class*="sidebar"] .form-submit:active,
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other .form-number:active,
#ma-donate-donate-form .form-item[class*="donation-amount"] input + label:active,
.db-access-wrap a:active,
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"]:active,
.block-access-prompt .link-tray a[href*="login"]:active,
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input:checked + label:active,
.system-block .form-actions .form-submit:active,
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout a:active,
.block-login .form-actions .form-submit:active,
.block[id*="user-login"] .form-actions .form-submit:active,
.tabs-custom .button-tray .button:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.button:focus,
.button-cta:focus,
.button-signpost:focus,
.button-event:focus,
.button-dim:focus,
.button-admin:focus,
.button-disabled:focus,
.button-donate:focus,
input[type="submit"]:focus,
input[type="submit"][id*="add-more"]:focus,
input[type="submit"][id*="upload-button"]:focus,
input[type="reset"]:focus,
form[id^="ma-ux-user-password"] input[type="submit"]:focus,
.region[class*="sidebar"] .form-submit:focus,
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other .form-number:focus,
#ma-donate-donate-form .form-item[class*="donation-amount"] input + label:focus,
.db-access-wrap a:focus,
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"]:focus,
.block-access-prompt .link-tray a[href*="login"]:focus,
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input:checked + label:focus,
.system-block .form-actions .form-submit:focus,
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout a:focus,
.block-login .form-actions .form-submit:focus,
.block[id*="user-login"] .form-actions .form-submit:focus,
.tabs-custom .button-tray .button:focus {
  outline: none;
}
.button:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.button:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.button.admin,
.button.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.button.admin-extra {
  color: #1890fb;
}
.button-cta {
  background-color: #d9a70f;
}
.button-cta:hover {
  background-color: #DC8802;
}
.button-cta:active {
  background-color: #DC5502;
}
.button-signpost {
  background-color: #4d5a6b;
}
.button-signpost:hover {
  background-color: #650000;
}
.button-signpost:active {
  background-color: #9A0000;
}
.button-donate {
  background-color: #FFFFFF;
  color: #666666;
  border-color: #666666;
  border-width: 3px;
  border: solid;
  padding: .5rem;
  min-width: 87px;
  text-align: center;
}
.button-donate:hover {
  color: #666666;
  background-color: #FFFFFF;
  border-color: #d9a70f;
}
.button-donate-cta {
  background-color: #d9a70f;
  color: #FFFFFF;
  border-color: #d9a70f;
  padding: .5rem;
}
.button-event {
  background-color: transparent;
  color: #FFFFFF;
  border-color: #FFFFFF;
  border-width: 3px;
  border: solid;
  letter-spacing: 0.06rem;
  padding: 1.16rem 1.6rem;
}
.button-event:hover {
  background-color: #FFFFFF;
  color: #1890fb;
  border-color: #FFFFFF;
}
.button-event:active {
  background-color: #98CFFF;
  color: #FFFFFF;
  border-color: #98CFFF;
}
.button-dim,
.button-admin {
  color: #707070;
  background-color: transparent;
  border-color: #707070;
  border-width: 3px;
  border: solid;
}
.button-dim:hover,
.button-admin:hover {
  background-color: #707070;
  color: #FFFFFF;
  border-color: #707070;
}
.button-dim:active,
.button-admin:active {
  background-color: #575757;
  color: #FFFFFF;
  border-color: #575757;
}
.button--back {
  position: relative;
  padding-left: 2rem;
  background-image: url("../images/icons/arrow-left--navy.svg");
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  background-size: 1rem 1rem;
}
.button--back:hover {
  position: relative;
  padding-left: 2rem;
  background-image: url("../images/icons/arrow-left--violet.svg");
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  background-size: 1rem 1rem;
}
.button--back:active {
  position: relative;
  padding-left: 2rem;
  background-image: url("../images/icons/arrow-left--red.svg");
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  background-size: 1rem 1rem;
}
.page-node-done .links {
  margin-top: 2rem;
}
.page-node-done .links a {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
}
.page-node-done .links a:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.page-node-done .links a:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.page-node-done .links a:focus {
  outline: none;
}
.page-node-done .links a:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.page-node-done .links a:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.page-node-done .links a.admin,
.page-node-done .links a.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.page-node-done .links a.admin-extra {
  color: #1890fb;
}
.view .more-link a {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input[type="reset"],
input[type="submit"],
input[type="button"],
.form-submit,
input.button,
a.button,
div.button > a,
span.button > a {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
}
button:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
input[type="button"]:hover,
.form-submit:hover,
input.button:hover,
a.button:hover,
div.button > a:hover,
span.button > a:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
button:active,
input[type="reset"]:active,
input[type="submit"]:active,
input[type="button"]:active,
.form-submit:active,
input.button:active,
a.button:active,
div.button > a:active,
span.button > a:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
button:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
input[type="button"]:focus,
.form-submit:focus,
input.button:focus,
a.button:focus,
div.button > a:focus,
span.button > a:focus {
  outline: none;
}
button:disabled,
input[type="reset"]:disabled,
input[type="submit"]:disabled,
input[type="button"]:disabled,
.form-submit:disabled,
input.button:disabled,
a.button:disabled,
div.button > a:disabled,
span.button > a:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
button:disabled:hover,
input[type="reset"]:disabled:hover,
input[type="submit"]:disabled:hover,
input[type="button"]:disabled:hover,
.form-submit:disabled:hover,
input.button:disabled:hover,
a.button:disabled:hover,
div.button > a:disabled:hover,
span.button > a:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
button.admin,
input[type="reset"].admin,
input[type="submit"].admin,
input[type="button"].admin,
.form-submit.admin,
input.button.admin,
a.button.admin,
div.button > a.admin,
span.button > a.admin,
button.admin-extra,
input[type="reset"].admin-extra,
input[type="submit"].admin-extra,
input[type="button"].admin-extra,
.form-submit.admin-extra,
input.button.admin-extra,
a.button.admin-extra,
div.button > a.admin-extra,
span.button > a.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
button.admin-extra,
input[type="reset"].admin-extra,
input[type="submit"].admin-extra,
input[type="button"].admin-extra,
.form-submit.admin-extra,
input.button.admin-extra,
a.button.admin-extra,
div.button > a.admin-extra,
span.button > a.admin-extra {
  color: #1890fb;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
.image-style-circle_mask {
  border-radius: 2000px;
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}
.field-body img {
  height: auto !important;
  margin: 36px 0;
}
.field-body img[style*="float"] {
  margin-top: 0;
}
.field-body img[style*="float"][style*="left"] {
  margin-right: 1.4rem;
  margin-bottom: 1.4rem;
}
.field-body img[style*="float"][style*="right"] {
  margin-left: 1.4rem;
  margin-bottom: 1.4rem;
}
.field-banner-image img {
  width: 100%;
}
.block-landing-page-banner-image .field-trans-image {
  display: none;
}
.bg-image {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.bg-image:hover {
  opacity: 1;
}
.bg-image:hover .image__inner:after {
  opacity: .125;
}
.bg-image img {
  display: none;
}
.bg-image.placeholder {
  background: rgba(0, 0, 0, 0.6);
}
.bg-image.placeholder.image-style-square {
  background: rgba(0, 0, 0, 0.8);
}
.bg-image .image__inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.bg-image .image__inner > * {
  position: relative;
  z-index: 1;
}
.bg-image .image__inner:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  z-index: 0;
  opacity: 1;
  transition: all 240ms ease 0ms;
}
.bg-image.image-style-full {
  padding-bottom: 66.667%;
}
.bg-image.image-style-full .image__inner {
  padding: 30px;
  color: #FFFFFF;
  letter-spacing: -1em;
}
.bg-image.image-style-full .image__inner > * {
  letter-spacing: normal;
}
.bg-image.image-style-full .image__inner:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 1px;
  background: transparent !important;
  margin-left: -1px;
}
.bg-image.image-style-full .image__inner:after {
  background-color: rgba(77, 90, 107, 0.5);
}
.bg-image.image-style-full .image__inner .teaser-label {
  position: absolute;
  top: 0;
  left: 0;
  background: #1890fb;
  color: #FFFFFF;
  line-height: 1;
  padding: 10px 10px 4px;
}
.bg-image.image-style-full .image__inner .group-titling {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-top: 40px;
}
.bg-image.image-style-full .image__inner .group-titling h2 {
  line-height: 1;
  margin: 0;
  margin-bottom: 4px;
}
@media only screen and (max-width: 480px) {
  .bg-image.image-style-full .image__inner .group-titling h2 {
    line-height: 1.2;
  }
}
.bg-image.image-style-full .image__inner .group-titling .field-name-field-subtitle {
  font-size: 18px;
}
@media only screen and (max-width: 480px) {
  .bg-image.image-style-full .image__inner .group-titling .field-name-field-subtitle {
    line-height: 1.4;
  }
}
.bg-image.image-style-full .image__inner .meta {
  position: absolute;
  bottom: 19px;
  left: 30px;
  right: 30px;
  top: auto;
}
.bg-image.image-style-teaser {
  padding-bottom: 55.55%;
}
.bg-image.image-style-square {
  padding-bottom: 100%;
}
.bg-image.image-style-square .image__inner {
  padding: 30px;
  color: #FFFFFF;
  letter-spacing: -1em;
  padding: 14px;
}
.bg-image.image-style-square .image__inner > * {
  letter-spacing: normal;
}
.bg-image.image-style-square .image__inner:before {
  content: ' ';
  display: inline-block;
  vertical-align: bottom;
  height: 100%;
  width: 1px;
  background: transparent !important;
  margin-left: -1px;
}
.bg-image.image-style-square .image__inner .group-titling {
  display: inline-block;
  vertical-align: bottom;
  width: 100%;
  margin-bottom: -8px;
  opacity: 1;
  transition: all 240ms ease 0ms;
}
.bg-image.image-style-square .image__inner .group-hover-box {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: rgba(24, 144, 251, 0.8);
  opacity: 0;
  transition: all 240ms ease 0ms;
}
.bg-image.image-style-square .image__inner .group-hover-box:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 100%;
  margin-left: -1px;
}
.bg-image.image-style-square .image__inner .group-hover-box > * {
  display: inline-block;
  vertical-align: middle;
}
.bg-image.image-style-square:hover .image__inner .group-titling {
  opacity: 0;
}
.bg-image.image-style-square:hover .image__inner .group-hover-box {
  opacity: 1;
}
.field-signature-video {
  margin-bottom: 1.5rem;
}
.field-body .field-contents > iframe {
  width: 100%;
  max-width: 100%;
  margin-top: 1.6rem;
}
.field-body .field-contents > iframe:first-child {
  margin-top: 0;
}
.vid-wrapper,
.embedded-video .player {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.vid-wrapper .ratio,
.embedded-video .player .ratio {
  display: none;
}
.vid-wrapper iframe,
.embedded-video .player iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  height: 100%;
  width: 100%;
}
.embedded-video .ratio {
  display: none;
}
ul.tabs.primary {
  list-style: none;
  padding: 0;
  padding: 4px;
  margin: 0 auto;
  max-width: 1200px;
}
ul.tabs.primary li {
  margin: 0;
}
ul.tabs.primary li:before {
  content: none;
}
.node-type-page ul.tabs.primary,
.node-type-story ul.tabs.primary {
  text-align: center;
}
ul.tabs.primary li {
  display: inline-block;
  padding: 4px;
}
ul.tabs.primary li > a {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 9px;
}
ul.tabs.primary li > a:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
ul.tabs.primary li > a:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
ul.tabs.primary li > a:focus {
  outline: none;
}
ul.tabs.primary li > a:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
ul.tabs.primary li > a:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
ul.tabs.primary li > a.admin,
ul.tabs.primary li > a.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
ul.tabs.primary li > a.admin-extra {
  color: #1890fb;
}
ul.tabs.primary li > a.active {
  text-decoration: none;
  pointer-events: none;
}
#admin-menu .dropdown {
  list-style: none;
  padding: 0;
}
#admin-menu .dropdown li {
  margin: 0;
}
#admin-menu .dropdown li:before {
  content: none;
}
.contextual-links {
  list-style: none;
  padding: 0;
}
.contextual-links li {
  margin: 0;
}
.contextual-links li:before {
  content: none;
}
.krumo-node {
  list-style: none;
  padding: 0;
}
.krumo-node li {
  margin: 0;
}
.krumo-node li:before {
  content: none;
}
.field-collection-container {
  margin: 0;
  border: none;
}
.field-collection-view {
  margin: 0;
  padding: 0;
}
.field-collection-view-final {
  border: none;
}
.table {
  border-color: transparent;
}
.table .td {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  padding: 0.8em 1.6rem;
  text-align: center;
  border: none;
}
.table .td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  max-width: 36.425rem;
  text-align: left;
}
.table .td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.table_wrapper {
  width: 100%;
  overflow: auto;
}
/* --- [ Tablesaw ] --------------- */
.tablesaw {
  border-color: transparent;
}
.tablesaw .td {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  padding: 0.8em 1.6rem;
  text-align: center;
  border: none;
}
.tablesaw .td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  max-width: 36.425rem;
  text-align: left;
}
.tablesaw .td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.tablesaw thead tr th:first-child,
.tablesaw thead tr:first-child th:first-child,
.tablesaw td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.tablesaw thead tr th:last-child,
.tablesaw thead tr:first-child th:last-child,
.tablesaw td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.tablesaw thead tr th,
.tablesaw thead tr:first-child th {
  background-color: #4a4a4a;
  padding: 0.8rem 1.6rem;
  text-align: center;
  border: none;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  color: #FFFFFF;
}
.tablesaw thead tr th .field-contents,
.tablesaw thead tr:first-child th .field-contents {
  display: inline;
}
.tablesaw thead tr th a,
.tablesaw thead tr:first-child th a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tablesaw thead tr th a:hover,
.tablesaw thead tr:first-child th a:hover {
  color: #1890fb;
}
.tablesaw thead tr th.field-date [class*="date-display"],
.tablesaw thead tr:first-child th.field-date [class*="date-display"] {
  display: inline;
}
.tablesaw thead tr th:first-child,
.tablesaw thead tr:first-child th:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  text-align: left;
}
.tablesaw thead tr th:last-child,
.tablesaw thead tr:first-child th:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.tablesaw thead tr th a,
.tablesaw thead tr:first-child th a {
  display: block;
  position: relative;
  color: inherit;
}
.tablesaw thead tr th a span,
.tablesaw thead tr:first-child th a span {
  border: none;
}
.tablesaw thead tr th a:before,
.tablesaw thead tr:first-child th a:before {
  content: ' ';
  position: absolute;
  top: -0.8rem;
  right: -1.6rem;
  bottom: -0.8rem;
  left: -1.6rem;
}
.tablesaw td {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  padding: 0.8em 1.6rem;
  text-align: center;
  border: none;
}
.tablesaw td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  max-width: 36.425rem;
  text-align: left;
}
.tablesaw td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
@media only screen and (max-width: 960px) {
  .tablesaw {
    border-collapse: collapse;
  }
  .tablesaw.tablesaw-stack td,
  .tablesaw.tablesaw-stack td:first-child,
  .tablesaw.tablesaw-stack td:last-child {
    text-align: left;
    padding: .4rem 2rem;
    max-width: 100%;
  }
  .tablesaw.tablesaw-stack td:first-child {
    padding-top: 1.8rem;
  }
  .tablesaw.tablesaw-stack td:last-child {
    padding-bottom: 1.8rem;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label {
    font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: .025em;
    position: relative;
    color: #666666;
    margin: 0;
    padding: 0;
    padding-right: 1.5rem;
    display: inline-block;
    width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label .field-contents {
    display: inline;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label a {
    text-decoration: none;
    color: #1890fb;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label a:hover {
    color: #1890fb;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label.field-date [class*="date-display"] {
    display: inline;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label a {
    color: inherit;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label a .text {
    border: none;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-content {
    display: inline-block;
    width: 70%;
  }
}
@media only screen and (max-width: 580px) {
  .tablesaw.tablesaw-stack .tablesaw-cell-label {
    font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.5;
    color: #666666;
    text-transform: uppercase;
    letter-spacing: .025em;
    position: relative;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label .field-contents {
    display: inline;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label a {
    text-decoration: none;
    color: #1890fb;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label a:hover {
    color: #1890fb;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label.field-date [class*="date-display"] {
    display: inline;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label a {
    color: inherit;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-label a .text {
    border: none;
  }
  .tablesaw.tablesaw-stack .tablesaw-cell-content {
    display: inline-block;
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .tablesaw.tablesaw-stack td,
  .tablesaw.tablesaw-stack td:first-child,
  .tablesaw.tablesaw-stack td:last-child {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }
}
@media only screen and (max-width: 960px) {
  .tablesaw.tablesaw-stack tbody tr {
    border: none;
  }
  .tablesaw.tablesaw-stack thead td,
  .tablesaw.tablesaw-stack thead th {
    display: none;
  }
  .tablesaw.tablesaw-stack tbody td,
  .tablesaw.tablesaw-stack tbody th {
    clear: left;
    float: left;
    width: 100%;
  }
  .tablesaw .tablesaw-cell-label {
    display: inline-block !important;
    vertical-align: top;
  }
  .tablesaw .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  .tablesaw.tablesaw-stack td:empty,
  .tablesaw.tablesaw-stack th:empty {
    display: none;
  }
}
@media only screen and (min-width: 961px) {
  .tablesaw {
    /* Show the table header rows */
    /* Hide the labels in each cell */
  }
  .tablesaw.tablesaw-stack tr {
    display: table-row;
  }
  .tablesaw.tablesaw-stack tbody tr {
    border: none;
  }
  .tablesaw.tablesaw-stack td,
  .tablesaw.tablesaw-stack th,
  .tablesaw.tablesaw-stack thead td,
  .tablesaw.tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .tablesaw.tablesaw-stack td .tablesaw-cell-label,
  .tablesaw.tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}
.profile {
  width: 100%;
}
.profile .applications {
  width: 100%;
  overflow: auto;
}
.profile table {
  border-color: transparent;
  width: 100%;
}
.profile table .td {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  padding: 0.8em 1.6rem;
  text-align: center;
  border: none;
}
.profile table .td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  max-width: 36.425rem;
  text-align: left;
}
.profile table .td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.profile table tbody > tr:nth-child(even) > td {
  background-color: #E6E6E6;
}
.profile table tbody tr {
  border: none;
}
.profile table th:first-child,
.profile table td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.profile table th:last-child,
.profile table td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.profile table th {
  background-color: #4a4a4a;
  padding: 0.8rem 1.6rem;
  text-align: center;
  border: none;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  color: #FFFFFF;
}
.profile table th .field-contents {
  display: inline;
}
.profile table th a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.profile table th a:hover {
  color: #1890fb;
}
.profile table th.field-date [class*="date-display"] {
  display: inline;
}
.profile table th:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  text-align: left;
}
.profile table th:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.profile table th a {
  display: block;
  position: relative;
  color: inherit;
}
.profile table th a span {
  border: none;
}
.profile table th a:before {
  content: ' ';
  position: absolute;
  top: -0.8rem;
  right: -1.6rem;
  bottom: -0.8rem;
  left: -1.6rem;
}
.profile table td {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  padding: 0.8em 1.6rem;
  text-align: center;
  border: none;
}
.profile table td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  max-width: 36.425rem;
  text-align: left;
}
.profile table td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.profile table .item-list ul {
  list-style: none;
  padding: 0;
}
.profile table .item-list ul li {
  margin: 0;
}
.profile table .item-list ul li:before {
  content: none;
}
.profile table .id,
.profile table .status {
  font-weight: bold;
}
.profile table .details,
.profile table .product {
  color: #4d5a6b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
}
.table-wrap {
  border-color: transparent;
  width: 100%;
  margin-top: .8rem;
}
.table-wrap .td {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  padding: 0.8em 1.6rem;
  text-align: center;
  border: none;
}
.table-wrap .td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  max-width: 36.425rem;
  text-align: left;
}
.table-wrap .td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.table-wrap tbody > tr:nth-child(even) > td {
  background-color: #E6E6E6;
}
.table-wrap tbody tr {
  border: none;
}
.table-wrap th:first-child,
.table-wrap td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.table-wrap th:last-child,
.table-wrap td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.table-wrap th {
  background-color: #4a4a4a;
  padding: 0.8rem 1.6rem;
  text-align: center;
  border: none;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  color: #FFFFFF;
}
.table-wrap th .field-contents {
  display: inline;
}
.table-wrap th a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.table-wrap th a:hover {
  color: #1890fb;
}
.table-wrap th.field-date [class*="date-display"] {
  display: inline;
}
.table-wrap th:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  text-align: left;
}
.table-wrap th:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.table-wrap th a {
  display: block;
  position: relative;
  color: inherit;
}
.table-wrap th a span {
  border: none;
}
.table-wrap th a:before {
  content: ' ';
  position: absolute;
  top: -0.8rem;
  right: -1.6rem;
  bottom: -0.8rem;
  left: -1.6rem;
}
.table-wrap td {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  padding: 0.8em 1.6rem;
  text-align: center;
  border: none;
}
.table-wrap td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  max-width: 36.425rem;
  text-align: left;
}
.table-wrap td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.table-wrap:first-child {
  margin-top: 0;
}
.table-wrap td,
.table-wrap td:first-child,
.table-wrap td:last-child {
  padding-top: .75rem;
  padding-bottom: .75rem;
}
.table-wrap[style*="width"] {
  width: 100% !important;
}
.owl-controls .owl-dots .owl-dot {
  display: block;
}
.region-preface-first-inner > .content {
  position: relative;
}
.region-preface-first-inner > .content .owl-carousel {
  margin: 0 auto;
  position: relative;
}
.region-preface-first-inner > .content .owl-carousel .owl-stage-outer {
  overflow: visible;
}
@media only screen and (max-width: 480px) {
  .region-preface-first-inner > .content .owl-carousel .owl-item:nth-child(3) ~ .owl-item {
    display: none;
  }
}
/* --- [ Behaviors ] --------------- */
@media only screen and (max-width: 960px) {
  .mobileMainMenuToggle {
    background: transparent;
    transition: background-color 150ms ease;
  }
  .mobileMainMenuToggle:hover {
    background: transparent;
  }
  .mobileMainMenuToggle:focus {
    outline: none;
  }
  .mobileMainMenuToggle .text {
    display: inline-block !important;
    vertical-align: middle;
    width: 30px;
    height: 4px;
    background: #FFFFFF;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    text-indent: -9999px;
    transition: background-color 150ms ease;
  }
  .mobileMainMenuToggle .text:before,
  .mobileMainMenuToggle .text:after {
    display: inline-block;
    width: 100%;
    height: 4px;
    background: #FFFFFF;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: center;
    transition: transform 150ms ease, top 150ms ease, color 150ms ease;
  }
  .mobileMainMenuToggle .text:before {
    top: 8px;
  }
  .mobileMainMenuToggle .text:after {
    top: -8px;
  }
  .mobileMainMenuToggle.isOpen {
    position: relative;
    padding-left: 3rem;
    padding-left: 14px;
  }
  .mobileMainMenuToggle.isOpen:before {
    content: ' ';
    position: absolute;
    left: 0;
    right: auto;
    display: block;
    background-image: url("../images/icons/close--x.svg");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 2rem 2rem;
    width: 2rem;
    height: 2rem;
    margin-top: .05rem;
  }
  .mobileMainMenuToggle.isOpen::before {
    left: auto;
    right: 17px;
    top: -7px;
  }
  .mobileMainMenuToggle.isOpen .text {
    display: none !important;
  }
}
@media only screen and (max-width: 960px) and only screen and (max-width: 960px) {
  .mobileMainMenuToggle.isOpen {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
@media only screen and (min-width: 481px) {
  .mobileMainMenuToggle .text {
    width: 28px;
  }
}
.toggleSwitch {
  padding: 10px 14px;
  width: 70px;
  text-align: center;
  display: inline-block;
}
.mainSearchToggle .text {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  padding: 0;
  padding-top: 5px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/icons/search-white.svg");
  width: 40px;
  position: relative;
  text-indent: -9999px;
  display: block;
}
.mainSearchToggle .text:hover {
  background-image: url("../images/icons/search-red.svg");
}
.mainSearchToggle .text:before,
.mainSearchToggle .text:after {
  content: ' ';
  position: absolute;
  display: block;
  top: 50%;
  left: 10px;
  right: 10px;
  height: 3px;
  width: 20px;
  background-color: transparent;
  z-index: 10;
  transform: translateY(calc(-50% - .5px));
  transition: all 240ms ease 0ms;
}
.mainSearchToggle .text:after {
  transform: rotate(-45deg);
}
.mainSearchToggle .text:before {
  transform: rotate(45deg);
}
.mainSearchToggle .text#search-helper .menu-item-inner:before {
  content: none;
}
.mainSearchToggle.isOpen .text {
  background-image: none;
}
.mainSearchToggle.isOpen .text:before,
.mainSearchToggle.isOpen .text:after {
  background-color: #4d5a6b;
}
.mainSearchToggle.isOpen .text:before {
  transform: translateY(calc(-50% - .5px)) rotate(45deg);
}
.mainSearchToggle.isOpen .text:after {
  transform: translateY(calc(-50% - .5px)) rotate(-45deg);
}
@media only screen and (max-width: 960px) {
  .mainSearchToggle {
    display: none;
  }
}
@media only screen and (max-width: 960px) {
  .togglePane.hasToggle.mainMenuPane {
    transform-origin: top center;
    transition: transform 100ms ease 0ms, padding 100ms ease 0ms, opacity 100ms ease 0ms;
    position: relative;
    z-index: 1;
    display: flex;
    position: absolute;
    z-index: 40;
  }
  .togglePane.hasToggle.mainMenuPane.isOpen {
    height: auto;
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  .togglePane.hasToggle.mainMenuPane.isClosed {
    height: 0;
    transform: scale3d(1, 0, 0);
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border: none;
    opacity: 0;
  }
}
@media only screen and (max-width: 960px) {
  .togglePane.hasToggle.tlAccordion {
    transform-origin: top center;
    transition: transform 100ms ease 0ms, padding 100ms ease 0ms, opacity 100ms ease 0ms;
    position: relative;
    z-index: 1;
    display: flex;
  }
  .togglePane.hasToggle.tlAccordion.isOpen {
    height: auto;
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  .togglePane.hasToggle.tlAccordion.isClosed {
    height: 0;
    transform: scale3d(1, 0, 0);
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border: none;
    opacity: 0;
  }
}
@media only screen and (max-width: 960px) {
  .togglePane.hasToggle.mainSearchPane {
    height: auto;
    transform: none;
    opacity: 1;
  }
}
@media only screen and (max-width: 580px) {
  .togglePane.hasToggle.tabAccordion {
    transform-origin: top center;
    transition: transform 100ms ease 0ms, padding 100ms ease 0ms, opacity 100ms ease 0ms;
    position: relative;
    z-index: 1;
    display: flex;
  }
  .togglePane.hasToggle.tabAccordion.isOpen {
    height: auto;
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  .togglePane.hasToggle.tabAccordion.isClosed {
    height: 0;
    transform: scale3d(1, 0, 0);
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border: none;
    opacity: 0;
  }
}
.hasAccordionSwitch {
  position: relative;
  padding-right: 60px;
}
.hasAccordionSwitch .toggle {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  font-size: 0.8125rem;
  padding: 0;
  width: 60px;
  background-image: url("../images/icons/dropdown-arrow--red.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
  transition: all 240ms ease 0ms;
}
.hasAccordionSwitch .toggle.isClosed {
  transform: scale3d(1, 1, 1);
}
.hasAccordionSwitch .toggle.isOpen {
  transform: scale3d(1, -1, 1);
}
.hasAccordionSwitch .toggle:hover {
  cursor: pointer;
}
.hasAccordionSwitch .toggle .text {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
  display: block;
  height: 100%;
}
.accordionPane.hasToggle {
  transform-origin: top center;
  transition: transform 100ms ease 0ms, padding 100ms ease 0ms, opacity 100ms ease 0ms;
  position: relative;
  z-index: 1;
  display: flex;
  padding: 1.5rem 20px;
  background-color: #FFFFFF;
}
.accordionPane.hasToggle.isOpen {
  height: auto;
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.accordionPane.hasToggle.isClosed {
  height: 0;
  transform: scale3d(1, 0, 0);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: none;
  opacity: 0;
}
.not-front.page-events-register .toggleSwitch,
.not-front.page-checkout .toggleSwitch,
.not-front.page-donate .toggleSwitch {
  display: none !important;
}
/* --- [ Forms ] --------------- */
/* --- [ Variables ] --------------- */
.__full-width {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__half-width {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__one-fifth-width {
  display: inline-block;
  vertical-align: top;
  width: 20%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__two-fifths-width {
  display: inline-block;
  vertical-align: top;
  width: 40%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__three-fifths-width {
  display: inline-block;
  vertical-align: top;
  width: 60%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__four-fifths-width {
  display: inline-block;
  vertical-align: top;
  width: 80%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.__auto-width {
  display: inline-block;
  vertical-align: top;
  width: auto;
}
.__p-left {
  padding-left: 0.75rem;
}
.__p-right {
  padding-right: 0.75rem;
}
.__m-top {
  margin: 0;
  margin-top: 1rem;
}
.__m-top-double {
  margin: 0;
  margin-top: 2.5rem;
}
.__m-top-0 {
  margin-top: 0;
}
.hidden {
  display: none;
}
.input,
.chosen-container {
  margin-top: 0;
  line-height: 1;
  font-weight: normal;
  color: #4a4a4a;
}
.input:focus,
.input:active {
  box-shadow: none;
  outline: none;
}
.input:disabled {
  opacity: .5;
  pointer-events: none;
}
textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
.form-item.bt-hosted-field {
  margin-top: 0;
  line-height: 1;
  font-weight: normal;
  color: #4a4a4a;
  box-sizing: border-box;
  height: 3rem;
  width: 100%;
  appearance: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  border: 2px solid #E6E6E6;
  background-color: #FFFFFF;
  padding: 10px;
}
textarea:focus,
select:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus,
.form-item.bt-hosted-field:focus,
textarea:active,
select:active,
input[type="date"]:active,
input[type="datetime"]:active,
input[type="datetime-local"]:active,
input[type="email"]:active,
input[type="month"]:active,
input[type="number"]:active,
input[type="password"]:active,
input[type="search"]:active,
input[type="tel"]:active,
input[type="text"]:active,
input[type="time"]:active,
input[type="url"]:active,
input[type="week"]:active,
.form-item.bt-hosted-field:active {
  box-shadow: none;
  outline: none;
}
textarea:disabled,
select:disabled,
input[type="date"]:disabled,
input[type="datetime"]:disabled,
input[type="datetime-local"]:disabled,
input[type="email"]:disabled,
input[type="month"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="text"]:disabled,
input[type="time"]:disabled,
input[type="url"]:disabled,
input[type="week"]:disabled,
.form-item.bt-hosted-field:disabled {
  opacity: .5;
  pointer-events: none;
}
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="datetime"]::-webkit-input-placeholder,
input[type="datetime-local"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="month"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="time"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="week"]::-webkit-input-placeholder,
.form-item.bt-hosted-field::-webkit-input-placeholder {
  font: inherit;
  line-height: 1.4;
  font-style: italic;
  color: inherit;
}
textarea:-moz-placeholder,
select:-moz-placeholder,
input[type="date"]:-moz-placeholder,
input[type="datetime"]:-moz-placeholder,
input[type="datetime-local"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="month"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="text"]:-moz-placeholder,
input[type="time"]:-moz-placeholder,
input[type="url"]:-moz-placeholder,
input[type="week"]:-moz-placeholder,
.form-item.bt-hosted-field:-moz-placeholder {
  font: inherit;
  line-height: 1.4;
  font-style: italic;
  color: inherit;
}
textarea::-moz-placeholder,
select::-moz-placeholder,
input[type="date"]::-moz-placeholder,
input[type="datetime"]::-moz-placeholder,
input[type="datetime-local"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="month"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="text"]::-moz-placeholder,
input[type="time"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="week"]::-moz-placeholder,
.form-item.bt-hosted-field::-moz-placeholder {
  font: inherit;
  line-height: 1.4;
  font-style: italic;
  color: inherit;
}
textarea:-ms-input-placeholder,
select:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="datetime"]:-ms-input-placeholder,
input[type="datetime-local"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="month"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="time"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="week"]:-ms-input-placeholder,
.form-item.bt-hosted-field:-ms-input-placeholder {
  font: inherit;
  line-height: 1.4;
  font-style: italic;
  color: inherit;
}
textarea.error,
select.error,
input[type="date"].error,
input[type="datetime"].error,
input[type="datetime-local"].error,
input[type="email"].error,
input[type="month"].error,
input[type="number"].error,
input[type="password"].error,
input[type="search"].error,
input[type="tel"].error,
input[type="text"].error,
input[type="time"].error,
input[type="url"].error,
input[type="week"].error,
.form-item.bt-hosted-field.error {
  color: #d0011b;
  border-color: #d0011b;
}
select {
  padding: 10px;
  padding-right: 3rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  appearance: none;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  border: 2px solid #E6E6E6;
  background-color: #FFFFFF;
  height: 3rem;
  padding: 5px 10px;
  line-height: 1.2;
  background-image: url("../images/icons/dropdown-arrow.svg");
  background-size: auto .6rem;
  background-repeat: no-repeat;
  background-position: right 1rem top .95rem;
  cursor: pointer;
}
select::-ms-expand {
  display: none;
}
input[type="checkbox"] {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 3px solid #E6E6E6;
  background-color: transparent;
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: -10px;
  border-radius: 2px;
  margin-right: 0;
  width: 14px;
  height: 14px;
}
input[type="checkbox"] .checked,
input[type="checkbox"]:checked {
  outline: none;
  box-shadow: none;
  background-color: #FFFFFF;
}
input[type="checkbox"]:checked {
  outline: none;
  box-shadow: none;
  background-color: #FFFFFF;
  background-color: #1890fb;
  border-color: #1890fb;
  background-image: url("../images/icons/checkmark--white.svg");
  border: none;
  width: 20px;
  height: 20px;
  background-size: contain;
}
.form-type-checkbox input[type="checkbox"],
.form-type-radios input[type="checkbox"] {
  position: absolute;
  margin-right: 0;
}
input[type="radio"] {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 3px solid #E6E6E6;
  background-color: transparent;
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: -10px;
  border-radius: 2px;
  margin-right: 0;
  border-radius: 100%;
  background-size: auto 75%;
  background-repeat: no-repeat;
  background-position: center;
  border-width: 2px;
}
input[type="radio"] .checked,
input[type="radio"]:checked {
  outline: none;
  box-shadow: none;
  background-color: #FFFFFF;
}
input[type="radio"]:checked {
  outline: none;
  box-shadow: none;
  background-color: #FFFFFF;
  background-image: url("../images/icons/radio-dot--brightblue.svg");
  border-color: #1890fb;
}
.form-type-checkbox input[type="radio"],
.form-type-radios input[type="radio"] {
  position: absolute;
  margin-right: 0;
}
input[type="checkbox"] {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
}
input[type="checkbox"] + label {
  font-size: 16px;
  margin-left: 3px;
  margin-right: 4px;
  position: relative;
  padding-left: 2rem;
}
input[type="checkbox"] + label:before {
  border-radius: 0;
  width: 1rem;
  height: 1rem;
  background-size: .75rem auto;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
  border: 1px solid #E6E6E6;
  content: "";
  display: inline-block;
  margin-left: -1.35rem;
  margin-right: 4px;
  position: relative;
  right: .3rem;
}
input[type="checkbox"]:checked + label:before {
  background-image: url("../images/icons/checkmark--white.svg");
  background-color: #4d5a6b;
  border-color: #4d5a6b;
}
input[type="radio"] {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
}
input[type="radio"] + label {
  font-size: 16px;
  margin-left: 3px;
  margin-right: 4px;
  position: relative;
  padding-left: 2rem;
}
input[type="radio"] + label:before {
  border-radius: 200rem;
  width: 1rem;
  height: 1rem;
  background-size: .75rem auto;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #666666;
  content: "";
  display: inline-block;
  margin-left: -1.35rem;
  margin-right: 4px;
  position: relative;
  right: .3rem;
}
input[type="radio"]:checked + label:before {
  background-image: url("../images/icons/radio-dot--brightblue.svg");
  background-color: transparent;
  border-color: #4d5a6b;
  box-shadow: 0px 0px 0px 1px #4d5a6b;
}
input::-ms-check {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Good browsers :) */
  opacity: 0;
}
textarea {
  height: auto;
  line-height: 1.4;
}
input.password-confirm,
input.password-field {
  margin-bottom: 0;
}
input[type="submit"] {
  background-color: #d9a70f;
}
input[type="submit"]:hover {
  background-color: #DC8802;
}
input[type="submit"]:active {
  background-color: #DC5502;
}
input[type="submit"][id*="add-more"] {
  background-color: #d9a70f;
}
input[type="submit"][id*="add-more"]:hover {
  background-color: #DC8802;
}
input[type="submit"][id*="add-more"]:active {
  background-color: #DC5502;
}
input[type="submit"][id*="upload-button"] {
  background-color: #d9a70f;
}
input[type="submit"][id*="upload-button"]:hover {
  background-color: #DC8802;
}
input[type="submit"][id*="upload-button"]:active {
  background-color: #DC5502;
}
input[type="reset"] {
  color: #707070;
  background-color: transparent;
  border-color: #707070;
  border-width: 3px;
  border: solid;
}
input[type="reset"]:hover {
  background-color: #707070;
  color: #FFFFFF;
  border-color: #707070;
}
input[type="reset"]:active {
  background-color: #575757;
  color: #FFFFFF;
  border-color: #575757;
}
button {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
}
button:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
button:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
button:focus {
  outline: none;
}
button:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
button:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
button.admin,
button.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
button.admin-extra {
  color: #1890fb;
}
html.js input.form-autocomplete {
  background-position: right 10px center !important;
}
.form-item {
  margin-bottom: 1rem;
}
.form-item .messages {
  margin-top: .5rem;
  margin-bottom: .5rem;
}
.form-item-agreement {
  margin-top: 1em;
}
.form-item-combine {
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .form-item-combine {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.form-item-combine:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/search--grey.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.form-item-combine:hover {
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .form-item-combine:hover {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.form-item-combine:hover:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/search--teal.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.form-item-combine input {
  padding-right: 2.5em;
}
.form-item-keys label {
  display: none !important;
}
.form-item-keys input {
  padding-right: 2.5em;
  font-size: 1rem;
  background-size: 1rem 1rem;
  background-image: url("../images/icons/search--grey.svg");
  padding-right: 3rem;
  background-position: right 1rem center !important;
  background-repeat: no-repeat;
}
.form-item-keys input:hover {
  background-image: url("../images/icons/search--teal.svg");
}
div.form-item div.password-suggestions {
  padding: 1rem 1.5rem;
  margin-bottom: 3rem;
  position: relative;
  background-image: none;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  color: #666666;
}
div.form-item div.password-suggestions.hasClose {
  padding-right: 8.75rem;
  max-height: 2000px;
  overflow: hidden;
  display: block;
  transform-origin: top center;
  backface-visibility: hidden;
  position: relative;
  transform: scale3d(1, 1, 1);
  transition: transform 150ms ease 0ms, max-height 150ms ease 0ms, margin-bottom 150ms ease 0ms, padding 0ms ease 150ms;
}
@media only screen and (max-width: 480px) {
  div.form-item div.password-suggestions.hasClose {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
    padding: 1.75rem 2rem 1.3rem;
  }
}
div.form-item div.password-suggestions.isClosed {
  padding: 0;
  max-height: 0;
  margin-bottom: 0;
  transform: scale3d(1, 0, 1);
}
div.form-item div.password-suggestions a {
  color: inherit;
  text-decoration: underline;
  text-decoration: none;
}
div.form-item div.password-suggestions a:hover {
  color: #1890fb;
}
div.form-item div.password-suggestions a,
div.form-item div.password-suggestions a:hover {
  color: #1890fb;
}
div.form-item div.password-suggestions a:hover {
  text-decoration: underline;
}
div.form-item div.password-suggestions .button,
div.form-item div.password-suggestions button {
  color: #FFFFFF;
  border-color: #FFFFFF;
}
div.form-item div.password-suggestions .close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  border: none;
  width: 8.75rem;
}
div.form-item div.password-suggestions .close .text {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
@media only screen and (max-width: 480px) {
  div.form-item div.password-suggestions .close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    width: 2.6rem;
    padding: 0;
    line-height: 1;
  }
}
div.form-item div.password-suggestions .close:after {
  content: '\00d7';
  font-size: 2.25rem;
}
@media only screen and (max-width: 960px) {
  div.form-item div.password-suggestions {
    margin-bottom: 2.5rem;
  }
}
.container-inline {
  position: relative;
}
.container-inline > div {
  display: inline-block;
}
.container-inline > .form-item {
  margin: 0;
}
.container-inline > .form-submit {
  display: inline-block;
}
legend {
  float: left;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: normal;
  color: inherit;
}
legend + * {
  clear: left;
  zoom: 1;
}
legend + *:before,
legend + *:after {
  content: " ";
  display: table;
}
legend + *:after {
  clear: both;
}
legend span {
  display: block;
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  margin: 0;
  font-size: 14.5px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2;
  letter-spacing: 0.03em;
  color: #FFFFFF;
}
@media only screen and (max-width: 480px) {
  legend span {
    font-size: 1.25rem;
  }
}
legend span a {
  display: block;
}
legend span a:link {
  color: inherit;
}
legend span a:hover {
  text-decoration: none;
}
fieldset {
  position: relative;
  padding: 0;
  border: none;
  margin: 0 auto;
}
fieldset ~ fieldset,
fieldset ~ [id*="ajax-wrapper"] {
  margin-top: 40px;
}
fieldset legend {
  float: left;
  width: 100%;
}
fieldset legend + * {
  clear: left;
}
fieldset legend span {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  display: block;
}
@media only screen and (max-width: 480px) {
  fieldset legend span {
    font-size: 1.25rem;
  }
}
.zone-footer fieldset legend span,
.ds-media-aside fieldset legend span {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer fieldset legend span,
  .ds-media-aside fieldset legend span {
    font-size: 1.25rem;
  }
}
.collapsible legend {
  background-color: #D7D7D7;
}
.collapsible .fieldset-wrapper {
  padding: 30px;
}
.collapsible .fieldset-wrapper fieldset {
  margin-top: 30px;
}
.collapsible > .fieldset-wrapper > .form-wrapper .fieldset-wrapper {
  margin-left: -30px;
  margin-right: -30px;
}
.collapsible .fieldset-title {
  position: relative;
}
.collapsible .fieldset-title:after {
  content: ' ';
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/icons/dropdown-arrow.svg");
  background-size: contain;
  background-size: 10px 10px;
  top: 8px;
  right: 0;
  transform: scale(1, -1, 1);
  width: 16px;
  height: 16px;
  transition: transform 100ms ease 0ms;
}
.collapsible .fieldset-wrapper .fieldset-title:after {
  background-image: url("../images/icons/dropdown-arrow--red.svg");
}
html.js .collapsible.collapsed {
  height: auto;
}
.collapsible.collapsed .fieldset-title:after {
  transform: scale3d(1, -1, 1);
}
.webform-client-form fieldset ~ fieldset {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #E6E6E6;
}
.webform-client-form label {
  display: inline-block;
  margin-bottom: 8px;
}
.webform-client-form .fieldset-description,
.webform-client-form .description {
  font-size: 0.8125rem;
  letter-spacing: normal;
  color: #666666;
}
.webform-client-form .form-actions {
  font-size: 0;
  margin: -0.5rem;
  margin-top: 1.875rem;
  padding-top: 1.25rem;
}
.webform-client-form .form-actions input[type="submit"],
.webform-client-form .form-actions input[type="reset"] {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
  margin: .5rem;
}
.webform-client-form .form-actions input[type="submit"]:hover,
.webform-client-form .form-actions input[type="reset"]:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.webform-client-form .form-actions input[type="submit"]:active,
.webform-client-form .form-actions input[type="reset"]:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.webform-client-form .form-actions input[type="submit"]:focus,
.webform-client-form .form-actions input[type="reset"]:focus {
  outline: none;
}
.webform-client-form .form-actions input[type="submit"]:disabled,
.webform-client-form .form-actions input[type="reset"]:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.webform-client-form .form-actions input[type="submit"]:disabled:hover,
.webform-client-form .form-actions input[type="reset"]:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.webform-client-form .form-actions input[type="submit"].admin,
.webform-client-form .form-actions input[type="reset"].admin,
.webform-client-form .form-actions input[type="submit"].admin-extra,
.webform-client-form .form-actions input[type="reset"].admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.webform-client-form .form-actions input[type="submit"].admin-extra,
.webform-client-form .form-actions input[type="reset"].admin-extra {
  color: #1890fb;
}
.webform-client-form .form-actions input[id*="pay"] {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
  margin: .5rem;
}
.webform-client-form .form-actions input[id*="pay"]:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.webform-client-form .form-actions input[id*="pay"]:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.webform-client-form .form-actions input[id*="pay"]:focus {
  outline: none;
}
.webform-client-form .form-actions input[id*="pay"]:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.webform-client-form .form-actions input[id*="pay"]:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.webform-client-form .form-actions input[id*="pay"].admin,
.webform-client-form .form-actions input[id*="pay"].admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.webform-client-form .form-actions input[id*="pay"].admin-extra {
  color: #1890fb;
}
.webform-client-form .form-actions .ajax-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  background: rgba(0, 0, 0, 0.75);
  animation: fadein 500ms;
}
.webform-client-form .form-actions .ajax-progress .throbber {
  background-image: url("../images/icons/triple-dots--white.svg");
  background-repeat: no-repeat;
  background-position: center;
  float: none;
  margin: 0 auto;
  width: 40px;
  height: 100%;
  animation: fadeinout 750ms infinite;
}
label {
  margin-bottom: 8px;
  display: inline-block;
}
.form-type-checkbox label,
.form-type-radios label {
  margin-bottom: 0;
  display: inline;
}
form .description {
  margin-top: 6px;
  font-size: 0.8125rem;
  letter-spacing: normal;
  color: #666666;
}
form {
  margin: 0 auto;
  /* --- [ Form type checkbox ] --------------- */
}
form label,
form .form-item.form-type-radios > label,
form .group-subscribe > div > span,
form .group-demo > div > span {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
  margin: 11px 0;
  font-weight: bold;
  color: #4d5a6b;
  display: inline-block;
  vertical-align: middle;
}
form .form-type-checkbox,
form .form-type-radios {
  position: relative;
}
form .form-type-checkbox input,
form .form-type-radios input {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
form .form-type-checkbox label,
form .form-type-radios label {
  margin: 0;
  display: block;
  position: relative;
  font-size: 1rem;
  line-height: 1.2;
  color: #666666;
  font-weight: 700;
  letter-spacing: .02em;
  font-weight: 600;
}
form .form-type-checkbox label:before,
form .form-type-radios label:before {
  content: ' ';
  position: absolute;
  left: 0;
}
form .form-type-checkbox input[type="checkbox"] + label .text {
  display: inline-block;
  vertical-align: top;
}
form .form-type-checkbox input[type="checkbox"] + label:before {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 3px solid #E6E6E6;
  background-color: transparent;
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: -10px;
  border-radius: 2px;
  margin-right: 0;
  width: 14px;
  height: 14px;
}
form .form-type-checkbox input[type="checkbox"] + label:before .checked,
form .form-type-checkbox input[type="checkbox"] + label:before:checked {
  outline: none;
  box-shadow: none;
  background-color: #FFFFFF;
}
form .form-type-checkbox input[type="checkbox"]:checked + label:before {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 3px solid #E6E6E6;
  background-color: transparent;
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: -10px;
  border-radius: 2px;
  margin-right: 0;
  width: 14px;
  height: 14px;
  outline: none;
  box-shadow: none;
  background-color: #FFFFFF;
  background-color: #1890fb;
  border-color: #1890fb;
  background-image: url("../images/icons/checkmark--white.svg");
  border: none;
  width: 20px;
  height: 20px;
  background-size: contain;
}
form .form-type-checkbox input[type="checkbox"]:checked + label:before .checked,
form .form-type-checkbox input[type="checkbox"]:checked + label:before:checked {
  outline: none;
  box-shadow: none;
  background-color: #FFFFFF;
}
form .form-radios input[type="radio"] + label:before,
form .form-type-radios input[type="radio"] + label:before {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 3px solid #E6E6E6;
  background-color: transparent;
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: -10px;
  border-radius: 2px;
  margin-right: 0;
  border-radius: 100%;
  background-size: auto 75%;
  background-repeat: no-repeat;
  background-position: center;
  border-width: 2px;
}
form .form-radios input[type="radio"] + label:before .checked,
form .form-type-radios input[type="radio"] + label:before .checked,
form .form-radios input[type="radio"] + label:before:checked,
form .form-type-radios input[type="radio"] + label:before:checked {
  outline: none;
  box-shadow: none;
  background-color: #FFFFFF;
}
form .form-radios input[type="radio"]:checked + label:before,
form .form-type-radios input[type="radio"]:checked + label:before {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 3px solid #E6E6E6;
  background-color: transparent;
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: -10px;
  border-radius: 2px;
  margin-right: 0;
  border-radius: 100%;
  background-size: auto 75%;
  background-repeat: no-repeat;
  background-position: center;
  border-width: 2px;
  outline: none;
  box-shadow: none;
  background-color: #FFFFFF;
  background-image: url("../images/icons/radio-dot--brightblue.svg");
  border-color: #1890fb;
}
form .form-radios input[type="radio"]:checked + label:before .checked,
form .form-type-radios input[type="radio"]:checked + label:before .checked,
form .form-radios input[type="radio"]:checked + label:before:checked,
form .form-type-radios input[type="radio"]:checked + label:before:checked {
  outline: none;
  box-shadow: none;
  background-color: #FFFFFF;
}
form .form-wrapper + fieldset,
form fieldset + .form-wrapper {
  margin-top: 30px;
}
form .fieldset-description {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #4d5a6b;
  background-color: rgba(255, 255, 255, 0.75);
  color: #1890fb;
}
form .fieldset-description > * {
  margin-top: 12px;
}
form .fieldset-description > *:last-child {
  margin-bottom: 0;
}
form .fieldset-description ul,
form .fieldset-description ol {
  margin-left: 0 !important;
}
form .fieldset-description ul li ~ li,
form .fieldset-description ol li ~ li {
  margin-top: 8px;
}
form .form-required,
form .name-required-component-marker {
  color: #d0011b;
}
form#commerce-registration-node-registrations-settings-form input[type="submit"] {
  margin-left: auto;
  margin-right: 0;
  display: block;
  width: 100%;
  margin-top: 30px;
}
form#commerce-registration-node-registrations-settings-form > div > .form-item,
form#commerce-registration-node-registrations-settings-form > div > .container-inline-date {
  border-bottom: 1px solid #D7D7D7;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date + .container-inline-date {
  margin-top: 20px;
  border-top: 1px solid #D7D7D7;
  padding-top: 12px;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup {
  width: 100%;
  margin-right: 0;
  float: none;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > label {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 20%;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup select {
  margin-left: 10px;
  margin-right: 10px;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-select,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-text,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .date-padding {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  float: none;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-select label,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-text label,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .date-padding label,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-select .form-item,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-text .form-item,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .date-padding .form-item {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-select label input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-text label input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .date-padding label input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-select .form-item input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-text .form-item input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .date-padding .form-item input {
  width: auto;
  display: inline-block;
  vertical-align: middle;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup .description {
  display: inline-block;
  vertical-align: middle;
}
form[id^="commerce-checkout-form-review"],
form[id^="commerce-checkout"] {
  margin: 0 auto;
  box-sizing: border-box;
  /**
   * The below selectors cause serious rendering issues on webforms.
   * It's uncertain if they're still necessary—I hope they're not!
   *
   * —Rory 11.14.16
   */
  /*
      .addressfield-container-inline {
        letter-spacing: -1em;
      }

      .addressfield-container-inline > .addressfield-container-inline,
      .addressfield-container-inline > .form-item,
      .street-block {
        .__m-top;
        letter-spacing: normal;
        float: none;
      }
  */
}
form[id^="commerce-checkout-form-review"] > div > fieldset:not(.cart_contents):not(.checkout-buttons):not(.registration_information) > .fieldset-wrapper,
form[id^="commerce-checkout"] > div > fieldset:not(.cart_contents):not(.checkout-buttons):not(.registration_information) > .fieldset-wrapper,
form[id^="commerce-checkout-form-review"] > div > div[id*="ajax-wrapper"] > fieldset > .fieldset-wrapper,
form[id^="commerce-checkout"] > div > div[id*="ajax-wrapper"] > fieldset > .fieldset-wrapper {
  background-color: #FFFFFF;
}
form[id^="commerce-checkout-form-review"] .product-title,
form[id^="commerce-checkout"] .product-title {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  color: #1890fb;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-top: 1.25rem;
  border-top: 2px solid #E6E6E6;
  display: inline-block;
  width: 100%;
}
form[id^="commerce-checkout-form-review"] input[type="submit"]#edit-skip,
form[id^="commerce-checkout"] input[type="submit"]#edit-skip {
  margin-bottom: 30px;
}
@media only screen and (max-width: 960px) {
  form[id^="commerce-checkout-form-review"] input[type="submit"],
  form[id^="commerce-checkout"] input[type="submit"] {
    width: 100%;
    margin-bottom: 0;
  }
}
form[id^="commerce-checkout-form-review"] input[type="submit"].checkout-back,
form[id^="commerce-checkout"] input[type="submit"].checkout-back {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
}
form[id^="commerce-checkout-form-review"] input[type="submit"].checkout-back:hover,
form[id^="commerce-checkout"] input[type="submit"].checkout-back:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
form[id^="commerce-checkout-form-review"] input[type="submit"].checkout-back:active,
form[id^="commerce-checkout"] input[type="submit"].checkout-back:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
form[id^="commerce-checkout-form-review"] input[type="submit"].checkout-back:focus,
form[id^="commerce-checkout"] input[type="submit"].checkout-back:focus {
  outline: none;
}
form[id^="commerce-checkout-form-review"] input[type="submit"].checkout-back:disabled,
form[id^="commerce-checkout"] input[type="submit"].checkout-back:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
form[id^="commerce-checkout-form-review"] input[type="submit"].checkout-back:disabled:hover,
form[id^="commerce-checkout"] input[type="submit"].checkout-back:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
form[id^="commerce-checkout-form-review"] input[type="submit"].checkout-back.admin,
form[id^="commerce-checkout"] input[type="submit"].checkout-back.admin,
form[id^="commerce-checkout-form-review"] input[type="submit"].checkout-back.admin-extra,
form[id^="commerce-checkout"] input[type="submit"].checkout-back.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
form[id^="commerce-checkout-form-review"] input[type="submit"].checkout-back.admin-extra,
form[id^="commerce-checkout"] input[type="submit"].checkout-back.admin-extra {
  color: #1890fb;
}
form[id^="commerce-checkout-form-review"] .button-operator,
form[id^="commerce-checkout"] .button-operator {
  color: #4d5a6b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
  display: inline-block;
  margin: 1rem;
  text-align: center;
}
@media only screen and (max-width: 960px) {
  form[id^="commerce-checkout-form-review"] .button-operator,
  form[id^="commerce-checkout"] .button-operator {
    display: block;
  }
}
form[id^="commerce-checkout-form-review"] #edit-customer-profile-billing-field-behalf-of-company-und,
form[id^="commerce-checkout"] #edit-customer-profile-billing-field-behalf-of-company-und {
  padding-top: 1rem;
}
form[id^="commerce-checkout-form-review"] .field-name-field-first-name,
form[id^="commerce-checkout"] .field-name-field-first-name,
form[id^="commerce-checkout-form-review"] .field-name-field-last-name,
form[id^="commerce-checkout"] .field-name-field-last-name,
form[id^="commerce-checkout-form-review"] .field-name-field-email,
form[id^="commerce-checkout"] .field-name-field-email,
form[id^="commerce-checkout-form-review"] .field-name-field-work-phone,
form[id^="commerce-checkout"] .field-name-field-work-phone,
form[id^="commerce-checkout-form-review"] .field-name-field-cell-phone,
form[id^="commerce-checkout"] .field-name-field-cell-phone,
form[id^="commerce-checkout-form-review"] .field-name-field-current-employer,
form[id^="commerce-checkout"] .field-name-field-current-employer,
form[id^="commerce-checkout-form-review"] .field-name-field-race-ethnicity,
form[id^="commerce-checkout"] .field-name-field-race-ethnicity,
form[id^="commerce-checkout-form-review"] .field-name-field-company-name,
form[id^="commerce-checkout"] .field-name-field-company-name,
form[id^="commerce-checkout-form-review"] .field-name-field-company-phone,
form[id^="commerce-checkout"] .field-name-field-company-phone,
form[id^="commerce-checkout-form-review"] .field-name-field-gender,
form[id^="commerce-checkout"] .field-name-field-gender,
form[id^="commerce-checkout-form-review"] .field-name-field-sexual-orientation,
form[id^="commerce-checkout"] .field-name-field-sexual-orientation,
form[id^="commerce-checkout-form-review"] .field-name-field-year-of-birth,
form[id^="commerce-checkout"] .field-name-field-year-of-birth,
form[id^="commerce-checkout-form-review"] .field-name-commerce-customer-address,
form[id^="commerce-checkout"] .field-name-commerce-customer-address,
form[id^="commerce-checkout-form-review"] .field-name-field-phone,
form[id^="commerce-checkout"] .field-name-field-phone,
form[id^="commerce-checkout-form-review"] .bt-wrapper,
form[id^="commerce-checkout"] .bt-wrapper {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  float: none;
  margin-left: 0;
  margin-right: 0;
  display: block;
  margin: 0;
  margin-top: 1rem;
}
@media only screen and (max-width: 960px) {
  form[id^="commerce-checkout-form-review"] .field-name-field-first-name,
  form[id^="commerce-checkout"] .field-name-field-first-name,
  form[id^="commerce-checkout-form-review"] .field-name-field-last-name,
  form[id^="commerce-checkout"] .field-name-field-last-name,
  form[id^="commerce-checkout-form-review"] .field-name-field-email,
  form[id^="commerce-checkout"] .field-name-field-email,
  form[id^="commerce-checkout-form-review"] .field-name-field-work-phone,
  form[id^="commerce-checkout"] .field-name-field-work-phone,
  form[id^="commerce-checkout-form-review"] .field-name-field-cell-phone,
  form[id^="commerce-checkout"] .field-name-field-cell-phone,
  form[id^="commerce-checkout-form-review"] .field-name-field-current-employer,
  form[id^="commerce-checkout"] .field-name-field-current-employer,
  form[id^="commerce-checkout-form-review"] .field-name-field-race-ethnicity,
  form[id^="commerce-checkout"] .field-name-field-race-ethnicity,
  form[id^="commerce-checkout-form-review"] .field-name-field-company-name,
  form[id^="commerce-checkout"] .field-name-field-company-name,
  form[id^="commerce-checkout-form-review"] .field-name-field-company-phone,
  form[id^="commerce-checkout"] .field-name-field-company-phone,
  form[id^="commerce-checkout-form-review"] .field-name-field-gender,
  form[id^="commerce-checkout"] .field-name-field-gender,
  form[id^="commerce-checkout-form-review"] .field-name-field-sexual-orientation,
  form[id^="commerce-checkout"] .field-name-field-sexual-orientation,
  form[id^="commerce-checkout-form-review"] .field-name-field-year-of-birth,
  form[id^="commerce-checkout"] .field-name-field-year-of-birth,
  form[id^="commerce-checkout-form-review"] .field-name-commerce-customer-address,
  form[id^="commerce-checkout"] .field-name-commerce-customer-address,
  form[id^="commerce-checkout-form-review"] .field-name-field-phone,
  form[id^="commerce-checkout"] .field-name-field-phone,
  form[id^="commerce-checkout-form-review"] .bt-wrapper,
  form[id^="commerce-checkout"] .bt-wrapper {
    width: 100%;
  }
}
form[id^="commerce-checkout-form-review"] .bt-wrapper,
form[id^="commerce-checkout"] .bt-wrapper {
  margin-top: 0;
  margin-bottom: 1rem;
}
form[id^="commerce-checkout-form-review"] .form-item[class*="field-registration-name"],
form[id^="commerce-checkout"] .form-item[class*="field-registration-name"],
form[id^="commerce-checkout-form-review"] .form-item[class*="field-registration-email"],
form[id^="commerce-checkout"] .form-item[class*="field-registration-email"] {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  float: none;
  margin-left: 0;
  margin-right: 0;
  width: 48%;
}
@media only screen and (max-width: 960px) {
  form[id^="commerce-checkout-form-review"] .form-item[class*="field-registration-name"],
  form[id^="commerce-checkout"] .form-item[class*="field-registration-name"],
  form[id^="commerce-checkout-form-review"] .form-item[class*="field-registration-email"],
  form[id^="commerce-checkout"] .form-item[class*="field-registration-email"] {
    width: 100%;
  }
}
form[id^="commerce-checkout-form-review"] #edit-commerce-payment-payment-details-cvv,
form[id^="commerce-checkout"] #edit-commerce-payment-payment-details-cvv {
  padding-left: 1rem;
}
form[id^="commerce-checkout-form-review"] #edit-commerce-payment-payment-details-expiration,
form[id^="commerce-checkout"] #edit-commerce-payment-payment-details-expiration,
form[id^="commerce-checkout-form-review"] #edit-commerce-payment-payment-details-cvv,
form[id^="commerce-checkout"] #edit-commerce-payment-payment-details-cvv {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  float: none;
  margin-left: 0;
  margin-right: 0;
  width: 24.7%;
}
@media only screen and (max-width: 960px) {
  form[id^="commerce-checkout-form-review"] #edit-commerce-payment-payment-details-expiration,
  form[id^="commerce-checkout"] #edit-commerce-payment-payment-details-expiration,
  form[id^="commerce-checkout-form-review"] #edit-commerce-payment-payment-details-cvv,
  form[id^="commerce-checkout"] #edit-commerce-payment-payment-details-cvv {
    width: 100%;
    padding-left: 0;
  }
}
form[id^="commerce-checkout-form-review"] .form-item[class*="field-registration-email"],
form[id^="commerce-checkout"] .form-item[class*="field-registration-email"] {
  float: right;
}
form[id^="commerce-checkout-form-review"] .form-item[class*="postal-code"],
form[id^="commerce-checkout"] .form-item[class*="postal-code"] {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  position: relative;
  margin-bottom: 0;
  margin-top: .9em;
}
@media only screen and (max-width: 960px) {
  form[id^="commerce-checkout-form-review"] .form-item[class*="postal-code"],
  form[id^="commerce-checkout"] .form-item[class*="postal-code"] {
    width: 100%;
  }
}
form[id^="commerce-checkout-form-review"] .field-name-field-first-name:not([id*="edit-registration-information"]),
form[id^="commerce-checkout"] .field-name-field-first-name:not([id*="edit-registration-information"]),
form[id^="commerce-checkout-form-review"] .field-name-field-last-name:not([id*="edit-registration-information"]),
form[id^="commerce-checkout"] .field-name-field-last-name:not([id*="edit-registration-information"]) {
  margin-top: 0;
}
form[id^="commerce-checkout-form-review"] .name-block > .form-item:first-child,
form[id^="commerce-checkout"] .name-block > .form-item:first-child {
  margin-top: 0;
}
form[id^="commerce-checkout-form-review"] #edit-customer-profile-billing .field-group-fieldset,
form[id^="commerce-checkout"] #edit-customer-profile-billing .field-group-fieldset {
  padding-top: 1.25rem;
  margin-top: 1.25rem;
  border-top: 2px solid #E6E6E6;
}
form[id^="commerce-checkout-form-review"] #edit-customer-profile-billing .field-group-fieldset .fieldset-legend,
form[id^="commerce-checkout"] #edit-customer-profile-billing .field-group-fieldset .fieldset-legend {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  color: #1890fb;
  padding-bottom: 1.25rem;
}
form[id^="commerce-checkout-form-review"] #payment-details > .form-item,
form[id^="commerce-checkout"] #payment-details > .form-item,
form[id^="commerce-checkout-form-review"] #payment-details > .commerce-credit-card-expiration,
form[id^="commerce-checkout"] #payment-details > .commerce-credit-card-expiration {
  margin: 0;
  margin-top: 1rem;
}
form[id^="commerce-checkout-form-review"] #payment-details .form-type-select,
form[id^="commerce-checkout"] #payment-details .form-type-select,
form[id^="commerce-checkout-form-review"] #payment-details .form-type-textfield,
form[id^="commerce-checkout"] #payment-details .form-type-textfield {
  display: inline-block;
  vertical-align: top;
  width: auto;
  vertical-align: bottom;
}
form[id^="commerce-checkout-form-review"] #payment-details .commerce-month-year-divider,
form[id^="commerce-checkout"] #payment-details .commerce-month-year-divider {
  color: transparent;
  text-indent: -9999px;
  height: 100%;
  display: inline-block;
  padding: 9px 4px 8px;
  position: relative;
  margin: 0 8px 0 6px;
}
form[id^="commerce-checkout-form-review"] #payment-details .commerce-month-year-divider:after,
form[id^="commerce-checkout"] #payment-details .commerce-month-year-divider:after {
  content: ' ';
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%) rotate(15deg);
  width: 2px;
  background-color: #FFFFFF;
}
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-type"],
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-type"] {
  padding-right: 0.75rem;
}
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-number"],
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-number"] {
  padding-left: 0.75rem;
}
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-exp-month"],
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-month"],
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-code"],
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] {
  white-space: nowrap;
  margin-bottom: 0;
}
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-exp-month"] label,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-month"] label,
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-code"] label,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] label {
  margin-right: 10px;
}
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-exp-month"] label,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-month"] label,
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-code"] label,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] label,
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-exp-month"] select,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-month"] select,
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-code"] select,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] select,
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-exp-month"] .form-text,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-month"] .form-text,
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-code"] .form-text,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] .form-text {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-exp-year"],
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-year"] {
  margin-bottom: 0;
}
form[id^="commerce-checkout-form-review"] #payment-details > .commerce-credit-card-expiration,
form[id^="commerce-checkout"] #payment-details > .commerce-credit-card-expiration {
  display: inline-block;
  margin-top: 24px;
  padding-right: 0.75rem;
}
form[id^="commerce-checkout-form-review"] #payment-details .form-item[class*="credit-card-code"],
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] {
  padding-left: 0.75rem;
}
form[id^="commerce-checkout-form-review"] .commerce_payment,
form[id^="commerce-checkout"] .commerce_payment {
  margin-top: 0;
}
form[id^="commerce-checkout-form-review"] .commerce_payment .fieldset-description,
form[id^="commerce-checkout"] .commerce_payment .fieldset-description {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
  margin: 11px 0;
  font-weight: bold;
  color: #4d5a6b;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  border: none;
  background: inherit;
}
form[id^="ma-ux-user-password"] .form-item-pass label:first-child {
  display: none;
}
form[id^="ma-ux-user-password"] .form-item-pass .form-type-password {
  display: inline-flex;
  flex-direction: column;
}
form[id^="ma-ux-user-password"] .form-item-pass .form-type-password div.password-strength,
form[id^="ma-ux-user-password"] .form-item-pass .form-type-password div.password-confirm {
  order: 2;
  margin-top: .5em;
}
form[id^="ma-ux-user-password"] div.confirm-parent,
form[id^="ma-ux-user-password"] div.password-parent,
form[id^="ma-ux-user-password"] div.password-confirm,
form[id^="ma-ux-user-password"] div.password-strength,
form[id^="ma-ux-user-password"] div.password-suggestions {
  width: 100%;
}
form[id^="ma-ux-user-password"] div.password-strength,
form[id^="ma-ux-user-password"] div.password-confirm {
  font-size: 0.875rem;
  letter-spacing: normal;
  color: #666666;
}
form[id^="ma-ux-user-password"] input[type="submit"] {
  background-color: #d9a70f;
  padding: 0.6rem 2em 0.7rem;
}
form[id^="ma-ux-user-password"] input[type="submit"]:hover {
  background-color: #DC8802;
}
form[id^="ma-ux-user-password"] input[type="submit"]:active {
  background-color: #DC5502;
}
.region[class*="sidebar"] .form-item label {
  display: inline-block;
  margin-top: 6px;
  width: 100%;
  color: #898989;
}
.region[class*="sidebar"] .form-item p {
  margin-top: 0;
}
.region[class*="sidebar"] .form-item.form-type-checkbox label,
.region[class*="sidebar"] .form-item.form-type-radio label {
  margin-top: 0;
}
.region[class*="sidebar"] .form-submit {
  display: block;
  margin-top: 1.875rem;
  width: 100%;
  background-color: #d9a70f;
}
.region[class*="sidebar"] .form-submit:hover {
  background-color: #DC8802;
}
.region[class*="sidebar"] .form-submit:active {
  background-color: #DC5502;
}
.region[class*="sidebar"] .form-submit[id*="reset"] {
  color: #4d5a6b;
  padding: 0;
  background-color: transparent;
}
.region[class*="sidebar"] .form-submit[id*="reset"]:hover {
  color: #1890fb;
}
.front .region[class*="sidebar"] [id^="search-block-form"] label {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
.region[class*="sidebar"] .field-registration-product {
  margin-bottom: 20px;
}
.region[class*="sidebar"] .field-registration-product:last-child {
  margin-bottom: 0;
}
.region[class*="sidebar"] .commerce-add-to-cart > div > * ~ * {
  margin-top: 20px;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-wrapper {
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #E6E6E6;
  min-width: 100%;
  width: calc(100% + 60px);
  margin: 20px -30px;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-wrapper > * {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-wrapper > *:nth-child(odd) {
  padding-right: 10px;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-wrapper > *:nth-child(even) {
  padding-left: 10px;
}
.region[class*="sidebar"] .commerce-add-to-cart .price {
  font-size: 24px;
  color: #4d5a6b;
  width: auto;
}
.region[class*="sidebar"] .commerce-add-to-cart .form-item-quantity {
  position: relative;
  width: 500px;
}
.region[class*="sidebar"] .commerce-add-to-cart .form-item-quantity label {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  border: 1px solid transparent;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons:after {
  content: ' ';
  height: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: auto;
  left: 0;
  background-color: #cacaca;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link {
  display: block;
  float: none;
  height: 50%;
  width: 30px;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a {
  margin: 0;
  display: block;
  height: 100%;
  border: none;
  border-radius: 0;
  padding: 0;
  line-height: 1;
  text-indent: -9999px;
  position: relative;
  background-color: #E6E6E6;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a:before {
  content: ' ';
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 10px 10px;
  background-position: center;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a:focus {
  transform: none;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a:active {
  background-color: #D7D7D7;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link[class*="increase"] a:before {
  background-image: url(../images/arrowup--gray.svg);
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link[class*="decrease"] a:before {
  background-image: url(../images/arrowdown--gray.svg);
}
.region[class*="sidebar"] .commerce-add-to-cart label[for="edit-quantity"] {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
.region[class*="sidebar"] .block-search-catalog form > div {
  position: relative;
}
.region[class*="sidebar"] .block-search-catalog form > div .form-type-textfield {
  margin: 0;
}
.region[class*="sidebar"] .block-search-catalog form > div .form-type-textfield input {
  padding-right: 40px;
}
.region[class*="sidebar"] .block-search-catalog form > div .form-submit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  margin: 0;
  background-color: transparent;
  background-image: url("../images/icons/search-slate.svg");
  background-repeat: no-repeat;
  background-position: 50% 46%;
  background-size: 20px 20px;
  color: transparent;
  text-shadow: none;
  box-shadow: none;
  width: 50px;
  height: 40px;
  border: none;
}
.region[class*="sidebar"] .block-search-catalog form > div .form-submit:hover {
  transform: none;
  background-image: url("../images/icons/search--teal.svg");
}
.views-exposed-form .clearfix {
  line-height: 0;
}
.views-exposed-form .views-exposed-widget {
  float: none;
  padding: 0;
  line-height: 1.4;
}
.views-exposed-form > .views-exposed-widgets {
  letter-spacing: -1em;
  margin: -0.75rem;
}
.region[class*="sidebar"] .views-exposed-form > .views-exposed-widgets {
  letter-spacing: normal;
  margin: auto;
}
.views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
  letter-spacing: normal;
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding: .75rem;
}
.region[class*="sidebar"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
  width: 100%;
  display: block;
  padding: 0;
}
@media only screen and (max-width: 480px) {
  .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
    width: 100%;
    padding: .6em .75em;
  }
  .region[class*="sidebar"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
    padding: 0;
  }
}
.views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button {
  padding: 0;
}
.views-exposed-form .form-item {
  margin-bottom: 0;
}
.views-exposed-form .form-type-select .description {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100% !important;
}
.chosen-container .chosen-drop {
  position: absolute;
  top: 100%;
  left: -9999px;
  z-index: 1010;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid;
  border-top: 0;
  box-shadow: 0px 0px 2px #D7D7D7;
}
.chosen-container .chosen-drop:before {
  content: ' ';
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  height: 2px;
}
.chosen-container.chosen-with-drop .chosen-drop {
  left: 0;
}
.chosen-container a {
  cursor: pointer;
}
.chosen-container-single .chosen-single {
  position: relative;
  display: table;
  width: 100%;
  overflow: hidden;
  padding: 10px;
  height: 3rem;
  border: 1px solid;
  border-radius: 0;
  background-clip: padding-box;
  box-shadow: none;
  text-decoration: none;
  white-space: nowrap;
}
.chosen-container-single .chosen-single span {
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
  margin-right: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chosen-container-single .chosen-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  font-size: 1px;
}
.chosen-container-single .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single .chosen-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 18px;
  height: 100%;
}
.chosen-container-single .chosen-single div b {
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .chosen-container-single .chosen-single div b {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.chosen-container-single .chosen-single div b:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.activeSelection .chosen-container-single .chosen-single {
  letter-spacing: 1px;
}
.chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px;
}
.chosen-container-single .chosen-default {
  color: #999;
}
.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single .chosen-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.chosen-container-single .chosen-search input[type="text"] {
  box-sizing: border-box;
  margin: 1px 0;
  padding: 10px;
  width: 100%;
  height: auto;
  outline: 0;
  border: none;
  border-radius: 0;
}
.chosen-container-single .chosen-drop {
  margin-top: -1px;
  border-radius: 0;
  background-clip: padding-box;
}
.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  left: -9999px;
}
.chosen-container-multi .chosen-choices {
  border: none;
  display: none;
}
.chosen-container-multi .chosen-drop {
  border: none;
  color: #898989;
}
.chosen-container-multi .chosen-drop .chosen-results > li {
  position: relative;
  padding-left: 30px;
  padding-top: 12px;
}
.chosen-container-multi .chosen-drop .chosen-results > li:before {
  content: ' ';
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid #D7D7D7;
  border-radius: 1px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  margin-top: -2px;
}
.chosen-container-multi .chosen-drop .chosen-results > li.highlighted:before {
  border-color: #FFFFFF;
}
.chosen-container-multi .chosen-drop .result-selected {
  color: #666666;
  opacity: .25;
}
.chosen-container-multi .chosen-drop .result-selected:before {
  background-image: url("../images/icons/small-x.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px;
}
/* @group Results */
.chosen-container .chosen-results {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;
}
.chosen-container .chosen-results li {
  display: none;
  margin: 0;
  padding: 10px;
  -webkit-touch-callout: none;
}
.chosen-container .chosen-results li.highlighted {
  color: #666666;
  background-color: #1890fb;
}
.chosen-container .chosen-results li.active-result {
  display: list-item;
  cursor: pointer;
}
.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  cursor: default;
}
.chosen-container .chosen-results li.highlighted {
  background-image: none;
}
.chosen-container .chosen-results li.no-results {
  display: list-item;
}
.chosen-container .chosen-results li.group-result {
  display: list-item;
  font-weight: bold;
  cursor: default;
}
.chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}
.chosen-container .chosen-results li em {
  font-style: normal;
  text-decoration: underline;
}
.chosen-container-active .chosen-single {
  border: 1px solid;
  box-shadow: 0px 0px 2px #D7D7D7;
}
.chosen-container-active.chosen-with-drop .chosen-single {
  border: 1px solid;
  border-radius: 0;
  background-image: none;
  box-shadow: 0 2px 2px #D7D7D7;
  overflow: visible;
}
.chosen-container-active.chosen-with-drop .chosen-single:after {
  content: ' ';
  position: absolute;
  bottom: -3px;
  left: -2px;
  right: -2px;
  height: 3px;
}
.chosen-container-active.chosen-with-drop .chosen-single div {
  border-left: none;
  background: transparent;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  position: relative;
  padding-left: 2.4rem;
}
@media only screen and (max-width: 960px) {
  .chosen-container-active.chosen-with-drop .chosen-single div b {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.chosen-container-active.chosen-with-drop .chosen-single div b:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/arrow-up.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.4rem 1.4rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: .05rem;
}
.chosen-container-active .chosen-choices {
  border: 1px solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.chosen-container-active .chosen-choices li.search-field input[type="text"] {
  color: #111 !important;
}
/* @group Disabled Support */
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
}
.chosen-disabled .chosen-single {
  cursor: default;
}
.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}
/* @end */
/* --- [ Mixin ] --------------- */
.ui-datepicker {
  padding: 1.5rem;
  width: 100%;
  max-width: 22rem;
  background: #FFFFFF;
  box-shadow: 0 1rem 3.4rem -1rem rgba(0, 0, 0, 0.1), 0 1px 5px 1px rgba(0, 0, 0, 0.05);
}
.ui-datepicker table {
  font-size: 100%;
}
.ui-datepicker table td > a:hover {
  background-color: rgba(230, 230, 230, 0.075);
}
.ui-datepicker .ui-datepicker-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: pointer;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev .ui-icon,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next .ui-icon {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  background-image: url("../images/icons/arrow-left.svg");
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  order: 100;
  background-image: url("../images/icons/arrow-right.svg");
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  margin: 0;
  width: 100%;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title .ui-datepicker-month,
.ui-datepicker .ui-datepicker-header .ui-datepicker-title .ui-datepicker-year {
  width: 44%;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title .ui-datepicker-month {
  margin: 0;
  margin-right: .25rem;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title .ui-datepicker-year {
  margin: 0;
  margin-left: .25rem;
}
.page-donate .section-header .zone-branding-wrapper .zone-branding {
  background: #1890fb;
}
.page-donate .section-header .zone-branding-wrapper .zone-branding .region-branding-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  padding: 0 2.063rem;
  min-height: 246px;
}
@media only screen and (max-width: 480px) {
  .page-donate .section-header .zone-branding-wrapper .zone-branding .region-branding-inner {
    padding: 0 1rem;
  }
}
.page-donate .section-header .zone-branding-wrapper .zone-branding .region-branding-inner > * {
  display: block;
}
@media only screen and (min-width: 961px) {
  .page-donate .section-header .zone-branding-wrapper .zone-branding .region-branding-inner #logo {
    padding: 2.1rem 0 3.25rem;
  }
}
.page-donate .section-header .zone-branding-wrapper .zone-branding .region-branding-inner #logo a {
  margin-left: 0;
}
.page-donate .section-header .zone-branding-wrapper .zone-branding .region-branding-inner #logo a img {
  max-width: 100%;
  max-height: 77px;
  width: inherit;
}
@media only screen and (max-width: 480px) {
  .page-donate .section-header .zone-branding-wrapper .zone-branding .region-branding-inner #logo a img {
    max-height: 50px;
  }
}
.page-donate .section-header .donate-header {
  margin-bottom: 8.2rem;
}
.page-donate .section-header .donate-header .title {
  color: #FFFFFF;
  font-size: 40px;
}
@media only screen and (max-width: 960px) {
  .page-donate .section-header .donate-header .title {
    font-size: 27px;
  }
}
.page-donate .section-header .donate-header .blurb {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  color: #FFFFFF;
  margin-bottom: 1rem;
}
.page-donate .section-header .donate-header .blurb a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  text-decoration: underline;
}
.page-donate .section-header .donate-header .small-print {
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  color: #FFFFFF;
}
.page-donate .zone-preface {
  display: none;
}
.page-donate .zone-content {
  margin-top: -83px;
}
.page-donate .zone-content #messages {
  margin-top: 83px;
}
.crm-core-profile-entry-form > div {
  letter-spacing: -1em;
}
.crm-core-profile-entry-form > div > * {
  letter-spacing: normal;
}
.crm-core-profile-entry-form h2 {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #D7D7D7;
  display: block;
}
.crm-core-profile-entry-form h2:first-child {
  margin-top: 0;
}
.crm-core-profile-entry-form [id*="customer-address"] > label:first-child {
  display: block;
  font-style: italic;
  font-weight: normal;
}
.crm-core-profile-entry-form .amounts {
  letter-spacing: -1em;
  margin: 0 -4px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.crm-core-profile-entry-form .amounts > * {
  display: inline-block;
  vertical-align: middle;
  width: 16.666%;
  min-width: 100px;
  flex: 1;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .amounts > * {
    flex-basis: 50%;
  }
}
.crm-core-profile-entry-form .amounts > select {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
.crm-core-profile-entry-form .amounts input[type="button"] {
  padding: 10px;
  display: inline-block;
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent !important;
  border-width: 4px;
  background-clip: padding-box;
  color: #FFFFFF !important;
  background-color: #1890fb;
  box-shadow: none !important;
}
.crm-core-profile-entry-form .amounts input[type="button"]:focus {
  transform: none;
  outline: none;
}
.crm-core-profile-entry-form .amounts input[type="button"].selected {
  background-color: #1890fb;
  color: #FFFFFF !important;
}
.crm-core-profile-entry-form .amounts #donation-other-amount {
  display: inline-block;
  box-sizing: border-box;
  box-shadow: none;
  border: 4px solid transparent;
  background-clip: padding-box;
  background-repeat: no-repeat !important;
  background-position: 12px center !important;
  background-image: url("../images/icons/dollar-sign--brightblue.svg") !important;
  background-size: 1em 1em;
  padding-left: 30px;
  outline: 1px solid #E6E6E6;
  outline-offset: -5px;
}
.crm-core-profile-entry-form .field-name-field-cmcd-designation-honor-mem[style*="block"],
.crm-core-profile-entry-form .field-name-field-cmcd-designation-honoree[style*="block"],
.crm-core-profile-entry-form .field-name-field-cmcd-designation-notify[style*="block"],
.crm-core-profile-entry-form .field-name-field-cmcd-designation-email[style*="block"] {
  display: inline-block !important;
  vertical-align: top !important;
  width: 50% !important;
  float: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .field-name-field-cmcd-designation-honor-mem[style*="block"],
  .crm-core-profile-entry-form .field-name-field-cmcd-designation-honoree[style*="block"],
  .crm-core-profile-entry-form .field-name-field-cmcd-designation-notify[style*="block"],
  .crm-core-profile-entry-form .field-name-field-cmcd-designation-email[style*="block"] {
    width: 100% !important;
  }
}
.crm-core-profile-entry-form .field-name-field-cmcd-designation-honor-mem,
.crm-core-profile-entry-form .field-name-field-cmcd-designation-notify {
  padding-right: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .field-name-field-cmcd-designation-honor-mem,
  .crm-core-profile-entry-form .field-name-field-cmcd-designation-notify {
    padding-right: 0;
  }
}
.crm-core-profile-entry-form .field-name-field-cmcd-designation-honoree,
.crm-core-profile-entry-form .field-name-field-cmcd-designation-email {
  padding-left: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .field-name-field-cmcd-designation-honoree,
  .crm-core-profile-entry-form .field-name-field-cmcd-designation-email {
    padding-left: 0;
  }
}
.crm-core-profile-entry-form .form-item-crm-core-contact-individual-contact-name-given,
.crm-core-profile-entry-form .form-item-crm-core-contact-individual-contact-name-family {
  display: inline-block !important;
  vertical-align: top !important;
  width: 50% !important;
  float: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .form-item-crm-core-contact-individual-contact-name-given,
  .crm-core-profile-entry-form .form-item-crm-core-contact-individual-contact-name-family {
    width: 100% !important;
  }
}
.crm-core-profile-entry-form .form-item-crm-core-contact-individual-contact-name-given {
  padding-right: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .form-item-crm-core-contact-individual-contact-name-given {
    padding-right: 0;
  }
}
.crm-core-profile-entry-form .form-item-crm-core-contact-individual-contact-name-family {
  padding-left: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .form-item-crm-core-contact-individual-contact-name-family {
    padding-left: 0;
  }
}
.crm-core-profile-entry-form .form-wrapper[class*="partner"][class*="fname"][style*="block"],
.crm-core-profile-entry-form .form-wrapper[class*="partner"][class*="lname"][style*="block"] {
  display: inline-block !important;
  vertical-align: top !important;
  width: 50% !important;
  float: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .form-wrapper[class*="partner"][class*="fname"][style*="block"],
  .crm-core-profile-entry-form .form-wrapper[class*="partner"][class*="lname"][style*="block"] {
    width: 100% !important;
  }
}
.crm-core-profile-entry-form .form-wrapper[class*="partner"][class*="fname"] {
  padding-right: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .form-wrapper[class*="partner"][class*="fname"] {
    padding-right: 0;
  }
}
.crm-core-profile-entry-form .form-wrapper[class*="partner"][class*="lname"] {
  padding-left: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .form-wrapper[class*="partner"][class*="lname"] {
    padding-left: 0;
  }
}
.crm-core-profile-entry-form .field-name-commerce-customer-address {
  margin-top: 30px;
}
.crm-core-profile-entry-form .street-block {
  letter-spacing: -1em;
}
.crm-core-profile-entry-form .street-block > * {
  letter-spacing: normal;
}
.crm-core-profile-entry-form .street-block .form-item[class*="customer-address"][class*="thoroughfare"],
.crm-core-profile-entry-form .street-block .form-item[class*="customer-address"][class*="premise"] {
  display: inline-block !important;
  vertical-align: top !important;
  width: 50% !important;
  float: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .street-block .form-item[class*="customer-address"][class*="thoroughfare"],
  .crm-core-profile-entry-form .street-block .form-item[class*="customer-address"][class*="premise"] {
    width: 100% !important;
  }
}
.crm-core-profile-entry-form .street-block .form-item[class*="customer-address"][class*="thoroughfare"] {
  padding-right: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .street-block .form-item[class*="customer-address"][class*="thoroughfare"] {
    padding-right: 0;
  }
}
.crm-core-profile-entry-form .street-block .form-item[class*="customer-address"][class*="premise"] {
  padding-left: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .street-block .form-item[class*="customer-address"][class*="premise"] {
    padding-left: 0;
  }
}
.crm-core-profile-entry-form .locality-block {
  letter-spacing: -1em;
}
.crm-core-profile-entry-form .locality-block > * {
  letter-spacing: normal;
}
.crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="locality"],
.crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="administrative-area"],
.crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="postal-code"] {
  display: inline-block !important;
  vertical-align: top !important;
  width: 50% !important;
  float: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="locality"],
  .crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="administrative-area"],
  .crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="postal-code"] {
    width: 100% !important;
  }
}
.crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="locality"],
.crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="postal-code"] {
  padding-right: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="locality"],
  .crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="postal-code"] {
    padding-right: 0;
  }
}
.crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="administrative-area"] {
  padding-left: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .locality-block .form-item[class*="customer-address"][class*="administrative-area"] {
    padding-left: 0;
  }
}
.crm-core-profile-entry-form #payment-details {
  letter-spacing: -1em;
}
.crm-core-profile-entry-form #payment-details > * {
  letter-spacing: normal;
}
.crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-type"],
.crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-number"],
.crm-core-profile-entry-form #payment-details .commerce-credit-card-expiration,
.crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-code"] {
  display: inline-block !important;
  vertical-align: top !important;
  width: 50% !important;
  float: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-type"],
  .crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-number"],
  .crm-core-profile-entry-form #payment-details .commerce-credit-card-expiration,
  .crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-code"] {
    width: 100% !important;
  }
}
.crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-type"],
.crm-core-profile-entry-form #payment-details .commerce-credit-card-expiration {
  padding-right: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-type"],
  .crm-core-profile-entry-form #payment-details .commerce-credit-card-expiration {
    padding-right: 0;
  }
}
.crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-number"],
.crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-code"] {
  padding-left: 0.75rem;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-number"],
  .crm-core-profile-entry-form #payment-details .form-item[class*="payment-details"][class*="credit-card-code"] {
    padding-left: 0;
  }
}
.crm-core-profile-entry-form .commerce-credit-card-expiration {
  padding-top: 20px;
  position: relative;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration > .form-item,
.crm-core-profile-entry-form .commerce-credit-card-expiration > .commerce-credit-card-expiration {
  margin: 0;
  margin-top: 1rem;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-type-select,
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-type-textfield {
  display: inline-block;
  vertical-align: top;
  width: auto;
  vertical-align: bottom;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration .commerce-month-year-divider {
  color: transparent;
  text-indent: -9999px;
  height: 100%;
  display: inline-block;
  padding: 9px 4px 8px;
  position: relative;
  margin: 0 8px 0 6px;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration .commerce-month-year-divider:after {
  content: ' ';
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%) rotate(15deg);
  width: 2px;
  background-color: #E6E6E6;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-type"] {
  padding-right: 0.75rem;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-number"] {
  padding-left: 0.75rem;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"],
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] {
  white-space: nowrap;
  margin-bottom: 0;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] label,
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] label {
  margin-right: 8px;
  position: absolute;
  top: 0;
  left: 0;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] label,
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] label,
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] select,
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] select,
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] .form-text,
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] .form-text {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-year"] {
  margin-bottom: 0;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration > .commerce-credit-card-expiration {
  display: inline-block;
  margin-top: 24px;
  padding-right: 0.75rem;
}
.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] {
  padding-left: 0.75rem;
}
.crm-core-profile-entry-form .form-actions input[type="submit"] {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 50px;
}
.crm-core-profile-entry-form .form-actions input[type="submit"]:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.crm-core-profile-entry-form .form-actions input[type="submit"]:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.crm-core-profile-entry-form .form-actions input[type="submit"]:focus {
  outline: none;
}
.crm-core-profile-entry-form .form-actions input[type="submit"]:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.crm-core-profile-entry-form .form-actions input[type="submit"]:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.crm-core-profile-entry-form .form-actions input[type="submit"].admin,
.crm-core-profile-entry-form .form-actions input[type="submit"].admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.crm-core-profile-entry-form .form-actions input[type="submit"].admin-extra {
  color: #1890fb;
}
@media only screen and (max-width: 480px) {
  .crm-core-profile-entry-form .form-actions input[type="submit"] {
    margin-right: 0;
    display: block;
    width: 100%;
  }
}
#ma-donate-donate-form {
  position: relative;
  background: #FFFFFF;
  z-index: 47;
  /* --- [ Donation specific ] --------------- */
}
#ma-donate-donate-form .messages {
  margin: -2.1rem -2rem;
  margin-bottom: 2rem;
}
#ma-donate-donate-form .messages.error {
  margin: 0;
  margin-bottom: 1rem;
}
#ma-donate-donate-form .messages.error #bt-message {
  font-size: initial;
}
#ma-donate-donate-form .fieldset-wrapper {
  font-size: 0;
}
#ma-donate-donate-form .fieldset-wrapper > .form-item {
  margin: 0;
  margin-top: 1rem;
}
#ma-donate-donate-form .fieldset-wrapper > .form-item[class*="conditional"] + .form-item[style*="display"],
#ma-donate-donate-form .fieldset-wrapper > .form-item[class*="conditional"] + .form-wrapper[style*="display"] {
  margin-top: -0.5rem;
  margin-bottom: 2rem;
}
#ma-donate-donate-form .fieldset-wrapper > .form-item[class*="conditional"] + .form-wrapper[style*="display"] .fieldset-wrapper > .form-item:first-child {
  margin-top: 0;
}
#ma-donate-donate-form .fieldset-wrapper .form-wrapper#edit-payment--2 #edit-number {
  width: 50%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#ma-donate-donate-form .fieldset-wrapper .form-wrapper#edit-payment--2 #edit-number #edit-cvv {
  float: right;
}
@media only screen and (max-width: 480px) {
  #ma-donate-donate-form .fieldset-wrapper .form-wrapper#edit-payment--2 #edit-number #edit-cvv {
    margin-top: -1rem;
    margin-bottom: 2rem;
  }
}
#ma-donate-donate-form .fieldset-wrapper .form-wrapper#edit-payment--2 #edit-number #edit-expiration,
#ma-donate-donate-form .fieldset-wrapper .form-wrapper#edit-payment--2 #edit-number #edit-cvv {
  width: 48%;
  display: inline-block;
}
@media only screen and (max-width: 480px) {
  #ma-donate-donate-form .fieldset-wrapper .form-wrapper#edit-payment--2 #edit-number #edit-expiration,
  #ma-donate-donate-form .fieldset-wrapper .form-wrapper#edit-payment--2 #edit-number #edit-cvv {
    display: block;
    float: left;
    width: 75%;
  }
}
@media only screen and (max-width: 960px) {
  #ma-donate-donate-form .fieldset-wrapper .form-wrapper#edit-payment--2 #edit-number {
    width: 100%;
  }
}
#ma-donate-donate-form .captcha.form-wrapper legend,
#ma-donate-donate-form .captcha.form-wrapper .fieldset-description {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
#ma-donate-donate-form .g-recaptcha {
  margin-bottom: 40px;
}
#ma-donate-donate-form .form-radios[id*="donation-amount"] {
  font-size: 0;
  margin: 0;
  margin-top: 2rem;
}
@media only screen and (max-width: 480px) {
  #ma-donate-donate-form .form-radios[id*="donation-amount"] {
    margin: 1rem -0.5rem 0;
  }
}
#ma-donate-donate-form .form-item[id*="edit-header"],
#ma-donate-donate-form .fieldset-legend {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  text-transform: none;
}
@media only screen and (max-width: 480px) {
  #ma-donate-donate-form .form-item[id*="edit-header"],
  #ma-donate-donate-form .fieldset-legend {
    font-size: 1.25rem;
  }
}
#ma-donate-donate-form .form-item[id*="edit-subheader"] {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  color: #1890fb;
}
#ma-donate-donate-form .form-item[class*="donation-type"] {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 0;
}
#ma-donate-donate-form .form-item[class*="donation-type"] label {
  display: none;
}
#ma-donate-donate-form .form-item[class*="donation-type"] > .form-radios {
  display: flex;
}
#ma-donate-donate-form .form-item[class*="donation-type"] input + label {
  text-align: center;
  background-color: #E6E6E6;
  padding: 2rem;
  height: 83px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#ma-donate-donate-form .form-item[class*="donation-type"] input + label:before {
  content: none;
}
#ma-donate-donate-form .form-item[class*="donation-type"] input + label .text {
  margin: 0 auto;
}
#ma-donate-donate-form .form-item[class*="donation-type"] input:checked + label {
  cursor: default;
  color: #d9a70f;
  border-top: 10px solid #d9a70f;
  background: #FFFFFF;
}
#ma-donate-donate-form .form-item[class*="donation-type"] input:checked + label .text {
  margin-top: -10px;
}
#ma-donate-donate-form .form-item[class*="donation-amount"] {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}
#ma-donate-donate-form .form-item[class*="donation-amount"].form-type-radio {
  padding-right: 1.4rem;
}
@media only screen and (max-width: 960px) {
  #ma-donate-donate-form .form-item[class*="donation-amount"] .form-radios {
    margin-top: 0;
  }
  #ma-donate-donate-form .form-item[class*="donation-amount"] .form-radios .form-type-radio {
    width: 48%;
    padding: .5rem;
  }
}
#ma-donate-donate-form .form-item[class*="donation-amount"] .edit-donation-amount-once,
#ma-donate-donate-form .form-item[class*="donation-amount"] .edit-donation-amount-monthy {
  padding: 0;
  margin-top: 0;
}
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other {
  flex: 1 auto;
  min-width: 200px;
  max-width: 300px;
  margin-bottom: -1.5rem;
  position: relative;
  padding-right: 1.2rem;
  padding-right: 0;
}
@media only screen and (max-width: 960px) {
  #ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other {
    width: 100%;
    max-width: inherit;
    padding-bottom: 1.5rem;
  }
}
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other:after {
  content: ' ';
  display: inline-block;
  background-image: url("../images/icons/dollar.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 0.9rem;
  height: 0.9rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other:after {
  left: 10px;
  top: 51%;
}
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other.checked-dollar:after {
  background-image: url("../images/icons/dollar--white.svg");
}
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other .form-number {
  max-height: 42px;
  display: block;
  cursor: pointer;
  padding: .5rem 1.2rem;
  color: #4d5a6b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
  color: inherit;
  background-color: #FFFFFF;
  color: #666666;
  border-color: #666666;
  border-width: 3px;
  border: solid;
  padding: .5rem;
  min-width: 87px;
  text-align: center;
  text-align: left;
  padding-left: 20px;
}
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other .form-number .text {
  color: #4d5a6b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
  color: inherit;
}
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other .form-number:hover {
  color: #666666;
  background-color: #FFFFFF;
  border-color: #d9a70f;
}
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other .form-number.checked {
  background: #d9a70f;
  color: #FFFFFF;
  border-color: #d9a70f;
  transition: all 240ms ease 0ms;
}
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other .form-number:hover {
  border-color: #d9a70f;
}
#ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other .form-number::placeholder {
  font-style: normal;
}
@media only screen and (max-width: 960px) {
  #ma-donate-donate-form .form-item[class*="donation-amount"].form-item-donation-amount-other:after {
    top: 39%;
  }
}
@media only screen and (max-width: 960px) {
  #ma-donate-donate-form .form-item[class*="donation-amount"] {
    text-align: center;
  }
  #ma-donate-donate-form .form-item[class*="donation-amount"] .form-item[class*="donation-amount"] {
    margin-right: 0;
    width: 50%;
    padding: .5rem 1rem;
  }
}
#ma-donate-donate-form .form-item[class*="donation-amount"] input + label {
  display: block;
  cursor: pointer;
  padding: .5rem 1.2rem;
  background-color: #FFFFFF;
  color: #666666;
  border-color: #666666;
  border-width: 3px;
  border: solid;
  padding: .5rem;
  min-width: 87px;
  text-align: center;
}
#ma-donate-donate-form .form-item[class*="donation-amount"] input + label:before {
  content: none;
}
#ma-donate-donate-form .form-item[class*="donation-amount"] input + label .text {
  color: #4d5a6b;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
  color: inherit;
}
#ma-donate-donate-form .form-item[class*="donation-amount"] input + label:hover {
  color: #666666;
  background-color: #FFFFFF;
  border-color: #d9a70f;
}
#ma-donate-donate-form .form-item[class*="donation-amount"] input:checked + label {
  background-color: #d9a70f;
  color: #FFFFFF;
  border-color: #d9a70f;
  padding: .5rem;
  display: block;
  cursor: default;
}
#ma-donate-donate-form .form-item[class*="donation-amount"] input[type="text"] {
  border-radius: 5rem;
  border-width: 3px;
}
#ma-donate-donate-form .form-item[class*="display-name"] {
  margin-bottom: 2.5rem;
}
#ma-donate-donate-form .form-item[class*="donor-information"][class*="first-name"],
#ma-donate-donate-form .form-item[class*="donor-information"][class*="last-name"],
#ma-donate-donate-form .form-item[class*="donor-information"][class*="email-address"],
#ma-donate-donate-form .form-item[class*="donor-information"][class*="street-address"],
#ma-donate-donate-form .form-item[class*="donor-information"][class*="city"],
#ma-donate-donate-form .form-item[class*="donor-information"][class*="state"],
#ma-donate-donate-form .form-item[class*="donor-information"][class*="phone"] {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
@media only screen and (max-width: 960px) {
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="first-name"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="last-name"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="email-address"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="street-address"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="city"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="state"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="phone"] {
    display: inline-block;
    vertical-align: top;
    width: 60%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width: 480px) {
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="first-name"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="last-name"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="email-address"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="street-address"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="city"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="state"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="phone"] {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
}
#ma-donate-donate-form .form-item[class*="donor-information"][class*="middle-initial"],
#ma-donate-donate-form .form-item[class*="donor-information"][class*="suffix"],
#ma-donate-donate-form .form-item[class*="donor-information"][class*="zip"] {
  display: inline-block;
  vertical-align: top;
  width: 20%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
@media only screen and (max-width: 700px) {
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="middle-initial"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="suffix"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="zip"] {
    display: inline-block;
    vertical-align: top;
    width: 40%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width: 480px) {
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="middle-initial"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="suffix"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="zip"] {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
}
#ma-donate-donate-form .form-item[class*="donor-information"][class*="email-address"],
#ma-donate-donate-form .form-item[class*="donor-information"][class*="city"] {
  display: block;
}
@media only screen and (min-width: 481px) {
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="first-name"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="last-name"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="email-address"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="street-address"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="city"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="state"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="phone"] {
    padding-right: 0.75rem;
  }
}
@media only screen and (min-width: 481px) {
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="middle-initial"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="suffix"],
  #ma-donate-donate-form .form-item[class*="donor-information"][class*="zip"] {
    padding-left: 0.75rem;
  }
}
#ma-donate-donate-form .form-item[class*="CurrentEmployer"] {
  margin-bottom: 1.6rem;
}
#ma-donate-donate-form > div > .form-wrapper {
  box-shadow: 0px 8px 10px 0px #d6dbe1;
  text-align: center;
  text-align: inherit;
  margin-top: 3rem;
  padding: 2.6rem 3rem;
}
#ma-donate-donate-form > div > .form-wrapper#edit-donation-amount {
  margin-top: 0;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
@media only screen and (max-width: 960px) {
  #ma-donate-donate-form > div > .form-wrapper {
    padding: 2.6rem 3rem;
  }
  #ma-donate-donate-form > div > .form-wrapper#edit-donor-billing-wrap {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  #ma-donate-donate-form > div > .form-wrapper#edit-donor-billing-wrap > .form-wrapper {
    box-shadow: 0px 8px 10px 0px #d6dbe1;
    text-align: center;
    text-align: inherit;
    margin-top: 2rem;
    padding: 2rem;
  }
}
@media only screen and (max-width: 480px) {
  #ma-donate-donate-form > div > .form-wrapper {
    padding: .7rem 1rem;
  }
  #ma-donate-donate-form > div > .form-wrapper#edit-donor-billing-wrap > .form-wrapper {
    padding: .7rem 1rem;
  }
}
@media only screen and (min-width: 961px) {
  #ma-donate-donate-form > div > .form-wrapper #edit-donor-information,
  #ma-donate-donate-form > div > .form-wrapper #edit-billing-address {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }
  #ma-donate-donate-form > div > .form-wrapper #edit-donor-information {
    padding-right: 3rem;
    border-right: 4px solid #E6E6E6;
  }
  #ma-donate-donate-form > div > .form-wrapper #edit-billing-address {
    padding-left: 3rem;
  }
}
#ma-donate-donate-form #edit-submitted[style*="display"][style*="none"] ~ .form-actions {
  margin-top: -0.5rem;
  padding-top: 0;
}
.page-donate-1478 .section-header .zone-menu-wrapper,
.page-node-380 .section-header .zone-menu-wrapper {
  background-color: #2cbfb1;
}
.page-donate-1478 .section-header .zone-branding-wrapper .zone-branding,
.page-node-380 .section-header .zone-branding-wrapper .zone-branding {
  background: #2cbfb1;
}
.page-donate-1478 .section-header .donate-header,
.page-node-380 .section-header .donate-header {
  margin-top: 4rem;
}
.page-donate-1478 #ma-donate-donate-form .form-item[class*="donation-type"] input:checked + label,
.page-node-380 #ma-donate-donate-form .form-item[class*="donation-type"] input:checked + label {
  color: #f75c03;
  border-top: 10px solid #f75c03;
}
.page-donate-1478 #ma-donate-donate-form .form-item[class*="donation-amount"] input:checked + label,
.page-node-380 #ma-donate-donate-form .form-item[class*="donation-amount"] input:checked + label {
  background-color: #f75c03;
  border-color: #f75c03;
}
.page-donate-1478 #ma-donate-donate-form input[type="submit"],
.page-node-380 #ma-donate-donate-form input[type="submit"] {
  background-color: #0a6788;
}
.page-donate-1478 #ma-donate-donate-form input[type="submit"]:hover,
.page-node-380 #ma-donate-donate-form input[type="submit"]:hover {
  background-color: #085570;
}
.page-donate-1478 .section-footer,
.page-node-380 .section-footer {
  background-color: #0a6788;
}
.page-node-380 .donate-header {
  margin-bottom: 4rem;
}
.page-node-380 .donate-header .title {
  display: none;
  visibility: none;
}
.page-node-380 .button-cta {
  background-color: #0a6788;
}
.page-node-380 .button-cta:hover {
  background-color: #085570;
}
#edit-submit-once {
  font-size: 1rem;
  color: #666666;
  letter-spacing: .02em;
  font-weight: 600;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"],
.page-membership form > div > .form-wrapper[id*="edit-terms"],
.page-membership form > div > .form-wrapper[id*="edit-info"],
.page-membership form > div > .form-wrapper[id*="edit-payment"] {
  background-color: #FFFFFF;
  padding: 2.05rem 1.625rem;
  padding: 2.1rem 2rem;
}
@media only screen and (max-width: 480px) {
  .page-membership form > div > .form-wrapper[id*="edit-summary"],
  .page-membership form > div > .form-wrapper[id*="edit-terms"],
  .page-membership form > div > .form-wrapper[id*="edit-info"],
  .page-membership form > div > .form-wrapper[id*="edit-payment"] {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .group-meta,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .group-meta,
.page-membership form > div > .form-wrapper[id*="edit-info"] .group-meta,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .group-meta {
  margin-bottom: .2rem;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field[class*="signature"] .entity > .field,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field[class*="signature"] .entity > .field,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field[class*="signature"] .entity > .field,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field[class*="signature"] .entity > .field {
  margin-top: .8rem;
  margin-bottom: 1.2rem;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field[class*="signature"] .entity > .field.field-image-placeholder,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field[class*="signature"] .entity > .field.field-image-placeholder,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field[class*="signature"] .entity > .field.field-image-placeholder,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field[class*="signature"] .entity > .field.field-image-placeholder {
  display: none;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field[class*="signature"] img,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field[class*="signature"] img,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field[class*="signature"] img,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field[class*="signature"] img {
  width: 100%;
  height: auto;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-title,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-title,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-title,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-title {
  color: #000000;
  line-height: 1.5;
  margin-bottom: 1.6rem;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-title > *,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-title > *,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-title > *,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-title > * {
  line-height: inherit;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-title a,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-title a,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-title a,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-title a {
  color: inherit;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-title a:hover,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-title a:hover,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-title a:hover,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-title a:hover {
  text-decoration: underline;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-body,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-body,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-body,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-body {
  line-height: 1.35;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-body ~ *,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-body ~ *,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-body ~ *,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-body ~ * {
  margin-top: 1.3rem;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-node-link,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-node-link,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-node-link,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-node-link {
  font-size: 16px;
  letter-spacing: .05em;
  text-transform: capitalize;
}
@media only screen and (max-width: 480px) {
  .page-membership form > div > .form-wrapper[id*="edit-summary"],
  .page-membership form > div > .form-wrapper[id*="edit-terms"],
  .page-membership form > div > .form-wrapper[id*="edit-info"],
  .page-membership form > div > .form-wrapper[id*="edit-payment"] {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-title,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-title,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-title,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-title {
  color: #000000;
  line-height: 1.65;
  margin-bottom: 1.5rem;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-title *,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-title *,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-title *,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-title * {
  line-height: inherit;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-title a,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-title a,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-title a,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-title a {
  color: inherit;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-title a:hover,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-title a:hover,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-title a:hover,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-title a:hover {
  text-decoration: underline;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-body,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-body,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-body,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-body {
  color: #666666;
  line-height: 1.35;
  font-size: 1rem;
}
.page-membership form > div > .form-wrapper[id*="edit-summary"] .field-body ~ *,
.page-membership form > div > .form-wrapper[id*="edit-terms"] .field-body ~ *,
.page-membership form > div > .form-wrapper[id*="edit-info"] .field-body ~ *,
.page-membership form > div > .form-wrapper[id*="edit-payment"] .field-body ~ * {
  margin-top: 1.3rem;
}
.page-membership .region-sidebar-second .region-inner div.block {
  background-color: #FFFFFF;
  padding: 2.05rem 1.625rem;
  margin: 0;
  padding: 2.1rem 2rem;
}
@media only screen and (max-width: 480px) {
  .page-membership .region-sidebar-second .region-inner div.block {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.page-membership .region-sidebar-second .region-inner div.block .group-meta {
  margin-bottom: .2rem;
}
.page-membership .region-sidebar-second .region-inner div.block .field[class*="signature"] .entity > .field {
  margin-top: .8rem;
  margin-bottom: 1.2rem;
}
.page-membership .region-sidebar-second .region-inner div.block .field[class*="signature"] .entity > .field.field-image-placeholder {
  display: none;
}
.page-membership .region-sidebar-second .region-inner div.block .field[class*="signature"] img {
  width: 100%;
  height: auto;
}
.page-membership .region-sidebar-second .region-inner div.block .field-title {
  color: #000000;
  line-height: 1.5;
  margin-bottom: 1.6rem;
}
.page-membership .region-sidebar-second .region-inner div.block .field-title > * {
  line-height: inherit;
}
.page-membership .region-sidebar-second .region-inner div.block .field-title a {
  color: inherit;
}
.page-membership .region-sidebar-second .region-inner div.block .field-title a:hover {
  text-decoration: underline;
}
.page-membership .region-sidebar-second .region-inner div.block .field-body {
  line-height: 1.35;
}
.page-membership .region-sidebar-second .region-inner div.block .field-body ~ * {
  margin-top: 1.3rem;
}
.page-membership .region-sidebar-second .region-inner div.block .field-node-link {
  font-size: 16px;
  letter-spacing: .05em;
  text-transform: capitalize;
}
@media only screen and (max-width: 480px) {
  .page-membership .region-sidebar-second .region-inner div.block {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.page-membership .region-sidebar-second .region-inner div.block .field-title {
  color: #000000;
  line-height: 1.65;
  margin-bottom: 1.5rem;
}
.page-membership .region-sidebar-second .region-inner div.block .field-title * {
  line-height: inherit;
}
.page-membership .region-sidebar-second .region-inner div.block .field-title a {
  color: inherit;
}
.page-membership .region-sidebar-second .region-inner div.block .field-title a:hover {
  text-decoration: underline;
}
.page-membership .region-sidebar-second .region-inner div.block .field-body {
  color: #666666;
  line-height: 1.35;
  font-size: 1rem;
}
.page-membership .region-sidebar-second .region-inner div.block .field-body ~ * {
  margin-top: 1.3rem;
}
@media only screen and (max-width: 960px) {
  .page-membership .container-12 .grid-3.region.region-sidebar-second {
    padding-top: 2.75rem;
  }
}
.page-membership-purchase .region-content.grid-12,
.page-user-membership .region-content.grid-12 {
  width: 74%;
  flex-basis: 74%;
  min-width: 74%;
  margin: 0;
}
@media only screen and (max-width: 960px) {
  .page-membership-purchase .region-content.grid-12,
  .page-user-membership .region-content.grid-12 {
    width: 100%;
    min-width: 100%;
    padding-right: 0;
    flex-basis: 100%;
  }
}
.form-item-Account {
  position: relative;
}
.form-item-Account .request-account-changes-wrap a {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
  position: absolute;
  top: 2.15rem;
  right: -230px;
}
.form-item-Account .request-account-changes-wrap a:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.form-item-Account .request-account-changes-wrap a:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.form-item-Account .request-account-changes-wrap a:focus {
  outline: none;
}
.form-item-Account .request-account-changes-wrap a:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.form-item-Account .request-account-changes-wrap a:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.form-item-Account .request-account-changes-wrap a.admin,
.form-item-Account .request-account-changes-wrap a.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.form-item-Account .request-account-changes-wrap a.admin-extra {
  color: #1890fb;
}
.form-item-Account .request-account-changes-wrap a:hover {
  color: white;
  text-decoration: none;
}
@media only screen and (max-width: 960px) {
  .form-item-Account .request-account-changes-wrap a {
    position: static;
  }
}
.page-contact form > div > .form-wrapper[id*="edit-primary"],
.page-contact form > div > .form-wrapper[id*="edit-details"],
.page-contact form > div > .form-wrapper[id*="edit-copyright"],
.page-contact form > div > .form-wrapper[id*="edit-payment"] {
  background-color: #FFFFFF;
  padding: 2.05rem 1.625rem;
  padding: 2.1rem 2rem;
}
@media only screen and (max-width: 480px) {
  .page-contact form > div > .form-wrapper[id*="edit-primary"],
  .page-contact form > div > .form-wrapper[id*="edit-details"],
  .page-contact form > div > .form-wrapper[id*="edit-copyright"],
  .page-contact form > div > .form-wrapper[id*="edit-payment"] {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .group-meta,
.page-contact form > div > .form-wrapper[id*="edit-details"] .group-meta,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .group-meta,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .group-meta {
  margin-bottom: .2rem;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field[class*="signature"] .entity > .field,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field[class*="signature"] .entity > .field,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field[class*="signature"] .entity > .field,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field[class*="signature"] .entity > .field {
  margin-top: .8rem;
  margin-bottom: 1.2rem;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field[class*="signature"] .entity > .field.field-image-placeholder,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field[class*="signature"] .entity > .field.field-image-placeholder,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field[class*="signature"] .entity > .field.field-image-placeholder,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field[class*="signature"] .entity > .field.field-image-placeholder {
  display: none;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field[class*="signature"] img,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field[class*="signature"] img,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field[class*="signature"] img,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field[class*="signature"] img {
  width: 100%;
  height: auto;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-title,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-title,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-title,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-title {
  color: #000000;
  line-height: 1.5;
  margin-bottom: 1.6rem;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-title > *,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-title > *,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-title > *,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-title > * {
  line-height: inherit;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-title a,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-title a,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-title a,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-title a {
  color: inherit;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-title a:hover,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-title a:hover,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-title a:hover,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-title a:hover {
  text-decoration: underline;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-body,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-body,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-body,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-body {
  line-height: 1.35;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-body ~ *,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-body ~ *,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-body ~ *,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-body ~ * {
  margin-top: 1.3rem;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-node-link,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-node-link,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-node-link,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-node-link {
  font-size: 16px;
  letter-spacing: .05em;
  text-transform: capitalize;
}
@media only screen and (max-width: 480px) {
  .page-contact form > div > .form-wrapper[id*="edit-primary"],
  .page-contact form > div > .form-wrapper[id*="edit-details"],
  .page-contact form > div > .form-wrapper[id*="edit-copyright"],
  .page-contact form > div > .form-wrapper[id*="edit-payment"] {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-title,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-title,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-title,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-title {
  color: #000000;
  line-height: 1.65;
  margin-bottom: 1.5rem;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-title *,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-title *,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-title *,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-title * {
  line-height: inherit;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-title a,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-title a,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-title a,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-title a {
  color: inherit;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-title a:hover,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-title a:hover,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-title a:hover,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-title a:hover {
  text-decoration: underline;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-body,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-body,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-body,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-body {
  color: #666666;
  line-height: 1.35;
  font-size: 1rem;
}
.page-contact form > div > .form-wrapper[id*="edit-primary"] .field-body ~ *,
.page-contact form > div > .form-wrapper[id*="edit-details"] .field-body ~ *,
.page-contact form > div > .form-wrapper[id*="edit-copyright"] .field-body ~ *,
.page-contact form > div > .form-wrapper[id*="edit-payment"] .field-body ~ * {
  margin-top: 1.3rem;
}
.page-contact .region-sidebar-second .region-inner > .block,
.page-contact .region-sidebar-second .region-inner > .ds-region {
  font-size: 1.375rem;
  line-height: 1.5;
  padding: 1.8rem 2.1rem 2.25rem;
  color: #666666;
  background-color: #FFFFFF;
}
.region-sidebar-second .page-contact .region-sidebar-second .region-inner > .block,
.region-sidebar-second .page-contact .region-sidebar-second .region-inner > .ds-region {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .page-contact .region-sidebar-second .region-inner > .block,
  .page-contact .region-sidebar-second .region-inner > .ds-region {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .page-contact .region-sidebar-second .region-inner > .block,
  .page-contact .region-sidebar-second .region-inner > .ds-region {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  }
}
.page-contact .region-sidebar-second .region-inner > .block .block-title,
.page-contact .region-sidebar-second .region-inner > .ds-region .block-title,
.page-contact .region-sidebar-second .region-inner > .block > .field > .field-label,
.page-contact .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
.page-contact .region-sidebar-second .region-inner > .block .wysiwyg-heading,
.page-contact .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .page-contact .region-sidebar-second .region-inner > .block .block-title,
  .page-contact .region-sidebar-second .region-inner > .ds-region .block-title,
  .page-contact .region-sidebar-second .region-inner > .block > .field > .field-label,
  .page-contact .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .page-contact .region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .page-contact .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .page-contact .region-sidebar-second .region-inner > .block .block-title,
  .page-contact .region-sidebar-second .region-inner > .ds-region .block-title,
  .page-contact .region-sidebar-second .region-inner > .block > .field > .field-label,
  .page-contact .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .page-contact .region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .page-contact .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (min-width: 481px) {
  .page-contact .region-sidebar-second .region-inner > .block .block-title,
  .page-contact .region-sidebar-second .region-inner > .ds-region .block-title,
  .page-contact .region-sidebar-second .region-inner > .block > .field > .field-label,
  .page-contact .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .page-contact .region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .page-contact .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .page-contact .region-sidebar-second .region-inner > .block .block-title,
  .page-contact .region-sidebar-second .region-inner > .ds-region .block-title,
  .page-contact .region-sidebar-second .region-inner > .block > .field > .field-label,
  .page-contact .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .page-contact .region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .page-contact .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    margin-bottom: 1.3rem;
  }
}
@media only screen and (max-width: 480px) {
  .page-contact .region-sidebar-second .region-inner > .block .block-title,
  .page-contact .region-sidebar-second .region-inner > .ds-region .block-title,
  .page-contact .region-sidebar-second .region-inner > .block > .field > .field-label,
  .page-contact .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .page-contact .region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .page-contact .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    color: #666666;
    margin-bottom: .75rem;
  }
}
.page-contact .region-sidebar-second .region-inner > .block ~ .block,
.page-contact .region-sidebar-second .region-inner > .ds-region ~ .block,
.page-contact .region-sidebar-second .region-inner > .block ~ .ds-region,
.page-contact .region-sidebar-second .region-inner > .ds-region ~ .ds-region {
  margin-top: 2rem;
}
@media only screen and (max-width: 480px) {
  .page-contact .region-sidebar-second .region-inner > .block ~ .block,
  .page-contact .region-sidebar-second .region-inner > .ds-region ~ .block,
  .page-contact .region-sidebar-second .region-inner > .block ~ .ds-region,
  .page-contact .region-sidebar-second .region-inner > .ds-region ~ .ds-region {
    border-top: none;
    padding-top: 1.8rem;
  }
}
.page-contact .region-sidebar-second .region-inner > .block > .field,
.page-contact .region-sidebar-second .region-inner > .ds-region > .field {
  padding-bottom: 2.25rem;
  padding-bottom: 3.05rem;
}
.page-contact .region-sidebar-second .region-inner > .block > .field:last-child,
.page-contact .region-sidebar-second .region-inner > .ds-region > .field:last-child {
  padding-bottom: 0;
}
@media only screen and (max-width: 960px) {
  .page-contact .region-sidebar-second .region-inner > .block > .field,
  .page-contact .region-sidebar-second .region-inner > .ds-region > .field {
    padding-bottom: 2.5rem;
  }
  .page-contact .region-sidebar-second .region-inner > .block > .field:last-child,
  .page-contact .region-sidebar-second .region-inner > .ds-region > .field:last-child {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 480px) {
  .page-contact .region-sidebar-second .region-inner > .block > .field,
  .page-contact .region-sidebar-second .region-inner > .ds-region > .field {
    padding-bottom: 1.8rem;
  }
  .page-contact .region-sidebar-second .region-inner > .block > .field:last-child,
  .page-contact .region-sidebar-second .region-inner > .ds-region > .field:last-child {
    padding-bottom: 0;
  }
}
.page-contact .region-sidebar-second .region-inner > .block > .field ~ .field,
.page-contact .region-sidebar-second .region-inner > .ds-region > .field ~ .field {
  padding-top: 1.8rem;
}
@media only screen and (max-width: 960px) {
  .page-contact .region-sidebar-second .region-inner > .block > .field ~ .field,
  .page-contact .region-sidebar-second .region-inner > .ds-region > .field ~ .field {
    padding-top: 2.15rem;
  }
}
@media only screen and (max-width: 480px) {
  .page-contact .region-sidebar-second .region-inner > .block > .field ~ .field,
  .page-contact .region-sidebar-second .region-inner > .ds-region > .field ~ .field {
    padding-top: 1.65rem;
  }
}
.page-contact .region-sidebar-second .region-inner > .block > .field .field-contents,
.page-contact .region-sidebar-second .region-inner > .ds-region > .field .field-contents {
  margin-top: 2.65rem;
}
.page-contact .region-sidebar-second .region-inner > .block > .field .field-contents:first-child,
.page-contact .region-sidebar-second .region-inner > .ds-region > .field .field-contents:first-child {
  margin-top: 0;
}
@media only screen and (max-width: 960px) {
  .page-contact .container-12 .grid-3.region.region-sidebar-second {
    padding-top: 2.75rem;
  }
}
.page-contact .region-content.grid-12 {
  width: 74%;
  flex-basis: 74%;
  min-width: 74%;
  margin: 0;
}
@media only screen and (max-width: 960px) {
  .page-contact .region-content.grid-12 {
    width: 100%;
    min-width: 100%;
    padding-right: 0;
    flex-basis: 100%;
  }
}
.context-users .db-access-wrap {
  padding: 0;
  float: right;
  background: none;
  width: 100px;
}
.context-users .db-access-wrap a {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
}
.context-users .db-access-wrap a:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.context-users .db-access-wrap a:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.context-users .db-access-wrap a:focus {
  outline: none;
}
.context-users .db-access-wrap a:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.context-users .db-access-wrap a:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.context-users .db-access-wrap a.admin,
.context-users .db-access-wrap a.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.context-users .db-access-wrap a.admin-extra {
  color: #1890fb;
}
.context-users .profile > div,
.context-users .profile > .user-profile-category {
  background-color: #FFFFFF;
  padding: 2.05rem 1.625rem;
  padding: 2.1rem 2rem;
}
@media only screen and (max-width: 480px) {
  .context-users .profile > div,
  .context-users .profile > .user-profile-category {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.context-users .profile > div .group-meta,
.context-users .profile > .user-profile-category .group-meta {
  margin-bottom: .2rem;
}
.context-users .profile > div .field[class*="signature"] .entity > .field,
.context-users .profile > .user-profile-category .field[class*="signature"] .entity > .field {
  margin-top: .8rem;
  margin-bottom: 1.2rem;
}
.context-users .profile > div .field[class*="signature"] .entity > .field.field-image-placeholder,
.context-users .profile > .user-profile-category .field[class*="signature"] .entity > .field.field-image-placeholder {
  display: none;
}
.context-users .profile > div .field[class*="signature"] img,
.context-users .profile > .user-profile-category .field[class*="signature"] img {
  width: 100%;
  height: auto;
}
.context-users .profile > div .field-title,
.context-users .profile > .user-profile-category .field-title {
  color: #000000;
  line-height: 1.5;
  margin-bottom: 1.6rem;
}
.context-users .profile > div .field-title > *,
.context-users .profile > .user-profile-category .field-title > * {
  line-height: inherit;
}
.context-users .profile > div .field-title a,
.context-users .profile > .user-profile-category .field-title a {
  color: inherit;
}
.context-users .profile > div .field-title a:hover,
.context-users .profile > .user-profile-category .field-title a:hover {
  text-decoration: underline;
}
.context-users .profile > div .field-body,
.context-users .profile > .user-profile-category .field-body {
  line-height: 1.35;
}
.context-users .profile > div .field-body ~ *,
.context-users .profile > .user-profile-category .field-body ~ * {
  margin-top: 1.3rem;
}
.context-users .profile > div .field-node-link,
.context-users .profile > .user-profile-category .field-node-link {
  font-size: 16px;
  letter-spacing: .05em;
  text-transform: capitalize;
}
@media only screen and (max-width: 480px) {
  .context-users .profile > div,
  .context-users .profile > .user-profile-category {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.context-users .profile > div .field-title,
.context-users .profile > .user-profile-category .field-title {
  color: #000000;
  line-height: 1.65;
  margin-bottom: 1.5rem;
}
.context-users .profile > div .field-title *,
.context-users .profile > .user-profile-category .field-title * {
  line-height: inherit;
}
.context-users .profile > div .field-title a,
.context-users .profile > .user-profile-category .field-title a {
  color: inherit;
}
.context-users .profile > div .field-title a:hover,
.context-users .profile > .user-profile-category .field-title a:hover {
  text-decoration: underline;
}
.context-users .profile > div .field-body,
.context-users .profile > .user-profile-category .field-body {
  color: #666666;
  line-height: 1.35;
  font-size: 1rem;
}
.context-users .profile > div .field-body ~ *,
.context-users .profile > .user-profile-category .field-body ~ * {
  margin-top: 1.3rem;
}
.context-users .profile > .block {
  margin-bottom: 2rem;
}
.context-users .profile > .block .block-title,
.context-users .profile > .block .block-title * {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  margin-bottom: .55rem;
  font-size: 1.625rem;
}
.context-users .profile > .block .block-title a,
.context-users .profile > .block .block-title * a {
  display: block;
}
.context-users .profile > .block .block-title a,
.context-users .profile > .block .block-title * a,
.context-users .profile > .block .block-title a *,
.context-users .profile > .block .block-title * a * {
  color: #1890fb;
}
.context-users .profile > .block .block-title > * {
  font: inherit;
}
.context-users .profile > .block .field[class*="signature"] img {
  width: 100%;
  height: auto;
}
.context-users .profile > .block .field[class*="signature"] ~ * {
  margin-top: .85rem;
}
.context-users .profile > .block .content {
  font-size: 1.375rem;
  line-height: 1.4;
}
.context-users .profile > .block .content ~ * {
  margin-top: .85rem;
}
.context-users .profile > .block .group-meta {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
}
.context-users .profile > .block .group-meta .field-contents {
  display: inline;
}
.context-users .profile > .block .group-meta a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.context-users .profile > .block .group-meta a:hover {
  color: #1890fb;
}
.context-users .profile > .block .group-meta.field-date [class*="date-display"] {
  display: inline;
}
.context-users .profile > .block .group-meta ~ * {
  margin-top: .85rem;
}
.context-users .profile > .block .group-meta ~ *.field[class*="signature"] {
  margin-top: 0;
}
.context-users .profile > .block .group-meta ~ *.field[class*="signature"] .entity > * {
  margin-top: .85rem;
}
.context-users .profile > .block .menu {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}
.context-users .profile > .block .menu li {
  margin: 0;
}
.context-users .profile > .block .menu li:before {
  content: none;
}
.context-users .profile > .block .menu li ~ li {
  border-top: 1px solid #D7D7D7;
  padding-top: 1.4rem;
  margin-top: 1.4rem;
}
.context-users .profile > .block .menu li a {
  position: relative;
  padding-right: 1.5em;
  display: block;
}
.context-users .profile > .block .menu li a:after {
  content: ' ';
  display: inline-block;
  background-image: url("../images/icons/arrow-right--gray.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 1.125em;
  height: 1.125em;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (max-width: 960px) {
  .context-users .profile > .block .menu li a {
    display: block;
  }
}
.context-users .profile > .block .menu li a:hover {
  position: relative;
  padding-right: 1.5em;
  display: block;
}
.context-users .profile > .block .menu li a:hover:after {
  content: ' ';
  display: inline-block;
  background-image: url("../images/icons/arrow-right--teal.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 1.125em;
  height: 1.125em;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (max-width: 960px) {
  .context-users .profile > .block .menu li a:hover {
    display: block;
  }
}
.context-users .profile > .block .item-list .menu li {
  display: block;
}
.context-users .zone-content .grid-3.region.region-sidebar-second {
  margin-top: 0;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region {
  font-size: 1.375rem;
  line-height: 1.5;
  padding: 1.8rem 2.1rem 2.25rem;
  color: #666666;
  background-color: #FFFFFF;
}
.region-sidebar-second .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block,
.region-sidebar-second .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  }
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .block-title,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .block-title,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field > .field-label,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field > .field-label,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .wysiwyg-heading,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .block-title,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .block-title,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field > .field-label,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .block-title,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .block-title,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field > .field-label,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (min-width: 481px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .block-title,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .block-title,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field > .field-label,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .block-title,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .block-title,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field > .field-label,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    margin-bottom: 1.3rem;
  }
}
@media only screen and (max-width: 480px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .block-title,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .block-title,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field > .field-label,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    color: #666666;
    margin-bottom: .75rem;
  }
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block ~ .block,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region ~ .block,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block ~ .ds-region,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region ~ .ds-region {
  margin-top: 2rem;
}
@media only screen and (max-width: 480px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block ~ .block,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region ~ .block,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block ~ .ds-region,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region ~ .ds-region {
    border-top: none;
    padding-top: 1.8rem;
  }
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field {
  padding-bottom: 2.25rem;
  padding-bottom: 3.05rem;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field:last-child,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field:last-child {
  padding-bottom: 0;
}
@media only screen and (max-width: 960px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field {
    padding-bottom: 2.5rem;
  }
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field:last-child,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field:last-child {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 480px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field {
    padding-bottom: 1.8rem;
  }
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field:last-child,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field:last-child {
    padding-bottom: 0;
  }
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field ~ .field,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field ~ .field {
  padding-top: 1.8rem;
}
@media only screen and (max-width: 960px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field ~ .field,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field ~ .field {
    padding-top: 2.15rem;
  }
}
@media only screen and (max-width: 480px) {
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field ~ .field,
  .context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field ~ .field {
    padding-top: 1.65rem;
  }
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field .field-contents,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field .field-contents {
  margin-top: 2.65rem;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .block > .field .field-contents:first-child,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner > .ds-region > .field .field-contents:first-child {
  margin-top: 0;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block {
  background: #FFFFFF;
  margin-top: 3rem;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-label,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-label * {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  margin-bottom: .55rem;
  font-size: 1.625rem;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-label a,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-label * a {
  display: block;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-label a,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-label * a,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-label a *,
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-label * a * {
  color: #1890fb;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-label > * {
  font: inherit;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field[class*="signature"] img {
  width: 100%;
  height: auto;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field[class*="signature"] ~ * {
  margin-top: .85rem;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-contents {
  font-size: 1.375rem;
  line-height: 1.4;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-contents ~ * {
  margin-top: .85rem;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .group-meta {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .group-meta .field-contents {
  display: inline;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .group-meta a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .group-meta a:hover {
  color: #1890fb;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .group-meta.field-date [class*="date-display"] {
  display: inline;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .group-meta ~ * {
  margin-top: .85rem;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .group-meta ~ *.field[class*="signature"] {
  margin-top: 0;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .group-meta ~ *.field[class*="signature"] .entity > * {
  margin-top: .85rem;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .field-contents {
  margin-top: 0;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .entity-field-collection-item {
  list-style: none;
  padding: 0;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .entity-field-collection-item li {
  margin: 0;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .entity-field-collection-item li:before {
  content: none;
}
.context-users .zone-content .grid-3.region.region-sidebar-second .region-inner .region-to-block .entity-field-collection-item ul {
  margin-top: 1rem;
  display: block;
}
.context-users .zone-content .grid-3.region.region-sidebar-second.region.grid-3:before {
  content: none;
}
.context-users .region-postscript-second {
  text-align: left;
}
.context-users .region-postscript-second .block-title {
  text-align: left;
}
.db-access-wrap {
  text-align: center;
}
.db-access-wrap > span,
.db-access-wrap > a {
  width: 6.5rem;
}
.db-access-wrap a {
  background-color: #d9a70f;
}
.db-access-wrap a:hover {
  background-color: #DC8802;
}
.db-access-wrap a:active {
  background-color: #DC5502;
}
.context-user .zone-content .region-content .fake-block,
.context-user .zone-content .region-content .block-system-main {
  background-color: #FFFFFF;
  padding: 2.05rem 1.625rem;
  padding: 2.1rem 2rem;
}
@media only screen and (max-width: 480px) {
  .context-user .zone-content .region-content .fake-block,
  .context-user .zone-content .region-content .block-system-main {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.context-user .zone-content .region-content .fake-block .group-meta,
.context-user .zone-content .region-content .block-system-main .group-meta {
  margin-bottom: .2rem;
}
.context-user .zone-content .region-content .fake-block .field[class*="signature"] .entity > .field,
.context-user .zone-content .region-content .block-system-main .field[class*="signature"] .entity > .field {
  margin-top: .8rem;
  margin-bottom: 1.2rem;
}
.context-user .zone-content .region-content .fake-block .field[class*="signature"] .entity > .field.field-image-placeholder,
.context-user .zone-content .region-content .block-system-main .field[class*="signature"] .entity > .field.field-image-placeholder {
  display: none;
}
.context-user .zone-content .region-content .fake-block .field[class*="signature"] img,
.context-user .zone-content .region-content .block-system-main .field[class*="signature"] img {
  width: 100%;
  height: auto;
}
.context-user .zone-content .region-content .fake-block .field-title,
.context-user .zone-content .region-content .block-system-main .field-title {
  color: #000000;
  line-height: 1.5;
  margin-bottom: 1.6rem;
}
.context-user .zone-content .region-content .fake-block .field-title > *,
.context-user .zone-content .region-content .block-system-main .field-title > * {
  line-height: inherit;
}
.context-user .zone-content .region-content .fake-block .field-title a,
.context-user .zone-content .region-content .block-system-main .field-title a {
  color: inherit;
}
.context-user .zone-content .region-content .fake-block .field-title a:hover,
.context-user .zone-content .region-content .block-system-main .field-title a:hover {
  text-decoration: underline;
}
.context-user .zone-content .region-content .fake-block .field-body,
.context-user .zone-content .region-content .block-system-main .field-body {
  line-height: 1.35;
}
.context-user .zone-content .region-content .fake-block .field-body ~ *,
.context-user .zone-content .region-content .block-system-main .field-body ~ * {
  margin-top: 1.3rem;
}
.context-user .zone-content .region-content .fake-block .field-node-link,
.context-user .zone-content .region-content .block-system-main .field-node-link {
  font-size: 16px;
  letter-spacing: .05em;
  text-transform: capitalize;
}
@media only screen and (max-width: 480px) {
  .context-user .zone-content .region-content .fake-block,
  .context-user .zone-content .region-content .block-system-main {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.context-user .zone-content .region-content .fake-block .field-title,
.context-user .zone-content .region-content .block-system-main .field-title {
  color: #000000;
  line-height: 1.65;
  margin-bottom: 1.5rem;
}
.context-user .zone-content .region-content .fake-block .field-title *,
.context-user .zone-content .region-content .block-system-main .field-title * {
  line-height: inherit;
}
.context-user .zone-content .region-content .fake-block .field-title a,
.context-user .zone-content .region-content .block-system-main .field-title a {
  color: inherit;
}
.context-user .zone-content .region-content .fake-block .field-title a:hover,
.context-user .zone-content .region-content .block-system-main .field-title a:hover {
  text-decoration: underline;
}
.context-user .zone-content .region-content .fake-block .field-body,
.context-user .zone-content .region-content .block-system-main .field-body {
  color: #666666;
  line-height: 1.35;
  font-size: 1rem;
}
.context-user .zone-content .region-content .fake-block .field-body ~ *,
.context-user .zone-content .region-content .block-system-main .field-body ~ * {
  margin-top: 1.3rem;
}
.context-user .zone-content .region-content .fake-block .fieldset-wrapper,
.context-user .zone-content .region-content .block-system-main .fieldset-wrapper {
  margin-top: 3rem;
  font-size: 0;
}
.context-user .zone-content .region-content .fake-block .form-item label,
.context-user .zone-content .region-content .block-system-main .form-item label {
  display: inline-block;
  margin-top: 6px;
  width: 100%;
  color: #4d5a6b;
}
.page-user-membership.context-user .zone-content .region-content .block-system-main,
.page-user-donations.context-user .zone-content .region-content .block-system-main {
  background-color: transparent;
  padding: 0;
}
.context-user .zone-content .region-content .fake-block.form-actions {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.context-user .zone-content .region-content .fake-block.form-actions .button {
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms, color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
}
.context-user .zone-content .region-content .fake-block.form-actions .button:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.context-user .zone-content .region-content .fake-block.form-actions .button:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.context-user .zone-content .region-content .fake-block.form-actions .button:focus {
  outline: none;
}
.context-user .zone-content .region-content .fake-block.form-actions .button:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.context-user .zone-content .region-content .fake-block.form-actions .button:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.context-user .zone-content .region-content .fake-block.form-actions .button.admin,
.context-user .zone-content .region-content .fake-block.form-actions .button.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.context-user .zone-content .region-content .fake-block.form-actions .button.admin-extra {
  color: #1890fb;
}
.context-user .zone-content .region-content .fake-block.form-actions .button:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.context-user .zone-content .region-content .fake-block.form-actions .button:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.context-user .zone-content .region-content .fake-block.form-actions .button:focus {
  outline: none;
}
.context-user .zone-content .region-content .fake-block.form-actions .button:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.context-user .zone-content .region-content .fake-block.form-actions .button:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.context-user .zone-content .region-content .fake-block.form-actions .button.admin,
.context-user .zone-content .region-content .fake-block.form-actions .button.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.context-user .zone-content .region-content .fake-block.form-actions .button.admin-extra {
  color: #1890fb;
}
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"] {
  background-color: #d9a70f;
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
}
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"]:hover {
  background-color: #DC8802;
}
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"]:active {
  background-color: #DC5502;
}
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"]:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"]:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"]:focus {
  outline: none;
}
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"]:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"]:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"].admin,
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"].admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.context-user .zone-content .region-content .fake-block.form-actions .button[href*="donate"].admin-extra {
  color: #1890fb;
}
.context-user .zone-content .region-content .block ~ .block {
  margin-top: 50px;
}
.context-user .zone-content .region-content .block ~ .block.form-actions {
  margin-top: 0;
  border-top: 3px solid transparent;
  background-color: transparent;
}
.context-user .zone-content,
.context-user .zone-preface {
  max-width: 1200px;
  min-width: 0;
  margin: 0 auto;
}
.context-user .region-sidebar-second .region-inner > .block,
.context-user .region-sidebar-second .region-inner > .ds-region {
  font-size: 1.375rem;
  line-height: 1.5;
  padding: 1.8rem 2.1rem 2.25rem;
  color: #666666;
  background-color: #FFFFFF;
}
.region-sidebar-second .context-user .region-sidebar-second .region-inner > .block,
.region-sidebar-second .context-user .region-sidebar-second .region-inner > .ds-region {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .context-user .region-sidebar-second .region-inner > .block,
  .context-user .region-sidebar-second .region-inner > .ds-region {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .context-user .region-sidebar-second .region-inner > .block,
  .context-user .region-sidebar-second .region-inner > .ds-region {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  }
}
.context-user .region-sidebar-second .region-inner > .block .block-title,
.context-user .region-sidebar-second .region-inner > .ds-region .block-title,
.context-user .region-sidebar-second .region-inner > .block > .field > .field-label,
.context-user .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
.context-user .region-sidebar-second .region-inner > .block .wysiwyg-heading,
.context-user .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .context-user .region-sidebar-second .region-inner > .block .block-title,
  .context-user .region-sidebar-second .region-inner > .ds-region .block-title,
  .context-user .region-sidebar-second .region-inner > .block > .field > .field-label,
  .context-user .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .context-user .region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .context-user .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .context-user .region-sidebar-second .region-inner > .block .block-title,
  .context-user .region-sidebar-second .region-inner > .ds-region .block-title,
  .context-user .region-sidebar-second .region-inner > .block > .field > .field-label,
  .context-user .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .context-user .region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .context-user .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (min-width: 481px) {
  .context-user .region-sidebar-second .region-inner > .block .block-title,
  .context-user .region-sidebar-second .region-inner > .ds-region .block-title,
  .context-user .region-sidebar-second .region-inner > .block > .field > .field-label,
  .context-user .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .context-user .region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .context-user .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .context-user .region-sidebar-second .region-inner > .block .block-title,
  .context-user .region-sidebar-second .region-inner > .ds-region .block-title,
  .context-user .region-sidebar-second .region-inner > .block > .field > .field-label,
  .context-user .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .context-user .region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .context-user .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    margin-bottom: 1.3rem;
  }
}
@media only screen and (max-width: 480px) {
  .context-user .region-sidebar-second .region-inner > .block .block-title,
  .context-user .region-sidebar-second .region-inner > .ds-region .block-title,
  .context-user .region-sidebar-second .region-inner > .block > .field > .field-label,
  .context-user .region-sidebar-second .region-inner > .ds-region > .field > .field-label,
  .context-user .region-sidebar-second .region-inner > .block .wysiwyg-heading,
  .context-user .region-sidebar-second .region-inner > .ds-region .wysiwyg-heading {
    color: #666666;
    margin-bottom: .75rem;
  }
}
.context-user .region-sidebar-second .region-inner > .block ~ .block,
.context-user .region-sidebar-second .region-inner > .ds-region ~ .block,
.context-user .region-sidebar-second .region-inner > .block ~ .ds-region,
.context-user .region-sidebar-second .region-inner > .ds-region ~ .ds-region {
  margin-top: 2rem;
}
@media only screen and (max-width: 480px) {
  .context-user .region-sidebar-second .region-inner > .block ~ .block,
  .context-user .region-sidebar-second .region-inner > .ds-region ~ .block,
  .context-user .region-sidebar-second .region-inner > .block ~ .ds-region,
  .context-user .region-sidebar-second .region-inner > .ds-region ~ .ds-region {
    border-top: none;
    padding-top: 1.8rem;
  }
}
.context-user .region-sidebar-second .region-inner > .block > .field,
.context-user .region-sidebar-second .region-inner > .ds-region > .field {
  padding-bottom: 2.25rem;
  padding-bottom: 3.05rem;
}
.context-user .region-sidebar-second .region-inner > .block > .field:last-child,
.context-user .region-sidebar-second .region-inner > .ds-region > .field:last-child {
  padding-bottom: 0;
}
@media only screen and (max-width: 960px) {
  .context-user .region-sidebar-second .region-inner > .block > .field,
  .context-user .region-sidebar-second .region-inner > .ds-region > .field {
    padding-bottom: 2.5rem;
  }
  .context-user .region-sidebar-second .region-inner > .block > .field:last-child,
  .context-user .region-sidebar-second .region-inner > .ds-region > .field:last-child {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 480px) {
  .context-user .region-sidebar-second .region-inner > .block > .field,
  .context-user .region-sidebar-second .region-inner > .ds-region > .field {
    padding-bottom: 1.8rem;
  }
  .context-user .region-sidebar-second .region-inner > .block > .field:last-child,
  .context-user .region-sidebar-second .region-inner > .ds-region > .field:last-child {
    padding-bottom: 0;
  }
}
.context-user .region-sidebar-second .region-inner > .block > .field ~ .field,
.context-user .region-sidebar-second .region-inner > .ds-region > .field ~ .field {
  padding-top: 1.8rem;
}
@media only screen and (max-width: 960px) {
  .context-user .region-sidebar-second .region-inner > .block > .field ~ .field,
  .context-user .region-sidebar-second .region-inner > .ds-region > .field ~ .field {
    padding-top: 2.15rem;
  }
}
@media only screen and (max-width: 480px) {
  .context-user .region-sidebar-second .region-inner > .block > .field ~ .field,
  .context-user .region-sidebar-second .region-inner > .ds-region > .field ~ .field {
    padding-top: 1.65rem;
  }
}
.context-user .region-sidebar-second .region-inner > .block > .field .field-contents,
.context-user .region-sidebar-second .region-inner > .ds-region > .field .field-contents {
  margin-top: 2.65rem;
}
.context-user .region-sidebar-second .region-inner > .block > .field .field-contents:first-child,
.context-user .region-sidebar-second .region-inner > .ds-region > .field .field-contents:first-child {
  margin-top: 0;
}
.context-user table {
  border-color: transparent;
  width: 100%;
}
.context-user table .td {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  padding: 0.8em 1.6rem;
  text-align: center;
  border: none;
}
.context-user table .td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  max-width: 36.425rem;
  text-align: left;
}
.context-user table .td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.context-user table tbody > tr:nth-child(even) > td {
  background-color: #E6E6E6;
}
.context-user table tbody tr {
  border: none;
}
.context-user table th:first-child,
.context-user table td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.context-user table th:last-child,
.context-user table td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.context-user table th {
  background-color: #4a4a4a;
  padding: 0.8rem 1.6rem;
  text-align: center;
  border: none;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  color: #FFFFFF;
}
.context-user table th .field-contents {
  display: inline;
}
.context-user table th a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.context-user table th a:hover {
  color: #1890fb;
}
.context-user table th.field-date [class*="date-display"] {
  display: inline;
}
.context-user table th:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  text-align: left;
}
.context-user table th:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.context-user table th a {
  display: block;
  position: relative;
  color: inherit;
}
.context-user table th a span {
  border: none;
}
.context-user table th a:before {
  content: ' ';
  position: absolute;
  top: -0.8rem;
  right: -1.6rem;
  bottom: -0.8rem;
  left: -1.6rem;
}
.context-user table td {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  padding: 0.8em 1.6rem;
  text-align: center;
  border: none;
}
.context-user table td:first-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  max-width: 36.425rem;
  text-align: left;
}
.context-user table td:last-child {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.context-user table th:first-child,
.context-user table td:first-child,
.context-user table th:last-child,
.context-user table td:last-child {
  width: 20%;
}
.region-sidebar-second-inner .block.block-account-info h2.block-title {
  font-size: 1rem;
  text-transform: uppercase;
  color: #898989;
}
.page-user-change-password .region-content.grid-12 {
  width: 74%;
  flex-basis: 74%;
  min-width: 74%;
  margin: 0;
}
@media only screen and (max-width: 960px) {
  .page-user-change-password .region-content.grid-12 {
    width: 100%;
    min-width: 100%;
    padding-right: 0;
    flex-basis: 100%;
  }
}
.page-user-login .region-content-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.page-user-login .region-content-inner .block.notification {
  margin-bottom: 0;
}
.page-user-login .region-content-inner #block-system-main,
.page-user-login .region-content-inner #block-block-6 {
  flex-basis: calc(50% - 1.5em);
}
@media (max-width: 880px) {
  .page-user-login .region-content-inner #block-system-main,
  .page-user-login .region-content-inner #block-block-6 {
    flex-basis: 100%;
  }
}
.cle-special .content {
  columns: 2;
}
.cle-special .content .form-item:last-of-type {
  margin-top: -1em;
  margin-bottom: 2em;
}
#commerce-checkout-form-account > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
#commerce-checkout-form-account > div #edit-help {
  flex-basis: 100%;
}
#commerce-checkout-form-account > div .form-wrapper {
  flex-basis: calc(50% - 2rem);
  margin: 0;
}
@media (max-width: 700px) {
  #commerce-checkout-form-account > div .form-wrapper {
    flex-basis: 100%;
  }
}
#commerce-checkout-form-account > div .form-wrapper legend {
  margin-bottom: 1rem;
}
#commerce-checkout-form-account > div #edit-account-form {
  padding: 2.1rem 0;
}
#commerce-checkout-form-account > div #edit-account-form #edit-account-form-select {
  background-color: white;
}
#commerce-checkout-form-account > div .checkout-buttons {
  padding: 2.1rem 2rem;
  background-color: #E6E6E6;
}
body.logged-in.page-checkout .account {
  background-color: #E6E6E6;
  padding: 2.1rem 2rem;
}
body.logged-in.page-checkout .account legend {
  margin-bottom: 1rem;
}
body.logged-in.page-checkout .account .fieldset-wrapper {
  columns: 2;
}
body.logged-in.page-checkout .account .fieldset-wrapper label {
  display: block;
  color: #666666 !important;
  text-transform: uppercase;
  font-size: 1rem;
}
body.logged-in.page-checkout .account .fieldset-wrapper #edit-account-suffix {
  color: #898989;
  font-size: 1rem;
  font-style: italic;
}
body.logged-in.page-checkout .account .fieldset-wrapper .form-item-commerce-fieldgroup-pane--group-cle-questionnaire-field-cle-question-und-select {
  margin-bottom: 5rem;
}
body.logged-in.page-checkout .account .fieldset-wrapper .form-item-commerce-fieldgroup-pane--group-cle-questionnaire-field-cle-question-und-other {
  padding-top: .1px;
}
body.not-logged-in.page-checkout .commerce-order-handler-area-order-total + a {
  bottom: 3rem;
}
body.not-logged-in.page-checkout .account {
  background-color: #E6E6E6;
  padding: 2.1rem 2rem 0;
}
body.not-logged-in.page-checkout .account legend {
  margin-bottom: 1rem;
}
.page-checkout .checkout_review table.checkout-review {
  display: block;
  width: 100%;
}
.page-checkout .checkout_review table.checkout-review tbody,
.page-checkout .checkout_review table.checkout-review tr,
.page-checkout .checkout_review table.checkout-review td {
  display: block;
  width: 100%;
}
.page-checkout .checkout_review table.checkout-review tbody:empty,
.page-checkout .checkout_review table.checkout-review tr:empty,
.page-checkout .checkout_review table.checkout-review td:empty {
  display: none;
}
.page-checkout .checkout_review table.checkout-review tbody h3,
.page-checkout .checkout_review table.checkout-review tr h3,
.page-checkout .checkout_review table.checkout-review td h3 {
  margin-top: 1.125rem;
  text-transform: none;
  letter-spacing: 0;
  font-size: 1.125rem;
  margin-bottom: .2rem;
}
.page-checkout .checkout_review table.checkout-review tbody .commerce-order-handler-area-order-total + a,
.page-checkout .checkout_review table.checkout-review tr .commerce-order-handler-area-order-total + a,
.page-checkout .checkout_review table.checkout-review td .commerce-order-handler-area-order-total + a {
  bottom: 0;
}
.page-checkout .checkout_review table.checkout-review tbody.pane-title,
.page-checkout .checkout_review table.checkout-review tr.pane-title,
.page-checkout .checkout_review table.checkout-review td.pane-title {
  font-size: 1.25rem;
}
.page-checkout .checkout_review table.checkout-review tbody.pane-data,
.page-checkout .checkout_review table.checkout-review tr.pane-data,
.page-checkout .checkout_review table.checkout-review td.pane-data {
  padding-bottom: 1rem;
}
.page-checkout .checkout_review table.checkout-review tbody:last-of-type,
.page-checkout .checkout_review table.checkout-review tr:last-of-type,
.page-checkout .checkout_review table.checkout-review td:last-of-type {
  text-align: left !important;
}
.page-checkout .checkout_review table.checkout-review tbody label,
.page-checkout .checkout_review table.checkout-review tr label,
.page-checkout .checkout_review table.checkout-review td label {
  display: block;
  font-size: 1.125rem !important;
  color: #666666 !important;
  font-weight: bold !important;
}
.page-checkout .checkout_review table.checkout-review .view-commerce-cart-summary td {
  display: table-cell;
}
.page-checkout .checkout_review table.checkout-review .view-commerce-cart-summary td .commerce-price-formatted-components {
  margin-left: 0;
}
.page-checkout .checkout_review table.checkout-review .view-commerce-cart-summary td .commerce-price-formatted-components td {
  display: block;
  padding: 0;
}
.page-checkout .checkout_review table.checkout-review .commerce-price-formatted-components .component-total,
.page-checkout .checkout_review table.checkout-review .commerce-price-formatted-components td:last-of-type {
  min-width: 100px;
  text-align: right !important;
}
.commerce-add-to-cart > div > * ~ * {
  margin-top: 20px;
}
.commerce-add-to-cart .quantity-wrapper {
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #D7D7D7;
  min-width: 100%;
  width: calc(100% + 60px);
  margin: 20px -30px;
}
.commerce-add-to-cart .quantity-wrapper > * {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}
.commerce-add-to-cart .quantity-wrapper > *:nth-child(odd) {
  padding-right: 10px;
}
.commerce-add-to-cart .quantity-wrapper > *:nth-child(even) {
  padding-left: 10px;
}
.commerce-add-to-cart .price {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 24px;
  color: #4d5a6b;
  width: auto;
}
.commerce-add-to-cart .form-item-quantity {
  position: relative;
  width: 500px;
}
.commerce-add-to-cart .form-item-quantity label {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
.commerce-add-to-cart .quantity-buttons {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  border: 1px solid transparent;
}
.commerce-add-to-cart .quantity-buttons:after {
  content: ' ';
  height: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: auto;
  left: 0;
  background-color: #cacaca;
}
.commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link {
  display: block;
  float: none;
  height: 50%;
  width: 30px;
}
.commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a {
  margin: 0;
  display: block;
  height: 100%;
  border: none;
  border-radius: 0;
  padding: 0;
  line-height: 1;
  text-indent: -9999px;
  position: relative;
  background-color: #D7D7D7;
}
.commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a:before {
  content: ' ';
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 10px 10px;
  background-position: center;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a:focus {
  transform: none;
}
.commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a:active {
  background-color: #FFFFFF;
}
.commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link[class*="increase"] a:before {
  background-image: url(../images/arrowup--gray.svg);
}
.commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link[class*="decrease"] a:before {
  background-image: url(../images/arrowdown--gray.svg);
}
.commerce-add-to-cart label[for="edit-quantity"] {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
#views-form-commerce-cart-block-default .form-submit {
  margin-left: auto;
  margin-right: 16px;
  display: block;
  width: auto;
  padding: 4px 8px 3px;
  position: relative;
  top: -20px;
  font-size: 13.5px;
}
#views-form-commerce-cart-block-default .form-submit:hover {
  color: #FFFFFF !important;
}
.cart_contents legend {
  padding: 0;
  background-color: transparent;
}
.cart_contents legend span {
  color: #4d5a6b;
  padding-bottom: 8px;
  border-bottom: 3px solid #D7D7D7;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 1.5;
}
.block-masquerade {
  padding: 2em;
  margin-top: 50px;
  background-color: #FFFFFF;
  display: inline-block;
  width: auto;
  max-width: 100%;
}
.block-masquerade #quick_switch_links {
  margin-top: 1em;
  border-top: 1px solid #D7D7D7;
  padding-top: 1em;
}
.block-masquerade #quick_switch_links .item-list ul {
  list-style: none;
  padding: 0;
  margin: -0.5em;
}
.block-masquerade #quick_switch_links .item-list ul li {
  margin: 0;
}
.block-masquerade #quick_switch_links .item-list ul li:before {
  content: none;
}
.block-masquerade #quick_switch_links .item-list ul li {
  display: inline-block;
  margin: .5em;
}
/* --- [ Owl Carousel ] --------------- */
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-item img {
  display: block;
  /*width: 100%;*/
  -webkit-transform-style: preserve-3d;
}
.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: #869791;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #869791; }

.region-inner,
.zone-preface,
.zone-content {
  margin: 0 auto;
  max-width: 100%;
  max-width: 1266px;
}
.region-content-inner {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 100%;
  flex-direction: row;
}
.region-content-inner > * {
  width: 100%;
  max-width: 100%;
}
.region-content-inner .block-guide-pager,
.region-content-inner .block[id*="guide-pager"] {
  align-self: flex-end;
  width: 100%;
}
.region-postscript-second-inner {
  padding-left: 2.063rem;
  padding-right: 2.063rem;
}
@media only screen and (max-width: 960px) {
  .region-postscript-second-inner {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}
@media only screen and (max-width: 480px) {
  .region-postscript-second-inner {
    padding-left: 0.934rem;
    padding-right: 0.934rem;
  }
}
.region-preface-first,
.region-preface-second,
.zone-content,
.region-user-first-inner {
  padding-left: 2.063rem;
  padding-right: 2.063rem;
}
@media only screen and (max-width: 960px) {
  .region-preface-first,
  .region-preface-second,
  .zone-content,
  .region-user-first-inner {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}
@media only screen and (max-width: 480px) {
  .region-preface-first,
  .region-preface-second,
  .zone-content,
  .region-user-first-inner {
    padding-left: 0.934rem;
    padding-right: 0.934rem;
  }
}
.not-front.node-type-landing-page .region-preface-first-inner {
  max-width: 1200px;
  padding: 1.125rem 5rem 1.813rem;
}
@media only screen and (max-width: 960px) {
  .not-front.node-type-landing-page .region-preface-first-inner {
    padding: 1.313rem 4rem 2.188rem;
  }
}
@media only screen and (max-width: 480px) {
  .not-front.node-type-landing-page .region-preface-first-inner {
    padding: 0.875rem 0.938rem 1.5rem;
  }
}
@media only screen and (max-width: 960px) {
  .not-front.node-type-landing-page .region-branding-inner {
    padding-bottom: 4.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .not-front.node-type-landing-page .region-branding-inner {
    padding-bottom: 3.5rem;
  }
}
.not-front.node-type-listing-page .region-preface-first-inner {
  max-width: 1200px;
  padding: 2rem 5rem 1.813rem;
}
@media only screen and (max-width: 960px) {
  .not-front.node-type-listing-page .region-preface-first-inner {
    padding: 2rem 4rem 1.188rem;
  }
}
@media only screen and (max-width: 480px) {
  .not-front.node-type-listing-page .region-preface-first-inner {
    padding: 0.875rem 0.938rem 0.3rem;
  }
}
.not-front.node-type-listing-page .region-preface-first-inner .field-subheading {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  padding-top: 1rem;
  padding-bottom: 2rem;
}
@media only screen and (max-width: 480px) {
  .not-front.node-type-listing-page .region-preface-first-inner .field-subheading {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .not-front.node-type-listing-page .region-preface-first-inner .field-subheading {
    font-size: 1.25rem;
  }
}
.not-front.node-type-event .region-preface-first-inner {
  max-width: 1200px;
  padding: 2.125rem 5rem 0;
}
.not-front.node-type-event .region-preface-first-inner .block-event-tabs {
  margin: 0 -5rem;
}
@media only screen and (max-width: 960px) {
  .not-front.node-type-event .region-preface-first-inner {
    padding: 1.313rem 4rem 0;
  }
  .not-front.node-type-event .region-preface-first-inner .block-event-tabs {
    margin: 0 -4rem;
  }
}
@media only screen and (max-width: 480px) {
  .not-front.node-type-event .region-preface-first-inner {
    padding: 0.875rem 0.938rem 0;
  }
  .not-front.node-type-event .region-preface-first-inner .block-event-tabs {
    margin: 0 -0.938rem;
  }
}
@media only screen and (max-width: 960px) {
  .not-front.node-type-event .region-branding-inner {
    padding-bottom: 4.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .not-front.node-type-event .region-branding-inner {
    padding-bottom: 3.5rem;
  }
}
.not-front.node-type-event .field-event-date-display .field-contents {
  display: inline;
  position: relative;
  padding-left: 2.75rem;
}
@media only screen and (max-width: 960px) {
  .not-front.node-type-event .field-event-date-display .field-contents {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
.not-front.node-type-event .field-event-date-display .field-contents:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: auto;
  display: block;
  background-image: url("../images/icons/calendar-gold.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1.75rem 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  margin-top: .05rem;
}
.not-front.node-type-event .field-event-date-display .field-contents:before {
  top: -5px;
  left: 10px;
}
@media only screen and (max-width: 700px) {
  .not-front.node-type-event .field-event-date-display .field-contents {
    position: relative;
    padding: 0;
    padding-top: 3rem;
    display: block;
  }
  .not-front.node-type-event .field-event-date-display .field-contents:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    background-image: url("../images/icons/calendar-gold.svg");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 2rem 2rem;
    width: 2rem;
    height: 2rem;
  }
}
.not-front.page-taxonomy-term .region-preface-first-inner,
.not-front.page-user .region-preface-first-inner,
.not-front.page-search-node .region-preface-first-inner,
.not-front.node-type-webform .region-preface-first-inner,
.not-front.node-type-page .region-preface-first-inner {
  max-width: 1200px;
  padding: 2.5rem 0rem 1.813rem;
}
@media only screen and (max-width: 960px) {
  .not-front.page-taxonomy-term .region-preface-first-inner,
  .not-front.page-user .region-preface-first-inner,
  .not-front.page-search-node .region-preface-first-inner,
  .not-front.node-type-webform .region-preface-first-inner,
  .not-front.node-type-page .region-preface-first-inner {
    padding: 2rem 0;
  }
}
@media only screen and (max-width: 480px) {
  .not-front.page-taxonomy-term .region-preface-first-inner,
  .not-front.page-user .region-preface-first-inner,
  .not-front.page-search-node .region-preface-first-inner,
  .not-front.node-type-webform .region-preface-first-inner,
  .not-front.node-type-page .region-preface-first-inner {
    padding: 0.875rem 0.938rem 1.5rem;
  }
}
.not-front.node-type-grantee-profile .region-preface-first-inner,
.not-front.node-type-person-profile .region-preface-first-inner,
.not-front.node-type-news-post .region-preface-first-inner,
.not-front.page-events-register .region-preface-first-inner,
.not-front.page-checkout .region-preface-first-inner,
.not-front.page-donate .region-preface-first-inner {
  max-width: 1200px;
  padding: 4.6rem 0rem 3rem;
}
@media only screen and (max-width: 960px) {
  .not-front.node-type-grantee-profile .region-preface-first-inner,
  .not-front.node-type-person-profile .region-preface-first-inner,
  .not-front.node-type-news-post .region-preface-first-inner,
  .not-front.page-events-register .region-preface-first-inner,
  .not-front.page-checkout .region-preface-first-inner,
  .not-front.page-donate .region-preface-first-inner {
    padding: 2rem 0;
  }
}
@media only screen and (max-width: 480px) {
  .not-front.node-type-grantee-profile .region-preface-first-inner,
  .not-front.node-type-person-profile .region-preface-first-inner,
  .not-front.node-type-news-post .region-preface-first-inner,
  .not-front.page-events-register .region-preface-first-inner,
  .not-front.page-checkout .region-preface-first-inner,
  .not-front.page-donate .region-preface-first-inner {
    padding: 1.6rem 0.938rem 1.5rem;
  }
}
.region-user-first-inner {
  padding-top: 0.95rem;
  padding-bottom: 1.05rem;
}
.zone-content {
  padding-top: 0;
  padding-bottom: 4.525rem;
}
@media only screen and (max-width: 480px) {
  .zone-content {
    padding-top: 0;
    padding-bottom: 3.225rem;
  }
}
.front .zone-content {
  padding-top: 3.025rem;
  padding-bottom: 2.625rem;
}
@media only screen and (max-width: 960px) {
  .front .zone-content {
    padding-top: 2.675rem;
    padding-bottom: 3.025rem;
  }
}
@media only screen and (max-width: 480px) {
  .front .zone-content {
    padding-top: 2.25rem;
    padding-bottom: 2.625rem;
  }
}
.region-postscript-second-inner {
  padding-top: 2.5rem;
  padding-bottom: 3.65rem;
}
@media only screen and (max-width: 960px) {
  .region-postscript-second-inner {
    padding-top: 2.35rem;
    padding-bottom: 3.7rem;
    padding-left: 3.425rem;
    padding-right: 3.425rem;
  }
}
@media only screen and (max-width: 480px) {
  .region-postscript-second-inner {
    padding-top: 1.35rem;
    padding-bottom: 3.05rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}
.region-footer-first .region-footer-first-inner {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 2.75rem;
  padding-bottom: 3.75rem;
}
@media only screen and (max-width: 960px) {
  .region-footer-first .region-footer-first-inner {
    padding-top: 2.125rem;
    padding-bottom: 2.563rem;
  }
}
@media only screen and (max-width: 480px) {
  .region-footer-first .region-footer-first-inner {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 1.688rem;
    padding-bottom: 2.438rem;
  }
}
.region-footer-second-inner {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .region-footer-second-inner {
    padding-left: 4.75rem;
    padding-right: 4.75rem;
    padding-top: 1.575rem;
    padding-bottom: 2.0625rem;
    max-width: 637px;
  }
}
@media only screen and (max-width: 480px) {
  .region-footer-second-inner {
    padding-top: 1.85rem;
    padding-bottom: 1.85rem;
    padding-top: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
/* --- [ Layout things ] --------------- */
.container-12 {
  width: 100%;
  letter-spacing: -1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.container-12 > * {
  letter-spacing: normal;
}
.container-12 .grid-1,
.container-12 .grid-2,
.container-12 .grid-3,
.container-12 .grid-4,
.container-12 .grid-5,
.container-12 .grid-6,
.container-12 .grid-7,
.container-12 .grid-8,
.container-12 .grid-9,
.container-12 .grid-10,
.container-12 .grid-11,
.container-12 .grid-12 {
  float: none;
  display: inline-block;
  vertical-align: top;
  display: flex;
}
.container-12 .grid-1 > *,
.container-12 .grid-2 > *,
.container-12 .grid-3 > *,
.container-12 .grid-4 > *,
.container-12 .grid-5 > *,
.container-12 .grid-6 > *,
.container-12 .grid-7 > *,
.container-12 .grid-8 > *,
.container-12 .grid-9 > *,
.container-12 .grid-10 > *,
.container-12 .grid-11 > *,
.container-12 .grid-12 > * {
  width: 100%;
  flex-basis: 100%;
}
.container-12 .grid-1#messages,
.container-12 .grid-2#messages,
.container-12 .grid-3#messages,
.container-12 .grid-4#messages,
.container-12 .grid-5#messages,
.container-12 .grid-6#messages,
.container-12 .grid-7#messages,
.container-12 .grid-8#messages,
.container-12 .grid-9#messages,
.container-12 .grid-10#messages,
.container-12 .grid-11#messages,
.container-12 .grid-12#messages {
  display: block;
  width: 100%;
  flex-basis: 100%;
  min-width: 100%;
}
.container-12 .grid-1#messages > *,
.container-12 .grid-2#messages > *,
.container-12 .grid-3#messages > *,
.container-12 .grid-4#messages > *,
.container-12 .grid-5#messages > *,
.container-12 .grid-6#messages > *,
.container-12 .grid-7#messages > *,
.container-12 .grid-8#messages > *,
.container-12 .grid-9#messages > *,
.container-12 .grid-10#messages > *,
.container-12 .grid-11#messages > *,
.container-12 .grid-12#messages > * {
  display: block;
}
.container-12 .grid-12,
.container-12 .grid-8 {
  width: 100%;
  flex-basis: 100%;
  min-width: 100%;
  margin: 0 auto;
}
.container-12 .grid-3 {
  width: 23.5%;
  flex-basis: 23.5%;
  margin: 0;
  box-sizing: border-box;
  min-width: 250px;
}
.container-12 .grid-3.region-sidebar-first {
  padding-right: 2.75rem;
  padding-left: 0;
}
.left-sidebar .container-12 .grid-3.region-sidebar-first {
  width: 33%;
  flex-basis: 33%;
  min-width: 33%;
}
@media only screen and (max-width: 1400px) {
  .page-blog .container-12 .grid-3.region-sidebar-first {
    padding-right: 0;
  }
}
@media only screen and (max-width: 960px) {
  .container-12 .grid-3.region-sidebar-first {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 42px;
  }
  .gjs-header-tray .container-12 .grid-3.region-sidebar-first {
    margin-bottom: 1.675rem;
  }
}
@media only screen and (max-width: 960px) {
  .container-12 .grid-3.region-sidebar-first,
  .left-sidebar .container-12 .grid-3.region-sidebar-first {
    padding: 0;
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
    position: relative;
  }
  .node-type-person-profile .container-12 .grid-3.region-sidebar-first {
    padding: initial;
    width: initial;
    flex-basis: initial;
    min-width: initial;
    padding-right: 2.75rem;
    width: 350px;
  }
}
@media only screen and (max-width: 480px) {
  .node-type-person-profile .container-12 .grid-3.region-sidebar-first,
  .left-sidebar .node-type-person-profile .container-12 .grid-3.region-sidebar-first {
    padding: 0;
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
    position: relative;
  }
}
.container-12 .grid-3.region-sidebar-second {
  padding-left: 2.75rem;
  padding-right: 0;
}
.right-sidebar .container-12 .grid-3.region-sidebar-second {
  width: 33%;
  flex-basis: 33%;
  min-width: 33%;
}
.front.right-sidebar .container-12 .grid-3.region-sidebar-second {
  width: 30.5%;
  flex-basis: 30.5%;
  min-width: 30.5%;
  padding-left: 0;
}
@media only screen and (max-width: 960px) {
  .front.right-sidebar .container-12 .grid-3.region-sidebar-second {
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
    padding-right: 0;
  }
}
@media only screen and (max-width: 960px) {
  .container-12 .grid-3.region-sidebar-second,
  .right-sidebar .container-12 .grid-3.region-sidebar-second {
    padding: 0;
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
    position: relative;
  }
}
@media only screen and (max-width: 480px) {
  .container-12 .grid-3.region-sidebar-second,
  .right-sidebar .container-12 .grid-3.region-sidebar-second {
    padding: 0;
  }
}
.two-sidebars .container-12 .grid-3.region-sidebar-second {
  width: 22.66%;
  flex-basis: 22.66%;
  min-width: 22.66%;
  padding: 0;
}
@media only screen and (max-width: 960px) {
  .two-sidebars .container-12 .grid-3.region-sidebar-second {
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
    margin-top: 42px;
    padding: 0;
  }
}
@media only screen and (max-width: 960px) {
  .container-12 .grid-3 {
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
  }
}
.container-12 .grid-6 {
  width: 76.5%;
  flex-basis: 76.5%;
  min-width: 76.5%;
  flex: 1 0 0%;
  margin: 0;
  box-sizing: border-box;
}
@media only screen and (max-width: 960px) {
  .container-12 .grid-6 {
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
  }
}
.container-12 .grid-9 {
  width: 76.5%;
  flex: 1 0 0%;
  margin: 0;
  box-sizing: border-box;
}
.left-sidebar .container-12 .grid-9,
.right-sidebar .container-12 .grid-9 {
  width: 61.9%;
  flex-basis: 61.9%;
  min-width: 61.9%;
}
.front.left-sidebar .container-12 .grid-9,
.front.right-sidebar .container-12 .grid-9 {
  width: 63.9%;
  flex-basis: 63.9%;
  min-width: 63.9%;
  padding-right: 2.75rem;
}
@media only screen and (max-width: 960px) {
  .front.left-sidebar .container-12 .grid-9,
  .front.right-sidebar .container-12 .grid-9 {
    width: 100%;
    min-width: 100%;
    padding-right: 0;
    flex-basis: 100%;
  }
}
@media only screen and (max-width: 960px) {
  .container-12 .grid-9,
  .right-sidebar .container-12 .grid-9 {
    width: 100%;
    padding: 0;
    flex-basis: 100%;
    min-width: 100%;
  }
}
.two-sidebars .container-12 .grid-9,
.two-sidebars .container-12 .grid-6 {
  width: 45.5%;
  flex-basis: 45.5%;
  min-width: 45.5%;
  padding-left: 0;
  padding-right: 2.75rem;
}
@media only screen and (max-width: 960px) {
  .two-sidebars .container-12 .grid-9,
  .two-sidebars .container-12 .grid-6 {
    width: 100%;
    min-width: 100%;
    padding-right: 0;
    flex-basis: 100%;
  }
}
.container-12 .gjs-region.gjs-region--content-right {
  display: inline-block;
  width: 76.5%;
  flex-basis: 76.5%;
  min-width: 76.5%;
}
@media only screen and (max-width: 960px) {
  .container-12 .gjs-region.gjs-region--content-right {
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
  }
}
.container-12 .gjs-region.gjs-region--content-right .gjs-region__header-tray {
  width: 100%;
  flex-basis: 100%;
  min-width: 100%;
}
.container-12 .gjs-region.gjs-region--content-right .grid-9 {
  width: 100%;
  flex-basis: 100%;
  min-width: 100%;
}
.container-12 .gjs-region.gjs-region--content-right .grid-6 {
  width: 76.5%;
  flex-basis: 76.5%;
  min-width: 76.5%;
}
@media only screen and (max-width: 580px) {
  .container-12 .gjs-region.gjs-region--content-right .grid-6 {
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
  }
}
.container-12 .gjs-region.gjs-region--content-right .grid-3 {
  width: 23.5%;
  flex-basis: 23.5%;
  min-width: 23.5%;
}
@media only screen and (max-width: 580px) {
  .container-12 .gjs-region.gjs-region--content-right .grid-3 {
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
  }
}
/* --- [ 2 Cols ] --------------- */
.grid.col-2 .grid-container {
  font-size: 0;
  margin: -20px;
}
.grid.col-2 .grid-container .views-row {
  font-size: 16px;
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding: 20px;
}
.grid.col-2 .grid-container .group-content > .views-row {
  font-size: 16px;
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding: 20px;
}
.grid.col-2 .grid-container .tile {
  font-size: 16px;
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding: 20px;
}
@media only screen and (max-width: 960px) {
  .grid.col-2 .grid-container {
    font-size: 0;
    margin: -20px;
  }
  .grid.col-2 .grid-container .views-row {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-2 .grid-container .group-content > .views-row {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-2 .grid-container .tile {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .grid.col-2 .grid-container {
    font-size: 0;
    margin: -30px -10px;
  }
  .grid.col-2 .grid-container .views-row {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 30px 10px;
  }
  .grid.col-2 .grid-container .group-content > .views-row {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 30px 10px;
  }
  .grid.col-2 .grid-container .tile {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 30px 10px;
  }
}
/* --- [ 3 Cols ] --------------- */
.grid.col-3 .grid-container {
  font-size: 0;
  margin: -12.5px;
  margin-top: -7px;
  margin-bottom: -7px;
}
.grid.col-3 .grid-container .views-row {
  font-size: 16px;
  display: inline-block;
  width: 33.33333333%;
  vertical-align: top;
  padding: 12.5px;
}
.grid.col-3 .grid-container .group-content > .views-row {
  font-size: 16px;
  display: inline-block;
  width: 33.33333333%;
  vertical-align: top;
  padding: 12.5px;
}
.grid.col-3 .grid-container .tile {
  font-size: 16px;
  display: inline-block;
  width: 33.33333333%;
  vertical-align: top;
  padding: 12.5px;
}
@media only screen and (max-width: 960px) {
  .grid.col-3 .grid-container {
    font-size: 0;
    margin: -20px;
    margin: -8px -20px -20px;
  }
  .grid.col-3 .grid-container .views-row {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-3 .grid-container .group-content > .views-row {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-3 .grid-container .tile {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .grid.col-3 .grid-container {
    font-size: 0;
    margin: -12.5px;
  }
  .grid.col-3 .grid-container .views-row {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 12.5px;
  }
  .grid.col-3 .grid-container .group-content > .views-row {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 12.5px;
  }
  .grid.col-3 .grid-container .tile {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 12.5px;
  }
}
/* --- [ 4 Cols ] --------------- */
.grid.col-4 .grid-container {
  font-size: 0;
  margin: -20px;
}
.grid.col-4 .grid-container .views-row {
  font-size: 16px;
  display: inline-block;
  width: 25%;
  vertical-align: top;
  padding: 20px;
}
.grid.col-4 .grid-container .group-content > .views-row {
  font-size: 16px;
  display: inline-block;
  width: 25%;
  vertical-align: top;
  padding: 20px;
}
.grid.col-4 .grid-container .tile {
  font-size: 16px;
  display: inline-block;
  width: 25%;
  vertical-align: top;
  padding: 20px;
}
@media only screen and (max-width: 960px) {
  .grid.col-4 .grid-container {
    font-size: 0;
    margin: -20px;
  }
  .grid.col-4 .grid-container .views-row {
    font-size: 16px;
    display: inline-block;
    width: 33.33333333%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-4 .grid-container .group-content > .views-row {
    font-size: 16px;
    display: inline-block;
    width: 33.33333333%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-4 .grid-container .tile {
    font-size: 16px;
    display: inline-block;
    width: 33.33333333%;
    vertical-align: top;
    padding: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .grid.col-4 .grid-container {
    font-size: 0;
    margin: -20px;
  }
  .grid.col-4 .grid-container .views-row {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-4 .grid-container .group-content > .views-row {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-4 .grid-container .tile {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .grid.col-4 .grid-container {
    font-size: 0;
    margin: -20px;
  }
  .grid.col-4 .grid-container .views-row {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-4 .grid-container .group-content > .views-row {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-4 .grid-container .tile {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 20px;
  }
}
/* --- [ 5 Cols ] --------------- */
.grid.col-5 .grid-container {
  font-size: 0;
  margin: -10px;
}
.grid.col-5 .grid-container .views-row {
  font-size: 16px;
  display: inline-block;
  width: 20%;
  vertical-align: top;
  padding: 10px;
}
.grid.col-5 .grid-container .group-content > .views-row {
  font-size: 16px;
  display: inline-block;
  width: 20%;
  vertical-align: top;
  padding: 10px;
}
.grid.col-5 .grid-container .tile {
  font-size: 16px;
  display: inline-block;
  width: 20%;
  vertical-align: top;
  padding: 10px;
}
@media only screen and (max-width: 960px) {
  .grid.col-5 .grid-container {
    font-size: 0;
    margin: -20px;
  }
  .grid.col-5 .grid-container .views-row {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-5 .grid-container .group-content > .views-row {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-5 .grid-container .tile {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .grid.col-5 .grid-container {
    font-size: 0;
    margin: -20px;
  }
  .grid.col-5 .grid-container .views-row {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-5 .grid-container .group-content > .views-row {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-5 .grid-container .tile {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 20px;
  }
}
/* --- [ 6 Cols ] --------------- */
.grid.col-6 .grid-container {
  font-size: 0;
  margin: -10px;
}
.grid.col-6 .grid-container .views-row {
  font-size: 16px;
  display: inline-block;
  width: 16.66666667%;
  vertical-align: top;
  padding: 10px;
}
.grid.col-6 .grid-container .group-content > .views-row {
  font-size: 16px;
  display: inline-block;
  width: 16.66666667%;
  vertical-align: top;
  padding: 10px;
}
.grid.col-6 .grid-container .tile {
  font-size: 16px;
  display: inline-block;
  width: 16.66666667%;
  vertical-align: top;
  padding: 10px;
}
@media only screen and (max-width: 960px) {
  .grid.col-6 .grid-container {
    font-size: 0;
    margin: -20px;
  }
  .grid.col-6 .grid-container .views-row {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-6 .grid-container .group-content > .views-row {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-6 .grid-container .tile {
    font-size: 16px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .grid.col-6 .grid-container {
    font-size: 0;
    margin: -20px;
  }
  .grid.col-6 .grid-container .views-row {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-6 .grid-container .group-content > .views-row {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 20px;
  }
  .grid.col-6 .grid-container .tile {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 20px;
  }
}
.search-results {
  list-style: none;
  padding: 0;
}
.search-results li {
  margin: 0;
}
.search-results li:before {
  content: none;
}
.search-results .title {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
}
.search-results .title a {
  text-decoration: none;
}
.search-results .search-snippet-info {
  padding: 0;
  font-size: 0.8125rem;
}
.search-results .search-info {
  margin-top: 1em;
}
.search-results .search-result {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 2px solid #E6E6E6;
}
.search-results .search-result > * {
  padding-bottom: .5rem;
}
.search-results .search-result:last-child {
  border-bottom: none;
}
.gsc-table-result,
.gsc-thumbnail-inside,
.gsc-url-top {
  padding-left: 0 !important;
  padding-right: 0 !importantˇ;
}
/* --- [ Aside Items ] --------------- */
/* --- [ Mixins ] --------------- */
.aside-menu-item {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  letter-spacing: 0.04rem;
  color: #FFFFFF;
  font-weight: 400;
  display: block;
  position: relative;
}
.aside-menu-item,
.aside-menu-item:hover,
.aside-menu-item.active,
.aside-menu-item:active {
  text-decoration: none;
}
.aside-menu-checkbox {
  display: block;
  position: relative;
  margin: 0;
  margin-bottom: 1.1rem;
}
.aside-menu-checkbox,
.aside-menu-checkbox label {
  display: block;
}
.aside-menu-checkbox label {
  padding: .125rem 1.35rem .025rem;
  padding-left: 2.25rem;
  cursor: pointer;
  margin: 0;
}
.aside-menu-checkbox input {
  position: absolute;
  top: 0.1875rem;
  right: auto;
  bottom: auto;
  left: 0.125rem;
  margin: 0;
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .aside-menu-checkbox {
    margin-bottom: 0;
  }
  .aside-menu-checkbox label {
    padding: .6375rem 1.55rem;
    padding-left: 1.7rem;
  }
  .aside-menu-checkbox input {
    position: absolute;
    top: 0.75rem;
    right: auto;
    bottom: auto;
    left: 0.125rem;
  }
}
.aside-menu-sub-item {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
  color: #FFFFFF;
  padding-left: 1.25rem;
  display: block;
}
.aside-menu-sub-item:first-child {
  margin-top: 0;
}
.aside-menu-sub-item:before {
  content: none;
}
.block-access-prompt {
  background-color: #FFFFFF;
  padding: 1.2rem 1.35rem;
  position: relative;
}
@media only screen and (max-width: 960px) {
  .block-access-prompt {
    padding: .75rem 1.5rem;
    margin: 0;
    width: auto;
    min-width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .block-access-prompt {
    padding: .6rem .75rem;
    margin: 0 -0.75rem;
  }
}
.block-access-prompt .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  padding-bottom: .625rem;
  border-bottom: 2px solid #D7D7D7;
  margin-bottom: 1.1875rem;
  margin-bottom: .885rem;
  padding-bottom: .725rem;
  display: block;
  position: relative;
}
@media only screen and (max-width: 480px) {
  .block-access-prompt .block-title {
    font-size: 1.25rem;
  }
}
.zone-footer .block-access-prompt .block-title,
.ds-media-aside .block-access-prompt .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer .block-access-prompt .block-title,
  .ds-media-aside .block-access-prompt .block-title {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .block-access-prompt .block-title {
    margin-bottom: 1rem;
  }
}
.block-access-prompt .block-title a {
  color: #666666;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.block-access-prompt .block-title a:hover {
  color: #1890fb;
}
@media only screen and (max-width: 960px) {
  .block-access-prompt .block-title.containsToggle {
    border: none;
    margin: 0;
    padding: 0;
  }
}
.block-access-prompt .link-tray {
  text-align: center;
  padding-top: 1.5rem;
  border-top: 2px solid #D7D7D7;
  margin-top: 1.1875rem;
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .block-access-prompt .link-tray {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-access-prompt .link-tray {
    padding-bottom: .75rem;
  }
}
.block-access-prompt .link-tray a {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
}
.block-access-prompt .link-tray a:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.block-access-prompt .link-tray a:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.block-access-prompt .link-tray a:focus {
  outline: none;
}
.block-access-prompt .link-tray a:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.block-access-prompt .link-tray a:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.block-access-prompt .link-tray a.admin,
.block-access-prompt .link-tray a.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.block-access-prompt .link-tray a.admin-extra {
  color: #1890fb;
}
.block-access-prompt .link-tray a[href*="login"] {
  background-color: #d9a70f;
}
.block-access-prompt .link-tray a[href*="login"]:hover {
  background-color: #DC8802;
}
.block-access-prompt .link-tray a[href*="login"]:active {
  background-color: #DC5502;
}
.block-access-prompt .link-tray a,
.block-access-prompt .link-tray a[href] {
  display: block;
}
.block-access-prompt .link-tray .button-dim {
  cursor: none;
  pointer-events: none;
}
.block-access-prompt .link-tray .separator {
  display: block;
}
.block-access-prompt .link-tray > *,
.block-access-prompt .link-tray > a,
.block-access-prompt .link-tray > *:first-child,
.block-access-prompt .link-tray > a:first-child,
.block-access-prompt .link-tray > *:last-child,
.block-access-prompt .link-tray > a:last-child,
.block-access-prompt .link-tray > *[href],
.block-access-prompt .link-tray > a[href] {
  margin-bottom: .5rem;
}
/* --- [ Apply Mixins ] --------------- */
[id*="form-database-details"] .views-exposed-form {
  margin-bottom: 0;
}
.no-sidebars [id*="form-database-details"] .views-exposed-form {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 960px) {
  .no-sidebars [id*="form-database-details"] .views-exposed-form {
    max-width: 100%;
    padding: 0;
  }
}
[id*="form-database-details"] .views-exposed-form .views-exposed-widget {
  float: none;
  padding: 0;
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets {
  letter-spacing: normal;
  margin: 0;
  position: relative;
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
  letter-spacing: inherit;
  width: 100%;
  display: block;
  vertical-align: top;
  padding: 0;
}
@media only screen and (max-width: 480px) {
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
    width: 100%;
    padding: 0;
  }
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button {
  padding-top: 0;
}
[class*="auto-submit"][id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button {
  padding: 0;
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets .ajax-progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
  background-color: rgba(255, 255, 255, 0.9);
  animation: fadein 500ms;
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets .ajax-progress .throbber {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: none;
  background-image: url("../images/icons/triple-dots--navy.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  height: auto;
  width: auto;
  float: none;
  animation: fadeinout 500ms infinite;
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
  background-color: #FFFFFF;
  padding: 1.2rem 1.35rem;
  position: relative;
}
@media only screen and (max-width: 960px) {
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
    padding: .75rem 1.5rem;
    margin: 0;
    width: auto;
    min-width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
    padding: .6rem .75rem;
    margin: 0 -0.75rem;
  }
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  padding-bottom: .625rem;
  border-bottom: 2px solid #D7D7D7;
  margin-bottom: 1.1875rem;
  margin-bottom: .885rem;
  padding-bottom: .725rem;
  display: block;
  position: relative;
}
@media only screen and (max-width: 480px) {
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child {
    font-size: 1.25rem;
  }
}
.zone-footer [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child,
.ds-media-aside [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child,
  .ds-media-aside [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child {
    margin-bottom: 1rem;
  }
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child a {
  color: #666666;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child a:hover {
  color: #1890fb;
}
@media only screen and (max-width: 960px) {
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child.containsToggle {
    border: none;
    margin: 0;
    padding: 0;
  }
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget {
  overflow: visible;
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item {
  margin: 0 -1.35rem;
}
@media only screen and (max-width: 960px) {
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item {
    margin: 0;
  }
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item {
    position: relative;
    width: 100%;
  }
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item > * {
    display: inline-block;
    width: 100%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links {
    position: relative;
  }
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item {
    column-count: 2;
    column-gap: 0;
  }
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links:before,
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0.6375rem;
    right: auto;
    bottom: 0.6375rem;
    left: auto;
    margin-left: -1px;
    border-left: 2px solid #D7D7D7;
  }
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links:before {
    left: 0;
  }
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links:after {
    left: auto;
    right: 50%;
  }
}
@media only screen and (max-width: 480px) {
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item {
    margin: 0 -0.75rem;
  }
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item {
    columns: auto;
  }
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item:before,
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item:after {
    content: none;
  }
  [id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item > * {
    display: block;
  }
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-link > a {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  letter-spacing: 0.04rem;
  color: #FFFFFF;
  font-weight: 400;
  display: block;
  position: relative;
}
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-link > a,
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-link > a:hover,
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-link > a.active,
[id*="form-database-details"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-link > a:active {
  text-decoration: none;
}
.block-views-exp-blog-posts-blog,
.block-views[id*="-exp-blog-posts-blog"] {
  background-color: #FFFFFF;
  padding: 1.2rem 1.35rem;
  position: relative;
}
.block-views-exp-blog-posts-blog .asidePane,
.block-views[id*="-exp-blog-posts-blog"] .asidePane {
  background-color: #FFFFFF;
}
@media only screen and (max-width: 960px) {
  .block-views-exp-blog-posts-blog,
  .block-views[id*="-exp-blog-posts-blog"] {
    padding: .75rem 1.5rem;
    margin: 0;
    width: auto;
    min-width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .block-views-exp-blog-posts-blog,
  .block-views[id*="-exp-blog-posts-blog"] {
    padding: .6rem .75rem;
    margin: 0;
  }
}
.block-views-exp-blog-posts-blog .block-title,
.block-views[id*="-exp-blog-posts-blog"] .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  padding-bottom: .625rem;
  border-bottom: 2px solid #D7D7D7;
  margin-bottom: 1.1875rem;
  margin-bottom: .885rem;
  padding-bottom: .725rem;
  display: block;
  position: relative;
}
@media only screen and (max-width: 480px) {
  .block-views-exp-blog-posts-blog .block-title,
  .block-views[id*="-exp-blog-posts-blog"] .block-title {
    font-size: 1.25rem;
  }
}
.zone-footer .block-views-exp-blog-posts-blog .block-title,
.zone-footer .block-views[id*="-exp-blog-posts-blog"] .block-title,
.ds-media-aside .block-views-exp-blog-posts-blog .block-title,
.ds-media-aside .block-views[id*="-exp-blog-posts-blog"] .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer .block-views-exp-blog-posts-blog .block-title,
  .zone-footer .block-views[id*="-exp-blog-posts-blog"] .block-title,
  .ds-media-aside .block-views-exp-blog-posts-blog .block-title,
  .ds-media-aside .block-views[id*="-exp-blog-posts-blog"] .block-title {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .block-views-exp-blog-posts-blog .block-title,
  .block-views[id*="-exp-blog-posts-blog"] .block-title {
    margin-bottom: 1rem;
  }
}
.block-views-exp-blog-posts-blog .block-title a,
.block-views[id*="-exp-blog-posts-blog"] .block-title a {
  color: #666666;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.block-views-exp-blog-posts-blog .block-title a:hover,
.block-views[id*="-exp-blog-posts-blog"] .block-title a:hover {
  color: #1890fb;
}
@media only screen and (max-width: 960px) {
  .block-views-exp-blog-posts-blog .block-title.containsToggle,
  .block-views[id*="-exp-blog-posts-blog"] .block-title.containsToggle {
    border: none;
    margin: 0;
    padding: 0;
  }
}
.block-views-exp-blog-posts-blog form .views-exposed-form,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form {
  margin-bottom: 0;
}
.no-sidebars .block-views-exp-blog-posts-blog form .views-exposed-form,
.no-sidebars .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 960px) {
  .no-sidebars .block-views-exp-blog-posts-blog form .views-exposed-form,
  .no-sidebars .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form {
    max-width: 100%;
    padding: 0;
  }
}
.block-views-exp-blog-posts-blog form .views-exposed-form .views-exposed-widget,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form .views-exposed-widget {
  float: none;
  padding: 0;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets {
  letter-spacing: normal;
  margin: 0;
  position: relative;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
  letter-spacing: inherit;
  width: 100%;
  display: block;
  vertical-align: top;
  padding: 0;
}
@media only screen and (max-width: 480px) {
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
    width: 100%;
    padding: 0;
  }
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button {
  padding-top: 0;
}
[class*="auto-submit"].block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button,
[class*="auto-submit"].block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button {
  padding: 0;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets .ajax-progress,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets .ajax-progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
  background-color: rgba(255, 255, 255, 0.9);
  animation: fadein 500ms;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets .ajax-progress .throbber,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets .ajax-progress .throbber {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: none;
  background-image: url("../images/icons/triple-dots--navy.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  height: auto;
  width: auto;
  float: none;
  animation: fadeinout 500ms infinite;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets {
  position: static;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget {
  overflow: visible;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .form-checkboxes,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .form-checkboxes {
  margin-top: .6rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes {
  max-height: 28.25rem;
  overflow-y: auto;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element div,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element div {
  border: none;
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element .scroll-element_outer,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element .scroll-element_outer {
  border-radius: 2000px;
  overflow: hidden;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element .scroll-element_track,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element .scroll-element_track {
  background-color: rgba(102, 102, 102, 0.15);
  border-radius: 2000px;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element .scroll-bar,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element .scroll-bar {
  background-color: rgba(102, 102, 102, 0.8);
  border-radius: 2000px;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element .scroll-bar:hover,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element .scroll-bar:hover {
  background-color: #666666;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-x,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-x {
  top: auto;
  bottom: .125rem;
  height: .75rem;
  left: 0;
  width: 100%;
  margin-left: 0;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-x .scroll-element_outer,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-x .scroll-element_outer {
  height: .75rem;
  top: 2px;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-x .scroll-bar,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-x .scroll-bar {
  min-width: .75rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-y,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-y {
  height: 100%;
  right: 0;
  bottom: 0;
  width: .75rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-y .scroll-element_outer,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-y .scroll-element_outer {
  left: 2px;
  width: .75rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-y .scroll-bar,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-y .scroll-bar {
  min-height: .75rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-draggable .scroll-bar,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #666666;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-scrolly_visible,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-scrolly_visible {
  right: .125rem;
  margin-left: .75rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-scrolly_visible.scroll-x,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-scrolly_visible.scroll-x {
  margin-left: 0;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-scrollx_visible,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-scrollx_visible {
  margin-top: .75rem;
  top: -0.75rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-scrollx_visible.scroll-x,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-scrollx_visible.scroll-x {
  top: auto;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -0.875rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -0.875rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -0.875rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -0.875rem;
}
@media only screen and (max-width: 960px) {
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes {
    max-height: 50vh;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes {
    position: relative;
  }
}
@media only screen and (min-width: 581px) and (max-width: 960px) {
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes {
    margin: 0;
    margin-top: .6rem;
    position: relative;
  }
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .form-item,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .form-item {
    display: inline-block;
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes {
    margin: 0;
    margin-top: .6rem;
    min-width: 100%;
  }
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes.scroll-content,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes.scroll-content {
    columns: auto;
    width: auto;
  }
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .form-item,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-checkboxes > .form-item {
    display: block;
  }
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button,
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button {
  margin-left: -0.65rem;
  margin-right: -0.65rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button input,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button input,
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button input,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button input {
  min-width: 100%;
  width: calc(100% + 1.3rem);
}
@media only screen and (max-width: 480px) {
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button,
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button {
    margin-left: 0;
    margin-right: 0;
  }
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button input,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button input,
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button input,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button input {
    min-width: 100%;
    width: 100%;
  }
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button input,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button input {
  border: none;
  padding: 0;
  text-align: left;
  margin-top: 1rem;
  text-decoration: none;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button input:hover,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-reset-button input:hover {
  text-decoration: underline;
  color: #1890fb;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox {
  display: block;
  position: relative;
  margin: 0;
  margin-bottom: 1.1rem;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox,
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox label,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox label {
  display: block;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox label,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox label {
  padding: .125rem 1.35rem .025rem;
  padding-left: 2.25rem;
  cursor: pointer;
  margin: 0;
}
.block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox input,
.block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox input {
  position: absolute;
  top: 0.1875rem;
  right: auto;
  bottom: auto;
  left: 0.125rem;
  margin: 0;
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox {
    margin-bottom: 0;
  }
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox label,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox label {
    padding: .6375rem 1.55rem;
    padding-left: 1.7rem;
  }
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox input,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-checkbox input {
    position: absolute;
    top: 0.75rem;
    right: auto;
    bottom: auto;
    left: 0.125rem;
  }
}
@media only screen and (max-width: 960px) {
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets .ajax-progress,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets .ajax-progress {
    position: absolute;
    top: -2rem;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media only screen and (max-width: 700px) {
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets .ajax-progress,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets .ajax-progress {
    position: absolute;
    top: -2rem;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media only screen and (max-width: 480px) {
  .block-views-exp-blog-posts-blog form .views-exposed-form > .views-exposed-widgets .ajax-progress,
  .block-views[id*="-exp-blog-posts-blog"] form .views-exposed-form > .views-exposed-widgets .ajax-progress {
    position: absolute;
    top: -2rem;
    right: -0.75rem;
    bottom: -2.05rem;
    left: -0.75rem;
  }
}
.not-front .region-sidebar-first-inner .group-inset,
.not-front.node-type-page .region-content .group-inset,
.not-front.node-type-news-post .region-content .group-inset,
.not-front .region-sidebar-first-inner .region-to-block,
.not-front.node-type-page .region-content .region-to-block,
.not-front.node-type-news-post .region-content .region-to-block {
  border: 4px solid #E6E6E6;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.not-front .region-sidebar-first-inner .group-inset .field-profile-image,
.not-front.node-type-page .region-content .group-inset .field-profile-image,
.not-front.node-type-news-post .region-content .group-inset .field-profile-image,
.not-front .region-sidebar-first-inner .region-to-block .field-profile-image,
.not-front.node-type-page .region-content .region-to-block .field-profile-image,
.not-front.node-type-news-post .region-content .region-to-block .field-profile-image {
  margin: -4px;
  width: inherit;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: 4px solid #E6E6E6;
}
.not-front .region-sidebar-first-inner .group-inset .field-profile-image img,
.not-front.node-type-page .region-content .group-inset .field-profile-image img,
.not-front.node-type-news-post .region-content .group-inset .field-profile-image img,
.not-front .region-sidebar-first-inner .region-to-block .field-profile-image img,
.not-front.node-type-page .region-content .region-to-block .field-profile-image img,
.not-front.node-type-news-post .region-content .region-to-block .field-profile-image img {
  display: block;
}
@media only screen and (max-width: 960px) {
  .not-front .region-sidebar-first-inner .group-inset .field-profile-image.square,
  .not-front.node-type-page .region-content .group-inset .field-profile-image.square,
  .not-front.node-type-news-post .region-content .group-inset .field-profile-image.square,
  .not-front .region-sidebar-first-inner .region-to-block .field-profile-image.square,
  .not-front.node-type-page .region-content .region-to-block .field-profile-image.square,
  .not-front.node-type-news-post .region-content .region-to-block .field-profile-image.square {
    text-align: center;
  }
}
.not-front .region-sidebar-first-inner .group-inset .field,
.not-front.node-type-page .region-content .group-inset .field,
.not-front.node-type-news-post .region-content .group-inset .field,
.not-front .region-sidebar-first-inner .region-to-block .field,
.not-front.node-type-page .region-content .region-to-block .field,
.not-front.node-type-news-post .region-content .region-to-block .field {
  padding: 1rem 1.375rem;
}
.not-front .region-sidebar-first-inner .group-inset .field *,
.not-front.node-type-page .region-content .group-inset .field *,
.not-front.node-type-news-post .region-content .group-inset .field *,
.not-front .region-sidebar-first-inner .region-to-block .field *,
.not-front.node-type-page .region-content .region-to-block .field *,
.not-front.node-type-news-post .region-content .region-to-block .field * {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
  color: #4d5a6b;
  margin-top: 0;
  text-transform: none;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.not-front .region-sidebar-first-inner .group-inset .field *:first-child,
.not-front.node-type-page .region-content .group-inset .field *:first-child,
.not-front.node-type-news-post .region-content .group-inset .field *:first-child,
.not-front .region-sidebar-first-inner .region-to-block .field *:first-child,
.not-front.node-type-page .region-content .region-to-block .field *:first-child,
.not-front.node-type-news-post .region-content .region-to-block .field *:first-child {
  margin-top: 0;
}
.not-front .region-sidebar-first-inner .group-inset .field.field-pull-quote,
.not-front.node-type-page .region-content .group-inset .field.field-pull-quote,
.not-front.node-type-news-post .region-content .group-inset .field.field-pull-quote,
.not-front .region-sidebar-first-inner .region-to-block .field.field-pull-quote,
.not-front.node-type-page .region-content .region-to-block .field.field-pull-quote,
.not-front.node-type-news-post .region-content .region-to-block .field.field-pull-quote,
.not-front .region-sidebar-first-inner .group-inset .field.field-pull-quote .field-contents,
.not-front.node-type-page .region-content .group-inset .field.field-pull-quote .field-contents,
.not-front.node-type-news-post .region-content .group-inset .field.field-pull-quote .field-contents,
.not-front .region-sidebar-first-inner .region-to-block .field.field-pull-quote .field-contents,
.not-front.node-type-page .region-content .region-to-block .field.field-pull-quote .field-contents,
.not-front.node-type-news-post .region-content .region-to-block .field.field-pull-quote .field-contents {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
}
@media only screen and (max-width: 480px) {
  .not-front .region-sidebar-first-inner .group-inset .field.field-pull-quote,
  .not-front.node-type-page .region-content .group-inset .field.field-pull-quote,
  .not-front.node-type-news-post .region-content .group-inset .field.field-pull-quote,
  .not-front .region-sidebar-first-inner .region-to-block .field.field-pull-quote,
  .not-front.node-type-page .region-content .region-to-block .field.field-pull-quote,
  .not-front.node-type-news-post .region-content .region-to-block .field.field-pull-quote,
  .not-front .region-sidebar-first-inner .group-inset .field.field-pull-quote .field-contents,
  .not-front.node-type-page .region-content .group-inset .field.field-pull-quote .field-contents,
  .not-front.node-type-news-post .region-content .group-inset .field.field-pull-quote .field-contents,
  .not-front .region-sidebar-first-inner .region-to-block .field.field-pull-quote .field-contents,
  .not-front.node-type-page .region-content .region-to-block .field.field-pull-quote .field-contents,
  .not-front.node-type-news-post .region-content .region-to-block .field.field-pull-quote .field-contents {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .not-front .region-sidebar-first-inner .group-inset .field.field-pull-quote,
  .not-front.node-type-page .region-content .group-inset .field.field-pull-quote,
  .not-front.node-type-news-post .region-content .group-inset .field.field-pull-quote,
  .not-front .region-sidebar-first-inner .region-to-block .field.field-pull-quote,
  .not-front.node-type-page .region-content .region-to-block .field.field-pull-quote,
  .not-front.node-type-news-post .region-content .region-to-block .field.field-pull-quote {
    text-align: center;
  }
}
.not-front .region-sidebar-first-inner .group-inset .field.field-focus-areas .field-label .text,
.not-front.node-type-page .region-content .group-inset .field.field-focus-areas .field-label .text,
.not-front.node-type-news-post .region-content .group-inset .field.field-focus-areas .field-label .text,
.not-front .region-sidebar-first-inner .region-to-block .field.field-focus-areas .field-label .text,
.not-front.node-type-page .region-content .region-to-block .field.field-focus-areas .field-label .text,
.not-front.node-type-news-post .region-content .region-to-block .field.field-focus-areas .field-label .text,
.not-front .region-sidebar-first-inner .group-inset .field.field-funded-since .field-label .text,
.not-front.node-type-page .region-content .group-inset .field.field-funded-since .field-label .text,
.not-front.node-type-news-post .region-content .group-inset .field.field-funded-since .field-label .text,
.not-front .region-sidebar-first-inner .region-to-block .field.field-funded-since .field-label .text,
.not-front.node-type-page .region-content .region-to-block .field.field-funded-since .field-label .text,
.not-front.node-type-news-post .region-content .region-to-block .field.field-funded-since .field-label .text,
.not-front .region-sidebar-first-inner .group-inset .field.field-executive-director .field-label .text,
.not-front.node-type-page .region-content .group-inset .field.field-executive-director .field-label .text,
.not-front.node-type-news-post .region-content .group-inset .field.field-executive-director .field-label .text,
.not-front .region-sidebar-first-inner .region-to-block .field.field-executive-director .field-label .text,
.not-front.node-type-page .region-content .region-to-block .field.field-executive-director .field-label .text,
.not-front.node-type-news-post .region-content .region-to-block .field.field-executive-director .field-label .text,
.not-front .region-sidebar-first-inner .group-inset .field.field-num-staff .field-label .text,
.not-front.node-type-page .region-content .group-inset .field.field-num-staff .field-label .text,
.not-front.node-type-news-post .region-content .group-inset .field.field-num-staff .field-label .text,
.not-front .region-sidebar-first-inner .region-to-block .field.field-num-staff .field-label .text,
.not-front.node-type-page .region-content .region-to-block .field.field-num-staff .field-label .text,
.not-front.node-type-news-post .region-content .region-to-block .field.field-num-staff .field-label .text,
.not-front .region-sidebar-first-inner .group-inset .field.field-news-categories .field-label .text,
.not-front.node-type-page .region-content .group-inset .field.field-news-categories .field-label .text,
.not-front.node-type-news-post .region-content .group-inset .field.field-news-categories .field-label .text,
.not-front .region-sidebar-first-inner .region-to-block .field.field-news-categories .field-label .text,
.not-front.node-type-page .region-content .region-to-block .field.field-news-categories .field-label .text,
.not-front.node-type-news-post .region-content .region-to-block .field.field-news-categories .field-label .text,
.not-front .region-sidebar-first-inner .group-inset .field.field-related .field-label .text,
.not-front.node-type-page .region-content .group-inset .field.field-related .field-label .text,
.not-front.node-type-news-post .region-content .group-inset .field.field-related .field-label .text,
.not-front .region-sidebar-first-inner .region-to-block .field.field-related .field-label .text,
.not-front.node-type-page .region-content .region-to-block .field.field-related .field-label .text,
.not-front.node-type-news-post .region-content .region-to-block .field.field-related .field-label .text {
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  text-transform: uppercase;
  color: #4d5a6b;
}
.not-front .region-sidebar-first-inner .group-inset .field.field-social-share,
.not-front.node-type-page .region-content .group-inset .field.field-social-share,
.not-front.node-type-news-post .region-content .group-inset .field.field-social-share,
.not-front .region-sidebar-first-inner .region-to-block .field.field-social-share,
.not-front.node-type-page .region-content .region-to-block .field.field-social-share,
.not-front.node-type-news-post .region-content .region-to-block .field.field-social-share {
  border-top: 4px solid #E6E6E6;
}
.not-front .region-sidebar-first-inner .group-inset .field.field-link,
.not-front.node-type-page .region-content .group-inset .field.field-link,
.not-front.node-type-news-post .region-content .group-inset .field.field-link,
.not-front .region-sidebar-first-inner .region-to-block .field.field-link,
.not-front.node-type-page .region-content .region-to-block .field.field-link,
.not-front.node-type-news-post .region-content .region-to-block .field.field-link,
.not-front .region-sidebar-first-inner .group-inset .field.field-website,
.not-front.node-type-page .region-content .group-inset .field.field-website,
.not-front.node-type-news-post .region-content .group-inset .field.field-website,
.not-front .region-sidebar-first-inner .region-to-block .field.field-website,
.not-front.node-type-page .region-content .region-to-block .field.field-website,
.not-front.node-type-news-post .region-content .region-to-block .field.field-website {
  border-top: 4px solid #E6E6E6;
  position: relative;
  padding-right: 1.5rem;
}
.not-front .region-sidebar-first-inner .group-inset .field.field-link:after,
.not-front.node-type-page .region-content .group-inset .field.field-link:after,
.not-front.node-type-news-post .region-content .group-inset .field.field-link:after,
.not-front .region-sidebar-first-inner .region-to-block .field.field-link:after,
.not-front.node-type-page .region-content .region-to-block .field.field-link:after,
.not-front.node-type-news-post .region-content .region-to-block .field.field-link:after,
.not-front .region-sidebar-first-inner .group-inset .field.field-website:after,
.not-front.node-type-page .region-content .group-inset .field.field-website:after,
.not-front.node-type-news-post .region-content .group-inset .field.field-website:after,
.not-front .region-sidebar-first-inner .region-to-block .field.field-website:after,
.not-front.node-type-page .region-content .region-to-block .field.field-website:after,
.not-front.node-type-news-post .region-content .region-to-block .field.field-website:after {
  content: ' ';
  display: inline-block;
  background-image: url("../images/icons/get--directions-blue.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.not-front .region-sidebar-first-inner .group-inset .field.field-link:after,
.not-front.node-type-page .region-content .group-inset .field.field-link:after,
.not-front.node-type-news-post .region-content .group-inset .field.field-link:after,
.not-front .region-sidebar-first-inner .region-to-block .field.field-link:after,
.not-front.node-type-page .region-content .region-to-block .field.field-link:after,
.not-front.node-type-news-post .region-content .region-to-block .field.field-link:after,
.not-front .region-sidebar-first-inner .group-inset .field.field-website:after,
.not-front.node-type-page .region-content .group-inset .field.field-website:after,
.not-front.node-type-news-post .region-content .group-inset .field.field-website:after,
.not-front .region-sidebar-first-inner .region-to-block .field.field-website:after,
.not-front.node-type-page .region-content .region-to-block .field.field-website:after,
.not-front.node-type-news-post .region-content .region-to-block .field.field-website:after {
  right: 24px;
  width: 1rem;
  height: 1rem;
}
.not-front .region-sidebar-first-inner .group-inset .field.field-link .text,
.not-front.node-type-page .region-content .group-inset .field.field-link .text,
.not-front.node-type-news-post .region-content .group-inset .field.field-link .text,
.not-front .region-sidebar-first-inner .region-to-block .field.field-link .text,
.not-front.node-type-page .region-content .region-to-block .field.field-link .text,
.not-front.node-type-news-post .region-content .region-to-block .field.field-link .text,
.not-front .region-sidebar-first-inner .group-inset .field.field-website .text,
.not-front.node-type-page .region-content .group-inset .field.field-website .text,
.not-front.node-type-news-post .region-content .group-inset .field.field-website .text,
.not-front .region-sidebar-first-inner .region-to-block .field.field-website .text,
.not-front.node-type-page .region-content .region-to-block .field.field-website .text,
.not-front.node-type-news-post .region-content .region-to-block .field.field-website .text {
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  color: #1890fb;
}
.not-front .region-sidebar-first-inner .group-inset .field ul.field-contents,
.not-front.node-type-page .region-content .group-inset .field ul.field-contents,
.not-front.node-type-news-post .region-content .group-inset .field ul.field-contents,
.not-front .region-sidebar-first-inner .region-to-block .field ul.field-contents,
.not-front.node-type-page .region-content .region-to-block .field ul.field-contents,
.not-front.node-type-news-post .region-content .region-to-block .field ul.field-contents {
  list-style: none;
  padding: 0;
}
.not-front .region-sidebar-first-inner .group-inset .field ul.field-contents li,
.not-front.node-type-page .region-content .group-inset .field ul.field-contents li,
.not-front.node-type-news-post .region-content .group-inset .field ul.field-contents li,
.not-front .region-sidebar-first-inner .region-to-block .field ul.field-contents li,
.not-front.node-type-page .region-content .region-to-block .field ul.field-contents li,
.not-front.node-type-news-post .region-content .region-to-block .field ul.field-contents li {
  margin: 0;
}
.not-front .region-sidebar-first-inner .group-inset .field ul.field-contents li:before,
.not-front.node-type-page .region-content .group-inset .field ul.field-contents li:before,
.not-front.node-type-news-post .region-content .group-inset .field ul.field-contents li:before,
.not-front .region-sidebar-first-inner .region-to-block .field ul.field-contents li:before,
.not-front.node-type-page .region-content .region-to-block .field ul.field-contents li:before,
.not-front.node-type-news-post .region-content .region-to-block .field ul.field-contents li:before {
  content: none;
}
@media only screen and (max-width: 480px) {
  .not-front .region-sidebar-first-inner .ds-media .ds-media-text,
  .not-front.node-type-page .region-content .ds-media .ds-media-text,
  .not-front.node-type-news-post .region-content .ds-media .ds-media-text {
    padding: 1rem 0.6rem;
  }
  .not-front .region-sidebar-first-inner .ds-media .ds-media-text .field-title h3,
  .not-front.node-type-page .region-content .ds-media .ds-media-text .field-title h3,
  .not-front.node-type-news-post .region-content .ds-media .ds-media-text .field-title h3 {
    font-size: 1.25rem;
  }
  .not-front .region-sidebar-first-inner .group-inset,
  .not-front.node-type-page .region-content .group-inset,
  .not-front.node-type-news-post .region-content .group-inset {
    width: 100%;
    float: none;
  }
}
[id*="form-research-guides"] .views-exposed-form {
  margin-bottom: 0;
}
.no-sidebars [id*="form-research-guides"] .views-exposed-form {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 960px) {
  .no-sidebars [id*="form-research-guides"] .views-exposed-form {
    max-width: 100%;
    padding: 0;
  }
}
[id*="form-research-guides"] .views-exposed-form .views-exposed-widget {
  float: none;
  padding: 0;
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets {
  letter-spacing: normal;
  margin: 0;
  position: relative;
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
  letter-spacing: inherit;
  width: 100%;
  display: block;
  vertical-align: top;
  padding: 0;
}
@media only screen and (max-width: 480px) {
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
    width: 100%;
    padding: 0;
  }
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button {
  padding-top: 0;
}
[class*="auto-submit"][id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget.views-submit-button {
  padding: 0;
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets .ajax-progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
  background-color: rgba(255, 255, 255, 0.9);
  animation: fadein 500ms;
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets .ajax-progress .throbber {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: none;
  background-image: url("../images/icons/triple-dots--navy.svg");
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  height: auto;
  width: auto;
  float: none;
  animation: fadeinout 500ms infinite;
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
  background-color: #FFFFFF;
  padding: 1.2rem 1.35rem;
  position: relative;
}
@media only screen and (max-width: 960px) {
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
    padding: .75rem 1.5rem;
    margin: 0;
    width: auto;
    min-width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget {
    padding: .6rem .75rem;
    margin: 0 -0.75rem;
  }
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  padding-bottom: .625rem;
  border-bottom: 2px solid #D7D7D7;
  margin-bottom: 1.1875rem;
  margin-bottom: .885rem;
  padding-bottom: .725rem;
  display: block;
  position: relative;
}
@media only screen and (max-width: 480px) {
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child {
    font-size: 1.25rem;
  }
}
.zone-footer [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child,
.ds-media-aside [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child,
  .ds-media-aside [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child {
    margin-bottom: 1rem;
  }
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child a {
  color: #666666;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child a:hover {
  color: #1890fb;
}
@media only screen and (max-width: 960px) {
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget > label:first-child.containsToggle {
    border: none;
    margin: 0;
    padding: 0;
  }
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget {
  overflow: visible;
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item {
  margin: 0 -1.35rem;
}
@media only screen and (max-width: 960px) {
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item {
    margin: 0;
  }
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item {
    position: relative;
    width: 100%;
  }
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item > * {
    display: inline-block;
    width: 100%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links {
    position: relative;
  }
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item {
    column-count: 2;
    column-gap: 0;
  }
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links:before,
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0.6375rem;
    right: auto;
    bottom: 0.6375rem;
    left: auto;
    margin-left: -1px;
    border-left: 2px solid #D7D7D7;
  }
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links:before {
    left: 0;
  }
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links:after {
    left: auto;
    right: 50%;
  }
}
@media only screen and (max-width: 480px) {
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item {
    margin: 0 -0.75rem;
  }
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item {
    columns: auto;
  }
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item:before,
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item:after {
    content: none;
  }
  [id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .views-widget > .form-item .bef-select-as-links > .form-item > * {
    display: block;
  }
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-link > a {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  letter-spacing: 0.04rem;
  color: #FFFFFF;
  font-weight: 400;
  display: block;
  position: relative;
}
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-link > a,
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-link > a:hover,
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-link > a.active,
[id*="form-research-guides"] .views-exposed-form > .views-exposed-widgets > .views-exposed-widget .form-type-bef-link > a:active {
  text-decoration: none;
}
.block-guide-contents,
.block[id*="guide-content"] {
  background-color: #FFFFFF;
  position: relative;
}
@media only screen and (max-width: 960px) {
  .block-guide-contents,
  .block[id*="guide-content"] {
    margin: 0;
    width: auto;
    min-width: 100%;
  }
}
.block-guide-contents .block-title,
.block[id*="guide-content"] .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  padding-bottom: .625rem;
  border-bottom: 2px solid #D7D7D7;
  margin-bottom: 1.1875rem;
  margin-bottom: .885rem;
  padding-bottom: .725rem;
  display: block;
  position: relative;
}
@media only screen and (max-width: 480px) {
  .block-guide-contents .block-title,
  .block[id*="guide-content"] .block-title {
    font-size: 1.25rem;
  }
}
.zone-footer .block-guide-contents .block-title,
.zone-footer .block[id*="guide-content"] .block-title,
.ds-media-aside .block-guide-contents .block-title,
.ds-media-aside .block[id*="guide-content"] .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer .block-guide-contents .block-title,
  .zone-footer .block[id*="guide-content"] .block-title,
  .ds-media-aside .block-guide-contents .block-title,
  .ds-media-aside .block[id*="guide-content"] .block-title {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .block-guide-contents .block-title,
  .block[id*="guide-content"] .block-title {
    margin-bottom: 1rem;
  }
}
.block-guide-contents .block-title a,
.block[id*="guide-content"] .block-title a {
  color: #666666;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.block-guide-contents .block-title a:hover,
.block[id*="guide-content"] .block-title a:hover {
  color: #1890fb;
}
@media only screen and (max-width: 960px) {
  .block-guide-contents .block-title.containsToggle,
  .block[id*="guide-content"] .block-title.containsToggle {
    border: none;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .block-guide-contents > .content > .item-list,
  .block[id*="guide-content"] > .content > .item-list {
    position: relative;
  }
  .block-guide-contents > .content > .item-list:before,
  .block[id*="guide-content"] > .content > .item-list:before,
  .block-guide-contents > .content > .item-list:after,
  .block[id*="guide-content"] > .content > .item-list:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0.6375rem;
    right: auto;
    bottom: 0.6375rem;
    left: auto;
    margin-left: -1px;
    border-left: 2px solid #D7D7D7;
  }
  .block-guide-contents > .content > .item-list:before,
  .block[id*="guide-content"] > .content > .item-list:before {
    left: 0;
  }
  .block-guide-contents > .content > .item-list:after,
  .block[id*="guide-content"] > .content > .item-list:after {
    left: auto;
    right: 50%;
  }
}
.block-guide-contents .item-list > ul,
.block[id*="guide-content"] .item-list > ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1.125rem;
  max-width: 350px;
}
.block-guide-contents .item-list > ul li,
.block[id*="guide-content"] .item-list > ul li {
  margin: 0;
}
.block-guide-contents .item-list > ul li:before,
.block[id*="guide-content"] .item-list > ul li:before {
  content: none;
}
@media only screen and (max-width: 960px) {
  .block-guide-contents .item-list > ul,
  .block[id*="guide-content"] .item-list > ul {
    max-width: 100%;
  }
}
.block-guide-contents .item-list > ul > li,
.block[id*="guide-content"] .item-list > ul > li {
  margin-bottom: 1.125rem;
  background: #1374CA;
  text-align: left;
}
.block-guide-contents .item-list > ul > li.active-trail,
.block[id*="guide-content"] .item-list > ul > li.active-trail {
  background-color: #4d5a6b;
}
.block-guide-contents .item-list > ul > li.active .tlAccSwitchWrap > a .text,
.block[id*="guide-content"] .item-list > ul > li.active .tlAccSwitchWrap > a .text,
.block-guide-contents .item-list > ul > li.active > a .text,
.block[id*="guide-content"] .item-list > ul > li.active > a .text {
  text-decoration: underline;
}
.block-guide-contents .item-list > ul > li > div > a,
.block[id*="guide-content"] .item-list > ul > li > div > a,
.block-guide-contents .item-list > ul > li > a,
.block[id*="guide-content"] .item-list > ul > li > a {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  letter-spacing: 0.04rem;
  color: #FFFFFF;
  font-weight: 400;
  display: block;
  position: relative;
}
.block-guide-contents .item-list > ul > li > div > a,
.block[id*="guide-content"] .item-list > ul > li > div > a,
.block-guide-contents .item-list > ul > li > a,
.block[id*="guide-content"] .item-list > ul > li > a,
.block-guide-contents .item-list > ul > li > div > a:hover,
.block[id*="guide-content"] .item-list > ul > li > div > a:hover,
.block-guide-contents .item-list > ul > li > a:hover,
.block[id*="guide-content"] .item-list > ul > li > a:hover,
.block-guide-contents .item-list > ul > li > div > a.active,
.block[id*="guide-content"] .item-list > ul > li > div > a.active,
.block-guide-contents .item-list > ul > li > a.active,
.block[id*="guide-content"] .item-list > ul > li > a.active,
.block-guide-contents .item-list > ul > li > div > a:active,
.block[id*="guide-content"] .item-list > ul > li > div > a:active,
.block-guide-contents .item-list > ul > li > a:active,
.block[id*="guide-content"] .item-list > ul > li > a:active {
  text-decoration: none;
}
.block-guide-contents .item-list > ul > li > .tlAccSwitchWrap,
.block[id*="guide-content"] .item-list > ul > li > .tlAccSwitchWrap,
.block-guide-contents .item-list > ul > li > a,
.block[id*="guide-content"] .item-list > ul > li > a {
  padding: 1rem 1.5rem 1.188rem 1.5rem;
}
.block-guide-contents .item-list > ul > li .tlAccSwitchWrap,
.block[id*="guide-content"] .item-list > ul > li .tlAccSwitchWrap,
.block-guide-contents .item-list > ul > li .slAccSwitchWrap,
.block[id*="guide-content"] .item-list > ul > li .slAccSwitchWrap {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.block-guide-contents .item-list > ul > li .tlAccSwitchWrap > *,
.block[id*="guide-content"] .item-list > ul > li .tlAccSwitchWrap > *,
.block-guide-contents .item-list > ul > li .slAccSwitchWrap > *,
.block[id*="guide-content"] .item-list > ul > li .slAccSwitchWrap > * {
  width: 100%;
}
.block-guide-contents .item-list > ul > li .tlAccSwitchWrap > a,
.block[id*="guide-content"] .item-list > ul > li .tlAccSwitchWrap > a,
.block-guide-contents .item-list > ul > li .slAccSwitchWrap > a,
.block[id*="guide-content"] .item-list > ul > li .slAccSwitchWrap > a {
  flex-shrink: 1;
}
.block-guide-contents .item-list > ul > li .tlAccSwitchWrap > a:active .text,
.block[id*="guide-content"] .item-list > ul > li .tlAccSwitchWrap > a:active .text,
.block-guide-contents .item-list > ul > li .slAccSwitchWrap > a:active .text,
.block[id*="guide-content"] .item-list > ul > li .slAccSwitchWrap > a:active .text,
.block-guide-contents .item-list > ul > li .tlAccSwitchWrap > a:hover .text,
.block[id*="guide-content"] .item-list > ul > li .tlAccSwitchWrap > a:hover .text,
.block-guide-contents .item-list > ul > li .slAccSwitchWrap > a:hover .text,
.block[id*="guide-content"] .item-list > ul > li .slAccSwitchWrap > a:hover .text {
  text-decoration: underline;
}
.block-guide-contents .item-list > ul > li > a:hover .text,
.block[id*="guide-content"] .item-list > ul > li > a:hover .text {
  text-decoration: underline;
}
.block-guide-contents .item-list > ul > li .tlAccSwitch,
.block[id*="guide-content"] .item-list > ul > li .tlAccSwitch,
.block-guide-contents .item-list > ul > li .slAccSwitch,
.block[id*="guide-content"] .item-list > ul > li .slAccSwitch {
  position: relative;
  width: 23px;
  margin-left: 5px;
  padding: 0;
}
.block-guide-contents .item-list > ul > li .tlAccSwitch:before,
.block[id*="guide-content"] .item-list > ul > li .tlAccSwitch:before,
.block-guide-contents .item-list > ul > li .slAccSwitch:before,
.block[id*="guide-content"] .item-list > ul > li .slAccSwitch:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
}
@media only screen and (max-width: 480px) {
  .block-guide-contents .item-list > ul > li .tlAccSwitch:before,
  .block[id*="guide-content"] .item-list > ul > li .tlAccSwitch:before,
  .block-guide-contents .item-list > ul > li .slAccSwitch:before,
  .block[id*="guide-content"] .item-list > ul > li .slAccSwitch:before {
    position: absolute;
    top: 0;
    right: auto;
    bottom: 0;
    left: -50%;
    width: 200%;
  }
}
.block-guide-contents .item-list > ul > li .tlAccSwitch .text,
.block[id*="guide-content"] .item-list > ul > li .tlAccSwitch .text,
.block-guide-contents .item-list > ul > li .slAccSwitch .text,
.block[id*="guide-content"] .item-list > ul > li .slAccSwitch .text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  /*rtl:ignore*/
  width: 100%;
  height: 100%;
  border-radius: 2000px;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
  background-color: transparent;
  background-image: url("../images/icons/dropdown-arrow--white.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale3d(1,1,1);
  transition: transform 700ms ease 0ms, background-color 150ms ease 0ms;
}
.block-guide-contents .item-list > ul > li .tlAccSwitch:active .text,
.block[id*="guide-content"] .item-list > ul > li .tlAccSwitch:active .text,
.block-guide-contents .item-list > ul > li .slAccSwitch:active .text,
.block[id*="guide-content"] .item-list > ul > li .slAccSwitch:active .text,
.block-guide-contents .item-list > ul > li .tlAccSwitch:hover .text,
.block[id*="guide-content"] .item-list > ul > li .tlAccSwitch:hover .text,
.block-guide-contents .item-list > ul > li .slAccSwitch:hover .text,
.block[id*="guide-content"] .item-list > ul > li .slAccSwitch:hover .text {
  background-color: transparent;
}
.block-guide-contents .item-list > ul > li .tlAccSwitch.isOpen .text,
.block[id*="guide-content"] .item-list > ul > li .tlAccSwitch.isOpen .text,
.block-guide-contents .item-list > ul > li .slAccSwitch.isOpen .text,
.block[id*="guide-content"] .item-list > ul > li .slAccSwitch.isOpen .text {
  transform: translateX(-50%) translateY(-50%) scale3d(1, 1, 1) rotate(180deg);
  background-color: transparent;
}
.block-guide-contents .item-list > ul > li .item-list > ul,
.block[id*="guide-content"] .item-list > ul > li .item-list > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 1.875rem;
  columns: auto;
  display: block;
}
.block-guide-contents .item-list > ul > li .item-list > ul li,
.block[id*="guide-content"] .item-list > ul > li .item-list > ul li {
  margin: 0;
}
.block-guide-contents .item-list > ul > li .item-list > ul li:before,
.block[id*="guide-content"] .item-list > ul > li .item-list > ul li:before {
  content: none;
}
.block-guide-contents .item-list > ul > li .item-list > ul > li,
.block[id*="guide-content"] .item-list > ul > li .item-list > ul > li {
  border-left: 3px solid #FFFFFF;
  padding-bottom: .625rem;
}
.block-guide-contents .item-list > ul > li .item-list > ul > li:last-child,
.block[id*="guide-content"] .item-list > ul > li .item-list > ul > li:last-child {
  padding-bottom: 0;
}
.block-guide-contents .item-list > ul > li .item-list > ul > li > a,
.block[id*="guide-content"] .item-list > ul > li .item-list > ul > li > a {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
  color: #FFFFFF;
  padding-left: 1.25rem;
  display: block;
}
.block-guide-contents .item-list > ul > li .item-list > ul > li > a:first-child,
.block[id*="guide-content"] .item-list > ul > li .item-list > ul > li > a:first-child {
  margin-top: 0;
}
.block-guide-contents .item-list > ul > li .item-list > ul > li > a:before,
.block[id*="guide-content"] .item-list > ul > li .item-list > ul > li > a:before {
  content: none;
}
.block-guide-contents .item-list > ul > li .item-list > ul.isClosed,
.block[id*="guide-content"] .item-list > ul > li .item-list > ul.isClosed {
  display: none;
}
.block-more-search-options .item-list > ul,
.block[id*="more-search-options"] .item-list > ul {
  list-style: none;
  padding: 0;
  font-size: 16px;
  clear: both;
}
.block-more-search-options .item-list > ul li,
.block[id*="more-search-options"] .item-list > ul li {
  margin: 0;
}
.block-more-search-options .item-list > ul li:before,
.block[id*="more-search-options"] .item-list > ul li:before {
  content: none;
}
.block-more-search-options .item-list > ul li ~ li,
.block[id*="more-search-options"] .item-list > ul li ~ li {
  margin-top: 1.45rem;
  margin-top: 1.25rem;
}
.block-more-search-options .item-list > ul li a,
.block[id*="more-search-options"] .item-list > ul li a {
  color: #FFFFFF;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.4;
  text-decoration: none;
  font-size: 12px;
  line-height: 1.273;
  font-weight: 400;
  color: #1890fb;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-right: .5rem;
  vertical-align: middle;
}
.block-more-search-options .item-list > ul li a:hover,
.block[id*="more-search-options"] .item-list > ul li a:hover {
  text-decoration: underline;
}
.block-more-search-options .item-list > ul li a.active,
.block[id*="more-search-options"] .item-list > ul li a.active {
  cursor: default;
  text-decoration: underline;
}
.block-more-search-options .item-list > ul li a.active:hover,
.block[id*="more-search-options"] .item-list > ul li a.active:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 960px) {
  .block-more-search-options .item-list > ul li a,
  .block[id*="more-search-options"] .item-list > ul li a {
    width: auto;
  }
}
/* --- [ Toggle Styling ] --------------- */
.asideSwitch {
  display: none;
  background: transparent;
}
@media only screen and (max-width: 960px) {
  .asideSwitch {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
  }
  .asideSwitch .text {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    /*rtl:ignore*/
    width: 100%;
    height: 100%;
    border-radius: 2000px;
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
    pointer-events: none;
    background-color: transparent;
    background-image: url("../images/icons/dropdown-arrow--gray.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: .875rem auto;
    transform-origin: center;
    transform: translateX(-50%) translateY(-50%) scale3d(1,1,1);
    transition: transform 150ms ease 0ms, background-color 150ms ease 0ms;
  }
  .asideSwitch.isOpen {
    background: transparent;
  }
  .asideSwitch.isOpen .text {
    transform: translateX(-50%) translateY(-50%) scale3d(1,-1,1);
    background-color: transparent;
  }
  .asideSwitch:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
  }
  .asideSwitch:after {
    content: ' ';
    position: absolute;
    top: -0.75rem;
    right: -1.5rem;
    bottom: -0.75rem;
    left: -1.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .asideSwitch:after {
    position: absolute;
    top: -0.6rem;
    right: -0.75rem;
    bottom: -0.6rem;
    left: -0.75rem;
  }
}
@media only screen and (max-width: 960px) {
  .asidePane {
    transform-origin: top center;
    transition: transform 100ms ease 0ms, padding 100ms ease 0ms, opacity 100ms ease 0ms;
    position: relative;
    z-index: 1;
    display: flex;
    overflow: visible;
    background-color: #FFFFFF;
    position: absolute;
    top: auto;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    padding-top: 1.735rem;
    padding: 2.375rem 2.5rem 1.3rem;
  }
  .asidePane.isOpen {
    height: auto;
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  .asidePane.isClosed {
    height: 0;
    transform: scale3d(1, 0, 0);
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border: none;
    opacity: 0;
  }
  .asidePane:before {
    content: ' ';
    position: absolute;
    top: 1.085rem;
    right: 1.5rem;
    bottom: auto;
    left: 1.5rem;
    display: block;
    border-top: 2px solid #D7D7D7;
  }
}
@media only screen and (max-width: 480px) {
  .asidePane {
    padding: 1.335rem 0 0;
    position: static;
  }
  .asidePane:before {
    position: absolute;
    top: 0.45rem;
    right: 0;
    bottom: auto;
    left: 0;
  }
}
/* --- [ Share links ] --------------- */
.share-links {
  list-style: none;
  padding: 0;
  letter-spacing: -1em;
  line-height: 0;
}
.share-links li {
  margin: 0;
}
.share-links li:before {
  content: none;
}
.share-links > * {
  letter-spacing: normal;
  line-height: normal;
}
.share-links > li {
  padding: .75rem;
  display: inline-block;
}
.share-links > li:first-child {
  padding-left: 0;
}
.share-links > li:last-child {
  padding-right: 0;
}
.share-links > li > a {
  width: 1.875rem;
  height: 1.875rem;
  display: block;
  position: relative;
  background: transparent;
}
.share-links > li > a:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 240ms ease 0ms;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 1.875rem;
  background-color: transparent;
}
.share-links > li > a.facebook:before {
  background-image: url("../images/icons/share-facebook.svg");
}
.share-links > li > a.mail:before,
.share-links > li > a.email:before {
  background-image: url("../images/icons/share-email.svg");
}
.share-links > li > a.twitter:before {
  background-image: url("../images/icons/share-twitter.svg");
}
.share-links > li > a.linkedin:before {
  background-image: url("../images/icons/share-linkedin.svg");
}
.share-links > li > a .text {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
/* --- [ View Modes ] --------------- */
/* --- [ Mixins ] --------------- */
.emboss.entity-impact-box,
.emboss > .region-preface-first-inner {
  margin-top: -67px;
  background: #FFFFFF;
  box-shadow: 0px 8px 10px 0px #d6dbe1;
  margin-bottom: 44px;
  z-index: 3;
  text-align: center;
}
.emboss.region-preface_first-listing_page .region-preface-first-inner {
  margin-top: -28px;
  text-align: center;
}
@media only screen and (max-width: 960px) {
  .emboss.region-preface_first-listing_page .region-preface-first-inner {
    margin-top: 0;
  }
}
.emboss.region-preface_first-event .region-preface-first-inner .title#page-title {
  padding-bottom: 2.2rem;
  letter-spacing: .1rem;
}
.emboss.region-preface_first-event .region-preface-first-inner .field-event-date-display {
  padding-bottom: 1.3rem;
  color: #4d5a6b;
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0.08em;
}
.emboss.region-preface_first-event .region-preface-first-inner .field-event-location {
  padding-bottom: 2.1rem;
  color: #4d5a6b;
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: 0.012em;
  text-transform: none;
  font-weight: 700;
  letter-spacing: 0.08em;
}
.emboss-body {
  box-shadow: 0px 8px 10px 0px #d6dbe1;
  text-align: center;
}
/* --- [ Apply Mixins ] --------------- */
[class*='view-mode'][class*="unpublished"] {
  position: relative !important;
}
[class*='view-mode'][class*="unpublished"]:after {
  content: ' ' !important;
  pointer-events: none !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  background-color: rgba(255, 0, 0, 0.2) !important;
  mix-blend-mode: multiply !important;
  visibility: visible !important;
  height: auto !important;
  display: block !important;
}
.front .view-mode-teaser.node-blog-post {
  background-color: #FFFFFF;
  padding: 2.05rem 1.625rem;
}
@media only screen and (max-width: 480px) {
  .front .view-mode-teaser.node-blog-post {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.front .view-mode-teaser.node-blog-post .group-meta {
  margin-bottom: .2rem;
}
.front .view-mode-teaser.node-blog-post .field[class*="signature"] .entity > .field {
  margin-top: .8rem;
  margin-bottom: 1.2rem;
}
.front .view-mode-teaser.node-blog-post .field[class*="signature"] .entity > .field.field-image-placeholder {
  display: none;
}
.front .view-mode-teaser.node-blog-post .field[class*="signature"] img {
  width: 100%;
  height: auto;
}
.front .view-mode-teaser.node-blog-post .field-title {
  color: #000000;
  line-height: 1.5;
  margin-bottom: 1.6rem;
}
.front .view-mode-teaser.node-blog-post .field-title > * {
  line-height: inherit;
}
.front .view-mode-teaser.node-blog-post .field-title a {
  color: inherit;
}
.front .view-mode-teaser.node-blog-post .field-title a:hover {
  text-decoration: underline;
}
.front .view-mode-teaser.node-blog-post .field-body {
  line-height: 1.35;
}
.front .view-mode-teaser.node-blog-post .field-body ~ * {
  margin-top: 1.3rem;
}
.front .view-mode-teaser.node-blog-post .field-node-link {
  font-size: 16px;
  letter-spacing: .05em;
  text-transform: capitalize;
}
.not-front .view-mode-teaser.node-blog-post {
  background-color: #FFFFFF;
  padding: 2.05rem 1.625rem;
  padding: 2.1rem 2rem;
}
@media only screen and (max-width: 480px) {
  .not-front .view-mode-teaser.node-blog-post {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.not-front .view-mode-teaser.node-blog-post .group-meta {
  margin-bottom: .2rem;
}
.not-front .view-mode-teaser.node-blog-post .field[class*="signature"] .entity > .field {
  margin-top: .8rem;
  margin-bottom: 1.2rem;
}
.not-front .view-mode-teaser.node-blog-post .field[class*="signature"] .entity > .field.field-image-placeholder {
  display: none;
}
.not-front .view-mode-teaser.node-blog-post .field[class*="signature"] img {
  width: 100%;
  height: auto;
}
.not-front .view-mode-teaser.node-blog-post .field-title {
  color: #000000;
  line-height: 1.5;
  margin-bottom: 1.6rem;
}
.not-front .view-mode-teaser.node-blog-post .field-title > * {
  line-height: inherit;
}
.not-front .view-mode-teaser.node-blog-post .field-title a {
  color: inherit;
}
.not-front .view-mode-teaser.node-blog-post .field-title a:hover {
  text-decoration: underline;
}
.not-front .view-mode-teaser.node-blog-post .field-body {
  line-height: 1.35;
}
.not-front .view-mode-teaser.node-blog-post .field-body ~ * {
  margin-top: 1.3rem;
}
.not-front .view-mode-teaser.node-blog-post .field-node-link {
  font-size: 16px;
  letter-spacing: .05em;
  text-transform: capitalize;
}
@media only screen and (max-width: 480px) {
  .not-front .view-mode-teaser.node-blog-post {
    padding: 1.6rem 1.25rem 2.4rem;
  }
}
.not-front .view-mode-teaser.node-blog-post .field-title {
  color: #000000;
  line-height: 1.65;
  margin-bottom: 1.5rem;
}
.not-front .view-mode-teaser.node-blog-post .field-title * {
  line-height: inherit;
}
.not-front .view-mode-teaser.node-blog-post .field-title a {
  color: inherit;
}
.not-front .view-mode-teaser.node-blog-post .field-title a:hover {
  text-decoration: underline;
}
.not-front .view-mode-teaser.node-blog-post .field-body {
  color: #666666;
  line-height: 1.35;
  font-size: 1rem;
}
.not-front .view-mode-teaser.node-blog-post .field-body ~ * {
  margin-top: 1.3rem;
}
.view-mode-teaser.node-news-post > .field {
  padding-bottom: .5rem;
}
.view-mode-teaser.node-news-post > .field .field-contents a .text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.022rem;
}
.view-mode-teaser.node-news-post .group-meta a,
.view-mode-teaser.node-news-post .group-meta .field-contents {
  color: #4a4a4a;
  font-weight: 600;
  letter-spacing: 0.05rem;
}
.view-mode-teaser.node-grantee-profile a,
.view-mode-teaser.node-event a {
  color: #FFFFFF;
}
.view-mode-teaser.node-grantee-profile .background,
.view-mode-teaser.node-event .background {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 2rem;
  position: relative;
}
@media only screen and (max-width: 960px) {
  .view-mode-teaser.node-grantee-profile .background,
  .view-mode-teaser.node-event .background {
    padding: 1rem;
  }
}
a:hover .view-mode-teaser.node-grantee-profile .background,
a:hover .view-mode-teaser.node-event .background {
  text-decoration: none;
}
.view-mode-teaser.node-grantee-profile .background .field-title h2,
.view-mode-teaser.node-event .background .field-title h2 {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  color: #FFFFFF;
  margin-bottom: 0;
}
@media only screen and (max-width: 480px) {
  .view-mode-teaser.node-grantee-profile .background .field-title h2,
  .view-mode-teaser.node-event .background .field-title h2 {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .view-mode-teaser.node-grantee-profile .background .field-title h2,
  .view-mode-teaser.node-event .background .field-title h2 {
    font-size: 1.625rem;
  }
}
.view-mode-teaser.node-grantee-profile .background .field-event-date-display,
.view-mode-teaser.node-event .background .field-event-date-display {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
  text-transform: none;
  margin-top: 0;
}
.view-mode-teaser.node-grantee-profile .background .field-event-date-display:first-child,
.view-mode-teaser.node-event .background .field-event-date-display:first-child {
  margin-top: 0;
}
.view-mode-teaser.node-grantee-profile .background .field-event-date-display .date-display-range,
.view-mode-teaser.node-event .background .field-event-date-display .date-display-range {
  padding-left: 0;
  padding-top: 0;
}
.view-mode-teaser.node-grantee-profile .background .field-event-date-display .date-display-range:before,
.view-mode-teaser.node-event .background .field-event-date-display .date-display-range:before {
  content: none;
}
.view-mode-teaser.node-grantee-profile .background .field,
.view-mode-teaser.node-event .background .field {
  color: #FFFFFF;
  text-align: center;
  z-index: 5;
}
.view-mode-teaser.node-grantee-profile .background:before,
.view-mode-teaser.node-event .background:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: #4d5a6b;
  opacity: .35;
  mix-blend-mode: multiply;
}
.view-mode-teaser.node-database-detail .field-title,
.view-mode-teaser.node-database-detail .field-title * {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  margin-bottom: .55rem;
  font-size: 1.625rem;
}
.view-mode-teaser.node-database-detail .field-title a,
.view-mode-teaser.node-database-detail .field-title * a {
  display: block;
}
.view-mode-teaser.node-database-detail .field-title a,
.view-mode-teaser.node-database-detail .field-title * a,
.view-mode-teaser.node-database-detail .field-title a *,
.view-mode-teaser.node-database-detail .field-title * a * {
  color: #1890fb;
}
.view-mode-teaser.node-database-detail .field-title > * {
  font: inherit;
}
.view-mode-teaser.node-database-detail .field[class*="signature"] img {
  width: 100%;
  height: auto;
}
.view-mode-teaser.node-database-detail .field[class*="signature"] ~ * {
  margin-top: .85rem;
}
.view-mode-teaser.node-database-detail .field-body {
  font-size: 1.375rem;
  line-height: 1.4;
}
.view-mode-teaser.node-database-detail .field-body ~ * {
  margin-top: .85rem;
}
.view-mode-teaser.node-database-detail .group-meta {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
}
.view-mode-teaser.node-database-detail .group-meta .field-contents {
  display: inline;
}
.view-mode-teaser.node-database-detail .group-meta a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.view-mode-teaser.node-database-detail .group-meta a:hover {
  color: #1890fb;
}
.view-mode-teaser.node-database-detail .group-meta.field-date [class*="date-display"] {
  display: inline;
}
.view-mode-teaser.node-database-detail .group-meta ~ * {
  margin-top: .85rem;
}
.view-mode-teaser.node-database-detail .group-meta ~ *.field[class*="signature"] {
  margin-top: 0;
}
.view-mode-teaser.node-database-detail .group-meta ~ *.field[class*="signature"] .entity > * {
  margin-top: .85rem;
}
.view-mode-teaser.node-guide .field-title,
.view-mode-teaser.node-guide .field-title * {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  margin-bottom: .55rem;
  font-size: 1.625rem;
}
.view-mode-teaser.node-guide .field-title a,
.view-mode-teaser.node-guide .field-title * a {
  display: block;
}
.view-mode-teaser.node-guide .field-title a,
.view-mode-teaser.node-guide .field-title * a,
.view-mode-teaser.node-guide .field-title a *,
.view-mode-teaser.node-guide .field-title * a * {
  color: #1890fb;
}
.view-mode-teaser.node-guide .field-title > * {
  font: inherit;
}
.view-mode-teaser.node-guide .field[class*="signature"] img {
  width: 100%;
  height: auto;
}
.view-mode-teaser.node-guide .field[class*="signature"] ~ * {
  margin-top: .85rem;
}
.view-mode-teaser.node-guide .field-body {
  font-size: 1.375rem;
  line-height: 1.4;
}
.view-mode-teaser.node-guide .field-body ~ * {
  margin-top: .85rem;
}
.view-mode-teaser.node-guide .group-meta {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
}
.view-mode-teaser.node-guide .group-meta .field-contents {
  display: inline;
}
.view-mode-teaser.node-guide .group-meta a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.view-mode-teaser.node-guide .group-meta a:hover {
  color: #1890fb;
}
.view-mode-teaser.node-guide .group-meta.field-date [class*="date-display"] {
  display: inline;
}
.view-mode-teaser.node-guide .group-meta ~ * {
  margin-top: .85rem;
}
.view-mode-teaser.node-guide .group-meta ~ *.field[class*="signature"] {
  margin-top: 0;
}
.view-mode-teaser.node-guide .group-meta ~ *.field[class*="signature"] .entity > * {
  margin-top: .85rem;
}
.view-mode-teaser.node-person-profile .ds-media {
  border: 4px solid #E6E6E6;
  width: 290px;
  display: flex;
  flex-direction: column;
}
.view-mode-teaser.node-person-profile .ds-media .ds-media-thumb {
  margin: -4px;
  width: inherit;
}
.view-mode-teaser.node-person-profile .ds-media .ds-media-thumb .field-profile-image img {
  height: 290px;
  width: 290px;
}
.view-mode-teaser.node-person-profile .ds-media .ds-media-text {
  padding: 2.1rem 2.2rem;
  width: inherit;
}
.view-mode-teaser.node-person-profile .ds-media .ds-media-text .field-title h3 {
  margin-bottom: .4rem;
}
.view-mode-teaser.node-person-profile .ds-media .ds-media-text .field-title h3 a {
  color: #4d5a6b;
}
.view-mode-teaser.node-person-profile .ds-media .ds-media-text .field-position {
  margin-bottom: 1.4rem;
}
.view-mode-teaser.node-person-profile .ds-media .ds-media-text .field-position .field-contents {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
  font-weight: 600;
  color: #4d5a6b;
  text-transform: none;
}
.view-mode-teaser.node-person-profile .ds-media .ds-media-text .field-position .field-contents:first-child {
  margin-top: 0;
}
.view-mode-teaser.node-person-profile .ds-media .ds-media-text .field-organization .field-contents {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
  font-weight: 400;
  color: #4d5a6b;
  text-transform: none;
}
.view-mode-teaser.node-person-profile .ds-media .ds-media-text .field-organization .field-contents:first-child {
  margin-top: 0;
}
@media only screen and (max-width: 480px) {
  .view-mode-teaser.node-person-profile .ds-media .ds-media-text {
    padding: 1rem 0.6rem;
  }
  .view-mode-teaser.node-person-profile .ds-media .ds-media-text .field-title h3 {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 960px) {
  .view-mode-full.node-page {
    margin: 0 auto;
  }
}
.view-mode-full.node-event .group-inset,
.view-mode-full.node-page .group-inset,
.view-mode-full.node-news-post .group-inset {
  float: left;
  margin-right: 2rem;
  margin-bottom: 2rem;
  max-width: 280px;
}
@media only screen and (max-width: 480px) {
  .view-mode-full.node-event .group-inset,
  .view-mode-full.node-page .group-inset,
  .view-mode-full.node-news-post .group-inset {
    float: none;
    max-width: none;
    width: 100%;
  }
}
@media only screen and (max-width: 960px) {
  .view-mode-full.node-event .ds-highlights .field {
    width: 100%;
    min-width: 100%;
    padding-right: 0;
    flex-basis: 100%;
  }
}
.view-mode-full.node-webform {
  position: relative;
  /* --- [ Donation specific ] --------------- */
}
.view-mode-full.node-webform .messages {
  margin: -2.1rem -2rem;
  margin-bottom: 2rem;
}
.view-mode-full.node-webform .fieldset-wrapper {
  font-size: 0;
}
.view-mode-full.node-webform .fieldset-wrapper > .form-item {
  margin: 0;
  margin-top: 1rem;
}
.view-mode-full.node-webform .fieldset-wrapper > .form-item[class*="conditional"] + .form-item[style*="display"],
.view-mode-full.node-webform .fieldset-wrapper > .form-item[class*="conditional"] + .form-wrapper[style*="display"] {
  margin-top: -0.5rem;
  margin-bottom: 2rem;
}
.view-mode-full.node-webform .fieldset-wrapper > .form-item[class*="conditional"] + .form-wrapper[style*="display"] .fieldset-wrapper > .form-item:first-child {
  margin-top: 0;
}
.view-mode-full.node-webform .form-radios[id*="gift-amount"] {
  font-size: 0;
  margin: 0;
  margin-top: 1rem;
}
@media only screen and (max-width: 960px) {
  .view-mode-full.node-webform .form-radios[id*="gift-amount"] {
    margin: 1rem -0.5rem 0;
  }
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] {
  display: inline-block;
  vertical-align: middle;
  margin-right: .5rem;
}
@media only screen and (max-width: 960px) {
  .view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] {
    margin-right: 0;
    width: 50%;
    padding: 0 .5rem;
  }
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input + label {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  display: block;
  cursor: pointer;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input + label:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input + label:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input + label:focus {
  outline: none;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input + label:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input + label:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input + label.admin,
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input + label.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input + label.admin-extra {
  color: #1890fb;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input + label:before {
  content: none;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input:checked + label {
  background-color: #d9a70f;
  display: block;
  cursor: default;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input:checked + label:hover {
  background-color: #DC8802;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input:checked + label:active {
  background-color: #DC5502;
}
.view-mode-full.node-webform .form-item[class*="submitted"][class*="gift-amount"] input:checked + label:hover {
  background-color: #4d5a6b;
  border-color: #4d5a6b;
}
.view-mode-full.node-webform .form-item[class*="display-name"] {
  margin-bottom: 2.5rem;
}
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="first-name"],
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="last-name"],
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="email-address"],
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="street-address"],
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="city"],
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="state"],
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="phone"] {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
@media only screen and (max-width: 960px) {
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="first-name"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="last-name"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="email-address"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="street-address"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="city"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="state"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="phone"] {
    display: inline-block;
    vertical-align: top;
    width: 60%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width: 480px) {
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="first-name"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="last-name"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="email-address"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="street-address"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="city"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="state"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="phone"] {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
}
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="middle-initial"],
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="suffix"],
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="zip"] {
  display: inline-block;
  vertical-align: top;
  width: 20%;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
@media only screen and (max-width: 700px) {
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="middle-initial"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="suffix"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="zip"] {
    display: inline-block;
    vertical-align: top;
    width: 40%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width: 480px) {
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="middle-initial"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="suffix"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="zip"] {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
}
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="email-address"],
.view-mode-full.node-webform .form-item[class*="donor-information"][class*="city"] {
  display: block;
}
@media only screen and (min-width: 481px) {
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="first-name"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="last-name"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="email-address"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="street-address"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="city"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="state"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="phone"] {
    padding-right: 0.75rem;
  }
}
@media only screen and (min-width: 481px) {
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="middle-initial"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="suffix"],
  .view-mode-full.node-webform .form-item[class*="donor-information"][class*="zip"] {
    padding-left: 0.75rem;
  }
}
.view-mode-full.node-webform #edit-submitted[style*="display"][style*="none"] ~ .form-actions {
  margin-top: -0.5rem;
  padding-top: 0;
}
.view-mode-full.node-listing-page {
  position: relative;
}
.view-mode-full.node-listing-page .section-heading {
  border-bottom: 4px solid #E6E6E6;
  padding-bottom: 0.8125rem;
  letter-spacing: 0.19rem;
}
.view-mode-full.node-listing-page > .field-body {
  font-size: 1.375rem;
  line-height: 1.5;
  color: #666666;
  margin-bottom: 2.25rem;
}
.view-mode-full.node-listing-page .view-output {
  padding-bottom: 2rem;
}
.view-mode-full.node-event .section-heading {
  border-bottom: 4px solid #E6E6E6;
  padding-bottom: 0.8125rem;
  margin-bottom: 3.125rem;
}
.view-mode-full.node-event .group-left {
  width: 72.5%;
  display: inline-block;
  padding-right: 1.25rem;
}
@media only screen and (max-width: 960px) {
  .view-mode-full.node-event .group-left {
    width: 100%;
    padding-right: 0;
  }
}
.view-mode-full.node-event .group-right {
  width: 27%;
  display: inline-block;
  vertical-align: top;
}
@media only screen and (max-width: 960px) {
  .view-mode-full.node-event .group-right {
    width: 100%;
  }
}
.view-mode-full.node-event .group-inset .field-event-links .field-contents {
  width: 286px;
  list-style: none;
  padding: 0;
  border: 4px solid #E6E6E6;
}
@media only screen and (max-width: 480px) {
  .view-mode-full.node-event .group-inset .field-event-links .field-contents {
    width: auto;
  }
}
.view-mode-full.node-event .group-inset .field-event-links .field-contents li {
  margin: 0;
}
.view-mode-full.node-event .group-inset .field-event-links .field-contents li:before {
  content: none;
}
.view-mode-full.node-event .group-inset .field-event-links .field-contents .parent {
  padding: 30px 24px 35px;
}
@media only screen and (max-width: 480px) {
  .view-mode-full.node-event .group-inset .field-event-links .field-contents .parent {
    padding: 20px 20px 23px;
  }
}
.view-mode-full.node-event .group-inset .field-event-links .field-contents .children,
.view-mode-full.node-event .group-inset .field-event-links .field-contents li {
  padding: 25px;
  border-bottom: 4px solid #E6E6E6;
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  position: relative;
  padding-right: 1.5rem;
}
.view-mode-full.node-event .group-inset .field-event-links .field-contents .children:after,
.view-mode-full.node-event .group-inset .field-event-links .field-contents li:after {
  content: ' ';
  display: inline-block;
  background-image: url("../images/icons/link--arrow-blue.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.view-mode-full.node-event .group-inset .field-event-links .field-contents .children:after,
.view-mode-full.node-event .group-inset .field-event-links .field-contents li:after {
  right: 24px;
}
.view-mode-full.node-event .group-inset .field-event-links .field-contents .children:last-child,
.view-mode-full.node-event .group-inset .field-event-links .field-contents li:last-child {
  border-bottom: none;
}
.view-mode-full.entity-sponsor .field-sponsor-image {
  border: 2px solid #E6E6E6;
  border-bottom: none;
  min-height: 250px;
  display: flex;
  align-items: center;
}
.view-mode-full.entity-sponsor .field-sponsor-image .field-contents {
  margin: auto;
}
.view-mode-full.entity-sponsor .field-sponsor-image .field-contents img {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.view-mode-full.entity-sponsor .group-text {
  padding: 1rem;
  border: 1px solid #E6E6E6;
  width: inherit;
}
.view-mode-full.entity-sponsor .group-text .field-sponsor-name,
.view-mode-full.entity-sponsor .group-text .field-sponsor-level,
.view-mode-full.entity-sponsor .group-text .field-organization .field-contents {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
  text-align: center;
  font-weight: 400;
  color: #4d5a6b;
  text-transform: none;
}
.view-mode-full.entity-sponsor .group-text .field-sponsor-name:first-child,
.view-mode-full.entity-sponsor .group-text .field-sponsor-level:first-child,
.view-mode-full.entity-sponsor .group-text .field-organization .field-contents:first-child {
  margin-top: 0;
}
.view-mode-default.bean-visitor-info > * ~ * {
  margin-top: 1.7rem;
}
@media only screen and (max-width: 480px) {
  .view-mode-default.bean-visitor-info > * ~ * {
    margin-top: 1.6rem;
  }
}
.view-mode-default.bean-visitor-info > .field-links {
  border-top: 2px solid #D7D7D7;
  padding-top: 1.75rem;
  margin-top: 2.45rem;
}
.view-mode-default.bean-visitor-info .field-links a {
  padding-right: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .05em;
  text-transform: uppercase;
  color: #1890fb;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.view-mode-default.bean-visitor-info .field-links a:after {
  content: none;
}
.view-mode-default.bean-visitor-info .field-links a:hover {
  text-decoration: underline;
}
.view-mode-default.bean-visitor-info .field-links a.active {
  text-decoration: underline;
  color: #1890fb;
}
.view-mode-default.bean-visitor-info .field-links a ~ a {
  margin-top: 1.1rem;
}
@media only screen and (min-width: 581px) and (max-width: 960px) {
  .view-mode-default.bean-visitor-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .view-mode-default.bean-visitor-info:before,
  .view-mode-default.bean-visitor-info:after {
    display: none;
  }
  .view-mode-default.bean-visitor-info > * {
    flex: 0 1 auto;
    margin: 0;
  }
  .view-mode-default.bean-visitor-info > *:nth-child(2),
  .view-mode-default.bean-visitor-info > *.group-tel {
    order: 3;
  }
  .view-mode-default.bean-visitor-info > *:nth-child(3),
  .view-mode-default.bean-visitor-info > *.field-hours {
    order: 2;
  }
  .view-mode-default.bean-visitor-info > *:last-child,
  .view-mode-default.bean-visitor-info > *.field-links {
    order: 100;
    flex-basis: 100%;
    margin-top: 1.8rem;
    margin-bottom: 0;
  }
  .view-mode-default.bean-visitor-info > .field-links {
    border: none;
    padding-top: 0;
  }
  .view-mode-default.bean-visitor-info .field-links {
    text-align: center;
  }
  .view-mode-default.bean-visitor-info .field-links a {
    padding-right: 0;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #1890fb;
    display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
  }
  .view-mode-default.bean-visitor-info .field-links a:after {
    content: none;
  }
  .view-mode-default.bean-visitor-info .field-links a:hover {
    text-decoration: underline;
  }
  .view-mode-default.bean-visitor-info .field-links a.active {
    text-decoration: underline;
    color: #1890fb;
  }
  .view-mode-default.bean-visitor-info .field-links a ~ a {
    margin: 0;
  }
  .view-mode-default.bean-visitor-info .field-links a ~ a:before {
    content: "\00a0|\00a0";
    color: #666666;
  }
}
.view-mode-default.bean-contact-us .field-contact-details {
  margin-top: .5rem;
}
.view-mode-default.bean-contact-us .field-contact-details > * ~ * {
  margin-top: 1.7rem;
}
@media only screen and (max-width: 480px) {
  .view-mode-default.bean-contact-us .field-contact-details > * ~ * {
    margin-top: 1.6rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .view-mode-default.bean-contact-us .field-contact-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .view-mode-default.bean-contact-us .field-contact-details:before,
  .view-mode-default.bean-contact-us .field-contact-details:after {
    display: none;
  }
  .view-mode-default.bean-contact-us .field-contact-details > * {
    flex: 0 1 auto;
    margin: 0;
    margin-bottom: 1.5rem;
  }
  .view-mode-default.bean-contact-us .field-contact-details > .field-links {
    border: none;
    padding-top: 0;
  }
  .view-mode-default.bean-contact-us .field-contact-details .field-links {
    text-align: center;
  }
  .view-mode-default.bean-contact-us .field-contact-details .field-links a {
    padding-right: 0;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #1890fb;
    display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
  }
  .view-mode-default.bean-contact-us .field-contact-details .field-links a:after {
    content: none;
  }
  .view-mode-default.bean-contact-us .field-contact-details .field-links a:hover {
    text-decoration: underline;
  }
  .view-mode-default.bean-contact-us .field-contact-details .field-links a.active {
    text-decoration: underline;
    color: #1890fb;
  }
  .view-mode-default.bean-contact-us .field-contact-details .field-links a ~ a {
    margin: 0;
  }
  .view-mode-default.bean-contact-us .field-contact-details .field-links a ~ a:before {
    content: "\00a0|\00a0";
    color: #666666;
  }
}
.view-mode-featured.node-event {
  padding-top: .8rem;
}
.view-mode-featured.node-event .ds-media {
  background-color: #1890fb;
  display: flex;
}
.view-mode-featured.node-event .ds-media .ds-media-thumb {
  width: 50%;
  display: block;
}
.view-mode-featured.node-event .ds-media .ds-media-thumb .field-banner-image {
  min-height: 275px;
  height: 100%;
  background-position: center;
  background-size: cover;
}
@media only screen and (min-width: 961px) {
  .view-mode-featured.node-event .ds-media .ds-media-thumb .field-banner-image {
    min-height: 400px;
  }
}
.view-mode-featured.node-event .ds-media .ds-media-thumb .field-banner-image img {
  display: none;
}
.view-mode-featured.node-event .ds-media .ds-media-thumb .field-banner-image .field-items {
  height: 100%;
  width: 100%;
}
.view-mode-featured.node-event .ds-media .ds-media-thumb .field-banner-image .field-items .field-item {
  height: 100%;
  width: 100%;
}
.view-mode-featured.node-event .ds-media .ds-media-thumb .field-banner-image .field-items .field-item a {
  display: block;
  height: 100%;
  width: 100%;
  min-height: 275px;
}
.view-mode-featured.node-event .ds-media .ds-media-text {
  width: 50%;
  padding: 2rem;
  padding-top: 1.7rem;
  padding-bottom: 3.4rem;
  color: #FFFFFF;
}
.view-mode-featured.node-event .ds-media .ds-media-text .meta,
.view-mode-featured.node-event .ds-media .ds-media-text a:not(.button-event) {
  color: #FFFFFF;
}
.view-mode-featured.node-event .ds-media .ds-media-text .field:first-child {
  padding-bottom: 1.3rem;
  text-transform: uppercase;
  font-weight: bold;
}
.view-mode-featured.node-event .ds-media .ds-media-text .field-title {
  padding-bottom: 0.3rem;
}
.view-mode-featured.node-event .ds-media .ds-media-text .field-title .text {
  letter-spacing: .165rem;
}
.view-mode-featured.node-event .ds-media .ds-media-text .field-title h2 {
  margin-bottom: 0;
}
.view-mode-featured.node-event .ds-media .ds-media-text .field-event-date-display {
  padding-bottom: 1rem;
  padding-left: 0;
  padding-top: 0;
}
.view-mode-featured.node-event .ds-media .ds-media-text .field-event-date-display::before {
  content: none;
}
.view-mode-featured.node-event .ds-media .ds-media-text .field-body {
  padding-bottom: 2rem;
}
@media only screen and (max-width: 960px) {
  .view-mode-featured.node-event .ds-media {
    flex-direction: column;
  }
  .view-mode-featured.node-event .ds-media .ds-media-thumb {
    width: 100%;
  }
  .view-mode-featured.node-event .ds-media .ds-media-text {
    width: 100%;
    padding-left: 2.7rem;
    padding-right: 2.7rem;
    padding-bottom: 2.7rem;
  }
}
@media only screen and (max-width: 480px) {
  .view-mode-featured.node-event .ds-media {
    margin-left: -0.934rem;
    margin-right: -0.934rem;
  }
  .view-mode-featured.node-event .ds-media .ds-media-text {
    text-align: center;
    padding: 1.1rem;
    padding-bottom: 2rem;
  }
  .view-mode-featured.node-event .ds-media .ds-media-text .field:first-child {
    padding-bottom: .6rem;
  }
}
/* --- [ Site Status ] --------------- */
.site-status,
[id="site-status"] {
  text-align: center;
  font-size: 1.375rem;
  font-weight: normal;
}
.site-status a,
[id="site-status"] a {
  color: #FFFFFF;
  text-decoration: none;
}
.site-status a .text,
[id="site-status"] a .text {
  border-bottom: 1px dotted #FFFFFF;
}
.site-status a:hover,
[id="site-status"] a:hover {
  text-decoration-style: solid;
}
.site-status a:hover .text,
[id="site-status"] a:hover .text {
  border-bottom: 1px solid #FFFFFF;
}
.hasEmergencyBanner {
  position: relative;
}
.hasEmergencyBanner.isClosed {
  display: none;
}
.hasEmergencyBanner .bannerClose {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  border: none;
  display: flex;
  align-content: center;
  border-bottom-color: transparent;
}
.hasEmergencyBanner .bannerClose .text {
  display: inline-block !important;
  vertical-align: middle;
  width: 20px;
  height: 4px;
  background: #FFFFFF;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  text-indent: -9999px;
  transition: background-color 150ms ease;
}
.hasEmergencyBanner .bannerClose .text:before,
.hasEmergencyBanner .bannerClose .text:after {
  display: inline-block;
  width: 100%;
  height: 4px;
  background: #FFFFFF;
  position: absolute;
  left: 0;
  content: '';
  transform-origin: center;
  transition: transform 150ms ease, top 150ms ease, color 150ms ease;
}
.hasEmergencyBanner .bannerClose .text:before {
  top: 8px;
}
.hasEmergencyBanner .bannerClose .text:after {
  top: -8px;
}
.hasEmergencyBanner .bannerClose .text {
  background-color: transparent;
  box-shadow: none;
}
.hasEmergencyBanner .bannerClose .text:before,
.hasEmergencyBanner .bannerClose .text:after {
  opacity: 1;
  box-shadow: none;
  height: 4px;
}
.hasEmergencyBanner .bannerClose .text:before {
  top: 0;
  transform: rotate(-45deg);
}
.hasEmergencyBanner .bannerClose .text:after {
  top: 0;
  transform: rotate(45deg);
}
/* --- [ Cookie Banner ] --------------- */
#sliding-popup {
  padding: 1em 0;
}
#sliding-popup .popup-content #popup-text p {
  font-size: 14px;
}
#sliding-popup .popup-content #popup-text a {
  color: #FFFFFF;
  text-decoration: underline;
}
#sliding-popup .popup-content #popup-buttons .agree-button {
  background: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 100px;
  box-shadow: none;
  color: #FFFFFF;
  font-weight: 700;
  margin-top: 20px;
  text-shadow: none;
}
#sliding-popup .popup-content #popup-buttons .agree-button:hover,
#sliding-popup .popup-content #popup-buttons .agree-button:focus {
  background: #FFFFFF;
  color: #1890fb;
}
/* --- [ Mixins ] --------------- */
.plain-block {
  font-size: 1.375rem;
  line-height: 1.5;
  padding-top: 1.8rem;
  padding-bottom: 2.25rem;
  color: #666666;
}
.plain-block:last-child {
  padding-bottom: 0;
}
@media only screen and (min-width: 961px) {
  .plain-block:first-child {
    padding-top: 0;
  }
}
.front .plain-block {
  line-height: 1.4;
}
.region-sidebar-second .plain-block {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .plain-block {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .plain-block {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  }
  .plain-block:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
  }
}
.plain-block .block-title,
.plain-block > .field > .field-label,
.plain-block .wysiwyg-heading {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .plain-block .block-title,
  .plain-block > .field > .field-label,
  .plain-block .wysiwyg-heading {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .plain-block .block-title,
  .plain-block > .field > .field-label,
  .plain-block .wysiwyg-heading {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (min-width: 481px) {
  .plain-block .block-title,
  .plain-block > .field > .field-label,
  .plain-block .wysiwyg-heading {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .plain-block .block-title,
  .plain-block > .field > .field-label,
  .plain-block .wysiwyg-heading {
    margin-bottom: 1.3rem;
  }
}
@media only screen and (max-width: 480px) {
  .plain-block .block-title,
  .plain-block > .field > .field-label,
  .plain-block .wysiwyg-heading {
    color: #666666;
    margin-bottom: .75rem;
  }
}
.plain-block--quiet {
  padding-top: 1.8rem;
  padding-bottom: 2.25rem;
  color: #666666;
  line-height: 1.45;
}
.plain-block--quiet:last-child {
  padding-bottom: 0;
}
.plain-block--quiet > .content {
  font-size: 16px;
}
@media only screen and (min-width: 961px) {
  .plain-block--quiet:first-child {
    padding-top: 0;
  }
}
@media only screen and (max-width: 960px) {
  .plain-block--quiet {
    margin: 0 auto;
  }
  .front .plain-block--quiet {
    max-width: 550px;
  }
}
.front .region-sidebar-second .plain-block--quiet {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .plain-block--quiet {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .plain-block--quiet {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  }
  .region-sidebar-second .plain-block--quiet {
    padding: 2.05rem 1.2rem 1.8rem;
  }
  .region-sidebar-second .plain-block--quiet:last-child {
    padding-bottom: 0;
  }
}
.plain-block--quiet .block-title {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .plain-block--quiet .block-title {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .plain-block--quiet .block-title {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (min-width: 481px) {
  .plain-block--quiet .block-title {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .plain-block--quiet .block-title {
    margin-bottom: 2.8rem;
  }
}
@media only screen and (max-width: 480px) {
  .plain-block--quiet .block-title {
    color: #1890fb;
    margin-bottom: 1.15rem;
  }
}
.plain-block--quiet .field-label,
.plain-block--quiet .label-inline,
.plain-block--quiet .label-above {
  font-size: 1rem;
  margin-bottom: 0;
}
.plain-block--quiet .field-label + *,
.plain-block--quiet .label-inline + *,
.plain-block--quiet .label-above + * {
  margin-top: 0;
}
.plain-block--quiet .label-above {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}
.plain-block--quiet .label-inline {
  font-weight: normal;
}
.sub-block {
  font-size: 1.375rem;
  line-height: 1.5;
  padding-top: 1.8rem;
  padding-bottom: 2.25rem;
  color: #666666;
}
.sub-block:last-child {
  padding-bottom: 0;
}
@media only screen and (min-width: 961px) {
  .sub-block:first-child {
    padding-top: 0;
  }
}
.front .sub-block {
  line-height: 1.4;
}
.region-sidebar-second .sub-block {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .sub-block {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .sub-block {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  }
  .sub-block:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
  }
}
.sub-block .block-title,
.sub-block > .field > .field-label,
.sub-block .wysiwyg-heading {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .sub-block .block-title,
  .sub-block > .field > .field-label,
  .sub-block .wysiwyg-heading {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .sub-block .block-title,
  .sub-block > .field > .field-label,
  .sub-block .wysiwyg-heading {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (min-width: 481px) {
  .sub-block .block-title,
  .sub-block > .field > .field-label,
  .sub-block .wysiwyg-heading {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .sub-block .block-title,
  .sub-block > .field > .field-label,
  .sub-block .wysiwyg-heading {
    margin-bottom: 1.3rem;
  }
}
@media only screen and (max-width: 480px) {
  .sub-block .block-title,
  .sub-block > .field > .field-label,
  .sub-block .wysiwyg-heading {
    color: #666666;
    margin-bottom: .75rem;
  }
}
@media only screen and (max-width: 480px) {
  .sub-block {
    margin-top: 1.65rem;
  }
}
.sub-block .block-title {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  margin-bottom: 1.25rem;
}
@media only screen and (max-width: 480px) {
  .sub-block .block-title {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 960px) {
  .sub-block .block-title {
    text-align: initial;
  }
}
.sub-block .item-list ul {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  padding: 0;
  list-style: none;
}
.sub-block .item-list ul .field-contents {
  display: inline;
}
.sub-block .item-list ul a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sub-block .item-list ul a:hover {
  color: #1890fb;
}
.sub-block .item-list ul.field-date [class*="date-display"] {
  display: inline;
}
.sub-block .item-list ul > * {
  display: inline-block;
  position: relative;
  margin: 0;
  margin-right: .4rem;
}
.sub-block .item-list ul > *:before {
  content: none;
}
.sub-block .item-list ul > *:first-child:not(:last-child):after {
  content: '\2022';
  color: inherit;
  margin-left: 0.4rem;
  display: inline;
  float: none;
  font-size: inherit;
  position: static;
}
.sub-block .item-list ul > *:first-child:not(:last-child) ~ *:not(:last-child):after {
  content: '\2022';
  color: inherit;
  margin-left: 0.4rem;
  display: inline;
  float: none;
  font-size: inherit;
  position: static;
}
.system-block {
  background-color: #FFFFFF;
  padding: 1.2rem 1.75rem 1.975rem;
}
@media only screen and (max-width: 960px) {
  .system-block {
    padding: 1.15rem 1.7rem 1.85rem;
  }
}
@media only screen and (max-width: 480px) {
  .system-block {
    padding: 1.05rem 1.25rem 1.55rem;
  }
}
.system-block .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  margin-bottom: 1.4rem;
}
@media only screen and (max-width: 480px) {
  .system-block .block-title {
    font-size: 1.25rem;
  }
}
.zone-footer .system-block .block-title,
.ds-media-aside .system-block .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer .system-block .block-title,
  .ds-media-aside .system-block .block-title {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 960px) {
  .system-block .block-title {
    text-align: initial;
    color: #4d5a6b;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.154;
    letter-spacing: 0.006em;
  }
}
@media only screen and (max-width: 960px) and only screen and (max-width: 480px) {
  .system-block .block-title {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .system-block .block-title {
    text-align: initial;
    margin-bottom: 1.4rem;
  }
}
.system-block .form-item {
  margin-bottom: .55rem;
}
@media only screen and (max-width: 480px) {
  .system-block .form-item {
    margin-bottom: .4rem;
  }
}
.system-block .form-item ~ .form-item {
  margin-top: 1.35rem;
}
@media only screen and (max-width: 480px) {
  .system-block .form-item ~ .form-item {
    margin-top: .8rem;
  }
}
.system-block .form-actions {
  margin-top: 1.35rem;
  text-align: center;
}
@media only screen and (max-width: 960px) {
  .system-block .form-actions {
    margin-top: 1.3rem;
  }
}
@media only screen and (max-width: 480px) {
  .system-block .form-actions {
    margin-top: 1.1rem;
  }
}
.system-block .form-actions .form-submit {
  border: 3px solid;
  padding: 0.5rem 1em .45rem;
  width: 86%;
  background-color: #d9a70f;
}
.system-block .form-actions .form-submit:hover {
  background-color: #DC8802;
}
.system-block .form-actions .form-submit:active {
  background-color: #DC5502;
}
@media only screen and (max-width: 960px) {
  .system-block .form-actions .form-submit {
    font-size: 16px;
    padding: 0.4rem 1em .5rem;
    background-color: #d9a70f;
    border-color: #d9a70f;
    color: #FFFFFF;
    border-width: 3px;
    margin-top: 0;
  }
  .system-block .form-actions .form-submit:hover {
    background-color: transparent;
    color: #d9a70f;
  }
}
@media only screen and (max-width: 480px) {
  .system-block .form-actions .form-submit {
    font-size: 0.8125rem;
    padding: 0.5rem 1em .5rem;
    width: calc(100% - 8px);
    display: block;
    margin: 0 4px;
  }
}
.system-block label:not(.option) {
  display: none;
}
.system-block input[type="text"],
.system-block input[type="password"],
.system-block input[type="email"] {
  border-width: 3px;
  padding: .5rem .85rem;
}
.system-block .item-list ul {
  list-style: none;
  padding: 0;
  font-size: 0.8125rem;
  padding: 0 .15rem;
}
.system-block .item-list ul li {
  margin: 0;
}
.system-block .item-list ul li:before {
  content: none;
}
.callout-block {
  margin-bottom: 1rem;
  border: 4px solid #E6E6E6;
}
.callout-block .block-title {
  padding: 1rem;
  color: #4d5a6b;
  text-align: center;
  border-bottom: 4px solid #E6E6E6;
}
.callout-block .content {
  padding: 1.188rem 1.375rem 1.875rem;
}
.front .region-sidebar-second .block {
  margin-bottom: 1rem;
  border: 4px solid #E6E6E6;
}
.front .region-sidebar-second .block .block-title {
  padding: 1rem;
  color: #4d5a6b;
  text-align: center;
  border-bottom: 4px solid #E6E6E6;
}
.front .region-sidebar-second .block .content {
  padding: 1.188rem 1.375rem 1.875rem;
}
.block-dc-ajax-add-cart {
  margin-bottom: 1rem;
  border: 4px solid #E6E6E6;
  position: sticky;
  top: 10px;
  min-width: 270px;
}
.block-dc-ajax-add-cart .block-title {
  padding: 1rem;
  color: #4d5a6b;
  text-align: center;
  border-bottom: 4px solid #E6E6E6;
}
.block-dc-ajax-add-cart .content {
  padding: 1.188rem 1.375rem 1.875rem;
}
.block-dc-ajax-add-cart .block-title {
  text-align: left;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-edit-link {
  position: absolute;
  right: 1.375rem;
  top: 1.375rem;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper {
  width: 100%;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .remove-from-cart .text {
  padding-right: 0.75rem;
  display: inline-block !important;
  vertical-align: middle;
  width: 10px;
  height: 4px;
  background: #FFFFFF;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  text-indent: -9999px;
  transition: background-color 150ms ease;
  text-align: center;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .remove-from-cart .text:after {
  content: ' ';
  display: inline-block;
  background-image: url("../images/icons/small-x--red.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  width: 0.5625rem;
  height: 0.5625rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-table {
  border-collapse: collapse;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-table .ajax-cart-row td {
  border-bottom: 2px solid #E6E6E6;
  padding: .5rem;
  padding-left: 0;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
  margin-top: 0;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-table .ajax-cart-row td:first-child {
  margin-top: 0;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-table .ajax-cart-row td:last-child {
  text-align: right;
  padding-right: 0;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-table .ajax-cart-row:last-child td {
  border-bottom: 4px solid #E6E6E6;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-more-info {
  text-align: center;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-more-info div:last-child {
  padding-bottom: 0;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-total {
  float: none;
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  width: 100%;
  padding: 1.5rem 0;
}
@media only screen and (max-width: 480px) {
  .block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-total {
    font-size: 1.25rem;
  }
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout {
  clear: left;
  float: none;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout a {
  background-color: #d9a70f;
  width: 100%;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout a:hover {
  background-color: #DC8802;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout a:active {
  background-color: #DC5502;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout a,
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout .text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  text-decoration: none;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout a:hover,
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout .text:hover {
  color: #FFFFFF;
  text-decoration: none;
}
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout a:active,
.block-dc-ajax-add-cart .content .ajax-shopping-cart-wrapper .ajax-shopping-cart-checkout .text:active {
  color: #FFFFFF;
  transition: none;
  text-decoration: none;
}
@media only screen and (max-width: 960px) {
  .block-dc-ajax-add-cart {
    margin-top: 3rem;
  }
}
/* --- [ Plain Blocks ] --------------- */
.white-block {
  font-size: 1.375rem;
  line-height: 1.5;
  padding: 1.8rem 2.1rem 2.25rem;
  color: #666666;
  background-color: #FFFFFF;
}
.region-sidebar-second .white-block {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .white-block {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .white-block {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  }
}
.white-block .block-title,
.white-block > .field > .field-label,
.white-block .wysiwyg-heading {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .white-block .block-title,
  .white-block > .field > .field-label,
  .white-block .wysiwyg-heading {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .white-block .block-title,
  .white-block > .field > .field-label,
  .white-block .wysiwyg-heading {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (min-width: 481px) {
  .white-block .block-title,
  .white-block > .field > .field-label,
  .white-block .wysiwyg-heading {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .white-block .block-title,
  .white-block > .field > .field-label,
  .white-block .wysiwyg-heading {
    margin-bottom: 1.3rem;
  }
}
@media only screen and (max-width: 480px) {
  .white-block .block-title,
  .white-block > .field > .field-label,
  .white-block .wysiwyg-heading {
    color: #666666;
    margin-bottom: .75rem;
  }
}
.region-sidebar-second .block-menu-block .menu-block-wrapper {
  background-color: #FFFFFF;
  position: relative;
}
@media only screen and (max-width: 960px) {
  .region-sidebar-second .block-menu-block .menu-block-wrapper {
    margin: 0;
    width: auto;
    min-width: 100%;
  }
}
.region-sidebar-second .block-menu-block .menu-block-wrapper .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  padding-bottom: .625rem;
  border-bottom: 2px solid #D7D7D7;
  margin-bottom: 1.1875rem;
  margin-bottom: .885rem;
  padding-bottom: .725rem;
  display: block;
  position: relative;
}
@media only screen and (max-width: 480px) {
  .region-sidebar-second .block-menu-block .menu-block-wrapper .block-title {
    font-size: 1.25rem;
  }
}
.zone-footer .region-sidebar-second .block-menu-block .menu-block-wrapper .block-title,
.ds-media-aside .region-sidebar-second .block-menu-block .menu-block-wrapper .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer .region-sidebar-second .block-menu-block .menu-block-wrapper .block-title,
  .ds-media-aside .region-sidebar-second .block-menu-block .menu-block-wrapper .block-title {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .region-sidebar-second .block-menu-block .menu-block-wrapper .block-title {
    margin-bottom: 1rem;
  }
}
.region-sidebar-second .block-menu-block .menu-block-wrapper .block-title a {
  color: #666666;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper .block-title a:hover {
  color: #1890fb;
}
@media only screen and (max-width: 960px) {
  .region-sidebar-second .block-menu-block .menu-block-wrapper .block-title.containsToggle {
    border: none;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .region-sidebar-second .block-menu-block .menu-block-wrapper .menu-block-wrapper {
    position: relative;
  }
  .region-sidebar-second .block-menu-block .menu-block-wrapper .menu-block-wrapper:before,
  .region-sidebar-second .block-menu-block .menu-block-wrapper .menu-block-wrapper:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0.6375rem;
    right: auto;
    bottom: 0.6375rem;
    left: auto;
    margin-left: -1px;
    border-left: 2px solid #D7D7D7;
  }
  .region-sidebar-second .block-menu-block .menu-block-wrapper .menu-block-wrapper:before {
    left: 0;
  }
  .region-sidebar-second .block-menu-block .menu-block-wrapper .menu-block-wrapper:after {
    left: auto;
    right: 50%;
  }
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu {
  list-style: none;
  padding: 0;
  margin-bottom: 1.125rem;
  max-width: 350px;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu li {
  margin: 0;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu li:before {
  content: none;
}
@media only screen and (max-width: 960px) {
  .region-sidebar-second .block-menu-block .menu-block-wrapper > .menu {
    max-width: 100%;
  }
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li {
  margin-bottom: 1.125rem;
  background: #1374CA;
  text-align: left;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li.active-trail {
  background-color: #4d5a6b;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li.active .tlAccSwitchWrap > a .text,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li.active > a .text {
  text-decoration: underline;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > div > a,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > a {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  letter-spacing: 0.04rem;
  color: #FFFFFF;
  font-weight: 400;
  display: block;
  position: relative;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > div > a,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > a,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > div > a:hover,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > a:hover,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > div > a.active,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > a.active,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > div > a:active,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > a:active {
  text-decoration: none;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > .tlAccSwitchWrap,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > a {
  padding: 1rem 1.5rem 1.188rem 1.5rem;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitchWrap,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitchWrap {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitchWrap > *,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitchWrap > * {
  width: 100%;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitchWrap > a,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitchWrap > a {
  flex-shrink: 1;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitchWrap > a:active .text,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitchWrap > a:active .text,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitchWrap > a:hover .text,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitchWrap > a:hover .text {
  text-decoration: underline;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > a:hover .text {
  text-decoration: underline;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitch,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitch {
  position: relative;
  width: 23px;
  margin-left: 5px;
  padding: 0;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitch:before,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitch:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
}
@media only screen and (max-width: 480px) {
  .region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitch:before,
  .region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitch:before {
    position: absolute;
    top: 0;
    right: auto;
    bottom: 0;
    left: -50%;
    width: 200%;
  }
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitch .text,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitch .text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  /*rtl:ignore*/
  width: 100%;
  height: 100%;
  border-radius: 2000px;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
  background-color: transparent;
  background-image: url("../images/icons/dropdown-arrow--white.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale3d(1,1,1);
  transition: transform 700ms ease 0ms, background-color 150ms ease 0ms;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitch:active .text,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitch:active .text,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitch:hover .text,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitch:hover .text {
  background-color: transparent;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .tlAccSwitch.isOpen .text,
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li .slAccSwitch.isOpen .text {
  transform: translateX(-50%) translateY(-50%) scale3d(1, 1, 1) rotate(180deg);
  background-color: transparent;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > .menu {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 1.875rem;
  columns: auto;
  display: block;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > .menu li {
  margin: 0;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > .menu li:before {
  content: none;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > .menu > li {
  border-left: 3px solid #FFFFFF;
  padding-bottom: .625rem;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > .menu > li:last-child {
  padding-bottom: 0;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > .menu > li > a {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
  color: #FFFFFF;
  padding-left: 1.25rem;
  display: block;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > .menu > li > a:first-child {
  margin-top: 0;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > .menu > li > a:before {
  content: none;
}
.region-sidebar-second .block-menu-block .menu-block-wrapper > .menu > li > .menu.isClosed {
  display: none;
}
@media only screen and (max-width: 960px) {
  .node-type-listing-page .region-sidebar-second-inner,
  .node-type-database-detail .region-sidebar-second-inner,
  .node-type-landing-page .region-sidebar-second-inner,
  .not-front.node-type-page .region-sidebar-second-inner,
  .node-type-blog-post .region-sidebar-second-inner,
  .page-search .region-sidebar-second-inner {
    text-align: center;
  }
}
.node-type-listing-page .region-sidebar-second-inner > .block,
.node-type-database-detail .region-sidebar-second-inner > .block,
.node-type-landing-page .region-sidebar-second-inner > .block,
.not-front.node-type-page .region-sidebar-second-inner > .block,
.node-type-blog-post .region-sidebar-second-inner > .block,
.page-search .region-sidebar-second-inner > .block,
.node-type-listing-page .region-sidebar-second-inner > .ds-region,
.node-type-database-detail .region-sidebar-second-inner > .ds-region,
.node-type-landing-page .region-sidebar-second-inner > .ds-region,
.not-front.node-type-page .region-sidebar-second-inner > .ds-region,
.node-type-blog-post .region-sidebar-second-inner > .ds-region,
.page-search .region-sidebar-second-inner > .ds-region {
  font-size: 1.375rem;
  line-height: 1.5;
  padding-top: 1.8rem;
  padding-bottom: 2.25rem;
  color: #666666;
}
.node-type-listing-page .region-sidebar-second-inner > .block:last-child,
.node-type-database-detail .region-sidebar-second-inner > .block:last-child,
.node-type-landing-page .region-sidebar-second-inner > .block:last-child,
.not-front.node-type-page .region-sidebar-second-inner > .block:last-child,
.node-type-blog-post .region-sidebar-second-inner > .block:last-child,
.page-search .region-sidebar-second-inner > .block:last-child,
.node-type-listing-page .region-sidebar-second-inner > .ds-region:last-child,
.node-type-database-detail .region-sidebar-second-inner > .ds-region:last-child,
.node-type-landing-page .region-sidebar-second-inner > .ds-region:last-child,
.not-front.node-type-page .region-sidebar-second-inner > .ds-region:last-child,
.node-type-blog-post .region-sidebar-second-inner > .ds-region:last-child,
.page-search .region-sidebar-second-inner > .ds-region:last-child {
  padding-bottom: 0;
}
@media only screen and (min-width: 961px) {
  .node-type-listing-page .region-sidebar-second-inner > .block:first-child,
  .node-type-database-detail .region-sidebar-second-inner > .block:first-child,
  .node-type-landing-page .region-sidebar-second-inner > .block:first-child,
  .not-front.node-type-page .region-sidebar-second-inner > .block:first-child,
  .node-type-blog-post .region-sidebar-second-inner > .block:first-child,
  .page-search .region-sidebar-second-inner > .block:first-child,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region:first-child,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region:first-child,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region:first-child,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region:first-child,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region:first-child,
  .page-search .region-sidebar-second-inner > .ds-region:first-child {
    padding-top: 0;
  }
}
.front .node-type-listing-page .region-sidebar-second-inner > .block,
.front .node-type-database-detail .region-sidebar-second-inner > .block,
.front .node-type-landing-page .region-sidebar-second-inner > .block,
.front .not-front.node-type-page .region-sidebar-second-inner > .block,
.front .node-type-blog-post .region-sidebar-second-inner > .block,
.front .page-search .region-sidebar-second-inner > .block,
.front .node-type-listing-page .region-sidebar-second-inner > .ds-region,
.front .node-type-database-detail .region-sidebar-second-inner > .ds-region,
.front .node-type-landing-page .region-sidebar-second-inner > .ds-region,
.front .not-front.node-type-page .region-sidebar-second-inner > .ds-region,
.front .node-type-blog-post .region-sidebar-second-inner > .ds-region,
.front .page-search .region-sidebar-second-inner > .ds-region {
  line-height: 1.4;
}
.region-sidebar-second .node-type-listing-page .region-sidebar-second-inner > .block,
.region-sidebar-second .node-type-database-detail .region-sidebar-second-inner > .block,
.region-sidebar-second .node-type-landing-page .region-sidebar-second-inner > .block,
.region-sidebar-second .not-front.node-type-page .region-sidebar-second-inner > .block,
.region-sidebar-second .node-type-blog-post .region-sidebar-second-inner > .block,
.region-sidebar-second .page-search .region-sidebar-second-inner > .block,
.region-sidebar-second .node-type-listing-page .region-sidebar-second-inner > .ds-region,
.region-sidebar-second .node-type-database-detail .region-sidebar-second-inner > .ds-region,
.region-sidebar-second .node-type-landing-page .region-sidebar-second-inner > .ds-region,
.region-sidebar-second .not-front.node-type-page .region-sidebar-second-inner > .ds-region,
.region-sidebar-second .node-type-blog-post .region-sidebar-second-inner > .ds-region,
.region-sidebar-second .page-search .region-sidebar-second-inner > .ds-region {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .node-type-listing-page .region-sidebar-second-inner > .block,
  .node-type-database-detail .region-sidebar-second-inner > .block,
  .node-type-landing-page .region-sidebar-second-inner > .block,
  .not-front.node-type-page .region-sidebar-second-inner > .block,
  .node-type-blog-post .region-sidebar-second-inner > .block,
  .page-search .region-sidebar-second-inner > .block,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region,
  .page-search .region-sidebar-second-inner > .ds-region {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .node-type-listing-page .region-sidebar-second-inner > .block,
  .node-type-database-detail .region-sidebar-second-inner > .block,
  .node-type-landing-page .region-sidebar-second-inner > .block,
  .not-front.node-type-page .region-sidebar-second-inner > .block,
  .node-type-blog-post .region-sidebar-second-inner > .block,
  .page-search .region-sidebar-second-inner > .block,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region,
  .page-search .region-sidebar-second-inner > .ds-region {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  }
  .node-type-listing-page .region-sidebar-second-inner > .block:before,
  .node-type-database-detail .region-sidebar-second-inner > .block:before,
  .node-type-landing-page .region-sidebar-second-inner > .block:before,
  .not-front.node-type-page .region-sidebar-second-inner > .block:before,
  .node-type-blog-post .region-sidebar-second-inner > .block:before,
  .page-search .region-sidebar-second-inner > .block:before,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region:before,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region:before,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region:before,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region:before,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region:before,
  .page-search .region-sidebar-second-inner > .ds-region:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
  }
}
.node-type-listing-page .region-sidebar-second-inner > .block .block-title,
.node-type-database-detail .region-sidebar-second-inner > .block .block-title,
.node-type-landing-page .region-sidebar-second-inner > .block .block-title,
.not-front.node-type-page .region-sidebar-second-inner > .block .block-title,
.node-type-blog-post .region-sidebar-second-inner > .block .block-title,
.page-search .region-sidebar-second-inner > .block .block-title,
.node-type-listing-page .region-sidebar-second-inner > .ds-region .block-title,
.node-type-database-detail .region-sidebar-second-inner > .ds-region .block-title,
.node-type-landing-page .region-sidebar-second-inner > .ds-region .block-title,
.not-front.node-type-page .region-sidebar-second-inner > .ds-region .block-title,
.node-type-blog-post .region-sidebar-second-inner > .ds-region .block-title,
.page-search .region-sidebar-second-inner > .ds-region .block-title,
.node-type-listing-page .region-sidebar-second-inner > .block > .field > .field-label,
.node-type-database-detail .region-sidebar-second-inner > .block > .field > .field-label,
.node-type-landing-page .region-sidebar-second-inner > .block > .field > .field-label,
.not-front.node-type-page .region-sidebar-second-inner > .block > .field > .field-label,
.node-type-blog-post .region-sidebar-second-inner > .block > .field > .field-label,
.page-search .region-sidebar-second-inner > .block > .field > .field-label,
.node-type-listing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
.node-type-database-detail .region-sidebar-second-inner > .ds-region > .field > .field-label,
.node-type-landing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
.not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
.node-type-blog-post .region-sidebar-second-inner > .ds-region > .field > .field-label,
.page-search .region-sidebar-second-inner > .ds-region > .field > .field-label,
.node-type-listing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
.node-type-database-detail .region-sidebar-second-inner > .block .wysiwyg-heading,
.node-type-landing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
.not-front.node-type-page .region-sidebar-second-inner > .block .wysiwyg-heading,
.node-type-blog-post .region-sidebar-second-inner > .block .wysiwyg-heading,
.page-search .region-sidebar-second-inner > .block .wysiwyg-heading,
.node-type-listing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
.node-type-database-detail .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
.node-type-landing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
.not-front.node-type-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
.node-type-blog-post .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
.page-search .region-sidebar-second-inner > .ds-region .wysiwyg-heading {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .node-type-listing-page .region-sidebar-second-inner > .block .block-title,
  .node-type-database-detail .region-sidebar-second-inner > .block .block-title,
  .node-type-landing-page .region-sidebar-second-inner > .block .block-title,
  .not-front.node-type-page .region-sidebar-second-inner > .block .block-title,
  .node-type-blog-post .region-sidebar-second-inner > .block .block-title,
  .page-search .region-sidebar-second-inner > .block .block-title,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region .block-title,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region .block-title,
  .page-search .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-listing-page .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-database-detail .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-landing-page .region-sidebar-second-inner > .block > .field > .field-label,
  .not-front.node-type-page .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-blog-post .region-sidebar-second-inner > .block > .field > .field-label,
  .page-search .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .page-search .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-listing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-database-detail .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-landing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .not-front.node-type-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-blog-post .region-sidebar-second-inner > .block .wysiwyg-heading,
  .page-search .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .page-search .region-sidebar-second-inner > .ds-region .wysiwyg-heading {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .node-type-listing-page .region-sidebar-second-inner > .block .block-title,
  .node-type-database-detail .region-sidebar-second-inner > .block .block-title,
  .node-type-landing-page .region-sidebar-second-inner > .block .block-title,
  .not-front.node-type-page .region-sidebar-second-inner > .block .block-title,
  .node-type-blog-post .region-sidebar-second-inner > .block .block-title,
  .page-search .region-sidebar-second-inner > .block .block-title,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region .block-title,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region .block-title,
  .page-search .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-listing-page .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-database-detail .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-landing-page .region-sidebar-second-inner > .block > .field > .field-label,
  .not-front.node-type-page .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-blog-post .region-sidebar-second-inner > .block > .field > .field-label,
  .page-search .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .page-search .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-listing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-database-detail .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-landing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .not-front.node-type-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-blog-post .region-sidebar-second-inner > .block .wysiwyg-heading,
  .page-search .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .page-search .region-sidebar-second-inner > .ds-region .wysiwyg-heading {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (min-width: 481px) {
  .node-type-listing-page .region-sidebar-second-inner > .block .block-title,
  .node-type-database-detail .region-sidebar-second-inner > .block .block-title,
  .node-type-landing-page .region-sidebar-second-inner > .block .block-title,
  .not-front.node-type-page .region-sidebar-second-inner > .block .block-title,
  .node-type-blog-post .region-sidebar-second-inner > .block .block-title,
  .page-search .region-sidebar-second-inner > .block .block-title,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region .block-title,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region .block-title,
  .page-search .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-listing-page .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-database-detail .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-landing-page .region-sidebar-second-inner > .block > .field > .field-label,
  .not-front.node-type-page .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-blog-post .region-sidebar-second-inner > .block > .field > .field-label,
  .page-search .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .page-search .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-listing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-database-detail .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-landing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .not-front.node-type-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-blog-post .region-sidebar-second-inner > .block .wysiwyg-heading,
  .page-search .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .page-search .region-sidebar-second-inner > .ds-region .wysiwyg-heading {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .node-type-listing-page .region-sidebar-second-inner > .block .block-title,
  .node-type-database-detail .region-sidebar-second-inner > .block .block-title,
  .node-type-landing-page .region-sidebar-second-inner > .block .block-title,
  .not-front.node-type-page .region-sidebar-second-inner > .block .block-title,
  .node-type-blog-post .region-sidebar-second-inner > .block .block-title,
  .page-search .region-sidebar-second-inner > .block .block-title,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region .block-title,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region .block-title,
  .page-search .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-listing-page .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-database-detail .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-landing-page .region-sidebar-second-inner > .block > .field > .field-label,
  .not-front.node-type-page .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-blog-post .region-sidebar-second-inner > .block > .field > .field-label,
  .page-search .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .page-search .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-listing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-database-detail .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-landing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .not-front.node-type-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-blog-post .region-sidebar-second-inner > .block .wysiwyg-heading,
  .page-search .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .page-search .region-sidebar-second-inner > .ds-region .wysiwyg-heading {
    margin-bottom: 1.3rem;
  }
}
@media only screen and (max-width: 480px) {
  .node-type-listing-page .region-sidebar-second-inner > .block .block-title,
  .node-type-database-detail .region-sidebar-second-inner > .block .block-title,
  .node-type-landing-page .region-sidebar-second-inner > .block .block-title,
  .not-front.node-type-page .region-sidebar-second-inner > .block .block-title,
  .node-type-blog-post .region-sidebar-second-inner > .block .block-title,
  .page-search .region-sidebar-second-inner > .block .block-title,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region .block-title,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region .block-title,
  .page-search .region-sidebar-second-inner > .ds-region .block-title,
  .node-type-listing-page .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-database-detail .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-landing-page .region-sidebar-second-inner > .block > .field > .field-label,
  .not-front.node-type-page .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-blog-post .region-sidebar-second-inner > .block > .field > .field-label,
  .page-search .region-sidebar-second-inner > .block > .field > .field-label,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .page-search .region-sidebar-second-inner > .ds-region > .field > .field-label,
  .node-type-listing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-database-detail .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-landing-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .not-front.node-type-page .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-blog-post .region-sidebar-second-inner > .block .wysiwyg-heading,
  .page-search .region-sidebar-second-inner > .block .wysiwyg-heading,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region .wysiwyg-heading,
  .page-search .region-sidebar-second-inner > .ds-region .wysiwyg-heading {
    color: #666666;
    margin-bottom: .75rem;
  }
}
@media only screen and (max-width: 480px) {
  .node-type-listing-page .region-sidebar-second-inner > .block ~ .block,
  .node-type-database-detail .region-sidebar-second-inner > .block ~ .block,
  .node-type-landing-page .region-sidebar-second-inner > .block ~ .block,
  .not-front.node-type-page .region-sidebar-second-inner > .block ~ .block,
  .node-type-blog-post .region-sidebar-second-inner > .block ~ .block,
  .page-search .region-sidebar-second-inner > .block ~ .block,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region ~ .block,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region ~ .block,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region ~ .block,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region ~ .block,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region ~ .block,
  .page-search .region-sidebar-second-inner > .ds-region ~ .block,
  .node-type-listing-page .region-sidebar-second-inner > .block ~ .ds-region,
  .node-type-database-detail .region-sidebar-second-inner > .block ~ .ds-region,
  .node-type-landing-page .region-sidebar-second-inner > .block ~ .ds-region,
  .not-front.node-type-page .region-sidebar-second-inner > .block ~ .ds-region,
  .node-type-blog-post .region-sidebar-second-inner > .block ~ .ds-region,
  .page-search .region-sidebar-second-inner > .block ~ .ds-region,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region ~ .ds-region,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region ~ .ds-region,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region ~ .ds-region,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region ~ .ds-region,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region ~ .ds-region,
  .page-search .region-sidebar-second-inner > .ds-region ~ .ds-region {
    border-top: none;
    padding-top: 1.8rem;
  }
}
.node-type-listing-page .region-sidebar-second-inner > .block > .field,
.node-type-database-detail .region-sidebar-second-inner > .block > .field,
.node-type-landing-page .region-sidebar-second-inner > .block > .field,
.not-front.node-type-page .region-sidebar-second-inner > .block > .field,
.node-type-blog-post .region-sidebar-second-inner > .block > .field,
.page-search .region-sidebar-second-inner > .block > .field,
.node-type-listing-page .region-sidebar-second-inner > .ds-region > .field,
.node-type-database-detail .region-sidebar-second-inner > .ds-region > .field,
.node-type-landing-page .region-sidebar-second-inner > .ds-region > .field,
.not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field,
.node-type-blog-post .region-sidebar-second-inner > .ds-region > .field,
.page-search .region-sidebar-second-inner > .ds-region > .field {
  padding-bottom: 2.25rem;
  padding-bottom: 3.05rem;
}
.node-type-listing-page .region-sidebar-second-inner > .block > .field:last-child,
.node-type-database-detail .region-sidebar-second-inner > .block > .field:last-child,
.node-type-landing-page .region-sidebar-second-inner > .block > .field:last-child,
.not-front.node-type-page .region-sidebar-second-inner > .block > .field:last-child,
.node-type-blog-post .region-sidebar-second-inner > .block > .field:last-child,
.page-search .region-sidebar-second-inner > .block > .field:last-child,
.node-type-listing-page .region-sidebar-second-inner > .ds-region > .field:last-child,
.node-type-database-detail .region-sidebar-second-inner > .ds-region > .field:last-child,
.node-type-landing-page .region-sidebar-second-inner > .ds-region > .field:last-child,
.not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field:last-child,
.node-type-blog-post .region-sidebar-second-inner > .ds-region > .field:last-child,
.page-search .region-sidebar-second-inner > .ds-region > .field:last-child {
  padding-bottom: 0;
}
@media only screen and (max-width: 960px) {
  .node-type-listing-page .region-sidebar-second-inner > .block > .field,
  .node-type-database-detail .region-sidebar-second-inner > .block > .field,
  .node-type-landing-page .region-sidebar-second-inner > .block > .field,
  .not-front.node-type-page .region-sidebar-second-inner > .block > .field,
  .node-type-blog-post .region-sidebar-second-inner > .block > .field,
  .page-search .region-sidebar-second-inner > .block > .field,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region > .field,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region > .field,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region > .field,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region > .field,
  .page-search .region-sidebar-second-inner > .ds-region > .field {
    padding-bottom: 2.5rem;
  }
  .node-type-listing-page .region-sidebar-second-inner > .block > .field:last-child,
  .node-type-database-detail .region-sidebar-second-inner > .block > .field:last-child,
  .node-type-landing-page .region-sidebar-second-inner > .block > .field:last-child,
  .not-front.node-type-page .region-sidebar-second-inner > .block > .field:last-child,
  .node-type-blog-post .region-sidebar-second-inner > .block > .field:last-child,
  .page-search .region-sidebar-second-inner > .block > .field:last-child,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region > .field:last-child,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region > .field:last-child,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region > .field:last-child,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field:last-child,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region > .field:last-child,
  .page-search .region-sidebar-second-inner > .ds-region > .field:last-child {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 480px) {
  .node-type-listing-page .region-sidebar-second-inner > .block > .field,
  .node-type-database-detail .region-sidebar-second-inner > .block > .field,
  .node-type-landing-page .region-sidebar-second-inner > .block > .field,
  .not-front.node-type-page .region-sidebar-second-inner > .block > .field,
  .node-type-blog-post .region-sidebar-second-inner > .block > .field,
  .page-search .region-sidebar-second-inner > .block > .field,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region > .field,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region > .field,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region > .field,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region > .field,
  .page-search .region-sidebar-second-inner > .ds-region > .field {
    padding-bottom: 1.8rem;
  }
  .node-type-listing-page .region-sidebar-second-inner > .block > .field:last-child,
  .node-type-database-detail .region-sidebar-second-inner > .block > .field:last-child,
  .node-type-landing-page .region-sidebar-second-inner > .block > .field:last-child,
  .not-front.node-type-page .region-sidebar-second-inner > .block > .field:last-child,
  .node-type-blog-post .region-sidebar-second-inner > .block > .field:last-child,
  .page-search .region-sidebar-second-inner > .block > .field:last-child,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region > .field:last-child,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region > .field:last-child,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region > .field:last-child,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field:last-child,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region > .field:last-child,
  .page-search .region-sidebar-second-inner > .ds-region > .field:last-child {
    padding-bottom: 0;
  }
}
.node-type-listing-page .region-sidebar-second-inner > .block > .field ~ .field,
.node-type-database-detail .region-sidebar-second-inner > .block > .field ~ .field,
.node-type-landing-page .region-sidebar-second-inner > .block > .field ~ .field,
.not-front.node-type-page .region-sidebar-second-inner > .block > .field ~ .field,
.node-type-blog-post .region-sidebar-second-inner > .block > .field ~ .field,
.page-search .region-sidebar-second-inner > .block > .field ~ .field,
.node-type-listing-page .region-sidebar-second-inner > .ds-region > .field ~ .field,
.node-type-database-detail .region-sidebar-second-inner > .ds-region > .field ~ .field,
.node-type-landing-page .region-sidebar-second-inner > .ds-region > .field ~ .field,
.not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field ~ .field,
.node-type-blog-post .region-sidebar-second-inner > .ds-region > .field ~ .field,
.page-search .region-sidebar-second-inner > .ds-region > .field ~ .field {
  padding-top: 1.8rem;
}
@media only screen and (max-width: 960px) {
  .node-type-listing-page .region-sidebar-second-inner > .block > .field ~ .field,
  .node-type-database-detail .region-sidebar-second-inner > .block > .field ~ .field,
  .node-type-landing-page .region-sidebar-second-inner > .block > .field ~ .field,
  .not-front.node-type-page .region-sidebar-second-inner > .block > .field ~ .field,
  .node-type-blog-post .region-sidebar-second-inner > .block > .field ~ .field,
  .page-search .region-sidebar-second-inner > .block > .field ~ .field,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region > .field ~ .field,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region > .field ~ .field,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region > .field ~ .field,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field ~ .field,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region > .field ~ .field,
  .page-search .region-sidebar-second-inner > .ds-region > .field ~ .field {
    padding-top: 2.15rem;
  }
}
@media only screen and (max-width: 480px) {
  .node-type-listing-page .region-sidebar-second-inner > .block > .field ~ .field,
  .node-type-database-detail .region-sidebar-second-inner > .block > .field ~ .field,
  .node-type-landing-page .region-sidebar-second-inner > .block > .field ~ .field,
  .not-front.node-type-page .region-sidebar-second-inner > .block > .field ~ .field,
  .node-type-blog-post .region-sidebar-second-inner > .block > .field ~ .field,
  .page-search .region-sidebar-second-inner > .block > .field ~ .field,
  .node-type-listing-page .region-sidebar-second-inner > .ds-region > .field ~ .field,
  .node-type-database-detail .region-sidebar-second-inner > .ds-region > .field ~ .field,
  .node-type-landing-page .region-sidebar-second-inner > .ds-region > .field ~ .field,
  .not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field ~ .field,
  .node-type-blog-post .region-sidebar-second-inner > .ds-region > .field ~ .field,
  .page-search .region-sidebar-second-inner > .ds-region > .field ~ .field {
    padding-top: 1.65rem;
  }
}
.node-type-listing-page .region-sidebar-second-inner > .block > .field .field-contents,
.node-type-database-detail .region-sidebar-second-inner > .block > .field .field-contents,
.node-type-landing-page .region-sidebar-second-inner > .block > .field .field-contents,
.not-front.node-type-page .region-sidebar-second-inner > .block > .field .field-contents,
.node-type-blog-post .region-sidebar-second-inner > .block > .field .field-contents,
.page-search .region-sidebar-second-inner > .block > .field .field-contents,
.node-type-listing-page .region-sidebar-second-inner > .ds-region > .field .field-contents,
.node-type-database-detail .region-sidebar-second-inner > .ds-region > .field .field-contents,
.node-type-landing-page .region-sidebar-second-inner > .ds-region > .field .field-contents,
.not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field .field-contents,
.node-type-blog-post .region-sidebar-second-inner > .ds-region > .field .field-contents,
.page-search .region-sidebar-second-inner > .ds-region > .field .field-contents {
  margin-top: 2.65rem;
}
.node-type-listing-page .region-sidebar-second-inner > .block > .field .field-contents:first-child,
.node-type-database-detail .region-sidebar-second-inner > .block > .field .field-contents:first-child,
.node-type-landing-page .region-sidebar-second-inner > .block > .field .field-contents:first-child,
.not-front.node-type-page .region-sidebar-second-inner > .block > .field .field-contents:first-child,
.node-type-blog-post .region-sidebar-second-inner > .block > .field .field-contents:first-child,
.page-search .region-sidebar-second-inner > .block > .field .field-contents:first-child,
.node-type-listing-page .region-sidebar-second-inner > .ds-region > .field .field-contents:first-child,
.node-type-database-detail .region-sidebar-second-inner > .ds-region > .field .field-contents:first-child,
.node-type-landing-page .region-sidebar-second-inner > .ds-region > .field .field-contents:first-child,
.not-front.node-type-page .region-sidebar-second-inner > .ds-region > .field .field-contents:first-child,
.node-type-blog-post .region-sidebar-second-inner > .ds-region > .field .field-contents:first-child,
.page-search .region-sidebar-second-inner > .ds-region > .field .field-contents:first-child {
  margin-top: 0;
}
.block-visitor-info {
  padding-top: 1.8rem;
  padding-bottom: 2.25rem;
  color: #666666;
  line-height: 1.45;
}
.block-visitor-info:last-child {
  padding-bottom: 0;
}
.block-visitor-info > .content {
  font-size: 16px;
}
@media only screen and (min-width: 961px) {
  .block-visitor-info:first-child {
    padding-top: 0;
  }
}
@media only screen and (max-width: 960px) {
  .block-visitor-info {
    margin: 0 auto;
  }
  .front .block-visitor-info {
    max-width: 550px;
  }
}
.front .region-sidebar-second .block-visitor-info {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .block-visitor-info {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-visitor-info {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  }
  .region-sidebar-second .block-visitor-info {
    padding: 2.05rem 1.2rem 1.8rem;
  }
  .region-sidebar-second .block-visitor-info:last-child {
    padding-bottom: 0;
  }
}
.block-visitor-info .block-title {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .block-visitor-info .block-title {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-visitor-info .block-title {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (min-width: 481px) {
  .block-visitor-info .block-title {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .block-visitor-info .block-title {
    margin-bottom: 2.8rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-visitor-info .block-title {
    color: #1890fb;
    margin-bottom: 1.15rem;
  }
}
.block-visitor-info .field-label,
.block-visitor-info .label-inline,
.block-visitor-info .label-above {
  font-size: 1rem;
  margin-bottom: 0;
}
.block-visitor-info .field-label + *,
.block-visitor-info .label-inline + *,
.block-visitor-info .label-above + * {
  margin-top: 0;
}
.block-visitor-info .label-above {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}
.block-visitor-info .label-inline {
  font-weight: normal;
}
.block-visitor-info a {
  padding-left: 0;
  display: initial;
}
.block-visitor-info a:before {
  content: none;
}
.block-research-contact-info,
.block[id*="research-contact-info"] {
  padding-top: 1.8rem;
  padding-bottom: 2.25rem;
  color: #666666;
  line-height: 1.45;
}
.block-research-contact-info:last-child,
.block[id*="research-contact-info"]:last-child {
  padding-bottom: 0;
}
.block-research-contact-info > .content,
.block[id*="research-contact-info"] > .content {
  font-size: 16px;
}
@media only screen and (min-width: 961px) {
  .block-research-contact-info:first-child,
  .block[id*="research-contact-info"]:first-child {
    padding-top: 0;
  }
}
@media only screen and (max-width: 960px) {
  .block-research-contact-info,
  .block[id*="research-contact-info"] {
    margin: 0 auto;
  }
  .front .block-research-contact-info,
  .front .block[id*="research-contact-info"] {
    max-width: 550px;
  }
}
.front .region-sidebar-second .block-research-contact-info,
.front .region-sidebar-second .block[id*="research-contact-info"] {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .block-research-contact-info,
  .block[id*="research-contact-info"] {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-research-contact-info,
  .block[id*="research-contact-info"] {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  }
  .region-sidebar-second .block-research-contact-info,
  .region-sidebar-second .block[id*="research-contact-info"] {
    padding: 2.05rem 1.2rem 1.8rem;
  }
  .region-sidebar-second .block-research-contact-info:last-child,
  .region-sidebar-second .block[id*="research-contact-info"]:last-child {
    padding-bottom: 0;
  }
}
.block-research-contact-info .block-title,
.block[id*="research-contact-info"] .block-title {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .block-research-contact-info .block-title,
  .block[id*="research-contact-info"] .block-title {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-research-contact-info .block-title,
  .block[id*="research-contact-info"] .block-title {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (min-width: 481px) {
  .block-research-contact-info .block-title,
  .block[id*="research-contact-info"] .block-title {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .block-research-contact-info .block-title,
  .block[id*="research-contact-info"] .block-title {
    margin-bottom: 2.8rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-research-contact-info .block-title,
  .block[id*="research-contact-info"] .block-title {
    color: #1890fb;
    margin-bottom: 1.15rem;
  }
}
.block-research-contact-info .field-label,
.block[id*="research-contact-info"] .field-label,
.block-research-contact-info .label-inline,
.block[id*="research-contact-info"] .label-inline,
.block-research-contact-info .label-above,
.block[id*="research-contact-info"] .label-above {
  font-size: 1rem;
  margin-bottom: 0;
}
.block-research-contact-info .field-label + *,
.block[id*="research-contact-info"] .field-label + *,
.block-research-contact-info .label-inline + *,
.block[id*="research-contact-info"] .label-inline + *,
.block-research-contact-info .label-above + *,
.block[id*="research-contact-info"] .label-above + * {
  margin-top: 0;
}
.block-research-contact-info .label-above,
.block[id*="research-contact-info"] .label-above {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}
.block-research-contact-info .label-inline,
.block[id*="research-contact-info"] .label-inline {
  font-weight: normal;
}
/* --- [ Plain Sub-Blocks ] --------------- */
.block-guide-categories,
.block[id*="guide-categories"] {
  font-size: 1.375rem;
  line-height: 1.5;
  padding-top: 1.8rem;
  padding-bottom: 2.25rem;
  color: #666666;
}
.block-guide-categories:last-child,
.block[id*="guide-categories"]:last-child {
  padding-bottom: 0;
}
@media only screen and (min-width: 961px) {
  .block-guide-categories:first-child,
  .block[id*="guide-categories"]:first-child {
    padding-top: 0;
  }
}
.front .block-guide-categories,
.front .block[id*="guide-categories"] {
  line-height: 1.4;
}
.region-sidebar-second .block-guide-categories,
.region-sidebar-second .block[id*="guide-categories"] {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .block-guide-categories,
  .block[id*="guide-categories"] {
    padding-top: 2.15rem;
    padding-bottom: 2.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-guide-categories,
  .block[id*="guide-categories"] {
    position: relative;
    padding: 1.65rem 0 1.8rem;
  }
  .block-guide-categories:before,
  .block[id*="guide-categories"]:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
  }
}
.block-guide-categories .block-title,
.block[id*="guide-categories"] .block-title,
.block-guide-categories > .field > .field-label,
.block[id*="guide-categories"] > .field > .field-label,
.block-guide-categories .wysiwyg-heading,
.block[id*="guide-categories"] .wysiwyg-heading {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 960px) {
  .block-guide-categories .block-title,
  .block[id*="guide-categories"] .block-title,
  .block-guide-categories > .field > .field-label,
  .block[id*="guide-categories"] > .field > .field-label,
  .block-guide-categories .wysiwyg-heading,
  .block[id*="guide-categories"] .wysiwyg-heading {
    text-align: center;
    margin-bottom: 1.525rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-guide-categories .block-title,
  .block[id*="guide-categories"] .block-title,
  .block-guide-categories > .field > .field-label,
  .block[id*="guide-categories"] > .field > .field-label,
  .block-guide-categories .wysiwyg-heading,
  .block[id*="guide-categories"] .wysiwyg-heading {
    font-size: 1.6875rem;
    line-height: 1.556;
    letter-spacing: 0.012rem;
    text-align: center;
    margin-bottom: .875rem;
  }
}
@media only screen and (min-width: 481px) {
  .block-guide-categories .block-title,
  .block[id*="guide-categories"] .block-title,
  .block-guide-categories > .field > .field-label,
  .block[id*="guide-categories"] > .field > .field-label,
  .block-guide-categories .wysiwyg-heading,
  .block[id*="guide-categories"] .wysiwyg-heading {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
    margin-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .block-guide-categories .block-title,
  .block[id*="guide-categories"] .block-title,
  .block-guide-categories > .field > .field-label,
  .block[id*="guide-categories"] > .field > .field-label,
  .block-guide-categories .wysiwyg-heading,
  .block[id*="guide-categories"] .wysiwyg-heading {
    margin-bottom: 1.3rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-guide-categories .block-title,
  .block[id*="guide-categories"] .block-title,
  .block-guide-categories > .field > .field-label,
  .block[id*="guide-categories"] > .field > .field-label,
  .block-guide-categories .wysiwyg-heading,
  .block[id*="guide-categories"] .wysiwyg-heading {
    color: #666666;
    margin-bottom: .75rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-guide-categories,
  .block[id*="guide-categories"] {
    margin-top: 1.65rem;
  }
}
.block-guide-categories .block-title,
.block[id*="guide-categories"] .block-title {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  margin-bottom: 1.25rem;
}
@media only screen and (max-width: 480px) {
  .block-guide-categories .block-title,
  .block[id*="guide-categories"] .block-title {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 960px) {
  .block-guide-categories .block-title,
  .block[id*="guide-categories"] .block-title {
    text-align: initial;
  }
}
.block-guide-categories .item-list ul,
.block[id*="guide-categories"] .item-list ul {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  padding: 0;
  list-style: none;
}
.block-guide-categories .item-list ul .field-contents,
.block[id*="guide-categories"] .item-list ul .field-contents {
  display: inline;
}
.block-guide-categories .item-list ul a,
.block[id*="guide-categories"] .item-list ul a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.block-guide-categories .item-list ul a:hover,
.block[id*="guide-categories"] .item-list ul a:hover {
  color: #1890fb;
}
.block-guide-categories .item-list ul.field-date [class*="date-display"],
.block[id*="guide-categories"] .item-list ul.field-date [class*="date-display"] {
  display: inline;
}
.block-guide-categories .item-list ul > *,
.block[id*="guide-categories"] .item-list ul > * {
  display: inline-block;
  position: relative;
  margin: 0;
  margin-right: .4rem;
}
.block-guide-categories .item-list ul > *:before,
.block[id*="guide-categories"] .item-list ul > *:before {
  content: none;
}
.block-guide-categories .item-list ul > *:first-child:not(:last-child):after,
.block[id*="guide-categories"] .item-list ul > *:first-child:not(:last-child):after {
  content: '\2022';
  color: inherit;
  margin-left: 0.4rem;
  display: inline;
  float: none;
  font-size: inherit;
  position: static;
}
.block-guide-categories .item-list ul > *:first-child:not(:last-child) ~ *:not(:last-child):after,
.block[id*="guide-categories"] .item-list ul > *:first-child:not(:last-child) ~ *:not(:last-child):after {
  content: '\2022';
  color: inherit;
  margin-left: 0.4rem;
  display: inline;
  float: none;
  font-size: inherit;
  position: static;
}
/* --- [ System Blocks ] --------------- */
.block-login,
.block[id*="user-login"] {
  background-color: #FFFFFF;
  padding: 1.2rem 1.75rem 1.975rem;
}
@media only screen and (max-width: 960px) {
  .block-login,
  .block[id*="user-login"] {
    padding: 1.15rem 1.7rem 1.85rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-login,
  .block[id*="user-login"] {
    padding: 1.05rem 1.25rem 1.55rem;
  }
}
.block-login .block-title,
.block[id*="user-login"] .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  margin-bottom: 1.4rem;
}
@media only screen and (max-width: 480px) {
  .block-login .block-title,
  .block[id*="user-login"] .block-title {
    font-size: 1.25rem;
  }
}
.zone-footer .block-login .block-title,
.zone-footer .block[id*="user-login"] .block-title,
.ds-media-aside .block-login .block-title,
.ds-media-aside .block[id*="user-login"] .block-title {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer .block-login .block-title,
  .zone-footer .block[id*="user-login"] .block-title,
  .ds-media-aside .block-login .block-title,
  .ds-media-aside .block[id*="user-login"] .block-title {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 960px) {
  .block-login .block-title,
  .block[id*="user-login"] .block-title {
    text-align: initial;
    color: #4d5a6b;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.154;
    letter-spacing: 0.006em;
  }
}
@media only screen and (max-width: 960px) and only screen and (max-width: 480px) {
  .block-login .block-title,
  .block[id*="user-login"] .block-title {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-login .block-title,
  .block[id*="user-login"] .block-title {
    text-align: initial;
    margin-bottom: 1.4rem;
  }
}
.block-login .form-item,
.block[id*="user-login"] .form-item {
  margin-bottom: .55rem;
}
@media only screen and (max-width: 480px) {
  .block-login .form-item,
  .block[id*="user-login"] .form-item {
    margin-bottom: .4rem;
  }
}
.block-login .form-item ~ .form-item,
.block[id*="user-login"] .form-item ~ .form-item {
  margin-top: 1.35rem;
}
@media only screen and (max-width: 480px) {
  .block-login .form-item ~ .form-item,
  .block[id*="user-login"] .form-item ~ .form-item {
    margin-top: .8rem;
  }
}
.block-login .form-actions,
.block[id*="user-login"] .form-actions {
  margin-top: 1.35rem;
  text-align: center;
}
@media only screen and (max-width: 960px) {
  .block-login .form-actions,
  .block[id*="user-login"] .form-actions {
    margin-top: 1.3rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-login .form-actions,
  .block[id*="user-login"] .form-actions {
    margin-top: 1.1rem;
  }
}
.block-login .form-actions .form-submit,
.block[id*="user-login"] .form-actions .form-submit {
  border: 3px solid;
  padding: 0.5rem 1em .45rem;
  width: 86%;
  background-color: #d9a70f;
}
.block-login .form-actions .form-submit:hover,
.block[id*="user-login"] .form-actions .form-submit:hover {
  background-color: #DC8802;
}
.block-login .form-actions .form-submit:active,
.block[id*="user-login"] .form-actions .form-submit:active {
  background-color: #DC5502;
}
@media only screen and (max-width: 960px) {
  .block-login .form-actions .form-submit,
  .block[id*="user-login"] .form-actions .form-submit {
    font-size: 16px;
    padding: 0.4rem 1em .5rem;
    background-color: #d9a70f;
    border-color: #d9a70f;
    color: #FFFFFF;
    border-width: 3px;
    margin-top: 0;
  }
  .block-login .form-actions .form-submit:hover,
  .block[id*="user-login"] .form-actions .form-submit:hover {
    background-color: transparent;
    color: #d9a70f;
  }
}
@media only screen and (max-width: 480px) {
  .block-login .form-actions .form-submit,
  .block[id*="user-login"] .form-actions .form-submit {
    font-size: 0.8125rem;
    padding: 0.5rem 1em .5rem;
    width: calc(100% - 8px);
    display: block;
    margin: 0 4px;
  }
}
.block-login label:not(.option),
.block[id*="user-login"] label:not(.option) {
  display: none;
}
.block-login input[type="text"],
.block[id*="user-login"] input[type="text"],
.block-login input[type="password"],
.block[id*="user-login"] input[type="password"],
.block-login input[type="email"],
.block[id*="user-login"] input[type="email"] {
  border-width: 3px;
  padding: .5rem .85rem;
}
.block-login .item-list ul,
.block[id*="user-login"] .item-list ul {
  list-style: none;
  padding: 0;
  font-size: 0.8125rem;
  padding: 0 .15rem;
}
.block-login .item-list ul li,
.block[id*="user-login"] .item-list ul li {
  margin: 0;
}
.block-login .item-list ul li:before,
.block[id*="user-login"] .item-list ul li:before {
  content: none;
}
/* --- [ Pager block ] --------------- */
.block-guide-pager,
.block[id*="guide-pager"] {
  background-color: #FFFFFF;
  margin-top: 1.8rem;
}
@media only screen and (max-width: 960px) {
  .block-guide-pager,
  .block[id*="guide-pager"] {
    margin-top: 2.15rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-guide-pager,
  .block[id*="guide-pager"] {
    margin-top: 1.65rem;
  }
}
.block-guide-pager > .content,
.block[id*="guide-pager"] > .content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
}
.block-guide-pager > .content > *,
.block[id*="guide-pager"] > .content > * {
  flex: 1 0 50%;
}
.block-guide-pager .prev-tab a,
.block[id*="guide-pager"] .prev-tab a,
.block-guide-pager .next-tab a,
.block[id*="guide-pager"] .next-tab a {
  display: block;
  padding: 1.2rem 1.35rem;
}
@media only screen and (max-width: 960px) {
  .block-guide-pager .prev-tab a,
  .block[id*="guide-pager"] .prev-tab a,
  .block-guide-pager .next-tab a,
  .block[id*="guide-pager"] .next-tab a {
    padding: .75rem 1.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-guide-pager .prev-tab a,
  .block[id*="guide-pager"] .prev-tab a,
  .block-guide-pager .next-tab a,
  .block[id*="guide-pager"] .next-tab a {
    padding: .6rem .75rem;
  }
}
.block-guide-pager .prev-tab,
.block[id*="guide-pager"] .prev-tab {
  text-align: left;
}
.block-guide-pager .next-tab,
.block[id*="guide-pager"] .next-tab {
  text-align: right;
}
/* --- [ Prompt/Notification block ] --------------- */
.block.notification {
  padding: 1rem 1.5rem;
  margin-bottom: 3rem;
  position: relative;
  background-image: none;
  border: none;
  background-color: #d0011b;
  color: #FFFFFF;
}
@media only screen and (max-width: 480px) {
  .block.notification {
    position: absolute;
    top: 3.45rem;
    left: -0.75rem;
    right: -0.75rem;
    z-index: 10;
  }
}
.block.notification.hasClose {
  padding-right: 8.75rem;
  max-height: 2000px;
  overflow: hidden;
  display: block;
  transform-origin: top center;
  backface-visibility: hidden;
  position: relative;
  transform: scale3d(1, 1, 1);
  transition: transform 150ms ease 0ms, max-height 150ms ease 0ms, margin-bottom 150ms ease 0ms, padding 0ms ease 150ms;
}
@media only screen and (max-width: 480px) {
  .block.notification.hasClose {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
    padding: 1.75rem 2rem 1.3rem;
  }
}
.block.notification.isClosed {
  padding: 0;
  max-height: 0;
  margin-bottom: 0;
  transform: scale3d(1, 0, 1);
}
.block.notification a {
  color: inherit;
  text-decoration: underline;
}
.block.notification a:hover {
  color: #1890fb;
}
.block.notification .button,
.block.notification button {
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.block.notification .close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  border: none;
  width: 8.75rem;
}
.block.notification .close .text {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
@media only screen and (max-width: 480px) {
  .block.notification .close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    width: 2.6rem;
    padding: 0;
    line-height: 1;
  }
}
.block.notification .close:after {
  content: '\00d7';
  font-size: 2.25rem;
}
@media only screen and (max-width: 960px) {
  .block.notification {
    margin-bottom: 2.5rem;
  }
}
/* --- [ Mixins ] --------------- */
/* --- [ Blog ] --------------- */
.view-blog-posts.view-display-id-blog_by_database > .view-content {
  color: #666666;
  list-style: none;
  padding: 0;
  font-size: 16px;
  clear: both;
}
.view-blog-posts.view-display-id-blog_by_database > .view-content li {
  margin: 0;
}
.view-blog-posts.view-display-id-blog_by_database > .view-content li:before {
  content: none;
}
.view-blog-posts.view-display-id-blog_by_database > .view-content .views-row ~ .views-row {
  margin-top: 1.45rem;
  margin-top: 1.25rem;
}
.view-blog-posts.view-display-id-blog_by_database > .view-content .views-row a {
  color: #FFFFFF;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.4;
  text-decoration: none;
  font-size: 12px;
  line-height: 1.273;
  font-weight: 400;
  color: #1890fb;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-right: .5rem;
  vertical-align: middle;
}
.view-blog-posts.view-display-id-blog_by_database > .view-content .views-row a:hover {
  text-decoration: underline;
}
.view-blog-posts.view-display-id-blog_by_database > .view-content .views-row a.active {
  cursor: default;
  text-decoration: underline;
}
.view-blog-posts.view-display-id-blog_by_database > .view-content .views-row a.active:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 960px) {
  .view-blog-posts.view-display-id-blog_by_database > .view-content .views-row a {
    width: auto;
  }
}
.view-blog-posts.view-display-id-blog_by_database > .view-content .views-field-title,
.view-blog-posts.view-display-id-blog_by_database > .view-content .views-field-created {
  display: inline;
}
.view-blog-posts.view-display-id-blog_by_database > .view-content .views-field-title a,
.view-blog-posts.view-display-id-blog_by_database > .view-content .views-field-created a {
  display: inline;
  margin-right: .4rem;
}
.view-blog-posts.view-display-id-blog_by_database > .view-content .views-field-created {
  position: relative;
  top: 1px;
}
.view-blog-posts.view-display-id-blog_by_database > .view-content .views-field-created:before {
  content: '\2022';
  color: inherit;
  margin-right: 0.4rem;
  display: inline;
  float: none;
  font-size: inherit;
  position: static;
}
.view-blog-posts.view-display-id-blog,
.view-blog-posts.view-blog-posts[class*="-page"] {
  margin-left: -0.875rem;
  margin-right: -0.875rem;
}
@media only screen and (min-width: 961px) {
  .view-blog-posts.view-display-id-blog,
  .view-blog-posts.view-blog-posts[class*="-page"] {
    margin-right: -0.7rem;
    margin-left: .8rem;
  }
}
.view-blog-posts.view-display-id-blog .view-content.masonry-processed,
.view-blog-posts.view-blog-posts[class*="-page"] .view-content.masonry-processed {
  list-style: none;
  padding: 0;
  position: relative;
  margin-bottom: -1.75rem;
}
.view-blog-posts.view-display-id-blog .view-content.masonry-processed li,
.view-blog-posts.view-blog-posts[class*="-page"] .view-content.masonry-processed li {
  margin: 0;
}
.view-blog-posts.view-display-id-blog .view-content.masonry-processed li:before,
.view-blog-posts.view-blog-posts[class*="-page"] .view-content.masonry-processed li:before {
  content: none;
}
.view-blog-posts.view-display-id-blog .view-content.masonry-processed > .masonry-item,
.view-blog-posts.view-blog-posts[class*="-page"] .view-content.masonry-processed > .masonry-item {
  padding: 0 .875rem 1.75rem;
  width: 50%;
}
@media only screen and (max-width: 700px) {
  .view-blog-posts.view-display-id-blog .view-content.masonry-processed > .masonry-item,
  .view-blog-posts.view-blog-posts[class*="-page"] .view-content.masonry-processed > .masonry-item {
    width: 100%;
  }
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping ~ .view-grouping {
  padding-top: 1.5rem;
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-header > h3 {
  font-size: 1.625rem;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  margin: 0;
  padding-bottom: .625rem;
  border-bottom: 2px solid #D7D7D7;
  margin-bottom: 1.1875rem;
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
}
@media only screen and (max-width: 480px) {
  .view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-header > h3 {
    font-size: 1.25rem;
  }
}
.zone-footer .view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-header > h3,
.ds-media-aside .view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-header > h3 {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  letter-spacing: 0.04rem;
  color: #4d5a6b;
  margin: 0;
  color: #898989;
}
@media only screen and (max-width: 480px) {
  .zone-footer .view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-header > h3,
  .ds-media-aside .view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-header > h3 {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-header > h3 {
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 960px) {
  .view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-header > h3 {
    color: #4d5a6b;
    font-size: 1.9375rem;
    font-weight: 700;
    line-height: 1.29;
    text-transform: uppercase;
    letter-spacing: 0.024rem;
  }
}
@media only screen and (max-width: 480px) {
  .view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-header > h3 {
    letter-spacing: 0.012rem;
    font-size: 1.6875rem;
    line-height: 1.556;
  }
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content > .views-row {
  flex: 0 1 auto;
  padding-right: 60px;
  width: 50%;
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  padding-bottom: 1.5rem;
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content > .views-row.menu-link-1366 {
  color: #1890fb;
  text-decoration: none;
  transition: all 240ms ease 0ms;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content > .views-row.menu-link-1366:hover {
  color: #1890fb;
  text-decoration: underline;
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content > .views-row.menu-link-1366.active {
  color: #1890fb;
  text-decoration: underline;
}
@media only screen and (max-width: 580px) {
  .view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content > .views-row {
    flex: 1 1 100%;
    width: 100%;
    padding-right: 0;
  }
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content .group-header {
  flex: 1 0 100%;
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content .group-header ~ * {
  margin-top: 1.5rem;
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content .group-header h4 {
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.389;
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content .group-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content .group-content .views-row[class*="menu-link"] {
  flex: 0 1 auto;
  padding-right: 60px;
  width: 50%;
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  padding-bottom: 1.5rem;
}
@media only screen and (max-width: 580px) {
  .view-menu.view-display-id-landing_page_eva > .view-content > .view-grouping > .view-grouping-content .group-content .views-row[class*="menu-link"] {
    flex: 1 1 100%;
    width: 100%;
    padding-right: 0;
  }
}
.node-homepage.view-mode-full .section-heading {
  letter-spacing: .15rem;
  margin-bottom: 1rem;
}
.view-taxonomy-term .view-content .views-row,
.view-news .view-content .views-row {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 2px solid #E6E6E6;
}
.view-taxonomy-term .view-content .views-row:last-child,
.view-news .view-content .views-row:last-child {
  border-bottom: none;
}
.view-taxonomy-term .more-link,
.view-news .more-link {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.view-grantees .grid .grid-container .views-row,
.view-events .grid .grid-container .views-row {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  vertical-align: top;
  height: 211px;
  width: 33.33%;
}
.view-grantees .grid .grid-container .views-row .node-teaser,
.view-events .grid .grid-container .views-row .node-teaser {
  flex: 1;
  max-height: 195px;
  height: 100%;
}
.view-grantees .grid .grid-container .views-row .node-teaser > a,
.view-events .grid .grid-container .views-row .node-teaser > a {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 960px) {
  .view-grantees .grid .grid-container .views-row,
  .view-events .grid .grid-container .views-row {
    padding-top: 1rem;
    padding-left: .5rem;
    padding-right: .5rem;
  }
  .view-grantees .grid .grid-container .views-row:nth-child(3n+1),
  .view-events .grid .grid-container .views-row:nth-child(3n+1) {
    padding-left: 0;
    float: left;
  }
  .view-grantees .grid .grid-container .views-row:nth-child(3n+3),
  .view-events .grid .grid-container .views-row:nth-child(3n+3) {
    padding-right: 0;
    float: right;
  }
}
@media only screen and (max-width: 960px) {
  .view-grantees .grid .grid-container .views-row,
  .view-events .grid .grid-container .views-row {
    width: 50%;
    padding-top: 0;
    margin-bottom: 1rem;
    height: 230px;
  }
  .view-grantees .grid .grid-container .views-row .node-teaser,
  .view-events .grid .grid-container .views-row .node-teaser {
    max-height: 230px;
  }
  .view-grantees .grid .grid-container .views-row.views-row-odd,
  .view-events .grid .grid-container .views-row.views-row-odd {
    padding-right: .5rem;
    padding-left: 0;
    float: left;
  }
  .view-grantees .grid .grid-container .views-row.views-row-even,
  .view-events .grid .grid-container .views-row.views-row-even {
    padding-left: .5rem;
    padding-right: 0;
    float: right;
  }
}
@media only screen and (max-width: 480px) {
  .view-grantees .grid .grid-container .views-row,
  .view-events .grid .grid-container .views-row {
    width: 100%;
    padding-bottom: 0;
  }
  .view-grantees .grid .grid-container .views-row.views-row-even,
  .view-events .grid .grid-container .views-row.views-row-even,
  .view-grantees .grid .grid-container .views-row.views-row-odd,
  .view-events .grid .grid-container .views-row.views-row-odd {
    padding-right: 0;
    padding-left: 0;
  }
}
.view-events.view-display-id-events_upcoming .grid .grid-container .views-row .node-teaser {
  max-height: none;
}
.view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-first {
  width: 66.6%;
  height: 422px;
  float: left;
}
.view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-first .node-event .background {
  width: 100%;
}
.view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-first .field-title h2 {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  color: #FFFFFF;
  margin-bottom: 0;
  letter-spacing: .17rem;
}
.view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-first .field {
  padding-bottom: 2rem;
}
.view-events.view-display-id-events_upcoming .grid .grid-container .views-row .field {
  padding-bottom: 1rem;
}
@media only screen and (min-width: 960px) {
  .view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-first {
    padding-bottom: 0;
    padding-top: 0;
    padding-right: .5rem;
  }
  .view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-2 {
    padding-bottom: .5rem;
    padding-top: 0;
    padding-left: .5rem;
    padding-right: 0;
    float: right;
  }
  .view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-3 {
    padding-top: .5rem;
    padding-left: .5rem;
    padding-right: 0;
    float: right;
  }
}
@media only screen and (max-width: 960px) {
  .view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-even {
    float: left;
    padding-left: 0;
    padding-right: 0.5rem;
  }
  .view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-odd {
    float: right;
    padding-right: 0;
    padding-left: 0.5rem;
  }
  .view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-first {
    width: 100%;
    padding-left: 0;
    height: 421px;
  }
}
@media only screen and (max-width: 480px) {
  .view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-even,
  .view-events.view-display-id-events_upcoming .grid .grid-container .views-row.views-row-odd {
    padding-right: 0;
    padding-left: 0;
  }
}
.view-output .view-events.view-display-id-events_past {
  margin-top: -1rem;
}
.view-people .grid .group-header > * {
  color: #4d5a6b;
  font-size: 1.9375rem;
  font-weight: 700;
  line-height: 1.29;
  text-transform: uppercase;
  letter-spacing: 0.024rem;
  border-bottom: 4px solid #E6E6E6;
  padding-bottom: 0.8125rem;
  letter-spacing: 0.19rem;
  margin-bottom: 0.83em;
}
.view-people .grid .group-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -1rem;
}
.view-people .grid .group-content .views-row {
  padding: 0 1rem 3.5rem;
}
@media only screen and (max-width: 480px) {
  .view-people .grid .group-content .views-row {
    width: 100%;
  }
}
.view-sponsors .view-content .grid-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}
.view-sponsors .view-content .grid-container .views-row {
  padding: 0 1rem 3.5rem;
}
.view-event-registrations .ajax-progress {
  display: none !important;
}
.view-event-registrations .views-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2.813rem;
}
.view-event-registrations .views-table caption {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  text-align: left;
  padding-bottom: 1rem;
  border-bottom: 4px solid #E6E6E6;
}
@media only screen and (max-width: 480px) {
  .view-event-registrations .views-table caption {
    font-size: 1.25rem;
  }
}
.view-event-registrations .views-table .field-title {
  color: #1890fb;
}
.view-event-registrations .views-table tr td {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 2px solid #E6E6E6;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
}
.view-event-registrations .views-table tr td:first-child {
  margin-top: 0;
}
.view-event-registrations .views-table tr td .commerce-add-to-cart > div > * ~ * {
  margin-top: 0;
}
.view-event-registrations .views-table tr td .commerce-add-to-cart .form-item-quantity {
  width: 55px;
  position: inherit;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0;
}
.view-event-registrations .views-table tr td .commerce-add-to-cart .form-item-quantity input[type="text"] {
  text-align: center;
}
.view-event-registrations .views-table tr td:first-child {
  width: 60%;
}
.view-event-registrations .views-table tr td:last-child {
  text-align: right;
}
@media only screen and (max-width: 960px) {
  .view-event-registrations .views-table tr td {
    width: 100%;
    text-align: left;
    border-bottom: none;
    padding: 0;
    margin-top: .5rem;
  }
  .view-event-registrations .views-table tr td:first-child {
    width: 100%;
    text-align: left;
    padding-left: 0;
  }
  .view-event-registrations .views-table tr td:last-child {
    text-align: left;
    padding-left: 0;
    border-bottom: 2px solid #E6E6E6;
  }
  .view-event-registrations .views-table tr td:last-child .form-item-quantity {
    margin-right: 15px;
  }
}
.view-event-registrations .views-table tr td input[type="submit"] {
  background-color: #1890fb;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
}
.view-event-registrations .views-table tr td input[type="submit"]:hover {
  background-color: #1374CA;
}
.view-event-registrations .views-table tr td input[type="submit"]:active {
  background-color: #0F5DA3;
}
.view-event-registrations .views-table tr:last-child td {
  border-bottom: none;
}
.view-footer {
  margin-bottom: 2rem;
}
.view-footer > .button {
  margin-top: 2rem;
}
.pager-bar {
  margin-top: 2.5rem;
  text-align: center;
  clear: both;
}
.pager {
  list-style: none;
  margin-top: 30px;
  padding: 0;
  background-color: transparent;
  padding-right: 6rem;
  position: relative;
}
.pager li {
  margin: 0;
}
.pager li:before {
  content: none;
}
.pager-default {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
}
.pager-left {
  width: 100%;
  padding: 0;
  padding-left: 6rem;
}
.pager-right {
  width: 100%;
  padding: 0;
  padding-right: 6rem;
}
.pager .pager-nav {
  background-color: transparent;
  overflow: hidden;
  position: relative;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.pager .pager-nav ul {
  list-style: none;
  padding: 0;
}
.pager .pager-nav ul li {
  margin: 0;
}
.pager .pager-nav ul li:before {
  content: none;
}
.pager .pager-nav ul li {
  display: inline-block;
}
.pager .pager-nav:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 0;
  left: auto;
  width: 1px;
}
.pager > li,
.pager .pager-nav ul li {
  color: #4d5a6b;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.154;
  letter-spacing: 0.006em;
  display: inline-block;
  vertical-align: middle;
  min-width: 2rem;
  text-align: center;
  line-height: initial;
}
@media only screen and (max-width: 480px) {
  .pager > li,
  .pager .pager-nav ul li {
    font-size: 1.25rem;
  }
}
.pager > li.pager-nav,
.pager .pager-nav ul li.pager-nav {
  width: auto;
}
.pager > li a,
.pager .pager-nav ul li a,
.pager > li span,
.pager .pager-nav ul li span {
  color: #4d5a6b;
  text-decoration: none;
  padding: 0rem;
  display: block;
  width: 100%;
  position: relative;
  white-space: nowrap;
  border-radius: 2000px;
}
.pager > li a:before,
.pager .pager-nav ul li a:before,
.pager > li span:before,
.pager .pager-nav ul li span:before {
  content: ' ';
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: 1.2rem;
  background-repeat: no-repeat;
}
.pager > li a:hover,
.pager .pager-nav ul li a:hover {
  background-color: transparent;
  color: #1890fb;
  font-weight: bold;
}
.pager > li.first,
.pager .pager-nav ul li.first {
  margin-right: 3.25rem;
}
.pager > li.pager-first a:before,
.pager .pager-nav ul li.pager-first a:before,
.pager > li.pager-first span:before,
.pager .pager-nav ul li.pager-first span:before {
  background-image: url("../images/icons/pager-left.svg");
  background-size: 2rem 2rem;
}
.pager > li.pager-first a:hover:before,
.pager .pager-nav ul li.pager-first a:hover:before {
  background-image: url("../images/icons/pager-left.svg");
}
.pager > li.pager-previous a,
.pager .pager-nav ul li.pager-previous a,
.pager > li.pager-previous span,
.pager .pager-nav ul li.pager-previous span {
  text-indent: -9999px;
  white-space: nowrap;
}
.pager > li.pager-previous a:before,
.pager .pager-nav ul li.pager-previous a:before,
.pager > li.pager-previous span:before,
.pager .pager-nav ul li.pager-previous span:before {
  background-image: url("../images/icons/pager-left.svg");
}
.pager > li.pager-previous a:hover:before,
.pager .pager-nav ul li.pager-previous a:hover:before {
  background-image: url("../images/icons/pager-left-blue.svg");
}
.pager > li.pager-next a:before,
.pager .pager-nav ul li.pager-next a:before,
.pager > li.pager-next span:before,
.pager .pager-nav ul li.pager-next span:before {
  background-image: url("../images/icons/pager-right.svg");
}
.pager > li.pager-next a:hover:before,
.pager .pager-nav ul li.pager-next a:hover:before {
  background-image: url("../images/icons/pager-right-blue.svg");
}
.pager > li.pager-last a:before,
.pager .pager-nav ul li.pager-last a:before,
.pager > li.pager-last span:before,
.pager .pager-nav ul li.pager-last span:before {
  background-image: url("../images/icons/pager-right.svg");
  background-size: 2rem 2rem;
}
.pager > li.pager-last a:hover:before,
.pager .pager-nav ul li.pager-last a:hover:before {
  background-image: url("../images/icons/pager-right.svg");
}
.pager > li.last,
.pager .pager-nav ul li.last {
  margin-left: 3.25rem;
}
.pager > li.pager-first a,
.pager .pager-nav ul li.pager-first a,
.pager > li.pager-previous a,
.pager .pager-nav ul li.pager-previous a,
.pager > li.pager-next a,
.pager .pager-nav ul li.pager-next a,
.pager > li.pager-last a,
.pager .pager-nav ul li.pager-last a {
  text-indent: -9999px;
  transition: opacity 240ms ease 0ms;
}
.pager > li.pager-first a:hover,
.pager .pager-nav ul li.pager-first a:hover,
.pager > li.pager-previous a:hover,
.pager .pager-nav ul li.pager-previous a:hover,
.pager > li.pager-next a:hover,
.pager .pager-nav ul li.pager-next a:hover,
.pager > li.pager-last a:hover,
.pager .pager-nav ul li.pager-last a:hover {
  color: #4d5a6b;
  background-color: transparent;
}
.pager > li.pager-current,
.pager .pager-nav ul li.pager-current {
  background-color: transparent;
  border: 3px solid transparent;
  border-radius: 2000px;
  text-align: center;
}
.pager > li.pager-current,
.pager .pager-nav ul li.pager-current,
.pager > li.pager-current a,
.pager .pager-nav ul li.pager-current a,
.pager > li.pager-current span,
.pager .pager-nav ul li.pager-current span {
  color: #650000;
  font-weight: 700;
}
.pager > li.pager-current a,
.pager .pager-nav ul li.pager-current a,
.pager > li.pager-current span,
.pager .pager-nav ul li.pager-current span {
  padding: 0rem;
}
.pager > li.pager-ellipsis,
.pager .pager-nav ul li.pager-ellipsis {
  display: none;
}
.pager-load-more {
  text-align: center;
  padding: 0;
}
.pager-load-more,
.pager-load-more:first-child {
  margin-top: 2.55rem;
}
.pager-load-more > li.pager-next {
  margin: 0 auto;
  width: auto;
  position: relative;
}
.pager-load-more > li.pager-next a {
  color: #4d5a6b;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  display: inline-block;
  margin: 0 auto;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 1.25rem 2.186rem;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 5rem;
  appearance: none;
  opacity: 1;
  border: none;
  transition: color 240ms ease 0ms, background-color 240ms ease 0ms, border-color 240ms ease 0ms;
  background: #1890fb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  cursor: pointer;
  text-indent: 0;
  width: auto;
}
.pager-load-more > li.pager-next a:hover {
  background: #1374CA;
  color: #FFFFFF;
  text-decoration: none;
}
.pager-load-more > li.pager-next a:active {
  background: #0F5DA3;
  color: #FFFFFF;
  transition: none;
}
.pager-load-more > li.pager-next a:focus {
  outline: none;
}
.pager-load-more > li.pager-next a:disabled {
  background: #4a4a4a;
  color: #898989;
  opacity: .3;
  cursor: not-allowed;
}
.pager-load-more > li.pager-next a:disabled:hover {
  background: #4a4a4a;
  color: #898989;
}
.pager-load-more > li.pager-next a.admin,
.pager-load-more > li.pager-next a.admin-extra {
  border: none;
  font-size: 12px;
  padding: 6px;
}
.pager-load-more > li.pager-next a.admin-extra {
  color: #1890fb;
}
.pager-load-more > li.pager-next a:before {
  content: none;
}
.pager-load-more > li.pager-next > span {
  display: none;
}
.pager-load-more > li.pager-next .ajax-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  background: rgba(0, 0, 0, 0.75);
  animation: fadein 500ms;
}
.pager-load-more > li.pager-next .ajax-progress .throbber {
  background-image: url("../images/icons/triple-dots--white.svg");
  background-repeat: no-repeat;
  background-position: center;
  float: none;
  margin: 0 auto;
  width: 40px;
  height: 100%;
  animation: fadeinout 750ms infinite;
}
.pager-load-more.pager-load-more-empty {
  display: none;
}
/* --- [ Progress Bar (System) ] --------------- */
.progress .bar {
  border: none;
  background-color: #D7D7D7;
}
.progress .filled {
  background-color: #1890fb;
  height: 5px;
}
.progress .percentage {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;
  float: right;
}
.progress .percentage .field-contents {
  display: inline;
}
.progress .percentage a {
  text-decoration: none;
  color: #1890fb;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.progress .percentage a:hover {
  color: #1890fb;
}
.progress .percentage.field-date [class*="date-display"] {
  display: inline;
}
.progress .message {
  color: #898989;
  font-size: 0.8125rem;
  margin-right: 40px;
}
.flexslider {
  list-style: none;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  display: block;
}
.flexslider li {
  margin: 0;
}
.flexslider li:before {
  content: none;
}
.flexslider img {
  width: 100%;
  max-width: auto;
}
.flexslider.default .slides {
  order: 3;
  height: 100%;
}
.flexslider.default .slides > li.flex-active-slide {
  display: block !important;
}
.flexslider.default .slide--inner {
  position: relative;
}
.flexslider.default .slide--tray {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2.25rem;
  background-color: rgba(0, 0, 0, 0.6);
  display: table;
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .flexslider.default .slide--tray {
    position: relative;
  }
}
.hasNoCaptions.flexslider.default .slide--tray,
.hasSoleSlide.flexslider.default .slide--tray {
  display: none;
}
.flexslider.default .slide--tray > * {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  vertical-align: middle;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 3.75rem;
}
.flexslider.default .slide--tray > *.slide--counter {
  justify-content: center;
}
.flexslider.default .slide--tray > *.flex-direction-nav {
  min-width: 10.5rem;
  padding: 0;
}
.flexslider.default .slide--tray > *.flex-direction-nav > li {
  position: absolute;
  top: 0;
  bottom: 0;
}
.flexslider.default .slide--tray > *.flex-direction-nav > li:nth-child(odd) {
  left: 0;
}
.flexslider.default .slide--tray > *.flex-direction-nav > li:nth-child(even) {
  right: 0;
}
.flexslider.default .flex-direction-nav {
  margin: 0 !important;
  font-size: 0;
  order: 1;
  position: absolute;
  bottom: 53%;
  width: 100%;
}
.hasSoleSlide.flexslider.default .flex-direction-nav {
  display: none;
}
.flexslider.default .flex-direction-nav > li {
  display: inline-block;
  font-size: 1rem;
}
.flexslider.default .flex-direction-nav > li:last-child {
  float: right;
}
.flexslider.default .flex-direction-nav a {
  display: block;
  opacity: 1;
  visibility: visible;
  width: 5.25rem;
  height: 2.25rem;
  line-height: 1;
  color: transparent;
  text-shadow: none;
  box-shadow: none;
  top: auto;
  bottom: 0;
  margin: 0;
  position: relative;
}
.flexslider.default .flex-direction-nav a:before,
.flexslider.default .flex-direction-nav a:after {
  display: block;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: auto;
  font-family: inherit;
  font-size: 2.25rem;
  line-height: 1;
  text-align: center;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.75rem;
  transition: all 240ms ease 0ms;
}
.flexslider.default .flex-direction-nav a:before {
  opacity: 1;
}
.flexslider.default .flex-direction-nav .flex-prev {
  left: 0;
}
.flexslider.default .flex-direction-nav .flex-prev:before {
  font-family: inherit;
  content: '\2039';
  color: transparent;
  text-shadow: none;
  background-image: url("../images/icons/dropdown-arrow--white.svg");
  transform: translateX(0) translateY(-50%) scale3d(1, 1, 1) rotate(90deg);
}
.flexslider.default .flex-direction-nav .flex-next {
  right: 0;
}
.flexslider.default .flex-direction-nav .flex-next:before {
  font-family: inherit;
  content: '\203A';
  color: transparent;
  text-shadow: none;
  background-image: url("../images/icons/dropdown-arrow--white.svg");
  transform: translateX(0) translateY(-50%) scale3d(1, 1, 1) rotate(-90deg);
}
.flexslider.default .flex-control-nav {
  display: none;
  margin: 0;
  position: static;
  order: 2;
}
.truncatorEnabled.flexslider.default .flex-caption {
  background: rgba(0, 0, 0, 0.8);
  text-shadow: 0 0 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 1rem 1.125rem .75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.truncatorEnabled.flexslider.default .flex-caption.flex-caption-empty {
  opacity: .2;
}
.truncatorEnabled.flexslider.default .flex-caption.hasTruncToggle {
  padding: 1rem 11.25px 0.75rem 6px;
  height: 2.25rem;
}
.hasSoleSlide.truncatorEnabled.flexslider.default .flex-caption.hasTruncToggle {
  padding: 1rem 6px 0.75rem 0.75rem;
}
.truncatorEnabled.flexslider.default .flex-caption.hasTruncToggle:after {
  content: none;
  position: absolute;
  display: block;
  background-color: transparent;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2.25rem;
  z-index: 0;
}
.truncatorEnabled.flexslider.default .flex-direction-nav {
  position: static;
  float: none;
  display: block;
}
.truncatorEnabled.flexslider.default .flex-direction-nav > li {
  display: inline;
}
.truncatorEnabled.flexslider.default .flex-direction-nav a {
  position: absolute;
}
.flexslider.default .toggleCaptionTruncation {
  position: absolute;
  bottom: 0;
  right: 5.25rem;
  z-index: 1;
  width: 5.25rem;
  height: 2.25rem;
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  background-image: url("../images/icons/triple-dots.svg") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  color: #FFFFFF !important;
  text-indent: -9999px;
  box-shadow: none;
  transition: background-color 240ms ease 0ms;
}
.hasSoleSlide.flexslider.default .toggleCaptionTruncation {
  right: 0;
}
.hasSoleSlide.flexslider.default .toggleCaptionTruncation.captionEmpty {
  display: none;
}
.flexslider.default .toggleCaptionTruncation:hover {
  opacity: .5;
}
.flexslider.default .toggleCaptionTruncation:hover,
.flexslider.default .toggleCaptionTruncation:focus {
  transform: none;
  box-shadow: none;
}
.flexslider.default .toggleCaptionTruncation.truncated {
  background-image: url("../images/icons/triple-dots--white.svg") !important;
}
.flexslider.default .flex-caption-empty .toggleCaptionTruncation {
  display: none;
}
.flexslider.default.hasFullCaption .flex-caption.hasTruncToggle {
  display: block;
  white-space: normal;
  padding: 2.25rem 1.125rem 3px;
  height: auto;
}
.flexslider.default.hasFullCaption .flex-caption.hasTruncToggle.flex-caption-empty {
  padding: 1rem 1.125rem .75rem;
}
.flexslider.default.hasFullCaption .flex-caption.hasTruncToggle:after {
  content: ' ';
}
@media only screen and (min-width: 961px) {
  .section-header .clearfix:before,
  .section-header .clearfix:after {
    content: none;
  }
  .section-header .quick-menu {
    display: none;
  }
  .section-header .menu {
    list-style: none;
    padding: 0;
  }
  .section-header .menu li {
    margin: 0;
  }
  .section-header .menu li:before {
    content: none;
  }
  .section-header .zone-branding-wrapper .zone-branding {
    background: #4d5a6b;
  }
  .section-header .zone-branding-wrapper .zone-branding .banner-image {
    height: 400px;
  }
  .section-header .zone-branding-wrapper .zone-branding .region-branding-homepage {
    height: 100%;
  }
  .section-header .zone-branding-wrapper .zone-branding .background {
    background-size: cover;
    position: relative;
  }
  .section-header .zone-branding-wrapper .zone-branding .background:before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: #000000;
    opacity: 0.6;
    mix-blend-mode: multiply;
  }
  .section-header .zone-branding-wrapper .zone-branding .ma_toggleSwitch {
    display: none;
  }
  .section-header .zone-branding-wrapper .zone-branding .branding-center {
    padding: 4rem 11rem 4rem;
    z-index: 5;
  }
  .section-header .region-branding-inner {
    padding: 0 2.063rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-basis: 100%;
    position: static;
  }
  .section-header .region-branding-inner > * {
    flex: 0 1 auto;
    z-index: 2;
  }
  .section-header .mobile-top-level-nav {
    display: none;
  }
  .section-header .top-level-nav {
    position: static;
    display: flex;
  }
  .section-header .top-level-nav .block-title {
    display: none;
  }
  .section-header .top-level-nav > .content,
  .section-header .top-level-nav .menu-block-wrapper,
  .section-header .top-level-nav .menu-block-wrapper > .menu,
  .section-header .top-level-nav .menu-block-wrapper > .menu > li,
  .section-header .top-level-nav .tlAccSwitchWrap {
    display: inline-block;
  }
  .section-header .top-level-nav > .content:before,
  .section-header .top-level-nav > .content:after {
    content: none;
  }
  .section-header .top-level-nav .menu-block-wrapper > .menu > li > a,
  .section-header .top-level-nav .menu-block-wrapper > .menu > li > .nolink,
  .section-header .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
  .section-header .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .section-header .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
  .section-header .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    max-width: 100%;
    width: auto;
    height: 100%;
  }
  .section-header .block-search.isClosed {
    display: none;
  }
  .section-header .zone-menu-wrapper {
    flex-basis: 100%;
    background-color: #1890fb;
  }
  .section-header .region-menu-inner {
    display: flex;
    flex-wrap: nowrap;
    padding: 0 2.063rem;
    position: relative;
  }
  .section-header .region-menu-inner .block.donate-button {
    position: absolute;
    top: 70px;
    z-index: 7;
    margin: 0;
    margin-left: auto;
    right: 0;
    padding: 0 2.063rem;
  }
  .section-header .region-menu-inner .block.block-search {
    flex-grow: 0;
    margin: 0;
    position: absolute;
    margin-left: auto;
    right: 0;
    padding: 0 2.063rem;
  }
  .section-header .region-menu-inner .ma_toggleSwitch {
    margin: 0;
    margin-left: auto;
    flex-grow: 0;
    width: 50px;
  }
  .section-header .menu-block-wrapper > .menu > li {
    display: inline-block;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .section-header {
    background-color: #1374CA;
    position: relative;
  }
  .section-header .menu {
    list-style: none;
    padding: 0;
  }
  .section-header .menu li {
    margin: 0;
  }
  .section-header .menu li:before {
    content: none;
  }
  .section-header .zone-menu-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 100%;
  }
  .section-header .zone-menu-wrapper .block-menu-block {
    order: 2;
    margin-top: 3px;
    position: relative;
  }
  .section-header .zone-menu-wrapper .block-menu-block ~ .block-menu-block:before {
    background-color: #FFFFFF;
  }
  .section-header .zone-menu-wrapper .block-menu-block:first-child[aria-hidden] + .block-menu-block:before {
    background-color: transparent;
  }
  .section-header .zone-menu-wrapper .block-search {
    order: 1;
  }
  .section-header .zone-menu-wrapper .block.donate-button {
    order: 3;
    margin: 0 auto;
    padding-top: 0.5rem;
    padding-bottom: 3.125rem;
    text-align: center;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper {
    position: relative;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper.menu-name-main-menu {
    padding-top: 1rem;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink {
    color: #4d5a6b;
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 1.308;
    letter-spacing: .01rem;
    text-decoration: none;
    line-height: 1.8;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    max-height: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    color: #FFFFFF;
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.273;
    text-transform: none;
    letter-spacing: 0.004em;
    margin-bottom: 0;
    display: block;
    letter-spacing: 0.01em;
    padding: 0;
    font-weight: 400;
    padding: 0.5rem 27px;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a .menu-item-inner,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink .menu-item-inner {
    position: relative;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a:hover,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink:hover {
    text-decoration: underline;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a .menu-item-inner:before,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink .menu-item-inner:before {
    content: none;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a .menu-item-inner,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink .menu-item-inner {
    display: inline-block;
    vertical-align: middle;
  }
  .section-header .zone-branding-wrapper {
    order: initial;
    flex-basis: 100%;
    padding: 0;
  }
  .section-header .zone-branding-wrapper .region-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .section-header .zone-branding-wrapper .region-inner > * {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .section-header .zone-branding-wrapper .region-inner .branding-top {
    width: 100%;
    z-index: 41;
  }
  .section-header .zone-branding-wrapper .region-inner .branding-center {
    padding: 3.6rem 7rem 3.6rem;
  }
  .section-header .zone-branding-wrapper .region-inner > .block-logo {
    flex-basis: auto;
    padding-right: 14px;
    padding-left: 14px;
    width: 30%;
  }
  .section-header .zone-branding-wrapper .region-inner > .block-logo > .content {
    width: 100%;
    height: 100%;
  }
  .section-header .zone-branding-wrapper .region-inner > .mainSearchToggle {
    display: none;
  }
  .section-header .zone-branding-wrapper .region-inner > .block-mobile-search {
    flex: 1 0 0%;
    padding-right: 24px;
    padding-left: 24px;
  }
  .section-header .mobile-top-level-nav {
    display: none;
  }
  .section-header .top-level-nav .block-title {
    display: none;
  }
  .section-header .zone-branding-wrapper {
    background-color: #1374CA;
    order: initial;
    padding: 0;
  }
  .section-header .zone-branding-wrapper .zone-branding .banner-image {
    height: 350px;
  }
  .section-header .zone-branding-wrapper .zone-branding .region-branding-homepage {
    height: 100%;
  }
  .section-header .zone-branding-wrapper .zone-branding .background {
    background-size: cover;
    position: relative;
  }
  .section-header .zone-branding-wrapper .zone-branding .background:before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: #4d5a6b;
    opacity: 0.7;
    mix-blend-mode: multiply;
  }
  .section-header .zone-menu-wrapper.isOpen + .zone-branding-wrapper .background {
    background-size: 0;
  }
  .section-header .zone-menu-wrapper.isOpen + .zone-branding-wrapper .branding-top,
  .section-header .zone-menu-wrapper.isOpen + .zone-branding-wrapper .branding-center {
    background-color: #1374CA;
  }
  .section-header .region-branding-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }
  .section-header .region-branding-inner > * {
    flex: 1 1 0%;
    z-index: 2;
  }
  .section-header .region-branding-inner .top-level-nav {
    order: 10;
    width: 100%;
    background-color: #4d5a6b;
    flex: 1 0 100%;
  }
  .section-header .region-branding-inner .top-level-nav > .content,
  .section-header .region-branding-inner .top-level-nav .menu-block-wrapper,
  .section-header .region-branding-inner .top-level-nav .menu-block-wrapper > .menu,
  .section-header .region-branding-inner .top-level-nav .tlAccSwitchWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 auto;
  }
  .section-header .region-branding-inner .top-level-nav > .content:before,
  .section-header .region-branding-inner .top-level-nav > .content:after {
    content: none;
  }
  .section-header .region-branding-inner .top-level-nav .menu-block-wrapper > .menu {
    justify-content: center;
  }
  .section-header .region-branding-inner .top-level-nav .menu-block-wrapper > .menu > li {
    display: flex;
  }
  .section-header .zone-menu-wrapper {
    flex-basis: 100%;
    background-color: #1374CA;
    position: absolute;
    top: 91px;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 100;
  }
  .section-header .zone-menu-wrapper a {
    color: #FFFFFF;
  }
  .section-header .region-menu-inner {
    display: flex;
    flex-direction: column;
    padding: 3rem 5rem 0 5.625rem;
  }
  .section-header .menu-block-wrapper > .menu > li {
    display: inline-block;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) and only screen and (min-width: 481px) and (max-width: 960px) {
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink {
    font-size: 16px;
    padding: .5rem 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .section-header {
    background-color: #1374CA;
    position: relative;
  }
  .section-header .menu {
    list-style: none;
    padding: 0;
  }
  .section-header .menu li {
    margin: 0;
  }
  .section-header .menu li:before {
    content: none;
  }
  .section-header .zone-menu-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 100%;
  }
  .section-header .zone-menu-wrapper .block-menu-block {
    order: 2;
    margin-top: 3px;
    position: relative;
  }
  .section-header .zone-menu-wrapper .block-menu-block ~ .block-menu-block:before {
    background-color: #FFFFFF;
  }
  .section-header .zone-menu-wrapper .block-menu-block:first-child[aria-hidden] + .block-menu-block:before {
    background-color: transparent;
  }
  .section-header .zone-menu-wrapper .block-search {
    order: 1;
  }
  .section-header .zone-menu-wrapper .block.donate-button {
    order: 3;
    margin: 0 auto;
    padding-top: 0.5rem;
    padding-bottom: 3.125rem;
    text-align: center;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper {
    position: relative;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper.menu-name-main-menu {
    padding-top: 1rem;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink {
    color: #4d5a6b;
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 1.308;
    letter-spacing: .01rem;
    text-decoration: none;
    line-height: 1.8;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    max-height: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    color: #FFFFFF;
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.273;
    text-transform: none;
    letter-spacing: 0.004em;
    margin-bottom: 0;
    display: block;
    letter-spacing: 0.01em;
    padding: 0;
    font-weight: 400;
    padding: 0.5rem 27px;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a .menu-item-inner,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink .menu-item-inner {
    position: relative;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a:hover,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink:hover {
    text-decoration: underline;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a .menu-item-inner:before,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink .menu-item-inner:before {
    content: none;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a .menu-item-inner,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink .menu-item-inner {
    display: inline-block;
    vertical-align: middle;
  }
  .section-header .zone-branding-wrapper {
    order: initial;
    flex-basis: 100%;
    padding: 0;
  }
  .section-header .zone-branding-wrapper .region-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .section-header .zone-branding-wrapper .region-inner > * {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .section-header .zone-branding-wrapper .region-inner .branding-top {
    width: 100%;
    z-index: 41;
  }
  .section-header .zone-branding-wrapper .region-inner .branding-center {
    padding: 3.6rem 7rem 3.6rem;
  }
  .section-header .zone-branding-wrapper .region-inner > .block-logo {
    flex-basis: auto;
    padding-right: 14px;
    padding-left: 14px;
    width: 30%;
  }
  .section-header .zone-branding-wrapper .region-inner > .block-logo > .content {
    width: 100%;
    height: 100%;
  }
  .section-header .zone-branding-wrapper .region-inner > .mainSearchToggle {
    display: none;
  }
  .section-header .zone-branding-wrapper .region-inner > .block-mobile-search {
    flex: 1 0 0%;
    padding-right: 24px;
    padding-left: 24px;
  }
  .section-header .mobile-top-level-nav {
    display: none;
  }
  .section-header .top-level-nav .block-title {
    display: none;
  }
  .section-header .zone-branding-wrapper {
    background-color: #1374CA;
    order: initial;
    padding: 0;
  }
  .section-header .zone-branding-wrapper .zone-branding .banner-image {
    height: 350px;
  }
  .section-header .zone-branding-wrapper .zone-branding .region-branding-homepage {
    height: 100%;
  }
  .section-header .zone-branding-wrapper .zone-branding .background {
    background-size: cover;
    position: relative;
  }
  .section-header .zone-branding-wrapper .zone-branding .background:before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: #4d5a6b;
    opacity: 0.7;
    mix-blend-mode: multiply;
  }
  .section-header .zone-menu-wrapper.isOpen + .zone-branding-wrapper .background {
    background-size: 0;
  }
  .section-header .zone-menu-wrapper.isOpen + .zone-branding-wrapper .branding-top,
  .section-header .zone-menu-wrapper.isOpen + .zone-branding-wrapper .branding-center {
    background-color: #1374CA;
  }
  .section-header .region-branding-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }
  .section-header .region-branding-inner > * {
    flex: 1 1 0%;
    z-index: 2;
  }
  .section-header .region-branding-inner .top-level-nav {
    order: 10;
    width: 100%;
    background-color: #4d5a6b;
    flex: 1 0 100%;
  }
  .section-header .region-branding-inner .top-level-nav > .content,
  .section-header .region-branding-inner .top-level-nav .menu-block-wrapper,
  .section-header .region-branding-inner .top-level-nav .menu-block-wrapper > .menu,
  .section-header .region-branding-inner .top-level-nav .tlAccSwitchWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 auto;
  }
  .section-header .region-branding-inner .top-level-nav > .content:before,
  .section-header .region-branding-inner .top-level-nav > .content:after {
    content: none;
  }
  .section-header .region-branding-inner .top-level-nav .menu-block-wrapper > .menu {
    justify-content: center;
  }
  .section-header .region-branding-inner .top-level-nav .menu-block-wrapper > .menu > li {
    display: flex;
  }
  .section-header .zone-menu-wrapper {
    flex-basis: 100%;
    background-color: #1374CA;
    position: absolute;
    top: 91px;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 100;
  }
  .section-header .zone-menu-wrapper a {
    color: #FFFFFF;
  }
  .section-header .region-menu-inner {
    display: flex;
    flex-direction: column;
    padding: 3rem 5rem 0 5.625rem;
  }
  .section-header .menu-block-wrapper > .menu > li {
    display: inline-block;
  }
  .section-header .region-menu-inner {
    padding: 0rem 1rem;
  }
  .section-header .zone-menu-wrapper {
    top: 70px;
    padding-top: 20px;
  }
  .section-header .zone-menu-wrapper .menu-block-wrapper.menu-name-main-menu {
    padding-top: 0;
  }
  .section-header .zone-menu-wrapper .block-menu-block {
    margin-top: 11px;
  }
  .section-header .zone-branding-wrapper .region-inner .branding-center {
    padding: 4rem 1.9rem 4rem;
  }
}
@media only screen and (max-width: 480px) and only screen and (min-width: 481px) and (max-width: 960px) {
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > a,
  .section-header .zone-menu-wrapper .menu-block-wrapper > .menu > li > .nolink {
    font-size: 16px;
    padding: .5rem 1rem;
  }
}
.front.node-type-homepage #page-title {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.front.node-type-homepage .region-branding-inner .branding-center {
  background-image: url(../images/60th-anniversary-justice-ball-graphic-light.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center -50px;
  padding-top: 0;
  transform: translateY(-35px);
  width: 100%;
  height: 600px;
}
@media only screen and (max-width: 960px) {
  .front.node-type-homepage .region-branding-inner .branding-center {
    height: 350px;
    background-position: center -35px;
  }
}
.front.node-type-homepage .field-box-icon img {
  clip-path: inset(71px 0 0 0);
}
.front.node-type-homepage .section-header .zone-branding-wrapper .zone-branding {
  height: 750px;
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .front.node-type-homepage .section-header .zone-branding-wrapper .zone-branding {
    height: 550px;
  }
}
@media only screen and (max-width: 480px) {
  .front.node-type-homepage .section-header .zone-branding-wrapper .zone-branding {
    height: 450px;
  }
}
.page-node-799 .section-header .zone-branding-wrapper .zone-branding .banner-image {
  min-height: 600px;
}
@media only screen and (min-width: 961px) and (max-width: 960px) {
  .page-node-799 .section-header .zone-branding-wrapper .zone-branding .banner-image {
    min-height: 700px;
  }
}
@media only screen and (min-width: 961px) {
  .page-node-799 .section-header .zone-branding-wrapper .zone-branding .banner-image {
    min-height: 700px;
  }
}
.page-node-799 .section-header .zone-branding-wrapper .zone-branding .background {
  min-height: 600px;
}
@media only screen and (min-width: 961px) {
  .page-node-799 .section-header .zone-branding-wrapper .zone-branding .background {
    min-height: 640px;
  }
}
#node-event-799.node-teaser h2 {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.top-level-nav .menu-block-wrapper,
.mobile-top-level-nav .menu-block-wrapper {
  z-index: 30;
}
.top-level-nav .menu-block-wrapper .menu,
.mobile-top-level-nav .menu-block-wrapper .menu {
  list-style: none;
  padding: 0;
  position: static;
}
.top-level-nav .menu-block-wrapper .menu li,
.mobile-top-level-nav .menu-block-wrapper .menu li {
  margin: 0;
}
.top-level-nav .menu-block-wrapper .menu li:before,
.mobile-top-level-nav .menu-block-wrapper .menu li:before {
  content: none;
}
.top-level-nav .menu-block-wrapper .menu-title-layout-group > .nolink,
.mobile-top-level-nav .menu-block-wrapper .menu-title-layout-group > .nolink {
  display: none !important;
}
.top-level-nav .menu-block-wrapper .menu-title-search .mainSearchToggleProxy,
.mobile-top-level-nav .menu-block-wrapper .menu-title-search .mainSearchToggleProxy {
  background-image: url("../images/icons/search-white.svg") !important;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 18px 18px;
  background-color: transparent !important;
}
.top-level-nav .menu-block-wrapper .menu-title-search .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper .menu-title-search .menu-item-inner {
  white-space: nowrap;
  text-indent: -9999px;
  overflow: hidden;
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper .menu-title-search,
  .mobile-top-level-nav .menu-block-wrapper .menu-title-search {
    display: none !important;
  }
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper,
  .mobile-top-level-nav .menu-block-wrapper {
    text-align: left;
  }
}
.top-level-nav .menu-block-wrapper a,
.mobile-top-level-nav .menu-block-wrapper a,
.top-level-nav .menu-block-wrapper .nolink,
.mobile-top-level-nav .menu-block-wrapper .nolink {
  display: block;
}
.top-level-nav .menu-block-wrapper a .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper a .menu-item-inner,
.top-level-nav .menu-block-wrapper .nolink .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper .nolink .menu-item-inner {
  display: block;
}
.top-level-nav .menu-block-wrapper > .menu > li,
.mobile-top-level-nav .menu-block-wrapper > .menu > li {
  height: 50px;
  border-left: 2px solid #1374CA;
  position: relative;
}
.top-level-nav .menu-block-wrapper > .menu > li:last-child,
.mobile-top-level-nav .menu-block-wrapper > .menu > li:last-child {
  border-right: 2px solid #1374CA;
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li {
    height: inherit;
    border: none;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li > a,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > a,
.top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
.top-level-nav .menu-block-wrapper > .menu > li > .nolink,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink,
.top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink {
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  color: #FFFFFF;
  text-decoration: none;
  line-height: 1.8;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  max-height: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink {
    font-size: 16px;
    padding: .5rem 1rem;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li > a .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > a .menu-item-inner,
.top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a .menu-item-inner,
.top-level-nav .menu-block-wrapper > .menu > li > .nolink .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink .menu-item-inner,
.top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink .menu-item-inner {
  position: relative;
}
.top-level-nav .menu-block-wrapper > .menu > li > a:hover,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > a:hover,
.top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a:hover,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a:hover,
.top-level-nav .menu-block-wrapper > .menu > li > .nolink:hover,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink:hover,
.top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink:hover,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink {
    color: #4d5a6b;
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 1.308;
    letter-spacing: .01rem;
    text-decoration: none;
    line-height: 1.8;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    max-height: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    color: #FFFFFF;
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.273;
    text-transform: none;
    letter-spacing: 0.004em;
    margin-bottom: 0;
    display: block;
    letter-spacing: 0.01em;
    padding: 0;
    padding: 0.9rem 1.65rem 0.8rem;
    padding-right: 1rem;
    flex: 0 1 auto;
    width: auto;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > a .menu-item-inner,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a .menu-item-inner,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a .menu-item-inner,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a .menu-item-inner,
  .top-level-nav .menu-block-wrapper > .menu > li > .nolink .menu-item-inner,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink .menu-item-inner,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink .menu-item-inner,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink .menu-item-inner {
    position: relative;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > a:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .nolink:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink:hover {
    text-decoration: underline;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > a .menu-item-inner:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a .menu-item-inner:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a .menu-item-inner:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a .menu-item-inner:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .nolink .menu-item-inner:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink .menu-item-inner:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink .menu-item-inner:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink .menu-item-inner:before {
    content: none;
  }
}
@media only screen and (max-width: 960px) and only screen and (min-width: 481px) and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink {
    font-size: 16px;
    padding: .5rem 1rem;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li > a:hover,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > a:hover,
.top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a:hover,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a:hover,
.top-level-nav .menu-block-wrapper > .menu > li > .nolink:hover,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink:hover,
.top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink:hover,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink:hover {
  text-decoration: none;
}
.top-level-nav .menu-block-wrapper > .menu > li > a span,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > a span {
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  color: #FFFFFF;
  text-decoration: none;
  line-height: 1.8;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  max-height: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li > a span,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a span {
    font-size: 16px;
    padding: .5rem 1rem;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li > a span .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > a span .menu-item-inner {
  position: relative;
}
.top-level-nav .menu-block-wrapper > .menu > li > a span:hover,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > a span:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li > a span,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a span {
    color: #4d5a6b;
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 1.308;
    letter-spacing: .01rem;
    text-decoration: none;
    line-height: 1.8;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    max-height: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    color: #FFFFFF;
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.273;
    text-transform: none;
    letter-spacing: 0.004em;
    margin-bottom: 0;
    display: block;
    letter-spacing: 0.01em;
    padding: 0;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > a span .menu-item-inner,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a span .menu-item-inner {
    position: relative;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > a span:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a span:hover {
    text-decoration: underline;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > a span .menu-item-inner:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a span .menu-item-inner:before {
    content: none;
  }
}
@media only screen and (max-width: 960px) and only screen and (min-width: 481px) and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li > a span,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a span {
    font-size: 16px;
    padding: .5rem 1rem;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li > a span:hover,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > a span:hover {
  text-decoration: none;
}
.top-level-nav .menu-block-wrapper .menu .menu,
.mobile-top-level-nav .menu-block-wrapper .menu .menu {
  text-align: justify;
  letter-spacing: -1em;
  line-height: 0;
}
.top-level-nav .menu-block-wrapper .menu .menu > *,
.mobile-top-level-nav .menu-block-wrapper .menu .menu > * {
  letter-spacing: normal;
  text-align: left;
  line-height: 1.308;
}
.top-level-nav .menu-block-wrapper .menu .menu:after,
.mobile-top-level-nav .menu-block-wrapper .menu .menu:after {
  content: ' ';
  display: inline-block;
  width: 100%;
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper .menu .menu:after,
  .mobile-top-level-nav .menu-block-wrapper .menu .menu:after {
    content: none;
  }
}
.top-level-nav .menu-block-wrapper .menu .menu a,
.mobile-top-level-nav .menu-block-wrapper .menu .menu a,
.top-level-nav .menu-block-wrapper .menu .menu .nolink,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .nolink {
  color: #FFFFFF;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.4;
  text-decoration: none;
}
.top-level-nav .menu-block-wrapper .menu .menu a:hover,
.mobile-top-level-nav .menu-block-wrapper .menu .menu a:hover,
.top-level-nav .menu-block-wrapper .menu .menu .nolink:hover,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .nolink:hover {
  text-decoration: underline;
}
.top-level-nav .menu-block-wrapper .menu .menu a.active,
.mobile-top-level-nav .menu-block-wrapper .menu .menu a.active,
.top-level-nav .menu-block-wrapper .menu .menu .nolink.active,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .nolink.active {
  cursor: default;
  text-decoration: underline;
}
.top-level-nav .menu-block-wrapper .menu .menu a.active:hover,
.mobile-top-level-nav .menu-block-wrapper .menu .menu a.active:hover,
.top-level-nav .menu-block-wrapper .menu .menu .nolink.active:hover,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .nolink.active:hover {
  text-decoration: underline;
}
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > a,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > a,
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink {
  color: #FFFFFF;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.4;
  text-decoration: none;
}
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > a:hover,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > a:hover,
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink:hover,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink:hover {
  text-decoration: underline;
}
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > a.active,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > a.active,
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink.active,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink.active {
  cursor: default;
  text-decoration: underline;
}
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > a.active:hover,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > a.active:hover,
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink.active:hover,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink.active:hover {
  text-decoration: underline;
}
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > a .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > a .menu-item-inner,
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink .menu-item-inner {
  position: relative;
}
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > a .menu-item-inner:after,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > a .menu-item-inner:after,
.top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink .menu-item-inner:after,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink .menu-item-inner:after {
  content: ' ';
  position: absolute;
  bottom: -0.75em;
  left: 0;
  right: 0;
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper .menu .menu .menu > li > a .menu-item-inner:after,
  .mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > a .menu-item-inner:after,
  .top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink .menu-item-inner:after,
  .mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink .menu-item-inner:after {
    content: none;
  }
}
@media only screen and (min-width: 481px) {
  .top-level-nav .menu-block-wrapper .menu .menu .menu > li > a,
  .mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > a,
  .top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink,
  .mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink {
    padding: 0 0 0.8125rem 1.063rem;
  }
}
@media only screen and (max-width: 480px) {
  .top-level-nav .menu-block-wrapper .menu .menu .menu > li > a,
  .mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > a,
  .top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink,
  .mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li > .nolink {
    padding: 12px;
  }
}
.top-level-nav .menu-block-wrapper .menu .menu .menu > li.last > a,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li.last > a,
.top-level-nav .menu-block-wrapper .menu .menu .menu > li.last > .nolink,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li.last > .nolink {
  padding-bottom: 0;
}
.top-level-nav .menu-block-wrapper .menu .menu .menu > li:first-child:last-child > a .menu-item-inner:after,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li:first-child:last-child > a .menu-item-inner:after,
.top-level-nav .menu-block-wrapper .menu .menu .menu > li:first-child:last-child > .nolink .menu-item-inner:after,
.mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li:first-child:last-child > .nolink .menu-item-inner:after {
  content: ' ';
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper .menu .menu .menu > li:first-child:last-child > a .menu-item-inner:after,
  .mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li:first-child:last-child > a .menu-item-inner:after,
  .top-level-nav .menu-block-wrapper .menu .menu .menu > li:first-child:last-child > .nolink .menu-item-inner:after,
  .mobile-top-level-nav .menu-block-wrapper .menu .menu .menu > li:first-child:last-child > .nolink .menu-item-inner:after {
    content: none;
  }
}
.top-level-nav .menu-block-wrapper .tlAccSwitchWrap,
.mobile-top-level-nav .menu-block-wrapper .tlAccSwitchWrap,
.top-level-nav .menu-block-wrapper .slAccSwitchWrap,
.mobile-top-level-nav .menu-block-wrapper .slAccSwitchWrap {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.top-level-nav .menu-block-wrapper .tlAccSwitchWrap > *,
.mobile-top-level-nav .menu-block-wrapper .tlAccSwitchWrap > *,
.top-level-nav .menu-block-wrapper .slAccSwitchWrap > *,
.mobile-top-level-nav .menu-block-wrapper .slAccSwitchWrap > * {
  width: 100%;
}
.top-level-nav .menu-block-wrapper .tlAccSwitchWrap > a,
.mobile-top-level-nav .menu-block-wrapper .tlAccSwitchWrap > a,
.top-level-nav .menu-block-wrapper .slAccSwitchWrap > a,
.mobile-top-level-nav .menu-block-wrapper .slAccSwitchWrap > a {
  flex-shrink: 1;
}
.top-level-nav .menu-block-wrapper .tlAccSwitchWrap,
.mobile-top-level-nav .menu-block-wrapper .tlAccSwitchWrap {
  height: 100%;
}
.top-level-nav .menu-block-wrapper .tlAccSwitch,
.mobile-top-level-nav .menu-block-wrapper .tlAccSwitch,
.top-level-nav .menu-block-wrapper .slAccSwitch,
.mobile-top-level-nav .menu-block-wrapper .slAccSwitch {
  display: none;
  position: relative;
  width: 15px;
  margin-left: 5px;
  padding: 0;
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper .tlAccSwitch,
  .mobile-top-level-nav .menu-block-wrapper .tlAccSwitch,
  .top-level-nav .menu-block-wrapper .slAccSwitch,
  .mobile-top-level-nav .menu-block-wrapper .slAccSwitch {
    width: 23px;
  }
}
.top-level-nav .menu-block-wrapper .tlAccSwitch:before,
.mobile-top-level-nav .menu-block-wrapper .tlAccSwitch:before,
.top-level-nav .menu-block-wrapper .slAccSwitch:before,
.mobile-top-level-nav .menu-block-wrapper .slAccSwitch:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
}
@media only screen and (max-width: 480px) {
  .top-level-nav .menu-block-wrapper .tlAccSwitch:before,
  .mobile-top-level-nav .menu-block-wrapper .tlAccSwitch:before,
  .top-level-nav .menu-block-wrapper .slAccSwitch:before,
  .mobile-top-level-nav .menu-block-wrapper .slAccSwitch:before {
    position: absolute;
    top: 0;
    right: auto;
    bottom: 0;
    left: -50%;
    width: 200%;
  }
}
.top-level-nav .menu-block-wrapper .tlAccSwitch .text,
.mobile-top-level-nav .menu-block-wrapper .tlAccSwitch .text,
.top-level-nav .menu-block-wrapper .slAccSwitch .text,
.mobile-top-level-nav .menu-block-wrapper .slAccSwitch .text {
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;
  border-radius: 2000px;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
  background-color: transparent;
  background-image: url("../images/icons/dropdown-arrow--white.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 14px;
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale3d(1,1,1);
  transition: transform 700ms ease 0ms, background-color 150ms ease 0ms;
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper .tlAccSwitch .text,
  .mobile-top-level-nav .menu-block-wrapper .tlAccSwitch .text,
  .top-level-nav .menu-block-wrapper .slAccSwitch .text,
  .mobile-top-level-nav .menu-block-wrapper .slAccSwitch .text {
    background-size: 20px;
  }
}
.top-level-nav .menu-block-wrapper .tlAccSwitch:active .text,
.mobile-top-level-nav .menu-block-wrapper .tlAccSwitch:active .text,
.top-level-nav .menu-block-wrapper .slAccSwitch:active .text,
.mobile-top-level-nav .menu-block-wrapper .slAccSwitch:active .text,
.top-level-nav .menu-block-wrapper .tlAccSwitch:hover .text,
.mobile-top-level-nav .menu-block-wrapper .tlAccSwitch:hover .text,
.top-level-nav .menu-block-wrapper .slAccSwitch:hover .text,
.mobile-top-level-nav .menu-block-wrapper .slAccSwitch:hover .text {
  background-color: transparent;
}
@media only screen and (min-width: 961px) {
  .top-level-nav .menu-block-wrapper .tlAccSwitch.isOpen,
  .mobile-top-level-nav .menu-block-wrapper .tlAccSwitch.isOpen,
  .top-level-nav .menu-block-wrapper .slAccSwitch.isOpen,
  .mobile-top-level-nav .menu-block-wrapper .slAccSwitch.isOpen {
    background-color: transparent;
  }
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper .tlAccSwitch.isOpen .text,
  .mobile-top-level-nav .menu-block-wrapper .tlAccSwitch.isOpen .text,
  .top-level-nav .menu-block-wrapper .slAccSwitch.isOpen .text,
  .mobile-top-level-nav .menu-block-wrapper .slAccSwitch.isOpen .text {
    transform: translateX(-50%) translateY(-50%) scale3d(1, 1, 1) rotate(180deg);
    background-color: transparent;
  }
}
.top-level-nav .menu-block-wrapper .tlAccSwitch,
.mobile-top-level-nav .menu-block-wrapper .tlAccSwitch {
  display: block;
}
@media only screen and (max-width: 480px) {
  .top-level-nav .menu-block-wrapper .slAccSwitch,
  .mobile-top-level-nav .menu-block-wrapper .slAccSwitch {
    display: block;
    padding: 0;
    display: none;
  }
}
.top-level-nav .menu-block-wrapper > .menu,
.mobile-top-level-nav .menu-block-wrapper > .menu {
  letter-spacing: -1em;
  text-align: right;
  line-height: 0;
  font-size: 0;
}
.top-level-nav .menu-block-wrapper > .menu > *,
.mobile-top-level-nav .menu-block-wrapper > .menu > * {
  letter-spacing: normal;
  display: inline-block;
  vertical-align: middle;
}
.top-level-nav .menu-block-wrapper > .menu > *,
.mobile-top-level-nav .menu-block-wrapper > .menu > * {
  line-height: normal;
  font-size: 16px;
}
.top-level-nav .menu-block-wrapper > .menu > li > a,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > a,
.top-level-nav .menu-block-wrapper > .menu > li > .nolink,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink,
.top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a {
  position: relative;
}
@media only screen and (min-width: 961px) {
  .top-level-nav .menu-block-wrapper > .menu > li > a.active-trail:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a.active-trail:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .nolink.active-trail:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink.active-trail:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a.active-trail:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a.active-trail:before {
    content: ' ';
    height: 5px;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    display: block;
    position: absolute;
    width: 100%;
  }
}
@media only screen and (min-width: 961px) {
  .top-level-nav .menu-block-wrapper > .menu > li > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a {
    padding: 0 15px;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a {
    padding: 0 30px 0 15px;
  }
}
@media only screen and (min-width: 961px) {
  .top-level-nav .menu-block-wrapper > .menu > li .toggleSwitch,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li .toggleSwitch {
    margin-left: -15px;
  }
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li {
    display: block;
    position: static;
    padding: 0.31rem 0;
    border-bottom: 3px solid #FFFFFF;
  }
  .top-level-nav .menu-block-wrapper > .menu > li:last-child,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li:last-child {
    border: none;
  }
  .top-level-nav .menu-block-wrapper > .menu > li.leaf > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.leaf > a,
  .top-level-nav .menu-block-wrapper > .menu > li > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink {
    padding-left: 0;
  }
  .top-level-nav .menu-block-wrapper > .menu > li.leaf > a:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.leaf > a:before,
  .top-level-nav .menu-block-wrapper > .menu > li > a:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > a:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .nolink:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink:before {
    content: none;
  }
  .top-level-nav .menu-block-wrapper > .menu > li.expanded > a:after,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded > a:after,
  .top-level-nav .menu-block-wrapper > .menu > li.expanded > .nolink:after,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded > .nolink:after,
  .top-level-nav .menu-block-wrapper > .menu > li.expanded > .tlAccSwitchWrap > a:after,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded > .tlAccSwitchWrap > a:after,
  .top-level-nav .menu-block-wrapper > .menu > li.expanded > .tlAccSwitchWrap > .nolink:after,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded > .tlAccSwitchWrap > .nolink:after {
    content: none;
  }
}
@media only screen and (min-width: 961px) {
  .top-level-nav .menu-block-wrapper > .menu > li:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li:hover {
    background-color: #1374CA;
  }
  .top-level-nav .menu-block-wrapper > .menu > li:hover.active,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li:hover.active {
    border-top: 0;
  }
  .top-level-nav .menu-block-wrapper > .menu > li:hover.active > .tlAccSwitchWrap,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li:hover.active > .tlAccSwitchWrap {
    margin-top: 0;
  }
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu,
  .mobile-top-level-nav .menu-block-wrapper > .menu {
    text-align: inherit;
    line-height: normal;
    display: block;
    position: static;
    width: 100%;
  }
  .top-level-nav .menu-block-wrapper > .menu > *,
  .mobile-top-level-nav .menu-block-wrapper > .menu > * {
    display: block;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li > .menu,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu {
  position: absolute;
  top: 50px;
  bottom: auto;
  overflow: hidden;
  transform-origin: top center;
  z-index: 30;
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li > .menu,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu {
    position: static;
    height: auto;
    min-width: 0;
    background-color: #1374CA;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li {
    padding-top: 1.3rem;
    border-left: 3px solid #FFFFFF;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li:first-child,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li:first-child {
    border-top: none;
    padding-top: 0;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > .menu.isClosed,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu.isClosed {
    height: 0 !important;
    display: none;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li > .menu .menu,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu .menu {
  height: auto;
  max-height: none;
  opacity: 1;
  position: static;
  transform: none;
  margin-top: 1.063rem;
  margin-bottom: 1.063rem;
  border-left: 3px solid #FFFFFF;
}
.top-level-nav .menu-block-wrapper > .menu > li > .menu .menu.slAccordion,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu .menu.slAccordion {
  overflow: hidden;
}
@media only screen and (max-width: 480px) {
  .top-level-nav .menu-block-wrapper > .menu > li > .menu .menu.slAccordion,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu .menu.slAccordion {
    display: none;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li.expanded,
.mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded {
  position: static;
  transition: background-color 240ms ease 0ms;
}
.top-level-nav .menu-block-wrapper > .menu > li.expanded.tlAccOpen,
.mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded.tlAccOpen {
  background-color: #1374CA;
}
@media only screen and (min-width: 961px) {
  .top-level-nav .menu-block-wrapper > .menu > li.expanded.tlAccOpen,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded.tlAccOpen {
    background-color: transparent;
  }
  .top-level-nav .menu-block-wrapper > .menu > li.expanded.tlAccOpen:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded.tlAccOpen:hover {
    background-color: #1374CA;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu,
.mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1.125rem 75px 2.5rem;
  margin-left: -2px;
}
@media only screen and (min-width: 961px) {
  .top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu {
    max-height: 0;
    padding: 0;
    overflow: visible;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu > li,
.mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu > li {
  max-width: 270px;
  overflow: hidden;
  padding-right: 40px;
  padding-bottom: 20px;
  width: 50%;
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    padding-top: 6px;
    padding-bottom: 23px;
    display: block;
  }
  .top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu > li,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu > li {
    max-width: none;
    padding-left: 1.1rem;
  }
  .top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu > li .menu,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded > .menu > li .menu {
    display: none;
  }
}
@media only screen and (min-width: 961px) {
  .top-level-nav .menu-block-wrapper > .menu > li.expanded:hover > .menu,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.expanded:hover > .menu {
    max-height: 4000px;
    padding: 2.5rem 2.063rem 4.063rem;
    overflow: visible;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li.mobile-only,
.mobile-top-level-nav .menu-block-wrapper > .menu > li.mobile-only {
  display: none;
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li.mobile-only,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li.mobile-only {
    display: block;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li > a,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > a,
.top-level-nav .menu-block-wrapper > .menu > li > .nolink,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .nolink {
  display: block;
}
.top-level-nav .menu-block-wrapper > .menu > li > .menu,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu {
  background-color: transparent;
  background-clip: padding-box;
  padding: 1.75em 0 1.5em;
  width: 100%;
  margin: 0 auto;
  overflow: visible;
  background-color: #1374CA;
}
@media only screen and (min-width: 961px) {
  .top-level-nav .menu-block-wrapper > .menu > li > .menu,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu {
    max-width: 610px;
    padding: 0;
    max-height: 0;
    overflow: visible;
    opacity: 0;
    transform-origin: top center;
    transform: scale3d(1, 0, 1);
  }
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li > .menu,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu {
    padding: 0;
    border-top: none;
    box-shadow: none;
    transform: none;
    max-height: 4000px;
    opacity: 1;
    overflow: hidden;
    position: relative;
    top: auto;
    bottom: auto;
  }
}
@media only screen and (min-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink {
    color: #4d5a6b;
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 1.308;
    letter-spacing: .01rem;
    text-decoration: none;
    line-height: 1.8;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    max-height: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    color: #FFFFFF;
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.273;
    text-transform: none;
    letter-spacing: 0.004em;
    margin-bottom: 0;
    display: block;
    letter-spacing: 0.01em;
    padding: 0;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a .menu-item-inner,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a .menu-item-inner,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a .menu-item-inner,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a .menu-item-inner,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a .menu-item-inner,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a .menu-item-inner,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink .menu-item-inner,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink .menu-item-inner,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink .menu-item-inner,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink .menu-item-inner,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink .menu-item-inner,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink .menu-item-inner {
    position: relative;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink:hover {
    text-decoration: underline;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a .menu-item-inner:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a .menu-item-inner:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a .menu-item-inner:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a .menu-item-inner:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a .menu-item-inner:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a .menu-item-inner:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink .menu-item-inner:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink .menu-item-inner:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink .menu-item-inner:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink .menu-item-inner:before,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink .menu-item-inner:before,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink .menu-item-inner:before {
    content: none;
  }
}
@media only screen and (min-width: 960px) and only screen and (min-width: 481px) and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink {
    font-size: 16px;
    padding: .5rem 1rem;
  }
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink {
    color: #FFFFFF;
    font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.08em;
    line-height: 1.4;
    text-decoration: none;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink:hover {
    text-decoration: underline;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a.active,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a.active,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a.active,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a.active,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a.active,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a.active,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink.active,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink.active,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink.active,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink.active,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink.active,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink.active {
    cursor: default;
    text-decoration: underline;
  }
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a.active:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > a.active:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a.active:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > a.active:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a.active:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > a.active:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink.active:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .nolink.active:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink.active:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .tlAccSwitchWrap > .nolink.active:hover,
  .top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink.active:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu > li > .slAccSwitchWrap > .nolink.active:hover {
    text-decoration: underline;
  }
}
@media only screen and (min-width: 961px) {
  .top-level-nav .menu-block-wrapper > .menu > li:hover > .menu,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li:hover > .menu {
    max-height: 4000px;
    padding: 1.75em 0 1.5em;
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition: max-height 0ms ease 200ms, padding 0ms ease 200ms, transform 300ms ease 300ms, opacity 200ms ease 300ms;
  }
}
@media only screen and (max-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li:hover > .menu,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li:hover > .menu {
    border-top: none;
    box-shadow: none;
    transform: none;
    opacity: 1;
  }
}
.top-level-nav .menu-block-wrapper > .menu > li > .menu li > a .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu li > a .menu-item-inner,
.top-level-nav .menu-block-wrapper > .menu > li > .menu li > .nolink .menu-item-inner,
.mobile-top-level-nav .menu-block-wrapper > .menu > li > .menu li > .nolink .menu-item-inner {
  position: relative;
}
@media only screen and (min-width: 960px) {
  .top-level-nav .menu-block-wrapper > .menu > li:last-child,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li:last-child {
    border: 0;
    position: absolute;
    right: 0;
    margin-right: 80px;
  }
  .top-level-nav .menu-block-wrapper > .menu > li:last-child:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li:last-child:hover {
    background: #1890fb;
  }
  .top-level-nav .menu-block-wrapper > .menu > li:last-child a,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li:last-child a {
    border: 2px solid #FFFFFF;
    height: 30px;
    margin-top: 10px;
    border-radius: 100px;
  }
  .top-level-nav .menu-block-wrapper > .menu > li:last-child a:hover,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li:last-child a:hover {
    background: #FFFFFF;
  }
  .top-level-nav .menu-block-wrapper > .menu > li:last-child a:hover span,
  .mobile-top-level-nav .menu-block-wrapper > .menu > li:last-child a:hover span {
    color: #1890fb;
  }
}
.block-search-form {
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 961px) {
  .block-search-form {
    top: 8px;
    left: inherit;
  }
}
.block-search-form > .content {
  flex: 1 0 0%;
}
.block-search-form > .altSearchCloseButton {
  display: none;
  width: 48px;
  padding-left: 4px;
  padding-right: 12px;
  align-self: stretch;
  align-items: center;
  order: -10;
  justify-content: center;
}
@media only screen and (max-width: 480px) {
  .block-search-form > .altSearchCloseButton {
    display: flex;
  }
}
.block-search-form > .altSearchCloseButton .text {
  text-indent: -9999px;
  display: block;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  position: relative;
}
.block-search-form > .altSearchCloseButton .text:before,
.block-search-form > .altSearchCloseButton .text:after {
  content: ' ';
  height: 3px;
  width: 22px;
  border-radius: 2000px;
  background-color: #FFFFFF;
  transform-origin: center center;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
}
.block-search-form > .altSearchCloseButton .text:before {
  transform: translateY(-50%) rotate(45deg);
}
.block-search-form > .altSearchCloseButton .text:after {
  transform: translateY(-50%) rotate(-45deg);
}
.block-search-form > .altSearchCloseButton .text .text {
  white-space: nowrap;
  text-indent: -9999px;
  display: block;
  position: absolute;
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem;
}
.block-search-form form {
  position: relative;
}
.block-search-form form > div > .container-inline {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 5rem;
  background-color: #FFFFFF;
}
.block-search-form form > div > .container-inline.hasFocus {
  background-color: #FFFFFF;
}
.block-search-form form > div > .container-inline [class*="form-item-search-block-form"] {
  order: 2;
  flex: 1 0 auto;
}
.block-search-form form > div > .container-inline [class*="form-item-search-select"] {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.block-search-form form > div > .container-inline [class*="form-item-search-select"] select {
  flex: 0 0 auto;
  border: none;
  background-color: transparent;
  background-image: url("../images/icons/dropdown-arrow--gray.svg");
  background-position: right 1rem top 50%;
  background-size: 10px auto;
  padding: .75rem 1rem .5rem;
  padding-right: 2rem;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  font-size: .7rem;
  letter-spacing: .15em;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  height: 100%;
  line-height: 1;
}
.block-search-form [class*="form-item-search-block-form"] {
  display: block;
}
.block-search-form [class*="form-item-search-block-form"] input[type="text"] {
  padding: 4px;
  padding-left: 20px;
  padding-right: 74px;
  color: #555555;
  background-color: transparent;
  border: none;
  height: 33px;
  line-height: 1;
  transition: background-color 240ms ease 0ms;
}
.block-search-form [class*="form-item-search-block-form"] input[type="text"]::-webkit-input-placeholder {
  color: #555555;
  font-style: normal;
}
.block-search-form [class*="form-item-search-block-form"] input[type="text"]:-moz-placeholder {
  color: #555555;
  font-style: normal;
}
.block-search-form [class*="form-item-search-block-form"] input[type="text"]::-moz-placeholder {
  color: #555555;
  font-style: normal;
}
.block-search-form [class*="form-item-search-block-form"] input[type="text"]:-ms-input-placeholder {
  color: #555555;
  font-style: normal;
}
.block-search-form [class*="form-item-search-block-form"] input[type="text"]::-ms-clear {
  display: none;
}
@media only screen and (max-width: 480px) {
  .block-search-form [class*="form-item-search-block-form"] input[type="text"] {
    padding-right: 60px;
    color: #555555;
  }
  .block-search-form [class*="form-item-search-block-form"] input[type="text"]::-webkit-input-placeholder {
    color: #555555;
  }
  .block-search-form [class*="form-item-search-block-form"] input[type="text"]:-moz-placeholder {
    color: #555555;
  }
  .block-search-form [class*="form-item-search-block-form"] input[type="text"]::-moz-placeholder {
    color: #555555;
  }
  .block-search-form [class*="form-item-search-block-form"] input[type="text"]:-ms-input-placeholder {
    color: #555555;
  }
}
.block-search-form .form-actions {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  overflow: hidden;
  width: 64px;
}
.block-search-form .form-actions .form-submit {
  background-color: transparent;
  background-image: url("../images/icons/search-slate.svg");
  background-repeat: no-repeat;
  background-position: 50% 46%;
  background-size: 20px 20px;
  color: transparent;
  text-shadow: none;
  box-shadow: none;
  width: 100%;
  height: 40px;
  border: none;
}
@media only screen and (min-width: 961px) {
  .block-search-form .form-actions .form-submit {
    display: none !important;
  }
}
.block-search-form .form-actions .form-submit:hover {
  transform: none;
  background-image: url("../images/icons/search-red.svg");
}
.block-search-form.togglePane.hasToggle.hasClose {
  padding-right: 50px;
  right: 0;
}
@media only screen and (max-width: 480px) {
  .block-search-form.togglePane.hasToggle.hasClose {
    padding-right: 60px;
  }
}
.block-search-form.togglePane.hasToggle.hasClose .close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 50px;
  background-color: transparent;
  background-image: url("../images/icons/small-x--white.svg");
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 480px) {
  .block-search-form.togglePane.hasToggle.hasClose .close {
    width: 60px;
  }
}
.block-search-form.togglePane.hasToggle.hasClose .close .text {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: 0 !important;
  padding: 0 !important;
}
.block-mobile-search [class*="form-item-search-block-form"] input[type="text"] {
  text-transform: uppercase;
}
#logo {
  padding: .9375rem 0 1.25rem;
}
@media only screen and (min-width: 960px) {
  .front #logo {
    padding: 1.5rem 0 1.25rem;
  }
}
.node-type-listing-page #logo {
  padding: 1.7rem 0 3.4rem;
}
@media only screen and (max-width: 960px) {
  .node-type-listing-page #logo {
    padding: 0 0 1.6rem;
  }
}
.node-type-event #logo,
.node-type-landing-page #logo {
  padding: 1.7rem 0 6rem;
}
@media only screen and (max-width: 960px) {
  .node-type-event #logo,
  .node-type-landing-page #logo {
    padding: 0 0 1.6rem;
  }
}
@media only screen and (max-width: 480px) {
  .node-type-listing-page #logo {
    padding: 0 0 0.8rem;
  }
}
@media only screen and (max-width: 960px) {
  #logo {
    height: 100%;
    width: 100%;
    padding: 0 0 1.5rem;
    z-index: 41;
  }
}
@media only screen and (max-width: 480px) {
  #logo {
    padding: 0 0 1rem;
  }
}
#logo a {
  display: block;
  background-image: none;
  background-repeat: no-repeat;
  background-size: contain;
}
@media only screen and (max-width: 960px) {
  #logo a {
    display: flex;
    margin-left: 26px;
    margin-top: 26px;
  }
}
@media only screen and (max-width: 480px) {
  #logo a {
    margin-left: 14px;
    margin-top: 14px;
  }
}
#logo img {
  width: 436px;
  transform: translateY(-20px);
  max-width: 100%;
  opacity: 1;
}
@media only screen and (max-width: 960px) {
  #logo img {
    max-height: 125px;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 480px) {
  #logo img {
    max-width: 233px;
  }
}
.tabs-custom {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.tabs-custom .button-tray {
  margin: 0;
  padding: 1.35rem 2.2rem 1.7rem;
  background-color: #4d5a6b;
}
.tabs-custom .button-tray .button {
  background-color: #d9a70f;
  display: block;
  text-align: center;
  padding-top: 0.5rem;
  letter-spacing: 0.05rem;
}
.tabs-custom .button-tray .button:hover {
  background-color: #DC8802;
}
.tabs-custom .button-tray .button:active {
  background-color: #DC5502;
}
.tabs-custom > .button-tray {
  display: none;
}
@media only screen and (max-width: 580px) {
  .tabs-custom {
    display: block;
  }
  .tabs-custom > .button-tray {
    display: block;
  }
}
.tabs,
.tab-bar {
  list-style: none;
  padding: 0;
  flex-direction: column;
  background: #E6E6E6;
  padding-left: 0;
}
.tabs li,
.tab-bar li {
  margin: 0;
}
.tabs li:before,
.tab-bar li:before {
  content: none;
}
.block-event-tabs .tabs,
.block-event-tabs .tab-bar,
.tabs-custom .tabs,
.tabs-custom .tab-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
}
@media only screen and (max-width: 700px) {
  .block-event-tabs .tabs,
  .block-event-tabs .tab-bar,
  .tabs-custom .tabs,
  .tabs-custom .tab-bar {
    flex-direction: column;
  }
}
.block-event-tabs .tabs > .tab,
.block-event-tabs .tab-bar > .tab,
.tabs-custom .tabs > .tab,
.tabs-custom .tab-bar > .tab {
  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: 0;
  border-left: 3px solid #FFFFFF;
  flex: 1 1 0;
}
.block-event-tabs .tabs > .tab li,
.block-event-tabs .tab-bar > .tab li,
.tabs-custom .tabs > .tab li,
.tabs-custom .tab-bar > .tab li {
  margin: 0;
}
.block-event-tabs .tabs > .tab li:before,
.block-event-tabs .tab-bar > .tab li:before,
.tabs-custom .tabs > .tab li:before,
.tabs-custom .tab-bar > .tab li:before {
  content: none;
}
.block-event-tabs .tabs > .tab.first,
.block-event-tabs .tab-bar > .tab.first,
.tabs-custom .tabs > .tab.first,
.tabs-custom .tab-bar > .tab.first {
  border: none;
}
@media only screen and (max-width: 700px) {
  .block-event-tabs .tabs > .tab,
  .block-event-tabs .tab-bar > .tab,
  .tabs-custom .tabs > .tab,
  .tabs-custom .tab-bar > .tab {
    width: 100%;
    flex: 1 0 100%;
    border-top: 3px solid #FFFFFF;
    border-left: none;
  }
  .block-event-tabs .tabs > .tab.first,
  .block-event-tabs .tab-bar > .tab.first,
  .tabs-custom .tabs > .tab.first,
  .tabs-custom .tab-bar > .tab.first {
    border: none;
  }
}
.block-event-tabs .tabs > .tab.selected,
.block-event-tabs .tab-bar > .tab.selected,
.tabs-custom .tabs > .tab.selected,
.tabs-custom .tab-bar > .tab.selected {
  background-color: #1890fb;
}
.block-event-tabs .tabs > .tab.selected a,
.block-event-tabs .tab-bar > .tab.selected a,
.tabs-custom .tabs > .tab.selected a,
.tabs-custom .tab-bar > .tab.selected a {
  color: #FFFFFF;
}
.block-event-tabs .tabs > .tab a,
.block-event-tabs .tab-bar > .tab a,
.tabs-custom .tabs > .tab a,
.tabs-custom .tab-bar > .tab a {
  color: #1890fb;
  display: block;
  padding: 1.875rem 0;
}
.block-event-tabs .tabs .button-tray,
.block-event-tabs .tab-bar .button-tray,
.tabs-custom .tabs .button-tray,
.tabs-custom .tab-bar .button-tray {
  padding: 0.625rem 1.375rem 2.7rem;
}
.views-widget-filter-field_focus_areas_tid label,
.views-widget-filter-field_news_categories_tid label {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  color: #4d5a6b;
  padding-bottom: 1rem;
  text-transform: uppercase;
}
.views-widget-filter-field_focus_areas_tid .bef-select-as-links > .form-item > .form-item,
.views-widget-filter-field_news_categories_tid .bef-select-as-links > .form-item > .form-item {
  border-top: 3px solid #FFFFFF;
  background: #E6E6E6;
}
.views-widget-filter-field_focus_areas_tid .bef-select-as-links > .form-item > .form-item a,
.views-widget-filter-field_news_categories_tid .bef-select-as-links > .form-item > .form-item a {
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
  line-height: 1.389;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.01rem;
  margin-bottom: 0;
  margin-top: 1.6rem;
  display: block;
  padding: 1.875rem;
  margin-top: 0;
  color: #1890fb;
}
.views-widget-filter-field_focus_areas_tid .bef-select-as-links > .form-item > .form-item a:first-child,
.views-widget-filter-field_news_categories_tid .bef-select-as-links > .form-item > .form-item a:first-child {
  margin-top: 0;
}
.views-widget-filter-field_focus_areas_tid .bef-select-as-links > .form-item > .form-item a.active,
.views-widget-filter-field_news_categories_tid .bef-select-as-links > .form-item > .form-item a.active {
  background-color: #1890fb;
  color: #FFFFFF;
  text-decoration: none;
}
.views-widget-filter-field_focus_areas_tid .button-tray,
.views-widget-filter-field_news_categories_tid .button-tray {
  padding: 0.625rem 1.375rem 2.7rem;
}
.block-views-exp-news-news-list .js-hide {
  display: none !important;
}
.block-views-exp-grantees-grantees-list form {
  margin: 0 -5rem;
}
@media only screen and (max-width: 960px) {
  .block-views-exp-grantees-grantees-list form {
    margin: 0 -4rem;
  }
}
@media only screen and (max-width: 480px) {
  .block-views-exp-grantees-grantees-list form {
    margin: 0 -0.75rem 1rem;
  }
}
.block-views-exp-grantees-grantees-list .views-exposed-widget.views-widget-filter-field_focus_areas_tid {
  width: 100%;
}
.block-views-exp-grantees-grantees-list .views-exposed-widget.views-widget-filter-field_focus_areas_tid .bef-select-as-links > .form-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.block-views-exp-grantees-grantees-list .views-exposed-widget.views-widget-filter-field_focus_areas_tid .bef-select-as-links > .form-item > .form-item {
  width: 33.333%;
}
.block-views-exp-grantees-grantees-list .views-exposed-widget.views-widget-filter-field_focus_areas_tid .bef-select-as-links > .form-item > .form-item:nth-child(3n+1),
.block-views-exp-grantees-grantees-list .views-exposed-widget.views-widget-filter-field_focus_areas_tid .bef-select-as-links > .form-item > .form-item:nth-child(3n+2) {
  border-right: 3px solid #FFFFFF;
}
@media only screen and (max-width: 480px) {
  .block-views-exp-grantees-grantees-list .views-exposed-widget.views-widget-filter-field_focus_areas_tid .bef-select-as-links > .form-item > .form-item.form-type-bef-link {
    width: 100%;
    border: none;
    border-bottom: 3px solid #FFFFFF;
  }
}
.block-views-exp-grantees-grantees-list .views-exposed-widget.views-widget-filter-field_focus_areas_tid .bef-select-as-links > .form-item > .form-item a {
  height: 100%;
}
.tab-switch {
  zoom: 1;
  display: block;
  border-left: 6px solid transparent;
  padding: 0.625rem 1.375rem .8125rem;
}
.tab-switch:before,
.tab-switch:after {
  content: " ";
  display: table;
}
.tab-switch:after {
  clear: both;
}
.tab-switch * {
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.273;
  text-transform: none;
  letter-spacing: 0.004em;
  margin-bottom: 0;
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
.tab-switch:hover {
  text-decoration: none;
}
.tab-switch:focus,
.tab-switch.selected {
  outline: none;
}
.tab-switch.selected {
  background-color: #FFFFFF;
  color: #1890fb;
  border-color: #1890fb;
}
.tab-switch.selected,
.tab-switch.selected:hover {
  cursor: default;
}
.tab-switch.selected * {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  color: inherit;
}
.tabs-inner {
  background-color: #FFFFFF;
  padding: 1.875rem 3.125rem ;
  flex-basis: 100%;
}
@media only screen and (max-width: 960px) {
  .tabs-inner {
    padding: 2.175rem 2.5rem 1.875rem;
  }
}
@media only screen and (max-width: 580px) {
  .tabs-inner {
    width: 100%;
    padding: 0;
    display: block;
  }
}
.tabs-inner .tab-title {
  display: none;
}
.tabs-inner .tab-title:hover {
  text-decoration: none;
}
.tabs-inner .tab-title > * {
  margin: 0;
  color: inherit;
}
@media only screen and (max-width: 580px) {
  .tabs-inner .tab-title {
    display: block;
    color: #FFFFFF;
    background: #4d5a6b;
    position: relative;
  }
  .tabs-inner .tab-title h3 {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.273;
    text-transform: none;
    letter-spacing: 0.004em;
    margin-bottom: 0;
    color: #FFFFFF;
    padding: .75rem;
  }
  .tabs-inner .tab-title .toggleSwitch {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
  }
  .tabs-inner .tab-title .toggleSwitch .text {
    position: relative;
    text-indent: -9999px;
    display: block;
    height: 100%;
  }
  .tabs-inner .tab-title .toggleSwitch .text:after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    left: auto;
    height: 1em;
    width: 1rem;
    background-image: url("../images/icons/dropdown-arrow--white.svg");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 12px 12px;
    transform: translateY(-50%) scale3d(1,1,1);
    transition: transform 240ms ease 0ms;
  }
  .tabs-inner .tab-title .toggleSwitch.isOpen .text:after {
    transform: translateY(-50%) scale3d(1,-1,1);
  }
}
@media only screen and (min-width: 960px) {
  .tabs-inner .field-research-link-sets > .field-contents ~ .field-contents {
    padding-top: 2.6rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .tabs-inner .field-research-link-sets > .field-contents ~ .field-contents {
    padding-top: 2.4rem;
  }
}
@media only screen and (max-width: 580px) {
  .tabs-inner .field-research-link-sets > .field-contents:last-child {
    padding-bottom: 1.75rem;
  }
}
.tabs-inner .start-research-link-set {
  color: #666666;
}
@media only screen and (max-width: 580px) {
  .tabs-inner .start-research-link-set {
    padding: 1.5rem .65rem .25rem;
  }
}
.tabs-inner .start-research-link-set > .ds-second {
  columns: 2;
  column-gap: 60px;
}
.tabs-inner .start-research-link-set > .ds-second .field-research-links > .field-contents {
  display: inline-block;
  vertical-align: top;
  line-height: initial;
  font-size: initial;
  padding-bottom: 1.5rem;
}
@media only screen and (max-width: 1100px) {
  .tabs-inner .start-research-link-set > .ds-second {
    columns: 1;
    column-gap: 60px;
  }
  .tabs-inner .start-research-link-set > .ds-second .field-research-links > .field-contents {
    display: inline-block;
    vertical-align: top;
    line-height: initial;
    font-size: initial;
    padding: 0 0 1.85rem;
  }
  .tabs-inner .start-research-link-set > .ds-second .field-research-links > .field-contents:last-child {
    padding-bottom: 0;
  }
  .tabs-inner .start-research-link-set > .ds-second .field-research-links > .field-contents {
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  .tabs-inner .start-research-link-set > .ds-second {
    columns: 1;
    column-gap: 60px;
  }
  .tabs-inner .start-research-link-set > .ds-second .field-research-links > .field-contents {
    display: inline-block;
    vertical-align: top;
    line-height: initial;
    font-size: initial;
    padding: .4rem 0 1.6rem;
    max-width: 100%;
  }
  .tabs-inner .start-research-link-set > .ds-second .field-research-links > .field-contents:last-child {
    padding-bottom: 0;
  }
  .tabs-inner .start-research-link-set > .ds-second .field-research-links > .field-contents {
    display: block;
  }
}
.tabs-inner .start-research-link-set > .ds-third {
  margin-top: 1rem;
}
@media only screen and (max-width: 960px) {
  .tabs-inner .start-research-link-set > .ds-third {
    margin-top: 1.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .tabs-inner .start-research-link-set > .ds-third {
    margin: 2rem 0 .5rem;
  }
}
.tabs-inner .start-research-link-set .field-link {
  line-height: 1.35;
  margin-bottom: .25rem;
}
.tabs-inner .start-research-link-set .field-link a {
  display: block;
}
.tabs-inner .start-research-link-set .field-research-more-link a {
  display: block;
  color: #666666;
  font-weight: 600;
}
.tab-pane {
  display: none;
}
.tab-pane.isActive {
  display: block;
}
#backtotop {
  display: none;
  position: fixed;
  bottom: 20px;
  z-index: 1;
  width: 100%;
}
#backtotop .text {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999px;
}
#backtotop .text:before {
  content: ' ';
  display: block;
  height: 45px;
  width: 45px;
  position: absolute;
  top: auto;
  right: 15px;
  bottom: 100%;
  left: auto;
  cursor: pointer;
  background-image: url("../images/icons/back-to-top.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.section-content {
  min-height: calc(100vh - 500px);
}
.section-footer {
  color: #FFFFFF;
  background-color: #1890fb;
}
@media only screen and (min-width: 961px) {
  .section-footer .region-footer-first .footer-support-ask h3 {
    margin-bottom: 0;
  }
  .section-footer .region-footer-first .footer-support-ask p {
    padding-top: 1.25rem;
    margin-top: 0;
  }
  .section-footer .region-footer-first .region-footer-first-inner {
    display: flex;
    justify-content: space-between;
  }
  .section-footer .region-footer-first .region-footer-first-inner .footer-support-ask {
    z-index: 2;
    width: 70%;
    padding-right: 3rem;
    text-align: left;
  }
  .section-footer .region-footer-first .region-footer-first-inner .footer-support-link {
    z-index: 2;
    margin: auto 0;
  }
  .section-footer .region-footer-first .background {
    position: relative;
  }
  .section-footer .region-footer-first .background:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    content: ' ';
    background: #4d5a6b;
    opacity: .9;
    mix-blend-mode: multiply;
  }
  .section-footer .region-footer-second-inner {
    display: flex;
    align-items: center;
  }
  .section-footer .region-footer-second-inner .region-blockgroup-footer-row-1 {
    flex: 1 100%;
    order: 2;
    justify-content: flex-end;
  }
  .section-footer .region-footer-second-inner .region-blockgroup-footer-row-2 {
    flex: 1 100%;
    order: 1;
  }
  .section-footer .region-blockgroup-footer-row-1-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu {
    flex: 1 0 100%;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper .menu {
    list-style: none;
    padding: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper .menu li {
    margin: 0;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper .menu li:before {
    content: none;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper .menu > li {
    max-width: 300px;
    margin-left: 1.25rem;
    flex: 0 1 auto;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-social {
    flex: 1 0 100%;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-social .content {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-social .content > a {
    height: 1.5rem;
    margin: 1.25rem;
    flex: 0 1 auto;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-pba-link {
    text-align: right;
    flex: 1 0 100%;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-pba-link .content .bell-link {
    justify-content: flex-end;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-signature {
    max-width: 450px;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-signature img {
    max-height: 100px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .section-footer .region-footer-first .footer-support-ask h3 {
    margin-bottom: 0;
  }
  .section-footer .region-footer-first .footer-support-ask p {
    padding-top: 1.25rem;
    padding-bottom: 2.25rem;
    margin-top: 0;
  }
  .section-footer .region-blockgroup-footer-row-1-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu {
    flex: 0 1 80%;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper .menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper .menu li {
    margin: 0;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper .menu li:before {
    content: none;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper > .menu {
    list-style: none;
    padding: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper > .menu li {
    margin: 0;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper > .menu li:before {
    content: none;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper > .menu > li {
    max-width: 300px;
    flex: 0 1 auto;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-social {
    flex: 0 1 20%;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-social .content {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-social .content > a {
    height: 1.5rem;
    margin-left: 1.25rem;
    flex: 0 1 auto;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-pba-link {
    margin: 1rem 0;
    flex: 1 0 100%;
  }
  .section-footer .region-blockgroup-footer-row-2-inner {
    text-align: center;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-social {
    margin-bottom: 3.5rem;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-social .content {
    display: flex;
    max-width: 300px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-social .icon {
    margin: 0 10px;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-signature {
    max-width: 450px;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-signature img {
    max-height: 100px;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-legal .content > *:first-child {
    margin-bottom: .875rem;
  }
}
@media only screen and (max-width: 480px) {
  .section-footer .menu-block-wrapper .menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .section-footer .menu-block-wrapper .menu li {
    margin: 0;
  }
  .section-footer .menu-block-wrapper .menu li:before {
    content: none;
  }
  .section-footer .menu-block-wrapper > .menu > li > .menu {
    display: none;
  }
  .section-footer .region-footer-first .block > .content > * {
    text-align: center;
  }
  .section-footer .region-footer-first .footer-support-ask h3 {
    margin-bottom: 0;
  }
  .section-footer .region-footer-first .footer-support-ask p {
    padding-top: 1.25rem;
    padding-bottom: 2.25rem;
    margin-top: 0;
  }
  .section-footer .region-blockgroup-footer-row-1-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu {
    flex: 1 0 100%;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper .menu {
    list-style: none;
    padding: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper .menu li {
    margin: 0;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper .menu li:before {
    content: none;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-menu .menu-block-wrapper .menu > li {
    max-width: 300px;
    flex: 0 1 auto;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-social {
    flex: 1 0 100%;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-social .content {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-social .content > a {
    height: 1.5rem;
    margin: 1.25rem;
    flex: 0 1 auto;
  }
  .section-footer .region-blockgroup-footer-row-1-inner .footer-pba-link {
    margin-bottom: 1.25rem;
    flex: 1 0 100%;
  }
  .section-footer .footer-contact,
  .section-footer .footer-address {
    margin-bottom: 2.85rem;
  }
  .section-footer .region-blockgroup-footer-row-2-inner {
    text-align: center;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-social {
    margin-bottom: 3.5rem;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-social .content {
    display: flex;
    max-width: 300px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-social .icon {
    margin: 0 10px;
  }
  .section-footer .region-blockgroup-footer-row-2-inner .footer-legal .content > *:first-child {
    margin-bottom: .875rem;
  }
  .section-footer .footer-signature .content {
    position: relative;
    display: inline-block;
    padding-bottom: 2rem;
    vertical-align: middle;
  }
  .section-footer .footer-signature .content img {
    transition: all 240ms ease 0ms;
    position: relative;
    z-index: 1;
    width: auto;
    height: 77px;
    opacity: 0;
  }
  .section-footer .footer-signature .content:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 240ms ease 0ms;
    z-index: 0;
    width: 100%;
    height: 80px;
    content: ' ';
    background-color: transparent;
    background-image: url(../images/icons/PBF_Tertiary_White_Mobile.svg);
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
  }
}
.section-footer * {
  color: inherit;
}
.section-footer .region-footer-first {
  text-align: center;
}
.section-footer .region-footer-first .block > .content h4 {
  line-height: 1.4;
  color: #FFFFFF;
}
.section-footer .footer-subscribe .subscribe-wrapper > .manual-form #subscribe-form input[type="text"] {
  padding: 0.625rem 1.3rem 0.5rem;
  font-weight: 600;
}
.section-footer .footer-subscribe .subscribe-wrapper > .manual-form #subscribe-form input[type="submit"] {
  min-width: 100px;
  padding: 0.45rem 1em 0.45rem;
  color: #FFFFFF;
  background-color: #1890fb;
  border-width: 3px;
}
.section-footer .footer-subscribe .subscribe-wrapper > .manual-form #subscribe-form input[type="submit"]:hover {
  background-color: #DC5502;
  border-color: #DC5502;
}
.section-footer .footer-social .icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.section-footer .footer-social .icon img {
  transition: all 240ms ease 0ms;
  position: relative;
  z-index: 1;
  width: auto;
  height: 1.5rem;
  opacity: 1;
}
.section-footer .footer-social .icon:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 240ms ease 0ms;
  z-index: 0;
  content: ' ';
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
}
.section-footer .footer-social .icon.facebook-link:before {
  background-image: url("../images/icons/facebook-reversed--red.svg");
}
.section-footer .footer-social .icon.twitter-link:before {
  background-image: url("../images/icons/twitter-share--red.svg");
}
.section-footer .footer-social .icon.linkedin-link:before {
  background-image: url("../images/icons/iconmonstr-linkedin--red.svg");
}
.section-footer .footer-social .icon:hover img {
  opacity: 0;
}
.section-footer .footer-social .icon:hover:before {
  opacity: 1;
}
.section-footer .footer-social .icon:hover:active {
  opacity: .5;
}
.section-footer .footer-copyright * {
  font-size: 0.75rem;
  line-height: 1.9;
  letter-spacing: 0.025em;
}
.section-footer .footer-copyright *:hover {
  color: #FFFFFF;
}
.section-footer .region-footer-second h4 {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: .12em;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  line-height: 1.4;
}
@media only screen and (max-width: 480px) {
  .section-footer .region-footer-second h4 {
    margin-bottom: 1.25rem;
  }
}
.section-footer .menu-block-wrapper .menu a {
  font-size: 16px;
  font-weight: 400;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: .59rem 0;
  line-height: 1.6;
}
@media only screen and (max-width: 480px) {
  .section-footer .menu-block-wrapper .menu a {
    padding: 0;
  }
}
.section-footer .menu-block-wrapper .menu a a[href*='tel'] {
  margin-right: .25rem;
}
.section-footer .menu-block-wrapper .menu a a:hover {
  color: #E6E6E6;
  text-decoration: underline;
}
.section-footer .menu-block-wrapper .menu a a.active {
  color: #1890fb;
  text-decoration: underline;
}
.section-footer .menu-block-wrapper > .menu > li > a {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: .12em;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}
.section-footer .footer-pba-link .content .bell-link {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.section-footer .footer-pba-link .content .bell-link.icon img {
  padding-right: 0.625rem;
}
.section-footer .footer-pba-link .content .bell-link .PhilBar {
  color: #4d5a6b;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1.308;
  text-transform: none;
  letter-spacing: .01rem;
  color: #FFFFFF;
}
.js_hide {
  display: none !important;
}
.js_no-admin-margin {
  padding-top: 0 !important;
}
.js_no-admin-margin:before {
  display: none !important;
}
.hide-me-always {
  display: none !important;
}
