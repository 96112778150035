
// Donation Feature (commerce)
// ================================

.page-donate {
  .section-header {
    .zone-branding-wrapper {
      .zone-branding {
        background: @brand-primary;
        .region-branding-inner {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          position: relative;
          padding: 0 2.063rem;
          min-height: 246px;
          .resp(@mobilemax, {
            padding: 0 1rem;
          });

          > * {
            display: block;
          }
          #logo {
            .resp-min(@tabletmax + 1, {
              padding: 2.1rem 0 3.25rem;
            });
            a {
              margin-left: 0;
              img {
                max-width: 100%;
                max-height: 77px;
                width: inherit;
                .resp(@mobilemax, {
                  max-height: 50px;
                });
              }
            }
          }
        }
      }
    }
    .donate-header {
      .title {
        color: @white;
        font-size: 40px;
        .resp(@tabletmax, {
          font-size: 27px;
        });
      }
      .blurb {
        .h4;
        color: @white;
        margin-bottom: 1rem;
        a {
          .font-smoothing;
          color: @white;
          text-decoration: underline;
        }
      }
      .small-print {
        .h6;
        color: @white;
      }
      margin-bottom: 8.2rem;
    }
  }
  .zone-preface {
    display: none;
  }
  .zone-content {
    margin-top: -83px;
    #messages {
      margin-top: 83px;
    }
  }
}


.crm-core-profile-entry-form {
  // See: /donate-now

  > div {
    letter-spacing: -1em;
    > * { letter-spacing: normal; }
  }

  > div > [class*="field-type"],
  > div >  [class*="form-item"][class*="text"],
  > div > [class*="credit-card-expiration"],
  > div >  [class*="form-item"][class*="select"],
  > [class*="field-"][class*="phone"] {

  }

  > div > [class*="individual-contact-name"],
  > div .field-type-addressfield > [id*="customer-address"] > .street-block,
  > div .field-type-addressfield > [id*="customer-address"] > [class*="form-item"],
  > div .field-type-addressfield > [id*="customer-address"] > [class*="addressfield-container"] > [class*="form-item"],
  > div >  [class*="form-item"][class*="credit-card"]  {

  }

  > div > [class*="field-type"][class*="addressfield"] {

  }

  h2 {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid @hr-color;
    display: block;
    &:first-child { margin-top: 0; }
  }

  [id*="customer-address"] > label:first-child {
    display: block;
    font-style: italic;
    font-weight: normal;
  }

  .amounts {
    letter-spacing: -1em;
    margin: 0 -4px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      display: inline-block;
      vertical-align: middle;
      width: 16.666%;
      min-width: 100px;

      //width: auto;
      //min-width: 100px;
      flex: 1;

      .resp(@mobilemax, {
        flex-basis: 50%;
      });
    }
    > select {
      .element-invisible;
    }

    input[type="button"] {
      padding: 10px;
      display: inline-block;
      box-sizing: border-box;
      border-style: solid;
      border-color: transparent !important;
      border-width: 4px;
      background-clip: padding-box;
      color: @white !important;
      background-color: @button-color;
      box-shadow: none !important;

      &:focus {
        transform: none;
        outline: none;
      }

      &.selected {
        background-color: @blue;
        color: @white !important;
      }
    }

    #donation-other-amount {
      display: inline-block;
      box-sizing: border-box;
      box-shadow: none;
      border: 4px solid transparent;
      background-clip: padding-box;
      background-repeat: no-repeat !important;
      background-position: 12px center !important;
      background-image: @dollar-sign--brightblue !important;
      background-size: 1em 1em;
      padding-left: 30px;
      outline: 1px solid @input-border-color;
      outline-offset: -5px;
    }
  }


  // Designation
  // -----------
  .field-name-field-cmcd-designation-honor-mem,
  .field-name-field-cmcd-designation-honoree,
  .field-name-field-cmcd-designation-notify,
  .field-name-field-cmcd-designation-email {
    &[style*="block"] {
      .__half-width() !important;

      .resp(@mobilemax, {
        width: 100% !important;
      });
    }
  }
      // Left
  .field-name-field-cmcd-designation-honor-mem,
  .field-name-field-cmcd-designation-notify {
    .__p-right();
    .resp(@mobilemax, { padding-right: 0; });
  }
      // Right
  .field-name-field-cmcd-designation-honoree,
  .field-name-field-cmcd-designation-email {
    .__p-left();
    .resp(@mobilemax, { padding-left: 0; });
  }



  // Your Information
  // -----------

  .form-item-crm-core-contact-individual-contact-name-given,
  .form-item-crm-core-contact-individual-contact-name-family {
    .__half-width() !important;

    .resp(@mobilemax, {
      width: 100% !important;
    });
  }
      // Left
  .form-item-crm-core-contact-individual-contact-name-given {
    .__p-right();
    .resp(@mobilemax, { padding-right: 0; });
  }
      // Right
  .form-item-crm-core-contact-individual-contact-name-family {
    .__p-left();
    .resp(@mobilemax, { padding-left: 0; });
  }


  // Partner/Spouse
  // -----------

  .form-wrapper[class*="partner"][class*="fname"],
  .form-wrapper[class*="partner"][class*="lname"] {
    &[style*="block"] {
      .__half-width() !important;

      .resp(@mobilemax, {
        width: 100% !important;
      });
    }
  }
      // Left
  .form-wrapper[class*="partner"][class*="fname"] {
    .__p-right();
    .resp(@mobilemax, { padding-right: 0; });
  }
      // Right
  .form-wrapper[class*="partner"][class*="lname"] {
    .__p-left();
    .resp(@mobilemax, { padding-left: 0; });
  }


  // Address
  // -----------

  .field-name-commerce-customer-address {
    margin-top: 30px;
  }

  .street-block {
    letter-spacing: -1em;
    > * { letter-spacing: normal; }

    .form-item[class*="customer-address"][class*="thoroughfare"],
    .form-item[class*="customer-address"][class*="premise"] {
      .__half-width() !important;

      .resp(@mobilemax, {
        width: 100% !important;
      });
    }

    // Left
    .form-item[class*="customer-address"][class*="thoroughfare"] {
      .__p-right();
      .resp(@mobilemax, { padding-right: 0; });
    }
    // Right
    .form-item[class*="customer-address"][class*="premise"] {
      .__p-left();
      .resp(@mobilemax, { padding-left: 0; });
    }
  }

  .locality-block {
    letter-spacing: -1em;
    > * { letter-spacing: normal; }

    .form-item[class*="customer-address"][class*="locality"],
    .form-item[class*="customer-address"][class*="administrative-area"],
    .form-item[class*="customer-address"][class*="postal-code"] {
      .__half-width() !important;

      .resp(@mobilemax, {
        width: 100% !important;
      });
    }
        // Left
    .form-item[class*="customer-address"][class*="locality"],
    .form-item[class*="customer-address"][class*="postal-code"] {
      .__p-right();
      .resp(@mobilemax, { padding-right: 0; });
    }
        // Right
    .form-item[class*="customer-address"][class*="administrative-area"] {
      .__p-left();
      .resp(@mobilemax, { padding-left: 0; });
    }
  }



  // Credit card
  // -----------

  #payment-details {

    letter-spacing: -1em;
    > * { letter-spacing: normal; }

    .form-item[class*="payment-details"][class*="credit-card-type"],
    .form-item[class*="payment-details"][class*="credit-card-number"],
    .commerce-credit-card-expiration,
    .form-item[class*="payment-details"][class*="credit-card-code"] {
      .__half-width() !important;

      .resp(@mobilemax, {
        width: 100% !important;
      });
    }

        // Left
    .form-item[class*="payment-details"][class*="credit-card-type"],
    .commerce-credit-card-expiration {
      .__p-right();
      .resp(@mobilemax, { padding-right: 0; });
    }
        // Right
    .form-item[class*="payment-details"][class*="credit-card-number"],
    .form-item[class*="payment-details"][class*="credit-card-code"] {
      .__p-left();
      .resp(@mobilemax, { padding-left: 0; });
    }

  }

  .commerce-credit-card-expiration {
    padding-top: 20px;
    position: relative;

    > .form-item,
    > .commerce-credit-card-expiration {
      .__m-top;
    }

    .form-type-select,
    .form-type-textfield {
      .__auto-width;
      vertical-align: bottom;
    }

    .commerce-month-year-divider {
      color: transparent;
      text-indent: -9999px;
      height: 100%;
      display: inline-block;
      padding: 9px 4px 8px;
      position: relative;
      margin: 0 8px 0 6px;

      &:after {
        content: ' ';
        position: absolute;
        top: 10%;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%) rotate(15deg);
        width: 2px;
        background-color: @input-border-color;
      }
    }

    .form-item[class*="credit-card-type"] {
      .__p-right;
    }
    .form-item[class*="credit-card-number"] {
      .__p-left;
    }
    .form-item[class*="credit-card-exp-month"],
    .form-item[class*="credit-card-code"] {
      white-space: nowrap;
      margin-bottom: 0;

      label {
        margin-right: 8px;
        position: absolute;
        top: 0;
        left: 0;
      }
      label,
      select,
      .form-text {
        display: inline-block;
        vertical-align: middle;
        width: auto;
      }
    }
    .form-item[class*="credit-card-exp-year"] {
      margin-bottom: 0;
    }
    > .commerce-credit-card-expiration {
      display: inline-block;
      margin-top: 24px;
      .__p-right;
    }
    .form-item[class*="credit-card-code"] {
      .__p-left;
    }

  }

  .form-actions {

    input[type="submit"] {
      .button;
      display: block;
      width: 100%;
      margin-top: 50px;
      .resp(@mobilemax, {
        margin-right: 0;
        display: block;
        width: 100%;
      });
    }
  }
}

// See: /donate
#ma-donate-donate-form {
  //.card-style-post();
  position: relative;
  // margin-top: -83px;
  background: @white;
  z-index: 47;

  .messages {
    margin: -2.1rem -2rem;
    margin-bottom: 2rem;
    &.error {
      margin: 0;
      margin-bottom: 1rem;
      #bt-message {
        font-size: initial;
      }
    }
  }

  .fieldset-wrapper {
    font-size: 0;
    > .form-item {
      .__m-top();

      &[class*="conditional"] {
        + .form-item[style*="display"],
        + .form-wrapper[style*="display"] {
          margin-top: -.5rem;
          margin-bottom: 2rem;
        }

        + .form-wrapper[style*="display"] {
          .fieldset-wrapper > .form-item:first-child { margin-top: 0; }
        }
      }
    }
    .form-wrapper#edit-payment--2 {
      #edit-number {
        width: 50%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        #bt-wrapper { // Credit Card Number
          //width: 50%;
        }
        #edit-cvv {
          float: right;
          .resp(@mobilemax, {
            margin-top: -1rem;
            margin-bottom: 2rem;
          });
        }
        #edit-expiration,
        #edit-cvv {
          width: 48%;
          display: inline-block;
          .resp(@mobilemax, {
            display: block;
            float: left;
            width: 75%;
          });
        }
        .resp(@tabletmax, {
          width: 100%;
        });
      }
    }
  }

  .captcha.form-wrapper {

    legend,
    .fieldset-description {
      .sr-only();
    }

  }

  .g-recaptcha {
    margin-bottom: 40px;
  }

  /* --- [ Donation specific ] --------------- */

  .form-radios[id*="donation-amount"] {
    font-size: 0;
    margin: 0;
    margin-top: 2rem;
    .resp(@mobilemax, {
      margin: 1rem -.5rem 0;
    });
  }
  .form-item[id*="edit-header"],
  .fieldset-legend {
    .h3;
    text-transform: none;
  }
  .form-item[id*="edit-subheader"] {
    .h4;
    color: @brand-primary;
  }
  .form-item[class*="donation-type"] {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    margin-bottom: 0;
    label {
      display: none;
    }
    > .form-radios {
      display: flex;
    }
    input {
      + label {
        text-align: center;
        background-color: @neutrallight;
        padding: 2rem;
        height: 83px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:before { content: none; }
        .text {
          margin: 0 auto;
        }
      }
      &:checked + label {
        cursor: default;
        color: @gold;
        border-top: 10px solid @gold;
        .text {
          margin-top: -10px;
        }
        background: @white;
      }
    }
  }
  .form-item[class*="donation-amount"] {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    &.form-type-radio {
      padding-right: 1.4rem;
    }
    .resp(@tabletmax, {
      //width: 100%;
      .form-radios {
        margin-top: 0;
        .form-type-radio {
          width: 48%;
          padding: .5rem;
        }
      }
    });
    .edit-donation-amount-once,
    .edit-donation-amount-monthy {
      padding: 0;
      margin-top: 0;
    }
    &.form-item-donation-amount-other {
      flex: 1 auto;
      min-width: 200px;
      max-width: 300px;
      margin-bottom: -1.5rem;
      .resp(@tabletmax, {
        width: 100%;
        max-width: inherit;
        padding-bottom: 1.5rem;
      });
      .icon-inline-after(@dollar, .8rem);
      &:after {
        left: 10px;
        top: 51%;
      }
      &.checked-dollar:after {
        background-image: @dollar--white;
      }
      .form-number {
        max-height: 42px;
        display: block;
        cursor: pointer;
        padding: .5rem 1.2rem;
        .h5;
        color: inherit;
        .text {
          .h5;
          color: inherit;
        }
        .button-donate();
        &.checked {
          background: @brand-bold;
          color: @white;
          border-color: @brand-bold;
          .trans;
        }
        &:hover {
          border-color: @brand-bold;
        }
        &::placeholder {
          font-style: normal;
        }
        text-align: left;
        padding-left: 20px;
      }
      .resp(@tabletmax, {
        &:after {
          top: 39%;
        }
      });
      padding-right: 0;
    }
    .resp(@tabletmax, {
      text-align: center;
      .form-item[class*="donation-amount"] {
        margin-right: 0;
        width: 50%;
        padding: .5rem 1rem;
      }
    });
    input {
      + label {
        display: block;
        cursor: pointer;
        padding: .5rem 1.2rem;
        &:before { content: none; }
        .text {
          .h5;
          color: inherit;
        }
        .button-donate();
      }
      &:checked + label {
        .button-donate-cta();
        display: block;
        cursor: default;
      }
    }
    input[type="text"] {
      border-radius: 5rem;
      border-width: 3px;
    }
  }
  .form-item[class*="display-name"] {
    margin-bottom: 2.5rem;
  }
  .form-item[class*="donor-information"] {
    &[class*="first-name"],
    &[class*="last-name"],
    &[class*="email-address"],
    &[class*="street-address"],
    &[class*="city"],
    &[class*="state"],
    &[class*="phone"] {
      .__half-width();
      .resp(@tabletmax, {
        .__three-fifths-width();
      });
      .resp(@mobilemax, {
        .__full-width();
      });
    }
    &[class*="middle-initial"],
    &[class*="suffix"],
    &[class*="zip"] {
      .__one-fifth-width();
      .resp(@goldilocks, {
        .__two-fifths-width();
      });
      .resp(@mobilemax, {
        .__full-width();
      });
    }

    &[class*="email-address"],
    &[class*="city"] {
      display: block;
    }

    &[class*="first-name"],
    &[class*="last-name"],
    &[class*="email-address"],
    &[class*="street-address"],
    &[class*="city"],
    &[class*="state"],
    &[class*="phone"] {
      .resp-min(@mobilemax + 1, {
        .__p-right();
      });
    }

    &[class*="middle-initial"],
    &[class*="suffix"],
    &[class*="zip"] {
      .resp-min(@mobilemax + 1, {
        .__p-left();
      });
    }
  }

  .form-item[class*="CurrentEmployer"] {
    margin-bottom: 1.6rem;
  }
  > div > .form-wrapper {
    .emboss-body;
    text-align: inherit;
    margin-top: 3rem;
    padding: 2.6rem 3rem;
    &#edit-donation-amount {
      margin-top: 0;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
    }
    .resp(@tabletmax, {
      padding: 2.6rem 3rem;
      &#edit-donor-billing-wrap {
        box-shadow: none;
        padding: 0;
        margin-top: 0;
        > .form-wrapper {
          .emboss-body;
          text-align: inherit;
          margin-top: 2rem;
          padding: 2rem;
        }
      }
    });
    .resp(@mobilemax, {
      padding: .7rem 1rem;
      &#edit-donor-billing-wrap {
        > .form-wrapper {
          padding: .7rem 1rem;
        }
      }
    });
    .resp-min(@tabletmax + 1, {
      #edit-donor-information,
      #edit-billing-address {
        width: 50%;
        display: inline-block;
        vertical-align: top;
      }
      #edit-donor-information {
        padding-right: 3rem;
        border-right: 4px solid @neutrallight;
      }
      #edit-billing-address {
        padding-left: 3rem;
      }
    });
  }

  #edit-submitted[style*="display"][style*="none"] ~ .form-actions {
    margin-top: -.5rem;
    padding-top: 0;
  }
}

// Donation overrides for Equal Justice Center
// (EJC) specific page
// ================================

.page-donate-1478,
.page-node-380 {
  .section-header {
    .zone-menu-wrapper {
      background-color: #2cbfb1;
    }
    .zone-branding-wrapper .zone-branding {
      background: #2cbfb1;
    }
    .donate-header {
      margin-top: 4rem;
    }
  }

  #ma-donate-donate-form {
    .form-item[class*="donation-type"] input:checked + label {
      color: #f75c03;
      border-top: 10px solid #f75c03;
    }
    .form-item[class*="donation-amount"] input:checked + label {
      background-color: #f75c03;
      border-color: #f75c03;
    }

    input[type="submit"] {
      background-color: #0a6788;

      &:hover {
        background-color: darken(#0a6788, 5%);
      }
    }
  }

  .section-footer {
    background-color: #0a6788;
  }
}

.page-node-380 {
  .donate-header {
    margin-bottom: 4rem;
    .title {
      display: none;
      visibility: none;
    }
  }
  .button-cta {
    background-color: #0a6788;
    &:hover {
      background-color: darken(#0a6788, 5%);
    }
  }
}

#edit-submit-once {
  font-size: 1rem;
  color: @darkgrey;
  letter-spacing: .02em;
  font-weight: 600;
}
