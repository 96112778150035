// Views
// ================================

/* --- [ Mixins ] --------------- */

.two-cols--left-aside() {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  > .view-filters {
    width: 31.8%;
    padding-right: 2.75rem;
    min-width: 250px;
  }
  > .view-content {
    width: 68.2%;
    margin-top: -.375rem;
    animation: fadein 1s;
  }
  .resp(@tabletmax, {
    flex-wrap: wrap;
    > .view-filters,
    > .view-content {
      width: 100%;
      padding: 0;
    }

    > .view-content {
      margin-top: 2.225rem;
    }
  });
  .resp(@mobilemax, {
     > .view-content {
      margin-top: 1.425rem;
    }
  });
}

.views-rows-ruled(@row_el: ~'.views-row', @padding:1.4rem, @margin:@padding) {
  ~ @{row_el} {
    border-top: 1px solid @hr-color;
    padding-top: @padding;
    margin-top: @margin;
  }
}



/* --- [ Blog ] --------------- */

.view-blog-posts {
  // See: /
  &.view-display-id-blog_homepage {

  }

  // See: /research/databases/example-database
  &.view-display-id-blog_by_database {
    > .view-content {
      color: @medium-text-color;
      .aside-list(~".views-row");

      .views-field-title,
      .views-field-created {
        display: inline;

        a {
          display: inline;
          margin-right: .4rem;
        }
      }
      .views-field-created {
        position: relative;
        top: 1px;
        .bullet-before(.4rem);
      }
    }
  }


  &.view-display-id-blog, // See: /blog
  &.view-blog-posts[class*="-page"] // See: /blog/user/9
  {
    margin-left: -.875rem;
    margin-right: -.875rem;
    .resp-min(@tabletmax + 1, {
      margin-right: -.7rem;
      margin-left: .8rem;
    });
    .view-content.masonry-processed {
      .unbullet();
      position: relative;
      margin-bottom: -1.75rem;

      > .masonry-item {
        padding: 0 .875rem 1.75rem;
        width: 50%;
        .resp(@goldilocks, { width: 100%; });
      }
    }
  }
}


// Landings

.view-menu {
  // See: /ex-research  (landing page)
  &.view-display-id-landing_page_eva {
    > .view-content > .view-grouping {
      //.views-rows-ruled(~".view-grouping");
      ~ .view-grouping {
        padding-top: 1.5rem;
      }

      > .view-grouping-header {
        > h3 {
          .block-title-underlined();
          .h2();
          .resp(@tabletmax, {
            .h2();
          });
          .resp(@mobilemax, {
            .h2--small;
            // MAKE THIS H2 SMALL !!!!!!
            font-size: @h2-small-size;
            line-height: @h2-small-line-height;
          });
        }
      }

      > .view-grouping-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        // See: /cle (About)
        // If not second level grouped
        > .views-row {
          flex: 0 1 auto;
          padding-right: 60px;
          width: 50%;
          &.menu-link-1366 {
            .link;
          }

          .resp(@baby_bear, {
            flex: 1 1 100%;
            width: 100%;
            padding-right: 0;
          });

          .h4();
          padding-bottom: 1.5rem
        }

        .group-header {
            flex: 1 0 100%;
          ~ * {
            margin-top: 1.5rem;
          }
          h4 {
            font-weight: normal;
            font-size: @large-copy-size;
            line-height: @large-copy-line-height;
          }
        }
        .group-content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;

          .views-row[class*="menu-link"] {
            flex: 0 1 auto;
            padding-right: 60px;
            width: 50%;

            .resp(@baby_bear, {
              flex: 1 1 100%;
              width: 100%;
              padding-right: 0;
            });

            .h4();
            padding-bottom: 1.5rem
          }
        }
      }
    }
  }
}

//news Homepage
.node-homepage.view-mode-full {
  .section-heading {
    letter-spacing: .15rem;
    margin-bottom: 1rem;
  }
}

.view-taxonomy-term,
.view-news {
  .view-content {
    .views-row {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      border-bottom: 2px solid @neutrallight;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .more-link {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.view-grantees,
.view-events {
  .grid {
    .grid-container {
      .views-row {
        display: inline-flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 1;
        vertical-align: top;
        height: 211px;
        width: 33.33%;
        .node-teaser {
          flex: 1;
          max-height: 195px;
          height: 100%;
          > a {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
        }
        .resp-min(@tabletmax, {
          padding-top: 1rem;
          padding-left: .5rem;
          padding-right: .5rem;
          &:nth-child(3n+1){
            padding-left: 0;
            float: left;
          }
          &:nth-child(3n+3){
            padding-right: 0;
            float: right;
          }
        });
        .resp(@tabletmax, {
          width: 50%;
          padding-top: 0;
          margin-bottom: 1rem;
          height: 230px;
          .node-teaser {
            max-height: 230px;
          }
          &.views-row-odd {
            padding-right: .5rem;
            padding-left: 0;
            float: left;
          }
          &.views-row-even {
            padding-left: .5rem;
            padding-right: 0;
            float: right;
          }
        });
        .resp(@mobilemax, {
          &.views-row-even,
          &.views-row-odd {
            padding-right: 0;
            padding-left: 0;
          }
          width: 100%;
          padding-bottom: 0;
        });
      }
    }
  }
}
.view-events.view-display-id-events_upcoming {
  .grid {
    .grid-container {
      .views-row {
        .node-teaser {
          max-height: none;
        }
        &.views-row-first {
          width: 66.6%;
          height: 422px;
          float: left;
          .node-event .background {
            width: 100%;
          }
          .field-title {
            h2 {
              .h2;
              color: @white;
              margin-bottom: 0;
              letter-spacing: .17rem;
            }
          }
          .field {
            padding-bottom: 2rem;
          }
        }
        .field {
          padding-bottom: 1rem;
        }
        .resp-min(@tabletmax, {
          &.views-row-first {
            padding-bottom: 0;
            padding-top: 0;
            padding-right: .5rem;
          }
          &.views-row-2 {
            padding-bottom: .5rem;
            padding-top: 0;
            padding-left: .5rem;
            padding-right: 0;
            float: right;
          }
          &.views-row-3 {
            padding-top: .5rem;
            padding-left: .5rem;
            padding-right: 0;
            float: right;
          }
        });
        .resp(@tabletmax, {
          &.views-row-even {
            float: left;
            padding-left: 0;
            padding-right: 0.5rem;
          }
          &.views-row-odd {
            float: right;
            padding-right: 0;
            padding-left: 0.5rem;
          }
          &.views-row-first {
            width: 100%;
            padding-left: 0;
            height: 421px;
          }
        });
        .resp(@mobilemax, {
          &.views-row-even,
          &.views-row-odd {
            padding-right: 0;
            padding-left: 0;
          }
        });
      }
    }
  }
}
.view-events.view-display-id-events_past {
  .view-output & {
    margin-top: -1rem;
  }
}
.view-people {
  .grid {
    .group-header {
      > * {
        .h2;
        border-bottom: 4px solid @neutrallight;
        padding-bottom: 0.8125rem;
        letter-spacing: 0.19rem;
        margin-bottom: 0.83em;
      }
    }
    .group-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 -1rem;
      .views-row {
        padding: 0 1rem 3.5rem;
        //display: inline-block;
        &.views-row-odd {
          //padding-right: 3.5rem;
        }
        &.views-row-even {
        }
        .resp(@mobilemax, {
          width: 100%;
        });
      }
    }
  }
}

.view-sponsors {
  .view-content {
    .grid-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1rem;
      .views-row {
        padding: 0 1rem 3.5rem;
        //display: inline-block;
        &.views-row-odd {
          //padding-right: 3.5rem;
        }
        &.views-row-even {
        }
      }
    }
  }
}

.view-event-registrations {
  .ajax-progress {
    .hide-me-always;
  }
  .views-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2.813rem;
    caption {
      .h3;
      text-align: left;
      padding-bottom: 1rem;
      border-bottom: 4px solid @neutrallight;
    }
    .field-title {
      color: @brand-primary;
    }
    tr td {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      padding-left: 0;
      padding-right: 0;
      border-bottom: 2px solid @neutrallight;
      .p;
      .commerce-add-to-cart {
        > div > * ~ * {
          margin-top: 0;
        }
        .form-item-quantity {
          width: 55px;
          position: inherit;
          display: inline-block;
          margin-right: 20px;
          margin-bottom: 0;
          input[type="text"] {
            text-align: center;
          }
        }
      }
      &:first-child {
        width: 60%;
      }
      &:last-child {
        text-align: right;
      }
      .resp(@tabletmax, {
        width: 100%;
        text-align: left;
        border-bottom: none;
        padding: 0;
        margin-top: .5rem;
        &:first-child {
          width: 100%;
          text-align: left;
          padding-left: 0;
        }
        &:last-child {
          text-align: left;
          padding-left: 0;
          border-bottom: 2px solid @neutrallight;
          .form-item-quantity {
            margin-right: 15px;
          }
        }
      });
      input[type="submit"] {
        background-color: @button-color;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
        &:hover {
          background-color: @button-hover-color;
        }
        &:active {
          background-color: @button-active-color;
        }
      }
    }
    tr:last-child td {
      border-bottom: none;
    }
  }
}

.view-footer {
  margin-bottom: 2rem;
  > .button {
   margin-top: 2rem;
  }
}
