#logo {
  padding: .9375rem 0 1.25rem;
  .resp-min(@tabletmax, {
    .front & {
      padding: 1.5rem 0 1.25rem;
    }
  });
  .node-type-listing-page & {
    padding: 1.7rem 0 3.4rem;
  }
  .resp(@tabletmax, {
    .node-type-listing-page & {
      padding: 0 0 1.6rem;
    }
  });
  .node-type-event &,
  .node-type-landing-page & {
    padding: 1.7rem 0 6rem;
  }
  .resp(@tabletmax, {
    .node-type-event &,
    .node-type-landing-page & {
      padding: 0 0 1.6rem;
    }
  });
  .resp(@mobilemax, {
    .node-type-listing-page & {
      padding: 0 0 0.8rem;
    }
  });
  .resp(@tabletmax, {
    height: 100%;
    width: 100%;
    padding: 0 0 1.5rem;
    z-index: 41;
  });
  .resp(@mobilemax, {
    padding: 0 0 1rem;
  });
  a {
    display: block;
    background-image: none;
    background-repeat: no-repeat;
    background-size: contain;
    .resp(@tabletmax, {
      display: flex;
      margin-left: 26px;
      margin-top: 26px;
    });
    .resp(@mobilemax, {
      margin-left: 14px;
      margin-top: 14px;
    });
  }
  img {
    // width: auto;
    // max-height: 77px;
    // For 60th anniversary logo (PBFSD-178):
    width: 436px;
    transform: translateY(-20px);
    // -------------------------------

    max-width: 100%;
    opacity: 1;
    .resp(@tabletmax, {
      // max-height: none;
      // For 60th anniversary logo (PBFSD-178):
      max-height: 125px;
      // -------------------------------
      width: 100%;
      height: 100%;
    });
    .resp(@mobilemax, {
      max-width: 233px;
    });
  }
}
