
/* --- [ Typography ] --------------- */

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400italic,600,700,700italic);
//////////// GOOD PLACE FOR A PX TO REM ////////////


// Font Families
// ================================

@sourcesans: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;

@display-font: @sourcesans;
@heading-font: @sourcesans;
@base-font: @sourcesans;


// Font Mixins
// ================================
.font(@font-class, @weight, @size: true) {
  font-family: @font-class;
  & when (@weight = 'black'), (@weight = 900) {
    font-weight: 900;
  }
  & when (@weight = 'bold'), (@weight = 700) {
    font-weight: 700;
  }
  & when (@weight = 'semi-bold'), (@weight = 600) {
    font-weight: 600;
  }
  & when (@weight = 'normal'), (@weight = 400) {
    font-weight: 400;
  }
  & when (@weight = 'light'), (@weight = 300) {
    font-weight: 300;
  }
  & when (@weight = 'thin'), (@weight = 200) {
    font-weight: 200;
  }
  & when not (@size) {
    // When size is supplied
    // See: < http://lesscss.org/features >
    // "Any value other than the keyword true is falsy"
    font-size: @size;
  }
}



// Base Font size
// ================================

//@import '../vendor/modular-scale';

@baseline: 1rem;

@base-font-size: 16px;
@base-line-height: 1.4;

@large-copy-size: 1.125rem; //18px
@large-copy-line-height: 1.389;

@mobile-base-font-size: 12px;
@mobile-base-line-height: 1.4;

@caption-font-size: @h6-size;
@caption-line-height: 1.6;

@fine-print-size: .75rem;
@fine-print-line-height: 1.9;
@fine-print-letter-spacing: .025em;

@fine-title-size: 1.025rem;
@fine-title-line-height: 1.5;
@fine-title-letter-spacing: 0.04em;


// Headings
// ================================
//
@h1-size: 3rem; // 48px
@h1-line-height: .979;
@h1-small-size: 2.25rem; // 36px
@h1-small-line-height: 1.167;

@page-title-size: @h1-size;
@page-title-line-height: @h1-line-height;

@h2-size: 1.9375rem; // 31px
@h2-line-height: 1.290;
@h2-small-size: 1.6875rem; // 27px
@h2-small-line-height: 1.556;

@h3-size: 1.625rem; // 26px
@h3-line-height: 1.154;
@h3-small-size: 1.25rem; // 20px

@h4-size: 1.375rem; // 22px
@h4-line-height: 1.273;

@h5-size: 1rem; // 16px
@h5-line-height: 1.25;

@h6-size: 0.8125rem; // 13px
@h6-line-height: 1.308;

@bullet-font-size: @h3-size;
@bullet-line-height: 1;
